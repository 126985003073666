import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-list-widget',
  templateUrl: './partner-list-widget.component.html',
  styleUrls: ['./partner-list-widget.component.css']
})
export class PartnerListWidgetComponent implements OnInit {

  @Input()
  partners = [];

  @Input()
  showDegreeOfResponsability = false;
  
  constructor() { }

  ngOnInit(): void {
  }

}
