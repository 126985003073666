import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ApplCodeForm, BankAccountForm, CommAddressForm, OrganisationForm, OrganisationService, ParameterForm, PartnerIdentifierForm, PartnerPartnerForm, PartnerRoleForm, PostalAddressForm } from 'src/app/core';
import { EntityEnum } from 'src/app/core/entity-enum';
import { AlertService } from 'src/app/core/service/alert.service';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import { BlockService } from 'src/app/core/service/block.service';
import * as _ from 'underscore';
import { BaseWizardComponent } from '../../base/base-wizard/base-wizard.component';
import { Editor } from '../../editors/editor';

@Component({
  selector: 'app-organisation-wizard',
  templateUrl: './organisation-wizard.component.html',
  styleUrls: ['./organisation-wizard.component.css']
})
export class OrganisationWizardComponent extends BaseWizardComponent implements OnInit, Editor {

  entityEnum = EntityEnum.Organisation;

  @Input() id: number;
  @Input() object: OrganisationForm;
  @ViewChild('form') clrForm: ClrForm;

  cdSectors$: Observable<ApplCodeForm[]>;

  partnerRoles: PartnerRoleForm[];
  partnerRolesValid = true;

  parameters: ParameterForm[];
  parametersValid = true;
  hideParameterStep = false;

  identifiers: PartnerIdentifierForm[];
  identifiersValid = true;

  postalAddresses: PostalAddressForm[];
  addressesValid = true;

  commAddresses: CommAddressForm[];
  commAddressesValid = true;

  accounts: BankAccountForm[];
  accountsValid = true;

  partnerPartners: PartnerPartnerForm[];
  partnerPartnersValid = true;

  form: UntypedFormGroup;

  constructor(
    private codeService: AppCodeService,
    private organisationService: OrganisationService,
    private translateService: TranslateService,
    alertService: AlertService,
    blockService: BlockService
  ) {
    super(blockService, alertService);

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(''),
      nameShort: new UntypedFormControl(),
      legalEntity: new UntypedFormControl(),
      cdSector: new UntypedFormControl()
    });
  }

  ngOnInit(): void {
    this.newEntity = !this.id;
    this.cdSectors$ = this.codeService.getList('cd_sector', false);

    if (this.object) {
      this.form.patchValue(this.object);
    }
    this.form.markAllAsTouched();
    this.loadData();
  }

  loadData() {
    if (!this.id) {
      // tslint:disable-next-line:no-angle-bracket-type-assertion
      this.object = {} as OrganisationForm;
      this.parameters = [];
      this.partnerRoles = [];
      this.identifiers = [];
      this.postalAddresses = [];
      this.commAddresses = [];
      this.accounts = [];
      this.partnerPartners = [];
      return;
    }
    this.organisationService.findByIdOrganisation(this.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: OrganisationForm) => {
        this.object = response;
        this.parameters = response.parameters;
        this.partnerRoles = response.partnerRoles;
        this.identifiers = response.partnerIdentifiers;
        this.postalAddresses = response.postalAddresses;
        this.commAddresses = response.commAddresses;
        this.accounts = response.accounts;
        this.partnerPartners = response.partnersRight;
        this.form.patchValue(this.object);
        this.evaluateWizardPagesCompleted();
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  protected onSubmit(): Observable<any> {
    if (this.form.invalid) {
      this.clrForm.markAsTouched();
      return;
    }
    let organisation = this.form.value as OrganisationForm;

    organisation = _.extendOwn(this.object, organisation);
    organisation.parameters = this.parameters;
    organisation.partnerRoles = this.partnerRoles;
    organisation.postalAddresses = this.postalAddresses;
    organisation.commAddresses = this.commAddresses;
    organisation.accounts = this.accounts;
    organisation.partnersRight = this.partnerPartners;
    organisation.partnerIdentifiers = this.identifiers;
    // tslint:disable-next-line:max-line-length
    const save$ = !organisation.id ? this.organisationService.createOrganisation(organisation) : this.organisationService.updateOrganisation(organisation);
    return save$;
  }
}
