import { Component, Input, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { LangChangeEvent } from '@ngx-translate/core';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { PageProcessHeaderView, ProcessHeaderSearchCriteria, ProcessheaderService } from 'src/app/core';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { BaseComponent } from 'src/app/shared/base/base/base.component';

export enum ProcessesWidgetMode {
  Own,
  Overdue
}

@Component({
  selector: 'app-processes-widget',
  templateUrl: './processes-widget.component.html',
  styleUrls: ['./processes-widget.component.css']
})
export class ProcessesWidgetComponent extends BaseComponent implements OnInit {

  @Input() mode: ProcessesWidgetMode = ProcessesWidgetMode.Own;

  loading = true;

  pageProcessHeaders: PageProcessHeaderView = {};
  criteria: ProcessHeaderSearchCriteria = {
    page: 0,
    pageSize: 10,
    sortColumn: 'reference',
    cdStatuses: 'cd_processh_status_enter_prov,cd_processh_status_in_progress'
  };

  constructor(
    private languageService: LanguageService,
    private processHeaderController: ProcessheaderService,
    private authenticationService: AuthenticationService,
  ) {
    super();
  }

  ngOnInit() {
    this.languageService.onLangChange().pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe((event: LangChangeEvent) => {
      this.refreshContext();
    });

    if (this.mode === ProcessesWidgetMode.Own) {
      this.authenticationService.auhtenticationInfo.pipe(
        takeUntil(this.ngUnsubscribe),
        map(ai => ai?.partnerId),
        filter(item => item != null)
      ).subscribe(partnerId => {
        this.criteria.partnerId = partnerId;
        this.loadData();
      });
    } else {
      this.criteria.overdueOnly = true;
      this.loadData();
    }
  }

  refresh(state: ClrDatagridStateInterface) {
    this.loading = true;

    if (state.sort
      && typeof state.sort.by === 'string') {
      this.criteria.sortColumn = state.sort.by;
      this.criteria.sortReverse = state.sort.reverse;
    }

    this.criteria.page = state.page.current - 1;
    this.criteria.pageSize = state.page.size;
    this.loadData();
  }


  loadData(): void {
    this.loading = true;
    this.processHeaderController.searchProcessHeaders(this.criteria).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response) => {
        this.pageProcessHeaders = response;
        this.loading = false;
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  refreshContext(): void {
    const tempProcessHeaders = this.pageProcessHeaders.content;
    this.pageProcessHeaders.content = [];
    this.pageProcessHeaders.content = tempProcessHeaders;
  }

}
