import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { TreatmentForm } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sunet-treatment-form',
  templateUrl: './sunet-treatment-form.component.html',
  styleUrls: ['./sunet-treatment-form.component.css']
})
export class SunetTreatmentFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() treatment: TreatmentForm;
  @Input() required = false;
  @Output() treatmentValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() treatmentChange: EventEmitter<TreatmentForm> = new EventEmitter<TreatmentForm>();

  countries$: any;

  constructor(
    private codeService: AppCodeService,
  ) {
    super();

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.maxLength(50)]),
      title: new UntypedFormControl(''),
      street: new UntypedFormControl('', [Validators.maxLength(32)]),
      postCode: new UntypedFormControl('', [Validators.maxLength(10)]),
      city: new UntypedFormControl('', [Validators.maxLength(30)]),
      cdCountry: new UntypedFormControl('cd_country_switzerland'),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    console.log('onInit');
    if (this.treatment) {
      this.form.patchValue(this.treatment);
    }

    this.countries$ = this.codeService.getList('cd_country', false);

    if (this.disabled) {
      this.form.disable();
    }
    this.form.markAllAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.treatment)) {
      this.form.patchValue(this.treatment);
      this.form.markAllAsTouched();
    }
  }

  onChange(): void {
    this.treatmentValid.emit(this.form.valid);
    const modified = _.extend(this.treatment, this.form.value);
    this.treatmentChange.emit(modified);
    console.log('changed');
  }
}
