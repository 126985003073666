<clr-datagrid>
    <clr-dg-column>{{ 'field.relationship' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.partner' | translate }}</clr-dg-column>
    <clr-dg-column *ngIf="showDegreeOfResponsability">{{ 'field.degree_of_responsability' | translate }}</clr-dg-column>

    <clr-dg-row *clrDgItems="let par of partners">
        <clr-dg-cell>{{ par.relationshipType | lookupRelationshipType | async }}</clr-dg-cell>
        <clr-dg-cell>
            <a [routerLink]="['/admin/partner-detail', par.partnerId]">{{ par.partnerName }}</a>
            <app-contact [email]="par.partnerEmail" [phone]="par.partnerPhone"></app-contact>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="showDegreeOfResponsability">{{ par.degreeOfResponsability }}</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
</clr-dg-footer>
</clr-datagrid>
