import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.css']
})
export class BaseFormComponent extends BaseComponent {

  @Input() disabled = false;
  @Input() readonly = false;

  form: UntypedFormGroup;

  markRequired(expression: boolean): string {
    return expression ? ' *' : '';
  }

}

