import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppCodeService } from '../service/app-code.service';
import { LanguageService } from '../service/language.service';

@Pipe({
  name: 'lookupApplCode'
})

@Injectable()
export class LookupApplCodePipe implements PipeTransform {
  constructor(
    private codeService: AppCodeService,
    private languageService: LanguageService
  ) {
  }

  transform(id: string): Observable<string> {
    if (!id) {
      return of('');
    }

    return this.codeService.getById(id).pipe(
      take(1),
      map(applCode => {
        let name = id;
        if (applCode != null) {
          name = this.languageService.getLanguageSpecifcValueFromApplText(applCode.applText, 'textLong');
        }
        return name;
      })
    );
  }
}
