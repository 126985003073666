<mat-card>
    <div class="form-container">
        <form>
            <div class="clr-flex-row">
                    <mat-form-field class="clr-col-8">
                        <mat-label>Choose a date</mat-label>
                        <input matInput [matDatepicker]="picker">
                        <mat-datepicker-toggle matSuffix [for]="picker">
                            <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                        </mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
            </div>
        </form>
    </div>
</mat-card>