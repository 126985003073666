<table class="table">
    <thead>
        <tr>
            <th class="left" translate>field.object</th>
            <th class="left"translate>field.type</th>
            <th class="left"translate>field.nr</th>
            <th class="left"translate>field.title</th>
            <th class="left" translate>field.amount_net</th>
            <th class="left" translate>field.amount_gross</th>
            <th class="left" translate>field.tax</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let fl of rows" [ngClass]="{'headerLine': fl.lineType === 'header', 'totalLine': fl.lineType === 'total'}">
            <td class="left">{{ translations['financial_view.' + fl.lineType] }}</td>
            <td class="left">{{ fl.type | lookupApplType | async }}</td>
            <td class="left" *ngIf="fl.processHeaderId"><a [routerLink]="['/processes/detail', fl.processHeaderId]">{{ fl.position }}</a></td>
            <td class="left" *ngIf="!fl.processHeaderId">{{ fl.position}}</td>
            <td class="left">{{ fl.title}}</td>
            <td class="right">{{ fl.amountNet | number: '1.2-2'}}</td>
            <td class="right">{{ fl.amountGross| number: '1.2-2'}}</td>
            <td class="right">{{ fl.tax | number: '1.2-2'}}</td>
        </tr>
    </tbody>
</table>