import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ProcessHeaderForm, ProcessheaderService } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { ValidationHelperService } from 'src/app/core/service/validation-helper.service';
import { ReferenceExistsValidator } from 'src/app/core/validator/reference-exists-validator';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';


@Component({
  selector: 'app-process-header-lite-form',
  templateUrl: './process-header-lite-form.component.html',
  styleUrls: ['./process-header-lite-form.component.css']
})
export class ProcessHeaderLiteFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() processHeader: ProcessHeaderForm;
  @Input() allowSelectCase = false;
  @Output() processHeaderValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() processHeaderChange: EventEmitter<ProcessHeaderForm> = new EventEmitter<ProcessHeaderForm>();

  cdStatuses$: Observable<any[]>;
  cdLanguages$: Observable<any[]>;

  caseId: number;

  constructor(
    private codeService: AppCodeService,
    private processHeaderService: ProcessheaderService,
    private validationHelperService: ValidationHelperService,
    private languageService: LanguageService,
    private fb: UntypedFormBuilder,
  ) {
    super();
    this.form = this.fb.group({
      type: this.fb.control('', [Validators.required]),
      cdStatus: this.fb.control('', [Validators.required]),
      reference: this.fb.control('', {
        validators: [Validators.required],
        asyncValidators: [ReferenceExistsValidator.proccesshReferenceExistsValidator(this.processHeaderService, validationHelperService)],
        updateOn: 'blur'
      }),
      reference2: this.fb.control(''),
      cdLanguageReport: this.fb.control(''),
      title: this.fb.control(''),
      entryDate: this.fb.control(null),
      completionDate: this.fb.control(null),
      caseId: this.fb.control(null),
    });

    this.cdStatuses$ = this.codeService.getList('cd_processh_status', false);
    this.cdLanguages$ = this.codeService.getList('cd_language', false);
  }

  ngOnInit(): void {
    console.log('onInit');
    if (this.processHeader) {
      this.validationHelperService.setId(this.processHeader.id);
      this.validationHelperService.setType(this.processHeader.type);
      this.caseId = this.processHeader.caseId;
      if (!this.processHeader.period) {
        this.processHeader.period = {};
      }
      this.form.patchValue(this.processHeader);
    }
    this.form.markAllAsTouched();

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.onChange();
    });
    this.form.statusChanges.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe(() => {
      this.onChange();
    });

    this.setDefaults();
  }

  private setDefaults() {
    if (this.processHeader?.id) {
      return;
    }
    this.cdStatuses$.pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(result => {
      this.form.patchValue({
        cdStatus: result[0].id,
        cdLanguageReport: 'cd_language_' + this.languageService.getCurrentLang()
      });
      this.form.markAllAsTouched();
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.processHeader)) {
      this.validationHelperService.setId(this.processHeader.id);
      this.validationHelperService.setType(this.processHeader.type);
      this.caseId = this.processHeader.caseId;
      this.form.reset();
      if (!this.processHeader.period) {
        this.processHeader.period = {};
      }
      this.form.patchValue(this.processHeader);
      this.form.markAllAsTouched();
      this.onChange();
    }
  }

  onChange(): void {
    if (this.form.pending) {
      return;
    }
    if (this.form.valid) {
      this.processHeader = _.extend(this.processHeader, this.form.value);
      this.processHeaderChange.emit(this.processHeader);
    }
    this.processHeaderValid.emit(this.form.valid);
    console.log('changed');
  }

  caseSelected() {
    this.form.patchValue({
      caseId: this.caseId
    });
    this.onChange();
  }
}
