<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.accident_type</mat-label>
            <mtx-select [searchable]="true" formControlName="subType" required>
                <mtx-option *ngFor="let subType of subTypes$ | async" [value]="subType.id">
                    {{ subType| language: 'name' }}
                </mtx-option>
            </mtx-select>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.accident_category</mat-label>
            <mtx-select [searchable]="true" formControlName="cdAccidentCategory" required>
                <mtx-option *ngFor="let category of cdAccidentCategories$ | async" [value]="category.id">
                    {{ category | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6" [hidden]="form.value.preciseAccidentDate == true">
            <mat-label translate>field.accident_date</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="accidentDate" [max]="now" [required]="form.value.preciseAccidentDate == false">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6" [hidden]="form.value.preciseAccidentDate == false">
            <mat-label translate>field.accident_date_time</mat-label>
            <input matInput [ngxMatDatetimePicker]="picker2" formControlName="accidentDate" [max]="now" [required]="form.value.preciseAccidentDate == true">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker [defaultTime]="defaultTime" #picker2></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6" *ngIf="accidentDetail.isRelapse">
            <mat-label translate>field.relapse_date</mat-label>
            <input matInput [matDatepicker]="picker3" formControlName="relapseDate" [min]="form.value.accidentDate" required>
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-checkbox class="clr-col-md-12" formControlName="preciseAccidentDate">
            <mat-label translate>field.precise_accident_date</mat-label>
        </mat-checkbox>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.location_of_accident</mat-label>
            <textarea matInput formControlName="location" maxlength="40" [rows]="2" required></textarea>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.scene_of_accident</mat-label>
            <textarea matInput formControlName="sceneOfAccident" maxlength="70" [rows]="2"></textarea>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.description</mat-label>
            <textarea matInput formControlName="description" maxlength="3900" [rows]="10" required></textarea>
        </mat-form-field>
    </div>

    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.involved_persons</mat-label>
            <textarea [rows]="1" formControlName="involvedPersons" matInput maxlength="100"></textarea>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.police_report</mat-label>
            <mtx-select [searchable]="true" formControlName="cdPoliceReport" [appendTo]="'body'">
                <mtx-option *ngFor="let report of cdPoliceReports$ | async" [value]="report.id">
                    {{ report | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>


</form>
