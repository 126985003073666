import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { filter, first } from 'rxjs/operators';
import { ProfileService } from '../api/api';
import { AlertService } from './alert.service';
import { AuhtenticationInfo } from './authenticationInfo';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
@Injectable({ providedIn: 'root' })
export class AuthenticationService implements OnDestroy {
  private auhtenticationInfoSubject: BehaviorSubject<AuhtenticationInfo>;
  public auhtenticationInfo: Observable<AuhtenticationInfo>;

  returnUrl: string;
  promptForLogin = false;

  private _token: string;

  constructor(
    private router: Router,
    private alertService: AlertService,
    private translateService: TranslateService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: MsalService,
    private profileService: ProfileService,
  ) {
    this.auhtenticationInfoSubject = new BehaviorSubject<AuhtenticationInfo>(null);
    this.auhtenticationInfo = this.auhtenticationInfoSubject.asObservable();
    this.init();
  }

  init(): void {
    console.log('subscribe to msal');

    this.msalBroadcastService.msalSubject$
      .subscribe((result: EventMessage) => {
      if (result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS) {
        console.log('received msal:acquireTokenSuccess', result);
        const payload = result.payload as AuthenticationResult;
        console.log('result', payload);
        this.token = payload.idToken;
        this.authService.instance.setActiveAccount(payload.account);
        this.getUserProfile();
      } else {
        console.log('received ' + result.eventType, result);
      }
    },
    (error) => {
      console.log('received error', error);

    }
    );

    this.msalBroadcastService.inProgress$.pipe(
      filter((status: InteractionStatus) => status === InteractionStatus.None)
    ).subscribe((status) => {
      console.log('Interactionstatus', status);
    });
  }

  private getUserProfile() {
    console.log('getting profiles');
    forkJoin({
//      profile: this.http.get(GRAPH_ENDPOINT),
      appProfile: this.profileService.getUserProfile()
    }).pipe(
      first()
    ).subscribe(response => {
      console.log('response - OK', response);
      this.alertService.info(this.translateService.instant('notification.user_authenticated'));

      const auhtenticationInfo = {
        partnerId: response.appProfile.partnerId,
        tenantName: response.appProfile.tenant,
        user: {
          username: response.appProfile.username,
          firstName: response.appProfile.firstName,
          lastName: response.appProfile.lastName
        },
        accessRightsByPartnerId: response.appProfile.accessRightsByPartnerId
      } as AuhtenticationInfo;
      this.auhtenticationInfoSubject.next(auhtenticationInfo);
      const url = this.returnUrl ? this.returnUrl : '/dashboard';
      this.returnUrl = null;
      this.router.navigateByUrl(url);
    }, error => {
      console.log('myclaims_error_code', error.headers.get('myclaims_error_code'));
      console.log('myclaims_error_message', error.headers.get('myclaims_error_message'));
      this.alertService.error(this.translateService.instant('notification.user_authentication_failed'));
      this.promptForLogin = true;
    });

  }

  public get authenticationInfoValue(): AuhtenticationInfo {
    return this.auhtenticationInfoSubject.value;
  }

  public authenticated(): boolean {
    const ai = this.auhtenticationInfoSubject.value;
    return ai != null;
  }

  public chooseAccount() {
    this.login(null, 'select_account');
  }

  public login(returnUrl?: string, prompt?: string) {
    console.log('calling login');
    this.returnUrl = returnUrl;

    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    if (this.promptForLogin) {
      prompt = 'select_account';
    }

    if (isIE) {
      this.authService.loginRedirect({
        scopes: ['user.read', 'openid', 'profile'],
        prompt
      });
    } else {
      console.log('calling loginPopup');
      this.authService.loginPopup({
        scopes: ['user.read', 'openid', 'profile'],
        prompt
      }).subscribe((response: AuthenticationResult) => {
        this.authService.instance.setActiveAccount(response.account);
      });
    }
  }

  get token(): string {
    return this._token;
  }
  set token(value: string) {
    this._token = value;
  }

  public logout() {
    this.auhtenticationInfoSubject.next(null);
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  ngOnDestroy(): void {
    this.auhtenticationInfoSubject.complete();
  }

}
