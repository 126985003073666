import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';
import { PersonForm } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';


@Component({
  selector: 'app-person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.css']
})
export class PersonFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() person: PersonForm;
  @Output() personValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() personChange: EventEmitter<PersonForm> = new EventEmitter<PersonForm>();

  maritalStatuses$: Observable<any[]>;
  languages$: Observable<any[]>;
  genders$: Observable<any[]>;
  nationalities$: Observable<any[]>;

  parRoles = [];

  constructor(
    private codeService: AppCodeService,
  ) {
    super();
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      givenName: new UntypedFormControl('', [Validators.required]),
      birthDate: new UntypedFormControl(),
      deathDate: new UntypedFormControl(),
      cdGender: new UntypedFormControl(),
      cdLanguage: new UntypedFormControl(),
      cdNationality: new UntypedFormControl(),
      cdNationality2: new UntypedFormControl(),
      cdMaritalStatus: new UntypedFormControl(),
      applFunction: new UntypedFormControl(),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    this.genders$ = this.codeService.getList('cd_gender', false);
    this.maritalStatuses$ = this.codeService.getList('cd_marital_status', false);
    this.languages$ = this.codeService.getList('cd_language', false);
    this.nationalities$ = this.codeService.getList('cd_nationality', false);

    this.codeService.getList('cd_partnerrole', false).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(result => {
      this.parRoles = result;
    }, error => {
      console.log('get organisationTypes - error', error);
    });

    if (this.person) {
      this.form.patchValue(this.person);
      this.form.markAllAsTouched();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.person)) {
      this.form.patchValue(this.person);
      this.form.markAllAsTouched();
    }
  }

  onChange(): void {
    if (this.form.valid) {
      const modifiedPerson = _.extendOwn(this.person, this.form.value);
      this.personChange.emit(modifiedPerson);
    }
    this.personValid.emit(this.form.valid);
    console.log('changed');
  }
}
