import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import * as _ from 'underscore';
import { AuthenticationService } from '../service/authentication.service';
import { AuthorizationService } from '../service/authorization.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        private authorizationService: AuthorizationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.authenticationService.authenticated()) {
            // check if route is restricted by role
            if (route.data.roles
                && _.intersection(route.data.roles, this.authenticationService.authenticationInfoValue).length === 0) {
                console.log('missing needed role(s)', route.data.roles);
                // role not authorised so redirect to home page
                this.router.navigate(['/']);
                return false;
            }

            if (route.data.functionalities) {
                let allowed = false;
                route.data.functionalities.forEach( functionality => {
                    allowed = allowed || this.authorizationService.hasCdFunctionality(functionality);
                });

                if (!allowed) {
                    console.log('missing needed functionalities(s)', route.data.functionalities);
                    // role not authorised so redirect to home page
                    this.router.navigate(['/']);
                    return false;
                }
            }

            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/entry'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}