import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { first, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { ApplCaseForm, CaseObjectForm, CasePartnerForm, CasePolicyHeaderForm, CaseService, DocumentForm, DocumentRefForm, DocumentService, ProcessHeaderForm, ProcessheaderService } from 'src/app/core';
import { EntityEnum } from 'src/app/core/entity-enum';
import { AlertService } from 'src/app/core/service/alert.service';
import { BlockService } from 'src/app/core/service/block.service';
import { FileService } from 'src/app/core/service/file.service';
import * as _ from 'underscore';
import { BaseWizardComponent } from '../../base/base-wizard/base-wizard.component';
import { Editor } from '../../editors/editor';
import { sortDocuments } from '../../sort-util';


@Component({
  selector: 'app-case-wizard',
  templateUrl: './case-wizard.component.html',
  styleUrls: ['./case-wizard.component.css']
})
export class CaseWizardComponent extends BaseWizardComponent implements OnInit, Editor {

  entityEnum = EntityEnum.Case;

  @Input() id: number;
  @Input() object: ApplCaseForm;

  case: ApplCaseForm;
  partners: CasePartnerForm[];
  objects: CaseObjectForm[];
  headers: ProcessHeaderForm[];
  policyheaders: CasePolicyHeaderForm[];
  documents: DocumentRefForm[];
  processHeaderDocuments: DocumentForm[];
  caseValid = false;
  casePartnersValid = true;
  caseObjectsValid = true;
  headersValid = true;
  policyheadersValid = true;
  documentValid = true;

  hidePolicyStep = false;
  hidePHStep = false;
  hideObjectStep = false;
  hideDocumentStep = false;

  constructor(
    private caseService: CaseService,
    private documentService: DocumentService,
    private processHeaderService: ProcessheaderService,
    private fileService: FileService,
    private translateService: TranslateService,
    alertService: AlertService,
    blockService: BlockService
    ) {
    super(blockService, alertService);
  }

  ngOnInit(): void {
    this.newEntity = !this.id;
    
    if (this.object) {
      this.case = this.object;
    }

    this.fileService.reset();
    this.loadData();
  }

  loadData() {
    if (!this.id) {
      // tslint:disable-next-line:no-angle-bracket-type-assertion
      if (!this.object) {
        this.object = {} as ApplCaseForm;
        this.case = {} as ApplCaseForm;
      }
      this.objects = [];
      this.partners = [];
      this.documents = [];
      this.headers = [];
      this.policyheaders = [];
      this.processHeaderDocuments = [];
      return;
    }
    this.caseService.getByIdApplCase(this.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: ApplCaseForm) => {
        this.object = response;
        this.case = this.object;
        this.objects = response.objects;
        this.partners = response.partners;
        this.headers = response.processHeaders;
        this.policyheaders = response.policyHeaders;
        this.documents = sortDocuments(response.documents);
        this.processHeaderDocuments = [];
        this.evaluateWizardPagesCompleted();
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  protected onSubmit(): Observable<any> {
    const tasks$ = [];
    let index = 0;
    this.documents.forEach(documentRef => {
      if (!documentRef.document.id) {
        tasks$.push(this.storeDocument(documentRef.document, index));
      }
      index++;
    });

    if (tasks$.length === 0) {
      return this.storeCase();
    }

    return forkJoin(tasks$).pipe(
      takeUntil(this.ngUnsubscribe),
      take(1),
      switchMap(() => this.storeCase())
    );
  }

  private storeDocument(document: DocumentForm, index: number): Observable<any> {
    return this.documentService.saveDocument(document).pipe(
      takeUntil(this.ngUnsubscribe),
      switchMap((doc) => {
        this.documents[index].document = doc;
        return this.documentService.storeContent(doc.id, this.fileService.get(index));
      })
    );
  }

  private storeCase(): Observable<any> {
    const applCase = _.extendOwn(this.object, this.case);
    applCase.partners = this.partners;
    applCase.processHeaders = this.headers;
    applCase.policyHeaders = this.policyheaders;
    applCase.objects = this.objects;
    applCase.documents = this.documents;
    const save$ = !applCase.id ? this.caseService.createApplCase(applCase) : this.caseService.updateApplCase(applCase);
    return save$.pipe(
      tap((objectSaved) => {
        this.entityId = objectSaved.id;
      }),
      switchMap((applCaseSaved) => this.storeDocumentsForProcessHeaders(applCaseSaved))
    );
  }

  private storeDocumentsForProcessHeaders(applCaseSaved: ApplCaseForm): Observable<any> {
    if (this.fileService.isEmpty('processHeader')) {
      return of(true);
    }

    const tasks$ = [];
    let index = 0;
    this.processHeaderDocuments.forEach(document => {
      if ( document
        && this.fileService.get(index, 'processHeader') != null ) {
        tasks$.push(this.storeProcessHeaderDocument(document, index, applCaseSaved.processHeaders[index].id));
      }
      index++;
    });

    return forkJoin(tasks$);
  }

  private storeProcessHeaderDocument(document: DocumentForm, index: number, processHeaderId: number): Observable<any> {
    return this.documentService.saveDocument(document).pipe(
      takeUntil(this.ngUnsubscribe),
      switchMap((doc) => {
        return forkJoin([
          this.documentService.storeContent(doc.id, this.fileService.get(index, 'processHeader')),
          this.processHeaderService.addDocument(processHeaderId, doc.id)
        ]);
      })
    );
  }

}
