import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PostalAddressForm } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-postal-address-form',
  templateUrl: './postal-address-form.component.html',
  styleUrls: ['./postal-address-form.component.css']
})
export class PostalAddressFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() postalAddress: PostalAddressForm;
  @Output() addressValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() postalAddressChange: EventEmitter<PostalAddressForm> = new EventEmitter<PostalAddressForm>();

  countries$: any;

  constructor(
    private codeService: AppCodeService,
  ) {
    super();

    this.form = new UntypedFormGroup({
      coAddress: new UntypedFormControl(''),
      poBox: new UntypedFormControl(''),
      poBoxNumber: new UntypedFormControl(''),
      street: new UntypedFormControl(''),
      streetNumber: new UntypedFormControl(''),
      postCode: new UntypedFormControl('', [Validators.required]),
      city: new UntypedFormControl('', [Validators.required]),
      type: new UntypedFormControl('', [Validators.required]),
      cdCountry: new UntypedFormControl('cd_country_switzerland'),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    console.log('onInit');
    if (this.postalAddress) {
      this.form.patchValue(this.postalAddress);
    }
    this.form.markAllAsTouched();

    this.countries$ = this.codeService.getList('cd_country', false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.postalAddress)) {
      this.form.patchValue(this.postalAddress);
      this.form.markAllAsTouched();
    }
  }

  onChange(): void {
    this.addressValid.emit(this.form.valid);
    if (this.form.valid) {
      this.postalAddress = _.extend(this.postalAddress, this.form.value);
      this.postalAddressChange.emit(this.postalAddress);
    }
    console.log('changed');
  }
}
