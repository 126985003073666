<div class="clr-row">
    <mat-form-field class="clr-col-md-4">
        <mat-label translate>field.relationship_type</mat-label>
        <input matInput [value]="casePolicyHeader.relationshipType | lookupRelationshipType: 'ltr'  | async" disabled>
    </mat-form-field>
    <div class="clr-col-md-8">
        <app-policy-header-search class="clr-row" [type]="typeObject" [(id)]="casePolicyHeaderEdit.policyHeaderId" (idChange)="onChange()"></app-policy-header-search>
        <app-policy-item-search class="clr-row" [label]="policyItemType | lookupApplType | async" [policyHeaderId]="casePolicyHeaderEdit.policyHeaderId" [(id)]="casePolicyHeaderEdit.policyItemId"
            (idChange)="onChange()"></app-policy-item-search>
    </div>
</div>