import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-absence-editor',
  templateUrl: './absence-editor.component.html',
  styleUrls: ['./absence-editor.component.css']
})
export class AbsenceEditorComponent implements OnInit {

  @Input() id: number;
  @ViewChild(ClrForm, { static: true }) clrForm;

  afterClosed = new Subject<any>();
  show = true;

  absenceForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder
  ) { }

  ngOnInit(): void {
    this.absenceForm = this.fb.group({
      name: this.fb.control('', Validators.required),
      startDate: this.fb.control(''),
      endDate: this.fb.control(''),
    });
    this.clrForm.markAsTouched();
  }

  submit() {
    if (this.absenceForm.invalid) {
      this.clrForm.markAsTouched();
      return;
    }
    this.show = false;
    this.afterClosed.next(true);
  }

  cancel() {
    this.show = false;
    this.afterClosed.next(false);
  }


}
