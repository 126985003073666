<div class="clr-row">
    <mat-form-field class="clr-col-md-2">
        <mat-label translate>field.relationship_type</mat-label>
        <input matInput [value]="caseObject.relationshipType | lookupRelationshipType: 'ltr'  | async" disabled>
    </mat-form-field>
    <div class="clr-col-md-8">
        <app-object-search [type]="typeObject" [(id)]="caseObject.objectId" (idChange)="onChange()"></app-object-search>
    </div>
    <mat-form-field class="clr-col-md-2">
        <mat-label translate>field.amount_net</mat-label>
        <input type="number" matInput [(ngModel)]="caseObjectEdit.amountNet">
    </mat-form-field>
</div>
<div class="clr-row">
    <mat-form-field class="clr-col-md-12">
        <mat-label translate>field.description</mat-label>
        <textarea matInput [(ngModel)]="caseObjectEdit.description" maxlength="120" [rows]="1"></textarea>
    </mat-form-field>
</div>