        <div clrLayout="vertical">

            <div class="clr-row">
                <mat-form-field class="clr-col-md-6" *ngIf="filteredRelTypes?.length > 0">
                    <mat-label translate>field.postal_address_type</mat-label>
                    <mtx-select [searchable]="true" class="custom" [(ngModel)]="selectedRelType">
                        <mtx-option *ngFor="let filteredRelType of filteredRelTypes" [value]="filteredRelType">
                            {{ filteredRelType.typeLeft | lookupApplType | async }}</mtx-option>
                    </mtx-select>
                </mat-form-field>
                <div class="clr-col-md-2">
                    <button class="btn btn-sm" (click)="onAdd()" [disabled]="!selectedRelType || !editorValid"
                        translate>button.add_postal_address</button>
                </div>
            </div>

            <clr-accordion [clrAccordionMultiPanel]="true">
                <clr-accordion-panel *ngFor="let wrappedItem of wrappedItems, index as i"
                    [clrAccordionPanelOpen]="wrappedItem.open">
                    <clr-accordion-title>{{ wrappedItem.item.type | lookupApplType | async}}: {{ wrappedItem.item?.street }} {{ wrappedItem.item?.streetNumber }}, {{ wrappedItem.item?.postCode }} {{ wrappedItem.item?.city }}</clr-accordion-title>
                    <clr-accordion-content *clrIfExpanded class="clr-row">
                        <div class="clr-col-md-11">
                            <app-postal-address-form [(postalAddress)]="wrappedItem.item" (postalAddressChange)="onChange(i, $event)"
                                (addressValid)="onChange(i, $event)"></app-postal-address-form>
                        </div>
                        <div class="clr-col-md-1">
                            <span *appDeleteAllowed="wrappedItem?.item?.relationshipType;immutable:wrappedItem.required" (click)="onDelete(i)">
                                <clr-icon shape="trash"></clr-icon>
                            </span>
                        </div>
                    </clr-accordion-content>
                </clr-accordion-panel>
            </clr-accordion>
        </div>
