import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { SalaryInfo } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import { AuthorizationService } from 'src/app/core/service/authorization.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sunet-salary-form',
  templateUrl: './sunet-salary-form.component.html',
  styleUrls: ['./sunet-salary-form.component.css']
})
export class SunetSalaryFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() salary: SalaryInfo;
  @Output() salaryValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() salaryChange: EventEmitter<SalaryInfo> = new EventEmitter<SalaryInfo>();

  employmentStatuses$: Observable<any[]>;
  positions$: Observable<any[]>;
  functions$: Observable<any[]>;
  salaryTypes$: Observable<any[]>;
  specialCases$: Observable<any[]>;
  otherInsurers$: Observable<any[]>;

  showSalary = false;

  constructor(
    private codeService: AppCodeService,
    private formBuilder: UntypedFormBuilder,
    protected authorizationService: AuthorizationService
  ) {
    super();
    this.form = this.formBuilder.group({
      basicSalary: this.formBuilder.group({
        amount: new UntypedFormControl(null),
        cdSalaryType: new UntypedFormControl(null)
      }),
      familyBenefits: this.formBuilder.group({
        amount: new UntypedFormControl(null),
        cdSalaryType: new UntypedFormControl(null)
      }),
      holidayCompensation: this.formBuilder.group({
        amount: new UntypedFormControl(null),
        cdSalaryType: new UntypedFormControl(null)
      }),
      gratuity: this.formBuilder.group({
        amount: new UntypedFormControl(null),
        cdSalaryType: new UntypedFormControl(null)
      }),
      otherSupplements: this.formBuilder.group({
        amount: new UntypedFormControl(null),
        cdSalaryType: new UntypedFormControl(null)
      }),
      otherSupplementsDescription: new UntypedFormControl(),
      cdSpecialCase: new UntypedFormControl(null),
      cdOtherInsurers: new UntypedFormControl(null),
      otherInsurersSpecification: new UntypedFormControl(null),
      otherEmployers: new UntypedFormControl(null),
      withholdingTax: new UntypedFormControl(false),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());

  }

  ngOnInit(): void {
    this.showSalary = this.authorizationService.showSalary();

    this.positions$ = this.codeService.getList('cd_emp_position', false);
    this.employmentStatuses$ = this.codeService.getList('cd_employment_status', false);
    this.functions$ = this.codeService.getList('cd_function', false);
    this.salaryTypes$ = this.codeService.getList('cd_salary_type', false);
    this.specialCases$ = this.codeService.getList('cd_special_case', false);
    this.otherInsurers$ = this.codeService.getList('cd_sunet_other_ins', false);

    if (this.disabled) {
      this.form.disable();
    }
    this.form.markAllAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.patchValue(this.salary);
    this.form.markAllAsTouched();
  }

  onChange(): void {
    const modified = _.extend(this.salary, this.form.value);
    this.salaryChange.emit(modified);
    this.salaryValid.emit(this.form.valid);

  }
}

