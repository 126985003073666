<mat-form-field class="clr-row clr-col-12">
    <mat-label translate>field.organisation</mat-label>
    <mtx-select [items]="result$ | async" bindLabel="name" [multiple]="false" [minTermLength]="2" [loading]="loading" [clearable]="false"
        [typeToSearchText]="'message.enter_2_or_more_characters' | translate" [typeahead]="searchInput$" [required]="required" [(ngModel)]="selectedItem"
        [appendTo]="'body'" (change)="onChange()">
            <ng-template ng-label-tmp let-item="item">
                {{ item.name }}
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                {{ item.name }}
            </ng-template>
            <ng-template ng-footer-tmp *ngIf="nothingFound">
                <button class="btn btn-link" (click)="open()" translate>button.create_organisation</button>
            </ng-template>
    </mtx-select>
</mat-form-field>
