<form clrForm [formGroup]="form" clrLayout="vertical" #empForm>
    <div class="clr-row"  formGroupName="basicSalary" *ngIf="showSalary">
        <mat-form-field class="clr-col-md-8">
            <mat-label translate>field.basic_salary</mat-label>
            <input matInput formControlName="amount" [required]="showSalary" [readonly]="readonly">
        </mat-form-field>
        <mat-form-field [ngClass]="{'readonly-wrapper': readonly}" class="clr-col-md-4">
            <mat-label translate>field.type</mat-label>
            <mtx-select formControlName="cdSalaryType" [appendTo]="'body'" [required]="showSalary" [readonly]="readonly">
                <mtx-option *ngFor="let salaryType of salaryTypes$ | async" [value]="salaryType.id">
                    {{ salaryType | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row" formGroupName="familyBenefits" *ngIf="showSalary">
        <mat-form-field class="clr-col-md-8">
            <mat-label translate>field.family_benefits</mat-label>
            <input matInput formControlName="amount">
        </mat-form-field>
        <mat-form-field class="clr-col-md-4">
            <mat-label translate>field.type</mat-label>
            <mtx-select formControlName="cdSalaryType" [appendTo]="'body'" [required]="form.value.familyBenefits.amount?.length > 0">
                <mtx-option *ngFor="let salaryType of salaryTypes$ | async" [value]="salaryType.id">
                    {{ salaryType | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row" formGroupName="holidayCompensation" *ngIf="showSalary">
        <mat-form-field class="clr-col-md-8">
            <mat-label translate>field.holiday_compensation</mat-label>
            <input matInput formControlName="amount">
        </mat-form-field>
        <mat-form-field class="clr-col-md-4">
            <mat-label translate>field.type</mat-label>
            <mtx-select class="custom" formControlName="cdSalaryType" [appendTo]="'body'" [required]="form.value.holidayCompensation.amount?.length > 0">
                <mtx-option *ngFor="let salaryType of salaryTypes$ | async" [value]="salaryType.id">
                    {{ salaryType | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row" formGroupName="gratuity" *ngIf="showSalary">
        <mat-form-field class="clr-col-md-8">
            <mat-label translate>field.gratuity</mat-label>
            <input matInput formControlName="amount">
        </mat-form-field>
        <mat-form-field class="clr-col-md-4">
            <mat-label translate>field.type</mat-label>
            <mtx-select class="custom" formControlName="cdSalaryType" [appendTo]="'body'" [required]="form.value.gratuity.amount?.length > 0">
                <mtx-option *ngFor="let salaryType of salaryTypes$ | async" [value]="salaryType.id">
                    {{ salaryType | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row" formGroupName="otherSupplements" *ngIf="showSalary">
        <mat-form-field class="clr-col-md-8">
            <mat-label translate>field.other_supplements</mat-label>
            <input matInput formControlName="amount">
        </mat-form-field>
        <mat-form-field class="clr-col-md-4">
            <mat-label translate>field.type</mat-label>
            <mtx-select class="custom" formControlName="cdSalaryType" [appendTo]="'body'" [required]="form.value.otherSupplements.amount?.length > 0">
                <mtx-option *ngFor="let salaryType of salaryTypes$ | async" [value]="salaryType.id">
                    {{ salaryType | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.other_supplements_description</mat-label>
            <input matInput formControlName="otherSupplementsDescription">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.special_case</mat-label>
            <mtx-select class="custom" formControlName="cdSpecialCase" [appendTo]="'body'">
                <mtx-option *ngFor="let specialCase of specialCases$| async" [value]="specialCase.id">
                    {{ specialCase | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>

    <div class="clr-row" *ngIf="showSalary">
        <mat-checkbox class="clr-col-md-12" formControlName="withholdingTax">
            <mat-label translate>field.withholding_tax</mat-label>
        </mat-checkbox>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.other_insurers</mat-label>
            <mtx-select class="custom" formControlName="cdOtherInsurers" [appendTo]="'body'">
                <mtx-option *ngFor="let otherInsurer of otherInsurers$| async" [value]="otherInsurer.id">
                    {{ otherInsurer | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.other_insurers_specification</mat-label>
            <input matInput formControlName="otherInsurersSpecification">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.other_employers</mat-label>
            <input matInput formControlName="otherEmployers">
        </mat-form-field>
    </div>
</form>
