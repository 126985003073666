import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { EmployeeModel } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.css']
})
export class EmployeeFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() employee: EmployeeModel;
  @Input() salaryDataAllowed: boolean;
  @Output() employeeChange: EventEmitter<EmployeeModel> = new EventEmitter<EmployeeModel>();
  @Output() employeeValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  employmentStatuses$: Observable<any[]>;
  positions$: Observable<any[]>;
  functions$: Observable<any[]>;
  salaryTypes$: Observable<any[]>;

  withEmployeeInfo = false;

  constructor(
    private codeService: AppCodeService,
    private formBuilder: UntypedFormBuilder
  ) {
    super();
    this.form = this.formBuilder.group({
      cdEmploymentStatus: new UntypedFormControl(null, [Validators.required]),
      cdWorkPermit: new UntypedFormControl(),
      cdEmploymentPosition: new UntypedFormControl(),
      dateOfEmployment: new UntypedFormControl(null, [Validators.required]),
      terminationDate: new UntypedFormControl(),
      occupationalRate: new UntypedFormControl(),
      workingPlace: new UntypedFormControl(null, [Validators.required]),
      workingDaysPerWeek: new UntypedFormControl(),
      workingHoursPerWeek: new UntypedFormControl(null, [Validators.required]),
      numberOfChildren: new UntypedFormControl(),
      profession: new UntypedFormControl(),
      costCenter: new UntypedFormControl(),
      otherSupplementsDescription: new UntypedFormControl(),
      withholdingTax: new UntypedFormControl(false),
      basicSalary: this.formBuilder.group({
        amount: new UntypedFormControl(null),
        cdSalaryType: new UntypedFormControl(null)
      }),
      familyBenefits: this.formBuilder.group({
        amount: new UntypedFormControl(null),
        cdSalaryType: new UntypedFormControl(null)
      }),
      holidayCompensation: this.formBuilder.group({
        amount: new UntypedFormControl(null),
        cdSalaryType: new UntypedFormControl(null)
      }),
      gratuity: this.formBuilder.group({
        amount: new UntypedFormControl(null),
        cdSalaryType: new UntypedFormControl(null)
      }),
      otherSupplements: this.formBuilder.group({
        amount: new UntypedFormControl(null),
        cdSalaryType: new UntypedFormControl(null)
      })
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());

  }

  ngOnInit(): void {
    this.positions$ = this.codeService.getList('cd_emp_position', false);
    this.employmentStatuses$ = this.codeService.getList('cd_employment_status', false);
    this.functions$ = this.codeService.getList('cd_function', false);
    this.salaryTypes$ = this.codeService.getList('cd_salary_type', false);

    this.form.markAllAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.withEmployeeInfo = !!this.employee;
    this.form.patchValue(this.employee);
    this.form.markAllAsTouched();
  }

  onChange(): void {
    console.log('called');
    if (this.withEmployeeInfo && this.form.valid) {
      const emp = _.extend(this.employee || {}, this.form.value);
      this.employeeChange.emit(emp);
    } else if (!this.withEmployeeInfo) {
      this.employeeChange.emit(null);
    }
    this.employeeValid.emit(!this.withEmployeeInfo || this.form.valid);
  }
}
