import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { concat, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OrgUnitSearchCriteria, OrgUnitService, OrgUnitView, PageOrgUnitView } from 'src/app/core';
import { BaseSearchComponent } from '../../base/base-search/base-search.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-org-unit-search',
  templateUrl: './org-unit-search.component.html',
  styleUrls: ['./org-unit-search.component.css']
})
export class OrgUnitSearchComponent extends BaseSearchComponent implements OnInit, OnChanges {

  criteria: OrgUnitSearchCriteria = {
    pageSize: 50,
    sortColumn: 'name'
  };

  result$: Observable<OrgUnitView[]>;
  selectedItem: OrgUnitView;

  constructor(
    private orgUnitController: OrgUnitService,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.id)) {
      this.orgUnitController.findByIdOrgUnitLite(this.id).pipe(
        takeUntil(this.ngUnsubscribe),
        first()
      ).subscribe(response => {
        this.selectedItem = response;
        this.ref.markForCheck();
      });
    }
  }

  ngOnInit(): void {
    this.searchOrgUnits();
  }

  trackByFn(item: OrgUnitView) {
    return item.id;
  }

  onChange() {
    this.idChange.emit(this.selectedItem.id);
  }

  private searchOrgUnits() {
    this.result$ = concat(
      of([]), // default item
      this.searchInput$.pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap((term) => {
          this.criteria.keywords = term;
          return this.orgUnitController.searchOrgUnits(this.criteria).pipe(
            catchError(() => of([])), // empty list on error
            map((response: PageOrgUnitView) => response.content),
            tap(() => this.loading = false)
          );
        })
      )
    );
  }
}

