<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.type</mat-label>
            <input matInput [value]="postalAddress.type | lookupApplType | async" disabled>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.co_address</mat-label>
            <input matInput formControlName="coAddress">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-9">
            <mat-label translate>field.street</mat-label>
            <input matInput formControlName="street" required>
        </mat-form-field>
        <mat-form-field class="clr-col-md-3">
            <mat-label translate>field.street_nr</mat-label>
            <input matInput formControlName="streetNumber">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-9">
            <mat-label translate>field.pobox</mat-label>
            <input matInput formControlName="poBox">
        </mat-form-field>
        <mat-form-field class="clr-col-md-3">
            <mat-label translate>field.pobox_nr</mat-label>
            <input matInput formControlName="poBoxNumber">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-2">
            <mat-label translate>field.post_code</mat-label>
            <input matInput formControlName="postCode" required>
        </mat-form-field>
        <mat-form-field class="clr-col-md-5">
            <mat-label translate>field.city</mat-label>
            <input matInput formControlName="city" required>
        </mat-form-field>
        <mat-form-field class="clr-col-md-5">
            <mat-label translate>field.country</mat-label>
            <mtx-select [searchable]="true" class="custom" required formControlName="cdCountry" [appendTo]="'body'"
                required>
                <mtx-option *ngFor="let country of countries$ | async" [value]="country.id">
                    {{ country | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
</form>