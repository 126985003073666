<clr-wizard #wizardlg [(clrWizardOpen)]="show" clrWizardSize="xl" [clrWizardClosable]="false" (clrWizardOnCancel)="onCancel()" [clrWizardPreventDefaultCancel]="true"
    [clrWizardPreventDefaultNext]="true" (clrWizardOnNext)="onNext()" (clrWizardOnFinish)="onFinish()">
    <clr-wizard-title>
        {{ 'field.person' | translate }}<br>
        <small>{{ object?.name }} {{ object?.givenName }}</small>
    </clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">{{ 'button.cancel' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{ 'button.back' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{ 'button.next' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{ 'button.finish' | translate }}</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!personValid" [clrWizardPageHasError]="!personValid">
        <ng-template clrPageTitle translate>{{ 'section.detail' | translate }}</ng-template>
        <app-person-form [(person)]="person" (personValid)="personValid = $event"></app-person-form>
        <div class="clr-row">
            <app-partner-role-form class="clr-col-10" type="type_partner_person" [(partnerRoles)]="partnerRoles"></app-partner-role-form>
        </div>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!identifiersValid" [clrWizardPageHasError]="!identifiersValid">
        <ng-template clrPageTitle>{{ 'section.identifiers' | translate }}</ng-template>
        <app-identifier-editor [(identifiers)]="identifiers" type="type_partner_person" (identifiersValid)="identifiersValid = $event"></app-identifier-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!parametersValid" [clrWizardPageHasError]="!parametersValid" *ngIf="!hideParameterStep">
        <ng-template clrPageTitle>{{ 'section.parameters' | translate }}</ng-template>
        <app-parameter-editor type="type_partner_person" [(parameters)]="parameters" (hide)="hideParameterStep = $event" (valid)="parametersValid = $event">
        </app-parameter-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!employeeValid" [clrWizardPageHasError]="!employeeValid">
        <ng-template clrPageTitle>{{ 'section.employment' | translate }}</ng-template>
        <app-employee-form [(employee)]="employee" [salaryDataAllowed]="salaryDataAllowed" (employeeValid)="employeeValid = $event"></app-employee-form>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!addressesValid" [clrWizardPageHasError]="!addressesValid">
        <ng-template clrPageTitle>{{ 'section.addresses' | translate }}</ng-template>
        <app-postal-address-editor typeRight="type_partner_person" [(postalAddresses)]="postalAddresses" (valid)="addressesValid = $event">
        </app-postal-address-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!commAddressesValid" [clrWizardPageHasError]="!commAddressesValid">
        <ng-template clrPageTitle>{{ 'section.communication_addresses' | translate }}</ng-template>
        <app-comm-address-editor type="type_partner_person" [(commAddresses)]="commAddresses" (valid)="commAddressesValid = $event">
        </app-comm-address-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!partnerPartnersLeftValid" [clrWizardPageHasError]="!partnerPartnersLeftValid" *ngIf="!hidePartnerPartnerLeftStep">
        <ng-template clrPageTitle>{{ 'section.relations_to_partner' | translate }}</ng-template>
        <app-partner-partner-editor type="type_partner_person" [(partnerPartners)]="partnerPartnersLeft" (hide)="hidePartnerPartnerLeftStep = $event" (valid)="partnerPartnersLeftValid = $event">
        </app-partner-partner-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!partnerPartnersRightValid" [clrWizardPageHasError]="!partnerPartnersRightValid" *ngIf="!hidePartnerPartnerRightStep">
        <ng-template clrPageTitle>{{ 'section.relations_to_partner_right_2_left' | translate }}</ng-template>
        <app-partner-partner-editor type="type_partner_person" mode="rtl" [(partnerPartners)]="partnerPartnersRight" (hide)="hidePartnerPartnerRightStep = $event" (valid)="partnerPartnersRightValid = $event">
        </app-partner-partner-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!documentValid" [clrWizardPageHasError]="!documentValid" *ngIf="!hideDocumentStep">
        <ng-template clrPageTitle>{{ 'section.documents' | translate }}</ng-template>
        <app-document-ref-editor typeLeft="type_partner_person" [(documentRefs)]="documents" (hide)="hideDocumentStep = $event" (valid)="documentValid = $event">
        </app-document-ref-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!accountsValid" [clrWizardPageHasError]="!accountsValid">
        <ng-template clrPageTitle>{{ 'section.bank_accounts' | translate }}</ng-template>
        <app-bankaccount-editor type="type_partner_person" [(bankAccounts)]="accounts" (valid)="accountsValid = $event">
        </app-bankaccount-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageHasError]="error != null" *ngIf="error">
        <ng-template clrPageTitle>{{ 'section.error_occured' | translate }}</ng-template>
        <app-error [error]="error"></app-error>
    </clr-wizard-page>
</clr-wizard>
