<form clrForm [formGroup]="form" clrLayout="vertical" #persForm>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.given_name</mat-label>
            <input matInput formControlName="givenName" required>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.last_name</mat-label>
            <input matInput formControlName="name" required>
        </mat-form-field>
    </div>

    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.gender</mat-label>
            <mtx-select class="custom" formControlName="cdGender" [appendTo]="'body'" required>
                <mtx-option *ngFor="let gender of genders$ | async" [value]="gender.id">
                    {{ gender | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.language</mat-label>
            <mtx-select class="custom" formControlName="cdLanguage" [appendTo]="'body'">
                <mtx-option *ngFor="let language of languages$ | async" [value]="language.id">
                    {{ language | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>

    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.birthdate</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="birthDate" [max]="form.value.deathDate">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.deathdate</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="deathDate" [min]="form.value.birthDate">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.nationality</mat-label>
            <mtx-select class="custom" formControlName="cdNationality" [appendTo]="'body'">
                <mtx-option *ngFor="let nationality of nationalities$ | async" [value]="nationality.id">
                    {{ nationality | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.nationality2</mat-label>
            <mtx-select class="custom" [searchable]="true" formControlName="cdNationality2" [appendTo]="'body'">
                <mtx-option *ngFor="let nationality of nationalities$ | async" [value]="nationality.id">
                    {{ nationality | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.marital_status</mat-label>
            <mtx-select class="custom" formControlName="cdMaritalStatus" [appendTo]="'body'">
                <mtx-option *ngFor="let maritalStatus of maritalStatuses$ | async" [value]="maritalStatus.id">
                    {{ maritalStatus | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.function</mat-label>
            <input matInput formControlName="applFunction">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-checkbox class="clr-col-md-6" formControlName="topManagement">
            <mat-label translate>field.top_management</mat-label>
        </mat-checkbox>
    </div>

</form>