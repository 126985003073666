<clr-wizard #wizardlg [(clrWizardOpen)]="show" clrWizardSize="xl" [clrWizardClosable]="false" (clrWizardOnCancel)="onCancel()" 
    [clrWizardPreventDefaultCancel]="true" [clrWizardPreventDefaultNext]="true" (clrWizardOnNext)="onNext()"
    (clrWizardOnFinish)="onFinish()">
    <clr-wizard-title>
        {{ toTranslateKey(entityEnum) | translate }} {{ object?.referenceNumber }}<br>
        <small>{{ object?.title }}</small>
    </clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">{{ 'button.cancel' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{ 'button.back' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{ 'button.next' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{ 'button.finish' | translate }}</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!caseValid" [clrWizardPageHasError]="!caseValid">
        <ng-template clrPageTitle translate>{{ 'field.case_detail' | translate }}</ng-template>
        <ng-template clrPageNavTitle>
            {{ 'section.detail' | translate }}
        </ng-template>
        <app-case-form [(case)]="case" (caseValid)="caseValid = $event">
        </app-case-form>
        </clr-wizard-page>
        <clr-wizard-page [clrWizardPageNextDisabled]="!casePartnersValid" [clrWizardPageHasError]="!casePartnersValid">
            <ng-template clrPageTitle>{{ 'section.relations_to_partner' | translate }}</ng-template>
            <app-case-partner-editor [typeLeft]="case?.type" [(casePartners)]="partners" (valid)="casePartnersValid = $event">
            </app-case-partner-editor>
        </clr-wizard-page>
        <clr-wizard-page [clrWizardPageNextDisabled]="!caseObjectsValid" [clrWizardPageHasError]="!caseObjectsValid" *ngIf="!hideObjectStep">
            <ng-template clrPageTitle>{{ 'section.objects' | translate }}</ng-template>
            <app-case-object-editor [caseType]="case?.type" [(caseObjects)]="objects" (valid)="caseObjectsValid = $event" (hide)="hideObjectStep = $event">
            </app-case-object-editor>
        </clr-wizard-page>
        <clr-wizard-page [clrWizardPageNextDisabled]="!policyheadersValid" [clrWizardPageHasError]="!policyheadersValid" *ngIf="!hidePolicyStep">
            <ng-template clrPageTitle>{{ 'section.policies' | translate }}</ng-template>
            <app-case-policy-editor [caseType]="case?.type" [(casePolicies)]="policyheaders" (valid)="policyheadersValid = $event" (hide)="hidePolicyStep = $event">
            </app-case-policy-editor>
        </clr-wizard-page>
<!--
        <clr-wizard-page [clrWizardPageNextDisabled]="!headersValid"
            [clrWizardPageHasError]="!headersValid" *ngIf="!hidePHStep">
            <ng-template clrPageTitle>{{ 'process_headers' | translate }}</ng-template>
            <app-process-header-editor (hide)="hidePHStep = $event" [case]="case" [caseType]="case?.type" [(processHeaders)]="headers"
                (documentsChange)="processHeaderDocuments = $event" (valid)="headersValid = $event">
            </app-process-header-editor>
        </clr-wizard-page>
-->
        <clr-wizard-page [clrWizardPageNextDisabled]="!documentValid" [clrWizardPageHasError]="!documentValid" *ngIf="!hideDocumentStep">
          <ng-template clrPageTitle>{{ 'section.documents' | translate }}</ng-template>
          <app-document-ref-editor [typeLeft]="case?.type" [(documentRefs)]="documents" (hide)="hideDocumentStep = $event" (valid)="documentValid = $event">
          </app-document-ref-editor>
        </clr-wizard-page>
        <clr-wizard-page [clrWizardPageHasError]="error != null" *ngIf="error">
            <ng-template clrPageTitle>{{ 'section.error_occured' | translate }}</ng-template>
            <app-error [error]="error"></app-error>
        </clr-wizard-page>

</clr-wizard>
