import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { first, takeUntil } from 'rxjs/operators';
import { PageProcessHeaderView, ProcessHeaderSearchCriteria, ProcessheaderService } from 'src/app/core';
import { LanguageService } from 'src/app/core/service/language.service';
import { BaseComponent } from '../../base/base/base.component';

@Component({
  selector: 'app-process-header-list-widget',
  templateUrl: './process-header-list-widget.component.html',
  styleUrls: ['./process-header-list-widget.component.css']
})
export class ProcessHeaderListWidgetComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() partnerId: number;
  @Input() caseId: number;
  @Input() excludeAbsences = false;
  @Input() hideCaseColumn = false;
  @Output() dataExists: EventEmitter<boolean> = new EventEmitter<boolean>();

  // tslint:disable-next-line:variable-name
  loading = true;

  pagedItems: PageProcessHeaderView = {};
  criteria: ProcessHeaderSearchCriteria = {
    page: 0,
    pageSize: 10,
    sortColumn: 'createdDate'
  };

  constructor(
    private languageService: LanguageService,
    private processHeaderController: ProcessheaderService,
  ) {
    super();

    this.languageService.onLangChange().pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe((event) => {
      console.log('languageChanged', event);
      this.refreshContent();
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.partnerId)) {
      this.setCriteriaAndLoad();
    }
    if (this.hasChanged(changes.caseId)) {
      this.setCriteriaAndLoad();
    }
  }

  ngOnInit() {
    this.setCriteriaAndLoad();
  }

  setCriteriaAndLoad() {
    if (this.excludeAbsences) {
      this.criteria.excludeAbsenceType = true;
    }
    if (this.partnerId) {
      this.criteria.partnerId = this.partnerId;
      this.loadData();
    }
    if (this.caseId) {
      this.criteria.caseId = this.caseId;
      this.loadData();
    }
  }

  refresh(state: ClrDatagridStateInterface) {
    this.loading = true;

    if (state.sort
      && typeof state.sort.by === 'string') {
      this.criteria.sortColumn = state.sort.by;
      this.criteria.sortReverse = state.sort.reverse;
    }

    this.criteria.page = state.page.current - 1;
    this.criteria.pageSize = state.page.size;
    this.loadData();
  }

  loadData(): void {
    this.loading = true;
    this.processHeaderController.searchProcessHeaders(this.criteria).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response) => {
        this.pagedItems = response;
        this.loading = false;
        if (response.totalElements === 0) {
          this.dataExists.emit(false);
        }
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  refreshContent(): void {
    const tempPersonCases = this.pagedItems.content;
    this.pagedItems.content = [];
    this.pagedItems.content = tempPersonCases;
  }
}
