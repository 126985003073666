<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading" class="clr-col-12">
    <clr-dg-column [clrDgSortBy]="'referenceNumber'">{{ 'field.reference_nr' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'applType.sort'">{{ 'field.type' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.title' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.start_date' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.event_emission_date' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.insured' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'codeStatus.sort'">{{ 'field.status' | translate }}
    </clr-dg-column>

    <clr-dg-row *ngFor="let pc of pageApplCases?.content" [clrDgItem]="pc">
        <clr-dg-cell><a [routerLink]="['/cases/detail', pc.id]">{{ pc.referenceNumber? pc.referenceNumber:'-' }}</a></clr-dg-cell>
        <clr-dg-cell>{{ pc.type | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell>{{ pc.title }}</clr-dg-cell>
        <clr-dg-cell>{{ pc.startDate | date:'dd.MM.yyyy' }}</clr-dg-cell>
        <clr-dg-cell>{{ pc.processHeaderDate | date:'dd.MM.yyyy' }}</clr-dg-cell>
        <clr-dg-cell><a [routerLink]="['/admin/partner-detail', pc.insuredPartnerId]">{{ pc.insuredName }} {{ pc.insuredGivenName }}</a></clr-dg-cell>
        <clr-dg-cell>{{ pc.cdStatus | lookupApplCode | async }}</clr-dg-cell>
    </clr-dg-row>

    <clr-dg-footer>
        <clr-dg-pagination #pagination [(clrDgPageSize)]="criteria.pageSize" [clrDgLastPage]="pageApplCases.totalPages"
            [clrDgTotalItems]="pageApplCases.totalElements">
            <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">{{ 'message.rows_per_page' | translate }}
            </clr-dg-page-size>
            {{ 'message.total_items' | translate: {begin: pagination.firstItem + 1, end: pagination.lastItem + 1, total: pagination.totalItems} }}
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>