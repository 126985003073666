import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-partner-partner-list-widget',
  templateUrl: './partner-partner-list-widget.component.html',
  styleUrls: ['./partner-partner-list-widget.component.css']
})
export class PartnerPartnerListWidgetComponent implements OnInit {

  @Input()
  partnerPartners = [];

  @Input()
  id: number;

  constructor() { }

  ngOnInit(): void {
  }

}
