<div class="clr-row">
    <mat-form-field class="clr-col-md-4">
        <mat-label translate>field.relationship_type</mat-label>
        <input matInput [value]="partnerPartnerEdit?.relationshipType | lookupRelationshipType: mode | async" disabled>
    </mat-form-field>
    <div class="clr-col-md-8" *ngIf="type === 'type_partner_person' && mode === 'ltr'">
        <app-person-search [(id)]="partnerPartnerEdit.partnerIdRight" (idChange)="onChange()"></app-person-search>
    </div>
    <div class="clr-col-md-8" *ngIf="type === 'type_partner_org' && mode === 'ltr'">
        <app-organisation-search [(id)]="partnerPartnerEdit.partnerIdRight" (idChange)="onChange()"></app-organisation-search>
    </div>
    <div class="clr-col-md-8" *ngIf="type === 'type_partner_orgunit' && mode === 'ltr'">
        <app-org-unit-search [(id)]="partnerPartnerEdit.partnerIdRight" (idChange)="onChange()"></app-org-unit-search>
    </div>
    <div class="clr-col-md-8" *ngIf="type === 'type_partner_person' && mode === 'rtl'">
        <app-person-search [(id)]="partnerPartnerEdit.partnerIdLeft" (idChange)="onChange()"></app-person-search>
    </div>
    <div class="clr-col-md-8" *ngIf="type === 'type_partner_org' && mode === 'rtl'">
        <app-organisation-search [(id)]="partnerPartnerEdit.partnerIdLeft" (idChange)="onChange()"></app-organisation-search>
    </div>
    <div class="clr-col-md-8" *ngIf="type === 'type_partner_orgunit' && mode === 'rtl'">
        <app-org-unit-search [(id)]="partnerPartnerEdit.partnerIdLeft" (idChange)="onChange()"></app-org-unit-search>
    </div>
</div>
