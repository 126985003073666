<div class="clr-row">
    <mat-form-field class="clr-col-md-6">
        <mat-label translate>field.relationship_type</mat-label>
        <input matInput [value]="processHeaderPartner.relationshipType | lookupRelationshipType: 'ltr'  | async" disabled>
    </mat-form-field>
    <div class="clr-col-md-6" *ngIf="typeRight === 'type_partner_person'">
        <app-person-search [(id)]="processHeaderPartner.partnerId" (idChange)="onChange()"></app-person-search>
    </div>
    <div class="clr-col-md-6" *ngIf="typeRight === 'type_partner_org'">
        <app-organisation-search [(id)]="processHeaderPartner.partnerId" (idChange)="onChange()"></app-organisation-search>
    </div>
</div>
