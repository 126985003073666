import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sunet-incapacity-work-form',
  templateUrl: './sunet-incapacity-work-form.component.html',
  styleUrls: ['./sunet-incapacity-work-form.component.css']
})
export class SunetIncapacityWorkFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() incapacityWork: any;
  @Input() processHeaderDate: any;
  @Output() incapacityWorkChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() incapacityWorkValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  translations = [];
  defaultTime = [0, 0, 0];

  constructor(
    private fb: UntypedFormBuilder
  ) {
    super();

    this.form = this.fb.group({
      withIncapacityToWork: this.fb.control(false),
      moreThan1month: this.fb.control(false),
      incapacityToWork : this.fb.group({
        stoppedWorkDate: this.fb.control(null),
        resumedWorkDate: this.fb.control(null),
        resumedWorkPercentage: this.fb.control(null)
      })
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.form.disable();
    }
    this.form.markAllAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.patchValue(this.incapacityWork);
    this.form.markAllAsTouched();
  }

  onChange(): void {
    this.form.markAllAsTouched();
    const modified = _.extend(this.incapacityWork || {}, this.form.value);
    this.incapacityWorkChange.emit(modified);
    this.incapacityWorkValid.emit(this.form.valid);
  }
}
