import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';
@Component({
  selector: 'app-sunet-maternity-form',
  templateUrl: './sunet-maternity-form.component.html',
  styleUrls: ['./sunet-maternity-form.component.scss']
})
export class SunetMaternityFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() maternityDetail: any;
  @Output() maternityDetailValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() maternityDetailChange: EventEmitter<any> = new EventEmitter<any>();

  translations = [];

  constructor(
    private fb: UntypedFormBuilder,
  ) {
    super();

    this.form = this.fb.group({
      expectedBirthDate: new UntypedFormControl('', [Validators.required]),
      stoppedWorkDate: this.fb.control(null),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    console.log('onInit');
    if (this.maternityDetail) {
      this.form.patchValue(this.maternityDetail);
    }
    this.form.markAllAsTouched();

    if (this.disabled) {
      this.form.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.maternityDetail)) {
      this.form.patchValue(this.maternityDetail);
      this.form.markAllAsTouched();
    }
  }

  onChange(): void {
    const maternityDetailModified = _.extend(this.maternityDetail, this.form.value);
    this.maternityDetailValid.emit(this.form.valid);
    this.maternityDetailChange.emit(maternityDetailModified);
    console.log('changed');
  }
}


