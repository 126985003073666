<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-2">
            <mat-label translate>field.position_short</mat-label>
            <input type="number" matInput formControlName="position" required>
        </mat-form-field>
        <mat-form-field class="clr-col-2">
            <mat-label translate>field.title</mat-label>
            <input matInput formControlName="title" required>
        </mat-form-field>
        <mat-form-field class="clr-col-2">
            <mat-label translate>field.quantity</mat-label>
            <input type="number" matInput formControlName="quantity">
        </mat-form-field>
        <mat-form-field class="clr-col-2">
            <mat-label translate>field.amount_net</mat-label>
            <input type="number" matInput formControlName="amountNet">
        </mat-form-field>
        <mat-form-field class="clr-col-2">
            <mat-label translate>field.tax_percentage</mat-label>
            <input type="number" matInput formControlName="tax" min="0" max="100">
        </mat-form-field>
        <mat-form-field class="clr-col-2">
            <mat-label translate>field.amount_gross</mat-label>
            <input type="number" matInput [value]="amountGross" readonly>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-8">
            <mat-label translate>field.description</mat-label>
            <input matInput formControlName="description">
        </mat-form-field>
        <mat-form-field class="clr-col-2">
            <mat-label translate>field.total_net</mat-label>
            <input type="number" matInput [value]="amountTotalNet" readonly>
        </mat-form-field>
        <mat-form-field class="clr-col-2">
            <mat-label translate>field.total_gross</mat-label>
            <input type="number" matInput [value]="amountTotalGross" readonly>
        </mat-form-field>
    </div>

</form>