<mat-form-field class="clr-row clr-col-12">
    <mat-label>{{ fieldName }}</mat-label>
    <mtx-select [items]="result$ | async" bindLabel="referenceNumber" [multiple]="false" [minTermLength]="2" [loading]="loading" clearable="false"
        [typeToSearchText]="'message.enter_2_or_more_characters' | translate" [typeahead]="searchInput$" [(ngModel)]="selectedItem"
        (change)="onChange()">
        <ng-template ng-label-tmp let-item="item">
            {{ item.referenceNumber }} {{ item.title?'-':''}} <small>{{ item.title }}</small>
        </ng-template> 
        <ng-template ng-option-tmp let-item="item">
            {{ item.referenceNumber }} {{ item.title?'-':''}} <small>{{ item.title }}</small>
        </ng-template>
    </mtx-select>
</mat-form-field>