import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guard/auth.guard';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EntryComponent } from './entry/entry.component';
import { TestComponent } from './shared/test/test.component';

const routes: Routes = [
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AuthGuard] },
  { path: 'cases', loadChildren: () => import('./case/case.module').then(m => m.CaseModule), canActivate: [AuthGuard] },
  // tslint:disable-next-line:max-line-length
  { path: 'objects', loadChildren: () => import('./object/object.module').then(m => m.ObjectModule), canActivate: [AuthGuard], data: { functionalities: ['cd_functionality_objects'] } },
  { path: 'processes', loadChildren: () => import('./process/process.module').then(m => m.ProcessModule), canActivate: [AuthGuard] },
  { path: 'employees', loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule), canActivate: [AuthGuard] },
  { path: 'entry', component: EntryComponent },
  { path: 'test', component: TestComponent },
  { path: '', redirectTo: 'entry', pathMatch: 'full' },
  { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
