import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first } from 'rxjs';

export enum EnvEnum {
  Local = 'LOCAL',
  Test = 'TEST',
  Prod = 'PROD'
}
@Injectable({ providedIn: 'root' })
export class EnvConfigService {

  private settings = new Map();

  private http: HttpClient;

  constructor(
    private readonly httpHandler: HttpBackend
  ) {
    this.http = new HttpClient(httpHandler);
  }

  init(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const env = this.getEnvironment();
      console.log('env', env);
      this.http.get(`config/config.${env}.json`).pipe(
        first()
      ).subscribe((resp) => {
        for (var key in resp) {
          console.log('setting key ' + key + ': ', resp[key]);
          this.settings.set(key, resp[key]);
        }
        resolve(true);
      },
      (error) => {
        console.error(error);
        reject(error);
      });
   });
  }

  get msal(): any {
    return this.settings.get('msal');
  }

  get production(): boolean {
    return this.settings.get('production');
  }


  private getEnvironment(): string {
    if (location.hostname === 'localhost') {
      return EnvEnum.Local;
    } else if (location.hostname.startsWith('test')) {
      return EnvEnum.Test;
    } else  {
      return EnvEnum.Prod;
    }
  }

}
