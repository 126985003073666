import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef, ViewRef } from '@angular/core';
import { AuthorizationService } from '../service/authorization.service';

@Directive({
  selector: '[appEditAllowed]'
})
export class EditAllowedDirective implements OnDestroy {

  private hasView = false;
  private viewRef: ViewRef;

  private immutable = false;
  private type: string;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private authorizationService: AuthorizationService
  ) { }

  @Input() set appEditAllowedImmutable(immutable: boolean) {
    this.immutable = immutable;
    this.evaluate();
  }

  @Input() set appEditAllowed(type: string) {
    this.type = type;
    this.evaluate();
  }

  private evaluate() {
    const show = !this.immutable && this.authorizationService.isEditAllowed(this.type);

    if (!this.hasView && show) {
      this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
    if (!show && this.hasView) {
      this.viewContainerRef.detach();
    }
    if (show && this.hasView) {
      this.viewContainerRef?.insert(this.viewRef, 0);
    }
  }
  ngOnDestroy(): void {
    if (this.hasView) {
      this.viewRef.destroy();
    }
  }
}
