import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-base-search',
  templateUrl: './base-search.component.html',
  styleUrls: ['./base-search.component.css']
})
export class BaseSearchComponent extends BaseComponent {

  @Input() id: number;
  @Input() ids: number[];
  @Input() type: string;
  @Input() required = false;
  @Output() idChange: EventEmitter<number> = new EventEmitter<number>();
  @Output() idsChange: EventEmitter<number[]> = new EventEmitter<number[]>();

  loading = false;
  searchInput$ = new Subject<string>();
  nothingFound = false;

  constructor() {
    super();
  }

  protected openWizardInNewWindow(route: string) {
    const url = window.location.href.substring(0, window.location.href.indexOf('#') + 2);
    window.open(url + route, '_blank');
  }
}
