<div class="clr-row">
    <mat-form-field class="clr-col-md-4">
        <mat-label translate>field.relationship_type</mat-label>
        <input matInput [value]="casePartner.relationshipType | lookupRelationshipType: 'ltr'  | async" disabled>
    </mat-form-field>
    <div [ngClass]="{'clr-col-md-6': showDegreeOfResponsability, 'clr-col-md-8': !showDegreeOfResponsability}" *ngIf="typeRight === 'type_partner_person'">
        <app-person-search [(id)]="casePartnerEdit.partnerId" (idChange)="onChange()"></app-person-search>
    </div>
    <div [ngClass]="{'clr-col-md-6': showDegreeOfResponsability, 'clr-col-md-8': !showDegreeOfResponsability}" *ngIf="typeRight === 'type_partner_org'">
        <app-organisation-search [(id)]="casePartnerEdit.partnerId" (idChange)="onChange()"></app-organisation-search>
    </div>
    <mat-form-field class="clr-col-md-2" *ngIf="typeRight !== 'type_partner_orgunit' && showDegreeOfResponsability">
        <mat-label translate>field.degree_of_responsability</mat-label>
        <input matInput type="number" [(ngModel)]="casePartner.degreeOfResponsability" min="0" max="100" (change)="onChange()">
    </mat-form-field>
    <div class="clr-col-md-8" *ngIf="typeRight === 'type_partner_orgunit'">
        <app-org-unit-search [(id)]="casePartnerEdit.partnerId" (idChange)="onChange()"></app-org-unit-search>
    </div>
</div>
