import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sunet-additions-accident-form',
  templateUrl: './sunet-additions-accident-form.component.html',
  styleUrls: ['./sunet-additions-accident-form.component.css']
})
export class SunetAdditionsAccidentFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() accidentDetails: any;
  @Input() additionsAccident: any;
  @Output() additionsAccidentChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() additionsAccidentValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  cdActivities$: any;

  translations = [];
  defaultTime = [0, 0, 0];

  constructor(
    private translateService: TranslateService,
    private codeService: AppCodeService,
    private fb: UntypedFormBuilder
  ) {
    super();

    this.translations = this.translateService.instant(['field.accident_professional', 'field.accident_non_professional']);

    this.form = this.fb.group({
      professionalAccident: this.fb.control(false),
      absenceReason: this.fb.control(null),
      lastWorkingDate: this.fb.control(null),
      involvedObjects: this.fb.control(null),
      cdActivity: this.fb.control(null),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    this.cdActivities$ = this.codeService.getList('cd_activity', false);

    if (this.disabled) {
      this.form.disable();
    }
    this.form.markAllAsTouched();

  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.patchValue(this.additionsAccident);
    this.form.markAllAsTouched();
  }

  onChange(): void {
    const modified = _.extend(this.additionsAccident || {}, this.form.value);
    this.additionsAccidentChange.emit(modified);
    this.additionsAccidentValid.emit(this.form.valid);
  }
}
