import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { concat, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApplCaseLiteView, ApplCaseSearchCriteria, CaseService, PageApplCaseLiteView } from 'src/app/core';
import { BaseSearchComponent } from '../../base/base-search/base-search.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-case-search',
  templateUrl: './case-search.component.html',
  styleUrls: ['./case-search.component.css']
})
export class CaseSearchComponent extends BaseSearchComponent implements OnInit, OnChanges {

  @Input() fieldName: string;

  criteria: ApplCaseSearchCriteria = {
    pageSize: 50,
    sortColumn: 'referenceNumber'
  };

  result$: Observable<ApplCaseLiteView[]>;
  selectedItem: ApplCaseLiteView;

  constructor(
    private caseController: CaseService,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.id)) {
      this.caseController.getByIdApplCaseLite(this.id).pipe(
        takeUntil(this.ngUnsubscribe),
        first()
      ).subscribe(response => {
        this.selectedItem = response;
        this.ref.markForCheck();
      });
    }
  }

  ngOnInit(): void {
    this.search();
  }

  trackByFn(item: ApplCaseLiteView) {
    return item.id;
  }

  onChange() {
    this.idChange.emit(this.selectedItem.id);
  }

  private search() {
    this.result$ = concat(
      of([]), // default item
      this.searchInput$.pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap((term) => {
          this.criteria.keywords = term;
          return this.caseController.searchApplCaseLite(this.criteria).pipe(
            catchError(() => of([])), // empty list on error
            map((response: PageApplCaseLiteView) => response.content),
            tap(() => this.loading = false)
          );
        })
      )
    );
  }
}
