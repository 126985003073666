<clr-modal [(clrModalOpen)]="show" clrModalSize="xl" [formGroup]="form">
    <h3 class="modal-title">{{ toTranslateKey(entityEnum | translate)}}</h3>
    <div class="modal-body">
        <div class="clr-row">
            <mat-form-field class="clr-col-md-6">
                <mat-label translate>field.to_recepients</mat-label>
                <mtx-select [addTag]="isEmail" addTagText="test" formControlName="toRecipients" [searchable]="true" [multiple]="true" [appendTo]="'body'"
                    required>
                    <mtx-option *ngFor="let toRecipient of form.value.toRecipient" [value]="toRecipient">
                        {{ toRecipient }}</mtx-option>
                </mtx-select>
            </mat-form-field>
            <mat-form-field class="clr-col-md-6">
                <mat-label translate>field.cc_recepients</mat-label>
                <mtx-select [addTag]="isEmail" addTagText="test" formControlName="ccRecipients" [searchable]="true" [multiple]="true" [appendTo]="'body'">
                    <mtx-option *ngFor="let ccRecipient of form.value.ccRecipient" [value]="ccRecipient">
                        {{ ccRecipient }}</mtx-option>
                </mtx-select>
            </mat-form-field>
        </div>
        <div class="clr-row">
            <mat-form-field class="clr-col-md-12">
                <mat-label translate>field.subject</mat-label>
                <input matInput formControlName="subject" maxlength="80" required />
            </mat-form-field>
        </div>
        <h4 translate>section.attachments</h4>
        <div class="clr-row">
            <div *ngFor="let attachment of object.attachments" class="clr-col-md-6">
                {{ attachment.name }} ({{ attachment.type | lookupApplType | async }})
            </div>
        </div>
        <div class="clr-row" *ngIf="!form.value.id">
            <file-upload class="clr-col-md-12 clr-col-lg-12" [control]="fileUploadControl" multiple="true">
                <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
                    <file-upload-drop-zone>
                        <ng-container *ngIf="isFileDragDropAvailable; else isNotDragDropAvailable" translate>
                            {{ 'message.fileupload_drag_and_drop' | translate }}
                        </ng-container>
                        <ng-template #isNotDragDropAvailable>
                            {{ 'message.fileupload_click' | translate }}
                        </ng-template>
                    </file-upload-drop-zone>
                </ng-template>
        
                <ng-template let-i="index" let-file="file" let-control="control" #item>
                    <file-upload-list-item [index]="i" [file]="file" [control]="control">{{'message.fileupload_remove' | translate}}</file-upload-list-item>
                </ng-template>
            </file-upload>
        </div>

        <div class="clr-row">
            <angular-editor class="clr-col-md-12" formControlName="content" [config]="editorConfig"></angular-editor>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="onCancel()" translate>button.cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="!form.valid" (click)="onFinish()" translate>button.send</button>
    </div>
</clr-modal>