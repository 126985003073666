export enum EntityEnum {
    Absence = 'absence',
    AccidentReport = 'accident_report',
    Case = 'case',
    Email = 'email',
    IllnessReport = 'illness_report',
    MaternityReport = 'maternity_report',
    PdfReport = 'pdf_report',
    Process = 'process',
    Object = 'object',
    Organisation = 'organisation',
    Orgunit = 'orgunit',
    Person = 'person',
    Role = 'role',
    User = 'user'
}

export function toTranslateKey(entityEnum: EntityEnum): string {
    return 'entity.' + entityEnum;
}
