import { Component, Input } from '@angular/core';
import { BankAccountForm } from 'src/app/core/model/bankAccountForm';

@Component({
  selector: 'app-account-list-widget',
  templateUrl: './account-list-widget.component.html',
  styleUrls: ['./account-list-widget.component.scss']
})
export class AccountListWidgetComponent {

  @Input()
  accounts = [];

  constructor() { }

  account(account: BankAccountForm): string {
    if (account.cdAccountType === 'cd_account_type_iban') {
      return account.iban;
    }

    return account.accountNr;
  }

}
