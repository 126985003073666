import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/base/base/base.component';
import { AlertService } from '../service/alert.service';
import { AuthenticationService } from '../service/authentication.service';
import { EnvConfigService } from '../service/env-config.service';
import { LanguageService } from '../service/language.service';
@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.css']
})
export class TopNavigationComponent extends BaseComponent implements OnInit {

  authenticated = false;
  name = '';
  tenant = '';

  hideWarning = false;
  production = true;

  alertCounter: number = -1;
  currentLang: string;

  constructor(
    public router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private envConfigService: EnvConfigService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private alertService: AlertService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.currentLang = this.languageService.getCurrentLang();
    console.log('currentLanguage', this.currentLang);
    this.languageService.onLangChange().pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe((event: LangChangeEvent) => {
      this.currentLang = event.lang;
    });

    this.production = this.envConfigService.production;
    this.authenticationService.auhtenticationInfo.pipe(
      takeUntil(this.ngUnsubscribe),
      ).subscribe(
      ai => {
        this.authenticated = ai && (ai.user && ai.user.username != null);
        if (this.authenticated === true) {
          this.name = ai.user.firstName + ' ' + ai.user.lastName;
          this.tenant = ai.tenantName;
        }
      }
    );
  }

  counterChanged(event: any) {
    console.log('counterChanged', event);
    this.alertCounter = event;
  }

  logout() {
    this.authenticationService.logout();
  }

  changeLanguage(language: string): void {
    // log//console.log('changing language to ', language);
    this.languageService.changeLanguage(language);
    this.alertService.info(this.translateService.instant('notification.language_changed'));
  }

  login() {
    this.authenticationService.login();
  }

  switch() {
    this.authenticationService.chooseAccount();
  }

}
