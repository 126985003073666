import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ApplEventView } from 'src/app/core';

@Component({
  selector: 'app-events-widget',
  templateUrl: './events-widget.component.html',
  styleUrls: ['./events-widget.component.scss']
})
export class EventsWidgetComponent implements OnChanges {

  @Input()
  events: ApplEventView[] = [];

  items: ApplEventView[] = [];

  constructor(
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.events) {
      this.items = [];
      this.items = this.events;
    }
  }
}
