import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ObjectPartnerForm } from 'src/app/core';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-object-partner-form',
  templateUrl: './object-partner-form.component.html',
  styleUrls: ['./object-partner-form.component.css']
})
export class ObjectPartnerFormComponent extends BaseFormComponent implements OnChanges {

  @Input() objectPartner: ObjectPartnerForm;
  @Input() typeRight: string;
  @Output() objectPartnerValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() objectPartnerChange: EventEmitter<ObjectPartnerForm> = new EventEmitter<ObjectPartnerForm>();

  objectPartnerEdit: ObjectPartnerForm = {};
  partnerId: number;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.objectPartner)) {
      this.objectPartnerEdit = changes.objectPartner.currentValue;
    }
  }

  onChange(): void {
    const valid = this.objectPartnerEdit.partnerId != null;
    if (valid) {
      this.objectPartnerChange.emit(this.objectPartnerEdit);
    }
    this.objectPartnerValid.emit(valid);
    console.log('changed', this.objectPartnerEdit.partnerId);
  }
}

