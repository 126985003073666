import { Component, Input, OnInit } from '@angular/core';
import { ClrDatagridStateInterface } from '@clr/angular';
import { LangChangeEvent } from '@ngx-translate/core';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { ApplCaseSearchCriteria, CaseService, PageApplCaseView } from 'src/app/core';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { BaseComponent } from 'src/app/shared/base/base/base.component';

export enum CasesWidgetMode {
  Own,
  Overdue
}

@Component({
  selector: 'app-cases-widget',
  templateUrl: './cases-widget.component.html',
  styleUrls: ['./cases-widget.component.css']
})
export class CasesWidgetComponent extends BaseComponent implements OnInit {

  @Input() mode: CasesWidgetMode = CasesWidgetMode.Own;

  loading = true;

  pageApplCases: PageApplCaseView = {};
  criteria: ApplCaseSearchCriteria = {
    page: 0,
    pageSize: 10,
    sortColumn: 'referenceNumber',
    cdStatuses: 'cd_case_status_open,cd_case_status_in_progress'
  };

  constructor(
    private languageService: LanguageService,
    private caseController: CaseService,
    private authenticationService: AuthenticationService,
  ) {
    super();

    this.languageService.onLangChange().pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe((event: LangChangeEvent) => {
      console.log('languageChanged');
      this.refreshContent();
    });
  }

  ngOnInit() {
    if (this.mode === CasesWidgetMode.Own) {
      this.authenticationService.auhtenticationInfo.pipe(
        takeUntil(this.ngUnsubscribe),
        map(ai => ai?.partnerId),
        filter(item => item != null)
      ).subscribe(partnerId => {
        this.criteria.partnerId = partnerId;
        this.loadData();
      });
    } else {
      this.criteria.overdueOnly = true;
      this.loadData();
    }
  }

  refresh(state: ClrDatagridStateInterface) {
    this.loading = true;

    if (state.sort
      && typeof state.sort.by === 'string') {
      this.criteria.sortColumn = state.sort.by;
      this.criteria.sortReverse = state.sort.reverse;
    }

    this.criteria.page = state.page.current - 1;
    this.criteria.pageSize = state.page.size;
    this.loadData();
  }

  loadData(): void {
    this.loading = true;
    this.caseController.searchApplCase(this.criteria).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response) => {
        this.pageApplCases = response;
        this.loading = false;
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  refreshContent(): void {
    const tempPersonCases = this.pageApplCases.content;
    this.pageApplCases.content = [];
    this.pageApplCases.content = tempPersonCases;
  }
}
