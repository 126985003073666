/**
 * myclaims API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface AbsenceView { 
    caseId?: number;
    endDate?: string;
    reference?: string;
    createdDate?: string;
    processHeaderDate?: string;
    incapacityForWork?: number;
    cdStatus?: string;
    insuredName?: string;
    insuredPartnerId?: number;
    startDate?: string;
    caseType?: string;
    caseReferenceNumber?: string;
    caseTitle?: string;
    id?: number;
    type?: string;
}

