<div clrLayout="vertical">
    <div class="toolbar" *ngIf="selectable && relTypes?.length > 0">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.type</mat-label>
            <mtx-select [searchable]="true" [(ngModel)]="relTypeSelected" [appendTo]="'body'">
                <mtx-option *ngFor="let relType of relTypes" [value]="relType">
                    {{ relType.typeRight | lookupApplType | async }}</mtx-option>
            </mtx-select>
        </mat-form-field>

        <button class="btn btn-sm" (click)="onAdd()"  translate>button.add_identifier</button>
    </div>

    <div class="clr-row" *ngFor="let ident of wrappedItems, index as i">
        <div class="clr-col-md-11">
            <app-identifier-form [fieldRequired]="ident.required" [(identifier)]="ident.item" (identifierValid)="onChange(i, $event)">
            </app-identifier-form>
        </div>
        <div class="clr-col-md-1">
            <span *appDeleteAllowed="ident?.item?.type;immutable:!selectable" (click)="onDelete(i)"><clr-icon shape="trash"></clr-icon></span>
        </div> 
    </div>
</div>