// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  showSalary: true,
  baseHref: '/',
  API_BASE_PATH: '',
  apiUrl: '/api',
  msal: {
    clientId: '04699797-2ec0-4aa3-a661-6fd907b7aec5', // client ID for myClaimsTest
    authority: 'https://login.microsoftonline.com/common', // This is your tenant ID
    redirectUri: 'https://test.myclaims.ch:8082'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
