import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { concat, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, distinctUntilChanged, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PagePolicyHeaderLiteView, PolicyHeaderLiteView, PolicyHeaderSearchCriteria, PolicyService } from 'src/app/core';
import { BaseSearchComponent } from '../../base/base-search/base-search.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-policy-header-search',
  templateUrl: './policy-header-search.component.html',
  styleUrls: ['./policy-header-search.component.css']
})
export class PolicyHeaderSearchComponent extends BaseSearchComponent implements OnInit, OnChanges {

  criteria: PolicyHeaderSearchCriteria;

  search$ = new ReplaySubject<PolicyHeaderSearchCriteria>();

  result$: Observable<any[]>;
  selectedItem: PolicyHeaderLiteView;

  constructor(
    private policyController: PolicyService,
    private ref: ChangeDetectorRef
  ) {
    super();

    this.criteria = {
      pageSize: 40,
      sortColumn: 'policyNumber',
      type: this.type
    };
  }

  ngOnInit(): void {
    this.search();

    this.searchInput$.next('');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.id)) {
      this.policyController.findByIdPolicyHeaderLite(this.id).pipe(
        takeUntil(this.ngUnsubscribe),
        first()
      ).subscribe(response => {
        this.selectedItem = response;
        this.ref.markForCheck();
      });
    }
    if (this.hasChanged(changes.type)) {
      this.criteria.type = this.type;
      this.search$.next(this.criteria);
    }
  }

  trackByFn(item: PolicyHeaderLiteView) {
    return item.id;
  }

  onChange() {
    this.idChange.emit(this.selectedItem?.id);
  }

  private search() {
    this.result$ = concat(
      of([]), // default item
      this.search$.pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap((criteria) => {
          return this.policyController.searchPolicyHeaderLite(criteria).pipe(
            catchError(() => of([])), // empty list on error
            map((response: PagePolicyHeaderLiteView) => response.content),
            tap( result => result.forEach( (item: any) => {
              item.search = [item.policyNumber, item.name, item.insurerName].join(' ');
            })),
            tap(() => this.loading = false)
          );
        })
      )
    );
  }
}
