<clr-wizard #wizardlg (clrWizardOnCancel)="onCancel()" (clrWizardOnFinish)="onFinish()" (clrWizardOnNext)="onNext()" [(clrWizardOpen)]="show"
    [clrWizardClosable]="false" [clrWizardPreventDefaultCancel]="true" [clrWizardPreventDefaultNext]="true" clrWizardSize="xl">
    <clr-wizard-title>
        {{ processHeader?.type | lookupApplType | async }}
    </clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">{{ 'button.cancel' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{ 'button.back' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{ 'button.next' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{ 'button.finish' | translate }}</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!processHeaderValid" [clrWizardPageHasError]="!(processHeaderValid )">
        <ng-template clrPageTitle>{{ 'section.disability_details' | translate }}</ng-template>
        <app-process-header-lite-form [allowSelectCase]="false" [(processHeader)]="processHeaderLite" (processHeaderValid)="processHeaderValid = $event">
        </app-process-header-lite-form>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!phPartnersValid">
        <ng-template clrPageTitle>{{ 'section.relations_to_partner' | translate }}</ng-template>
        <app-process-header-partner-editor [(processHeaderPartners)]="partners" [typeLeft]="processHeader?.type" (valid)="phPartnersValid = $event"
            (processHeaderPartnersChange)="processPartners()">
        </app-process-header-partner-editor>
        <h4 translate>section.copy_data_from_partner</h4>
        <div class="toolbar">
            <button (click)="loadInsured()" [disabled]="!insuredPartnerId" class="btn btn-sm">
                <clr-icon shape="copy"></clr-icon> {{ 'button.copy_insured_data' | translate }}
            </button>
        </div>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid">
        <ng-template clrPageTitle>{{ 'section.form' | translate }}</ng-template>
        <app-pdf-form [type]="processHeader?.type" [(formData)]="formData"></app-pdf-form>
    </clr-wizard-page>
    <clr-wizard-page *ngIf="error" [clrWizardPageHasError]="error != null">
        <ng-template clrPageTitle>{{ 'section.error_occured' | translate }}</ng-template>
        <app-error [error]="error"></app-error>
    </clr-wizard-page>

</clr-wizard>