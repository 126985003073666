<mat-form-field class="clr-row clr-col-12">
    <mat-label translate>field.organisation</mat-label>
    <mtx-select *ngIf="multipleSelect" [items]="result$ | async" bindLabel="name" [multiple]="true" [minTermLength]="2" [loading]="loading" [clearable]="true"
        [typeToSearchText]="'message.enter_2_or_more_characters' | translate" [typeahead]="searchInput$" [(ngModel)]="selectedItems" [appendTo]="'body'"
        (change)="onChange()">
        <ng-template ng-label-tmp let-item="item">
            {{ item.name }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            {{ item.name }}
        </ng-template>
    </mtx-select>
    <mtx-select *ngIf="!multipleSelect"  [items]="result$ | async" bindLabel="name" [minTermLength]="2" [loading]="loading" [clearable]="true"
        [typeToSearchText]="'message.enter_2_or_more_characters' | translate" [typeahead]="searchInput$" [(ngModel)]="selectedItem" [appendTo]="'body'"
        (change)="onChange()">
        <ng-template ng-label-tmp let-item="item">
            {{ item.name }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            {{ item.name }}
        </ng-template>
    </mtx-select>
</mat-form-field>