<form [formGroup]="form" clrForm clrLayout="vertical">
    <div class="clr-row">
        <div class="clr-col-md-12 field">
            <label id="with-incapacity-work-label" translate>field.with_incapacity_work</label>
            <mat-radio-group aria-labelledby="with-incapacity-work-label" formControlName="withIncapacityToWork" (change)="onChange()">
                <mat-radio-button [value]="false">{{ 'button.no' | translate }}</mat-radio-button>
                <mat-radio-button [value]="true">{{ 'button.yes' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>

    <div class="clr-row" formGroupName="incapacityToWork" *ngIf="form.value.withIncapacityToWork == true">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.stopped_work_date</mat-label>
            <input [ngxMatDatetimePicker]="picker2" formControlName="stoppedWorkDate" matInput [min]="processHeaderDate" [required]="form.value.withIncapacityToWork == true">
            <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
            <ngx-mat-datetime-picker [defaultTime]="defaultTime" #picker2></ngx-mat-datetime-picker>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <div class="clr-col-md-12 field"  *ngIf="form.value.withIncapacityToWork == true">
            <label id="more-than-1-month-label" translate>field.more_than_1_month</label>
            <mat-radio-group aria-labelledby="more-than-1-month-label" formControlName="moreThan1month">
                <mat-radio-button [value]="false">{{ 'button.no' | translate }}</mat-radio-button>
                <mat-radio-button [value]="true">{{ 'button.yes' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="clr-row" formGroupName="incapacityToWork" *ngIf="form.value.withIncapacityToWork == true">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.resumed_work_date</mat-label>
            <input [matDatepicker]="picker3" formControlName="resumedWorkDate" matInput [min]="processHeaderDate">
            <mat-datepicker-toggle [for]="picker3" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.resumed_work_percentage</mat-label>
            <input type="number" formControlName="resumedWorkPercentage" matInput min="0" max="100">
        </mat-form-field>
    </div>
</form>
