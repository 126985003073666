import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { first, takeUntil, tap } from 'rxjs/operators';
import { ApplCaseForm, CaseService } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import { AppTypeService } from 'src/app/core/service/app-type.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { ValidationHelperService } from 'src/app/core/service/validation-helper.service';
import { ReferenceExistsValidator } from 'src/app/core/validator/reference-exists-validator';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';


@Component({
  selector: 'app-case-form',
  templateUrl: './case-form.component.html',
  styleUrls: ['./case-form.component.css']
})
export class CaseFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() case: ApplCaseForm;
  @Output() caseValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() caseChange: EventEmitter<ApplCaseForm> = new EventEmitter<ApplCaseForm>();

  subTypes$: Observable<any[]>;
  cdStatuses$: Observable<any[]>;
  cdLanguages$: Observable<any[]>;
  cdNotInReport$: Observable<any[]>;
  cdCovered$: Observable<any[]>;

  withSubTypes = true;

  hideDescription = true;

  constructor(
    private codeService: AppCodeService,
    private typeService: AppTypeService,
    private caseService: CaseService,
    private validationHelperService: ValidationHelperService,
    private languageService: LanguageService,
    private fb: UntypedFormBuilder,
  ) {
    super();
    this.form = this.fb.group({
      type: this.fb.control('', [Validators.required]),
      subType: this.fb.control(''),
      caseUuid: this.fb.control(''),
      cdCaseKind: this.fb.control('cd_case_kind_mat'),
      // tslint:disable-next-line:max-line-length
      referenceNumber: this.fb.control('', {
        validators: [Validators.required],
        asyncValidators: [ReferenceExistsValidator.caseReferenceExistsValidator(this.caseService, validationHelperService)],
        updateOn: 'blur'}),
      referenceNumber2: this.fb.control(''),
      title: this.fb.control(''),
      description: this.fb.control(''),
      remark: this.fb.control(''),
      cdStatus: this.fb.control('', [Validators.required]),
      cdCovered: this.fb.control(''),
      cdLanguageReport: this.fb.control(''),
      cdNotInReport: this.fb.control(''),
      entryDate: this.fb.control(null),
      period: this.fb.group({
        start: this.fb.control([Validators.required]),
        end: this.fb.control(null),
      }),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe( () => {
      this.onChange();
      this.validationHelperService.setType(this.form.value.type);
    });
    this.form.statusChanges.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe(() => {
      this.onChange();
    });
  }

  ngOnInit(): void {
    if (this.case) {
      this.validationHelperService.setId(this.case.id);
      this.validationHelperService.setType(this.case.type);
      this.form.patchValue(this.case);
    }
    this.form.markAllAsTouched();

    this.subTypes$ = this.typeService.getListRelatedToReltypeForSubs('applcase_sub', 'applcase', this.case?.type).pipe(
      tap( result => {
        if (result.length > 0) {
          this.form.get('subType').addValidators(Validators.required);
        }
       })
    );
    this.cdStatuses$ = this.codeService.getList('cd_case_status', false);
    this.cdCovered$ = this.codeService.getList('cd_covered', false);
    this.cdLanguages$ = this.codeService.getList('cd_language', false);
    this.cdNotInReport$ = this.codeService.getList('cd_not_in_rep', false);
    this.setDefaults();
  }

  private setDefaults() {
    if (this.case?.id) {
      return;
    }
    this.cdStatuses$.pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(result => {
      this.form.patchValue({
        cdStatus: result[0].id,
        cdLanguageReport: 'cd_language_' + this.languageService.getCurrentLang()
      });
      this.form.markAllAsTouched();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.case)) {
      this.subTypes$ = this.typeService.getListRelatedToReltypeForSubs('applcase_sub', 'applcase', this.case?.type);
      this.validationHelperService.setId(this.case.id);
      this.validationHelperService.setType(this.case.type);
      this.form.reset();
      this.form.patchValue(this.case);
      this.form.markAllAsTouched();

      this.onChange();
    }
  }

  onChange(): void {
    if (this.form.pending) {
      return;
    }
    this.caseValid.emit(this.form.valid);
    if (this.form.valid === true) {
      this.case = _.extend(this.case, this.form.value);
      this.caseChange.emit(this.case);
    }
    console.log('changed');
  }
}
