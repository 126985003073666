import { Component, Input, OnInit } from '@angular/core';
import * as FileSaver from 'file-saver';
import { first, takeUntil } from 'rxjs/operators';
import { DocumentRefForm, DocumentService } from 'src/app/core';
import { DynamicComponentService } from 'src/app/core/service/dynamic-component.service';
import { BaseComponent } from '../../base/base/base.component';
import { PdfViewerComponent } from '../../pdf-viewer/pdf-viewer.component';

@Component({
  selector: 'app-document-ref-list-widget',
  templateUrl: './document-ref-list-widget.component.html',
  styleUrls: ['./document-ref-list-widget.component.css']
})
export class DocumentRefListWidgetComponent extends BaseComponent implements OnInit {

  @Input()
  documents = [];

  selected: DocumentRefForm;

  constructor(
    private documentService: DocumentService,
    private dynamicComponentService: DynamicComponentService
  ) {
    super();
  }

  ngOnInit(): void {
  }

  onDownload() {
    this.documentService.download(this.selected.document.id, 'body', true, { httpHeaderAccept: '*/*' }).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: Blob) => {
        console.log('success download', response);
        const data = new Blob([response], { type: this.selected.document.mimeType });
        FileSaver.saveAs(data, this.selected.document.name);
      },
      (error) => {
        console.log('error download', error);
      }
    );
  }

  openViewer() {
    // tslint:disable-next-line:max-line-length
    this.dynamicComponentService.openEditor(PdfViewerComponent, this.dynamicComponentService.buildParams(this.selected.document.id), (result) => {});
  }



}
