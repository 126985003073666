<form [formGroup]="form" clrForm clrLayout="vertical">
    <div class="clr-row">
        <div class="clr-col-md-12 field">
            <label id="last-working-date-label" translate>field.insurance_branch</label>
            <mat-radio-group aria-labelledby="last-working-date-label" formControlName="professionalAccident">
                <mat-radio-button [value]="true">{{ translations['field.accident_professional'] }}</mat-radio-button>
                <mat-radio-button [value]="false">{{ translations['field.accident_non_professional'] }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="clr-row" [hidden]="form.value.professionalAccident">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.last_working_date</mat-label>
            <input [ngxMatDatetimePicker]="picker2" formControlName="lastWorkingDate" matInput [required]="!form.value.professionalAccident" [max]="accidentDetails?.accidentDate">
            <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
            <ngx-mat-datetime-picker [defaultTime]="defaultTime" #picker2></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.activity_at_accident</mat-label>
            <mtx-select [searchable]="true" formControlName="cdActivity" [required]="!form.value.professionalAccident">
                <mtx-option *ngFor="let cdActivity of cdActivities$ | async" [value]="cdActivity.id">
                    {{ cdActivity | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row" *ngIf="form.value.professionalAccident == false">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.reason_absence</mat-label>
            <textarea [rows]="1" formControlName="absenceReason" matInput maxlength="40"></textarea>
        </mat-form-field>
    </div>
    <div class="clr-row" *ngIf="form.value.professionalAccident == true">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.involved_objects</mat-label>
            <textarea [rows]="1" formControlName="involvedObjects" matInput maxlength="80"></textarea>
        </mat-form-field>
    </div>

</form>
