<clr-datagrid>
    <clr-dg-column [clrDgSortBy]="'type'">{{ 'field.type' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'referenceNumber'">{{ 'field.reference_nr' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'position'">{{ 'field.position' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.description' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.quantity' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.amount_net' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.tax_percentage' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.amount_gross' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.amount_total_net_gross' | translate }}
    </clr-dg-column>

    <clr-dg-row *ngFor="let pi of processItems">
        <clr-dg-cell>{{ pi.type | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell>{{ pi.referenceNumber }}</clr-dg-cell>
        <clr-dg-cell>{{ pi.position }}</clr-dg-cell>
        <clr-dg-cell>{{ pi.description }}</clr-dg-cell>
        <clr-dg-cell>{{ pi.quantity }}</clr-dg-cell>
        <clr-dg-cell>{{ pi.amountNet | number: '1.2-2' }}</clr-dg-cell>
        <clr-dg-cell>{{ pi.tax | number: '1.2-2' }}</clr-dg-cell>
        <clr-dg-cell>{{ pi.amountGross | number: '1.2-2' }}</clr-dg-cell>
        <clr-dg-cell>{{ pi.amountTotalNet | number: '1.2-2' }} / {{ pi.amountTotalGross | number: '1.2-2' }}</clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>