import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CasePartnerForm } from 'src/app/core';
import { PartnerUpdateService } from 'src/app/core/service/partner-update.service';
import { BaseFormComponent } from '../../base/base-form/base-form.component';


@Component({
  selector: 'app-case-partner-form',
  templateUrl: './case-partner-form.component.html',
  styleUrls: ['./case-partner-form.component.css']
})
export class CasePartnerFormComponent extends BaseFormComponent implements OnChanges {

  @Input() casePartner: CasePartnerForm;
  @Input() typeRight: string;
  @Input() relType: string;
  @Input() showDegreeOfResponsability = false;
  @Output() casePartnerValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() casePartnerChange: EventEmitter<CasePartnerForm> = new EventEmitter<CasePartnerForm>();

  casePartnerEdit: CasePartnerForm = {};
  partnerId: number;

  constructor(
    private partnerUpdateService: PartnerUpdateService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.casePartner)) {
      this.casePartnerEdit = changes.casePartner.currentValue;
    }
  }

  onChange(): void {
    const valid = this.casePartnerEdit.partnerId != null;
    if (valid) {
      this.partnerUpdateService.publish(this.casePartnerEdit.partnerId, this.relType);
      this.casePartnerChange.emit(this.casePartnerEdit);
    }
    this.casePartnerValid.emit(valid);
    console.log('changed', this.casePartnerEdit.partnerId);
  }
}
