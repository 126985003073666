<div *ngIf="show" class="alert alert-danger" role="alert">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
            </div>
            <span class="alert-text" *ngIf="!errorMessage">
                An error occured: {{ error | json }}
            </span>
            <span class="alert-text" *ngIf="errorMessage">
                {{ errorMessage }}
            </span>
        </div>
    </div>
</div>