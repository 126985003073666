import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take, takeUntil } from 'rxjs/operators';
import { CaseService, FinancialLine } from 'src/app/core';
import { BaseComponent } from '../../base/base/base.component';

@Component({
  selector: 'app-financial-view-widget',
  templateUrl: './financial-view-widget.component.html',
  styleUrls: ['./financial-view-widget.component.css']
})
export class FinancialViewWidgetComponent extends BaseComponent implements OnChanges {

  @Input()
  caseId: number;

  @Output()
  hide: EventEmitter<boolean> = new EventEmitter<boolean>();

  rows: FinancialLine[] = [];

  translations = [];

  constructor(
    private translateService: TranslateService,
    private caseService: CaseService
  ) {
    super();
    this.translations = this.translateService.instant(['financial_view.subtotal', 'financial_view.total', 'financial_view.header', 'financial_view.item']);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes?.caseId)) {
      this.load();
    }
  }

  load(): void {
    this.caseService.getFinancialView(this.caseId).pipe(
      takeUntil(this.ngUnsubscribe),
      take(1)
    ).subscribe( data => {
      this.rows = data;

      if (this.rows.length == 0) {
        this.hide.emit(true);
      }
    });
  }

}
