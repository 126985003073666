import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { AsYouType } from 'libphonenumber-js';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PartnerWithPhoneForm } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-sunet-address-form',
  templateUrl: './sunet-address-form.component.html',
  styleUrls: ['./sunet-address-form.component.css']
})
export class SunetAddressFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() address: PartnerWithPhoneForm;
  @Output() addressChange: EventEmitter<PartnerWithPhoneForm> = new EventEmitter<PartnerWithPhoneForm>();
  @Output() addressValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  countries$: any;
  formatter = new AsYouType('CH');
  formattedPhone: string;

  constructor(
    private codeService: AppCodeService,
    private fb: UntypedFormBuilder
  ) {
    super();

    this.form = this.fb.group({
      street: this.fb.control('', [Validators.required, Validators.maxLength(32)]),
      addressLine2: this.fb.control('', [Validators.maxLength(32)]),
      postCode: this.fb.control('', [Validators.required, Validators.maxLength(10)]),
      city: this.fb.control('', [Validators.required, Validators.maxLength(30)]),
      cdCountry: this.fb.control('cd_country_switzerland', [Validators.required]),
      phone: this.fb.control(''),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    console.log('onInit');
    if (this.address) {
      this.form.patchValue(this.address);
    }
    if (this.disabled) {
      this.form.disable();
    }
    this.form.markAllAsTouched();
    this.countries$ = this.codeService.getList('cd_country', false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.address)) {
      this.form.patchValue(this.address);
      this.form.markAllAsTouched();
    }
  }

  onChange(): void {
    //this.formatter.reset();
    //this.formattedPhone = this.formatter.input(this.form.value.phone);

    this.addressValid.emit(this.form.valid);
    const address = _.extend(this.address, this.form.value);
    this.addressChange.emit(address);
    console.log('changed');
  }
}
