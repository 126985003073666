<clr-modal [(clrModalOpen)]="show">
    <h3 class="modal-title" translate>title.select_processh_type</h3>
    <div class="modal-body">
        <div *ngIf="loading">
            <clr-spinner [clrInline]="true"></clr-spinner>&nbsp;<span translate>message.evaluating_available_types</span>
        </div>
        <mat-form-field class="clr-row clr-col-12" *ngIf="!loading">
            <mat-label translate>field.type</mat-label>
            <mtx-select [searchable]="true" required [(ngModel)]="relationshipType" [appendTo]="'body'">
                <mtx-option *ngFor="let filteredRelType of filteredRelTypes" [value]="filteredRelType">
                    {{ filteredRelType?.typeRight | lookupApplType | async }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="onCancel()" translate>button.cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="!relationshipType" (click)="onSuccess()" translate>button.next</button>
    </div>
</clr-modal>
