import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CaseObjectForm, RelationshipTypeForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';

@Component({
  selector: 'app-case-object-editor',
  templateUrl: './case-object-editor.component.html',
  styleUrls: ['./case-object-editor.component.css']
})
export class CaseObjectEditorComponent extends BaseEditorComponent<CaseObjectForm> implements OnInit, OnChanges {

  @Input() caseObjects: CaseObjectForm[];
  @Input() caseType: string;
  @Output() caseObjectsChange: EventEmitter<CaseObjectForm[]> = new EventEmitter<CaseObjectForm[]>();

  tableName = 'applcase_applobject';
  translations = [];

  constructor(
    private translateService: TranslateService,
    protected reltypeService: RelationshipTypeService,
  ) {
    super(reltypeService);
  }

  ngOnInit(): void {
    this.translations = this.translateService.instant(['button.add_relationship']);
  }

  protected buildFilter(): any {
    const filter = {
      typeLeft: this.caseType
    };
    return filter;
  }

  protected processObjects() {
    if (!this.relTypes
        || !this.caseObjects) {
      return;
    }

    const wrappers = [];
    this.caseObjects.forEach((caseobj: CaseObjectForm) => {
      const relType = _.find(this.relTypes, (item => item.id === caseobj.relationshipType));
      const required = relType.ltrMinCardinality > 0
        && wrappers.filter(item => item.relType === relType).length < relType.ltrMinCardinality;

      wrappers.push({
        item: caseobj,
        valid: true,
        relType,
        required
      } as ItemWrapper<CaseObjectForm>);
    });

    const counter = wrappers.length;
    this.relTypes.forEach(relType => {
      if (relType.ltrMinCardinality > 0
        && wrappers.filter(item => item.relType === relType).length < relType.ltrMinCardinality) {
        const wrapperItem = this.buildWrapperItem(relType, false, true);
        wrappers.push(wrapperItem);
      }
    });


    wrappers.sort((e1, e2) => e1?.relType.sort - e2?.relType?.sort);
    this.wrappedItems = wrappers;
    this.calculateFiltered();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.caseObjects)
      && !this.wrappedItems) {
      this.processObjects();
    }
    if (this.hasChanged(changes.caseType)) {
      this.loadRelationships();
    }
  }

  onAdd() {
    this.wrappedItems.push({
      item: {
        relationshipType: this.selectedRelType.id
      } as CaseObjectForm,
      relType: this.selectedRelType,
      valid: false
    } as ItemWrapper<CaseObjectForm>);

    this.calculateFiltered();
    this.handleRelTypeSelection();


    this.valid.emit(false);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);
    this.calculateFiltered();

    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    console.log('onchange', index, valid);
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.caseObjectsChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.valid.emit(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => !item.valid).length === 0);
    return result;
  }

  private buildWrapperItem(relType: RelationshipTypeForm, valid: boolean, required?: boolean): ItemWrapper<CaseObjectForm> {
    const item = {
      item: {
        relationshipType: relType.id
      } as CaseObjectForm,
      relType,
      valid,
      required
    } as ItemWrapper<CaseObjectForm>;

    return item;
  }
}
