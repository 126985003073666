import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import { AppTypeService } from 'src/app/core/service/app-type.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';
import { AccidentDetailForm } from './accident-detail-form';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sunet-accident-form',
  templateUrl: './sunet-accident-form.component.html',
  styleUrls: ['./sunet-accident-form.component.css']
})
export class SunetAccidentFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() accidentDetail: AccidentDetailForm;
  @Output() accidentDetailValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() accidentDetailChange: EventEmitter<any> = new EventEmitter<AccidentDetailForm>();

  subTypes$: any;
  cdAccidentCategories$: any;
  cdPoliceReports$: any;

  now = new Date();
  defaultTime = [0, 0, 0];

  constructor(
    private typeService: AppTypeService,
    private codeService: AppCodeService,
    private fb: UntypedFormBuilder,
  ) {
    super();
    this.form = this.fb.group({
      subType: this.fb.control('', [Validators.required]),
      cdAccidentCategory: this.fb.control('', [Validators.required]),
      relapseDate: new UntypedFormControl(''),
      accidentDate: new UntypedFormControl('', [Validators.required]),
      preciseAccidentDate: new UntypedFormControl(false),
      location: new UntypedFormControl('', [Validators.required]),
      sceneOfAccident: new UntypedFormControl(''),
      description: new UntypedFormControl('', [Validators.required]),
      involvedPersons: this.fb.control(null),
      cdPoliceReport: this.fb.control(null)
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    console.log('onInit');
    if (this.accidentDetail) {
      this.form.patchValue(this.accidentDetail);
    }
    this.form.markAllAsTouched();

    this.subTypes$ = this.typeService.getListRelatedToReltypeForSubs('processh_sub', 'processh', 'type_processh_accident_rep');
    this.cdPoliceReports$ = this.codeService.getList('cd_police_report', false);
    this.cdAccidentCategories$ = this.codeService.getList('cd_accident_cat', false);

    if (this.disabled) {
      this.form.disable();
    }

    this.form.markAllAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.accidentDetail)) {
      this.form.patchValue(this.accidentDetail);
      this.form.markAllAsTouched();
    }
  }

  onChange(): void {
    this.accidentDetailValid.emit(this.form.valid);
    const modified = _.extendOwn(this.accidentDetail, this.form.value);
    this.accidentDetailChange.emit(modified);
    console.log('changed');
  }
}

