import { CommonModule, DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { FileUploadModule } from '@iplab/ngx-file-upload';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { TranslateModule } from '@ngx-translate/core';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CreateAllowedDirective } from '../core/directives/create-allowed.directive';
import { DeleteAllowedDirective } from '../core/directives/delete-allowed.directive';
import { EditAllowedDirective } from '../core/directives/edit-allowed.directive';
import { ShowWithCdFunctionalityDirective } from '../core/directives/show-with-cd-functionality.directive';
import { MaterialModule } from '../core/material.module';
import { LanguageApplTextPipe } from '../core/pipe/language-appl-text.pipe';
import { LanguagePipe } from '../core/pipe/language.pipe';
import { LookupApplCodePipe } from '../core/pipe/lookup-appl-code.pipe';
import { LookupApplTypePipe } from '../core/pipe/lookup-appl-type.pipe';
import { LookupPartnerPipe } from '../core/pipe/lookup-partner.pipe';
import { LookupRelationshipTypePipe } from '../core/pipe/lookup-relationship-type.pipe';
import { AlertComponent } from './alert/alert.component';
import { BaseEditorComponent } from './base/base-editor/base-editor.component';
import { BaseFormComponent } from './base/base-form/base-form.component';
import { BaseSearchComponent } from './base/base-search/base-search.component';
import { BaseComponent } from './base/base/base.component';
import { ContactComponent } from './contact/contact.component';
import { CaseTypeDialogComponent } from './dialogs/case-type-dialog/case-type-dialog.component';
import { ConfirmationDialogComponent } from './dialogs/confirmation-dialog/confirmation-dialog.component';
import { ObjectTypeDialogComponent } from './dialogs/object-type-dialog/object-type-dialog.component';
import { ProcessHeaderProcesseHeaderDialogComponent } from './dialogs/process-header-processe-header-dialog/process-header-processe-header-dialog.component';
import { ProcessHeaderTypeDialogComponent } from './dialogs/process-header-type-dialog/process-header-type-dialog.component';
import { DocumentViewerComponent } from './document-viewer/document-viewer.component';
import { AbsenceEditorComponent } from './editors/absence-editor/absence-editor.component';
import { BankaccountEditorComponent } from './editors/bankaccount-editor/bankaccount-editor.component';
import { CaseObjectEditorComponent } from './editors/case-object-editor/case-object-editor.component';
import { CasePartnerEditorComponent } from './editors/case-partner-editor/case-partner-editor.component';
import { CasePolicyEditorComponent } from './editors/case-policy-editor/case-policy-editor.component';
import { CommAddressEditorComponent } from './editors/comm-address-editor/comm-address-editor.component';
import { DocumentRefEditorComponent } from './editors/document-ref-editor/document-ref-editor.component';
import { IdentifierEditorComponent } from './editors/identifier-editor/identifier-editor.component';
import { MeetingEditorComponent } from './editors/meeting-editor/meeting-editor.component';
import { ObjectPartnerEditorComponent } from './editors/object-partner-editor/object-partner-editor.component';
import { ParameterEditorComponent } from './editors/parameter-editor/parameter-editor.component';
import { PartnerPartnerEditorComponent } from './editors/partner-partner-editor/partner-partner-editor.component';
import { PolicyHeaderObjectEditorComponent } from './editors/policy-header-object-editor/policy-header-object-editor.component';
import { PostalAddressEditorComponent } from './editors/postal-address-editor/postal-address-editor.component';
import { ProcessHeaderEditorComponent } from './editors/process-header-editor/process-header-editor.component';
import { ProcessHeaderPartnerEditorComponent } from './editors/process-header-partner-editor/process-header-partner-editor.component';
import { ProcessItemEditorComponent } from './editors/process-item-editor/process-item-editor.component';
import { ProcessPolicyEditorComponent } from './editors/process-policy-editor/process-policy-editor.component';
import { ErrorComponent } from './error/error.component';
import { BankaccountFormComponent } from './forms/bankaccount-form/bankaccount-form.component';
import { CaseFormComponent } from './forms/case-form/case-form.component';
import { CaseObjectFormComponent } from './forms/case-object-form/case-object-form.component';
import { CasePartnerFormComponent } from './forms/case-partner-form/case-partner-form.component';
import { CasePolicyFormComponent } from './forms/case-policy-form/case-policy-form.component';
import { CommAddressFormComponent } from './forms/comm-address-form/comm-address-form.component';
import { DocumentFormComponent } from './forms/document-form/document-form.component';
import { EmployeeFormComponent } from './forms/employee-form/employee-form.component';
import { IdentifierFormComponent } from './forms/identifier-form/identifier-form.component';
import { ObjectFormComponent } from './forms/object-form/object-form.component';
import { ObjectPartnerFormComponent } from './forms/object-partner-form/object-partner-form.component';
import { OtherEmployersFormComponent } from './forms/other-employers-form/other-employers-form.component';
import { ParameterFormComponent } from './forms/parameter-form/parameter-form.component';
import { PartnerFormComponent } from './forms/partner-form/partner-form.component';
import { PartnerPartnerFormComponent } from './forms/partner-partner-form/partner-partner-form.component';
import { PartnerRoleFormComponent } from './forms/partner-role-form/partner-role-form.component';
import { PartnerWithPhoneFormComponent } from './forms/partner-with-phone-form/partner-with-phone-form.component';
import { PdfFormComponent } from './forms/pdf-form/pdf-form.component';
import { PersonFormComponent } from './forms/person-form/person-form.component';
import { PolicyHeaderObjectFormComponent } from './forms/policy-header-object-form/policy-header-object-form.component';
import { PostalAddressFormComponent } from './forms/postal-address-form/postal-address-form.component';
import { ProcessHeaderFormComponent } from './forms/process-header-form/process-header-form.component';
import { ProcessHeaderLiteFormComponent } from './forms/process-header-lite-form/process-header-lite-form.component';
import { ProcessHeaderPartnerFormComponent } from './forms/process-header-partner-form/process-header-partner-form.component';
import { ProcessItemFormComponent } from './forms/process-item-form/process-item-form.component';
import { ProcessPolicyFormComponent } from './forms/process-policy-form/process-policy-form.component';
import { PdfViewerComponent } from './pdf-viewer/pdf-viewer.component';
import { CaseSearchComponent } from './search/case-search/case-search.component';
import { ObjectSearchComponent } from './search/object-search/object-search.component';
import { OrgUnitSearchComponent } from './search/org-unit-search/org-unit-search.component';
import { OrganisationSearchComponent } from './search/organisation-search/organisation-search.component';
import { OrganisationsSearchComponent } from './search/organisations-search/organisations-search.component';
import { PersonSearchComponent } from './search/person-search/person-search.component';
import { PolicyHeaderSearchComponent } from './search/policy-header-search/policy-header-search.component';
import { PolicyItemSearchComponent } from './search/policy-item-search/policy-item-search.component';
import { SunetAccidentFormComponent } from './sunet/sunet-accident-form/sunet-accident-form.component';
import { SunetAdditionsAccidentFormComponent } from './sunet/sunet-additions-accident-form/sunet-additions-accident-form.component';
import { SunetAddressFormComponent } from './sunet/sunet-address-form/sunet-address-form.component';
import { SunetAgencyFormComponent } from './sunet/sunet-agency-form/sunet-agency-form.component';
import { SunetEmployeeFormComponent } from './sunet/sunet-employee-form/sunet-employee-form.component';
import { SunetEmployerFormComponent } from './sunet/sunet-employer-form/sunet-employer-form.component';
import { SunetEmploymentFormComponent } from './sunet/sunet-employment-form/sunet-employment-form.component';
import { SunetIllnessFormComponent } from './sunet/sunet-illness-form/sunet-illness-form.component';
import { SunetIncapacityWorkFormComponent } from './sunet/sunet-incapacity-work-form/sunet-incapacity-work-form.component';
import { SunetInjuriesFormComponent } from './sunet/sunet-injuries-form/sunet-injuries-form.component';
import { SunetInjuryFormComponent } from './sunet/sunet-injury-form/sunet-injury-form.component';
import { SunetMaternityFormComponent } from './sunet/sunet-maternity-form/sunet-maternity-form.component';
import { SunetPaymentFormComponent } from './sunet/sunet-payment-form/sunet-payment-form.component';
import { SunetSalaryFormComponent } from './sunet/sunet-salary-form/sunet-salary-form.component';
import { SunetTreatmentFormComponent } from './sunet/sunet-treatment-form/sunet-treatment-form.component';
import { TestComponent } from './test/test.component';
import { AbsenceListWidgetComponent } from './widgets/absence-list-widget/absence-list-widget.component';
import { AccountListWidgetComponent } from './widgets/account-list-widget/account-list-widget.component';
import { AddressListWidgetComponent } from './widgets/address-list-widget/address-list-widget.component';
import { CaseListWidgetComponent } from './widgets/case-list-widget/case-list-widget.component';
import { CasePolicyListWidgetComponent } from './widgets/case-policy-list-widget/case-policy-list-widget.component';
import { DocumentListWidgetComponent } from './widgets/document-list-widget/document-list-widget.component';
import { DocumentRefListWidgetComponent } from './widgets/document-ref-list-widget/document-ref-list-widget.component';
import { EventsWidgetComponent } from './widgets/events-widget/events-widget.component';
import { FinancialViewWidgetComponent } from './widgets/financial-view-widget/financial-view-widget.component';
import { ObjectListWidgetComponent } from './widgets/object-list-widget/object-list-widget.component';
import { PartnerListWidgetComponent } from './widgets/partner-list-widget/partner-list-widget.component';
import { PartnerPartnerListWidgetComponent } from './widgets/partner-partner-list-widget/partner-partner-list-widget.component';
import { PolicyItemListWidgetComponent } from './widgets/policy-item-list-widget/policy-item-list-widget.component';
import { PolicyListWidgetComponent } from './widgets/policy-list-widget/policy-list-widget.component';
import { PostalAddressListWidgetComponent } from './widgets/postal-address-list-widget/postal-address-list-widget.component';
import { ProcessHeaderListWidgetComponent } from './widgets/process-header-list-widget/process-header-list-widget.component';
import { ProcessItemListWidgetComponent } from './widgets/process-item-list-widget/process-item-list-widget.component';
import { ProcessProcessListWidgetComponent } from './widgets/process-process-list-widget/process-process-list-widget.component';
import { TaskListWidgetComponent } from './widgets/task-list-widget/task-list-widget.component';
import { TasksWidgetComponent } from './widgets/tasks-widget/tasks-widget.component';
import { WorkflowListWidgetComponent } from './widgets/workflow-list-widget/workflow-list-widget.component';
import { AccidentWizardComponent } from './wizards/accident-wizard/accident-wizard.component';
import { CaseWizardComponent } from './wizards/case-wizard/case-wizard.component';
import { EmailWizardComponent } from './wizards/email-wizard/email-wizard.component';
import { FillClaimNrWizardComponent } from './wizards/fill-claim-nr-wizard/fill-claim-nr-wizard.component';
import { GenericPdfWizardComponent } from './wizards/generic-pdf-wizard/generic-pdf-wizard.component';
import { IllnessWizardComponent } from './wizards/illness-wizard/illness-wizard.component';
import { MaternityWizardComponent } from './wizards/maternity-wizard/maternity-wizard.component';
import { ObjectWizardComponent } from './wizards/object-wizard/object-wizard.component';
import { OrganisationWizardComponent } from './wizards/organisation-wizard/organisation-wizard.component';
import { OrgunitWizardComponent } from './wizards/orgunit-wizard/orgunit-wizard.component';
import { PersonWizardComponent } from './wizards/person-wizard/person-wizard.component';
import { ProcessHeaderWizardComponent } from './wizards/process-header-wizard/process-header-wizard.component';

const comps = [
  AccountListWidgetComponent,
  AbsenceEditorComponent,
  AbsenceListWidgetComponent,
  AddressListWidgetComponent,
  AlertComponent,
  BaseComponent,
  CaseFormComponent,
  CaseListWidgetComponent,
  CaseObjectEditorComponent,
  CaseObjectFormComponent,
  CasePartnerEditorComponent,
  CasePartnerFormComponent,
  CasePolicyEditorComponent,
  CasePolicyFormComponent,
  CaseSearchComponent,
  CaseWizardComponent,
  CommAddressEditorComponent,
  CommAddressFormComponent,
  DocumentFormComponent,
  DocumentListWidgetComponent,
  DocumentRefListWidgetComponent,
  DocumentRefEditorComponent,
  DocumentViewerComponent,
  EmployeeFormComponent,
  ErrorComponent,
  IdentifierFormComponent,
  IdentifierEditorComponent,
  LanguagePipe,
  LanguageApplTextPipe,
  LookupApplCodePipe,
  LookupApplTypePipe,
  LookupPartnerPipe,
  LookupRelationshipTypePipe,
  ShowWithCdFunctionalityDirective,
  MeetingEditorComponent,
  ObjectFormComponent,
  ObjectListWidgetComponent,
  ObjectPartnerEditorComponent,
  ObjectPartnerFormComponent,
  ObjectSearchComponent,
  ObjectWizardComponent,
  OrganisationSearchComponent,
  OrganisationWizardComponent,
  OrgUnitSearchComponent,
  OrgunitWizardComponent,
  ParameterEditorComponent,
  ParameterFormComponent,
  PartnerFormComponent,
  PartnerWithPhoneFormComponent,
  PartnerListWidgetComponent,
  PartnerPartnerEditorComponent,
  PartnerPartnerFormComponent,
  PartnerPartnerListWidgetComponent,
  PartnerRoleFormComponent,
  PersonFormComponent,
  PersonSearchComponent,
  PersonWizardComponent,
  PolicyListWidgetComponent,
  PolicyHeaderSearchComponent,
  PolicyItemSearchComponent,
  PostalAddressListWidgetComponent,
  ProcessHeaderEditorComponent,
  ProcessHeaderFormComponent,
  ProcessHeaderLiteFormComponent,
  ProcessHeaderListWidgetComponent,
  ProcessHeaderWizardComponent,
  ProcessHeaderPartnerFormComponent,
  ProcessHeaderPartnerEditorComponent,
  ProcessItemEditorComponent,
  ProcessItemFormComponent,
  ProcessItemListWidgetComponent,
  PostalAddressEditorComponent,
  PostalAddressFormComponent,
  TestComponent,
  BaseEditorComponent,
  PolicyHeaderObjectEditorComponent,
  PolicyHeaderObjectFormComponent,
  ContactComponent,
  PdfViewerComponent,
  FinancialViewWidgetComponent,
  BaseSearchComponent,
  TaskListWidgetComponent,
  TasksWidgetComponent,
  AccidentWizardComponent,
  MaternityWizardComponent,
  SunetInjuryFormComponent,
  WorkflowListWidgetComponent,
  ProcessHeaderTypeDialogComponent,
  CaseTypeDialogComponent,
  ObjectTypeDialogComponent,
  SunetEmployeeFormComponent,
  SunetSalaryFormComponent,
  SunetEmploymentFormComponent,
  SunetAddressFormComponent,
  SunetAccidentFormComponent,
  SunetTreatmentFormComponent,
  BaseFormComponent,
  SunetInjuriesFormComponent,
  SunetIncapacityWorkFormComponent,
  SunetPaymentFormComponent,
  SunetAdditionsAccidentFormComponent,
  IllnessWizardComponent,
  SunetIllnessFormComponent,
  SunetMaternityFormComponent,
  SunetEmployerFormComponent,
  ProcessPolicyEditorComponent,
  ProcessPolicyFormComponent,
  BankaccountEditorComponent,
  BankaccountFormComponent,
  ConfirmationDialogComponent,
  PolicyItemListWidgetComponent,
  EmailWizardComponent,
  ProcessHeaderProcesseHeaderDialogComponent,
  ProcessProcessListWidgetComponent,
  FillClaimNrWizardComponent,
  CasePolicyListWidgetComponent,
  PdfFormComponent,
  GenericPdfWizardComponent,
  OtherEmployersFormComponent,
  OrganisationsSearchComponent,
  SunetAgencyFormComponent,
  EventsWidgetComponent,
  CreateAllowedDirective,
  EditAllowedDirective,
  DeleteAllowedDirective
];

const modules = [
  ClarityModule,
  CommonModule,
  RouterModule,
  FileUploadModule,
  FormsModule,
  MaterialModule,
  NgxDocViewerModule,
  ReactiveFormsModule,
  TranslateModule,
  AngularEditorModule,
  NgxExtendedPdfViewerModule
];

@NgModule({
  declarations: [...comps, AbsenceListWidgetComponent],
  imports: [...modules],
  exports: [...modules , ...comps]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        DatePipe,
      ]
    };
  }
}
