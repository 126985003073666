<clr-datagrid>
    <clr-dg-column>{{ 'field.relationship' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.process' | translate }}</clr-dg-column>

    <clr-dg-row *clrDgItems="let process of processProcess" [clrDgItem]="process">
        <clr-dg-cell>{{ process.relationshipType | lookupRelationshipType: (process.processHeaderIdRight == id ? 'rtl' : 'ltr') | async }}</clr-dg-cell>
        <clr-dg-cell><a [routerLink]="['/processes/detail', (process.processHeaderIdRight === id ? process.processHeaderIdLeft : process.processHeaderIdRight)]">{{
                (process.processHeaderIdRight
                === id ?
                process.processHeaderTypeLeft : process.processHeaderTypeRight) | lookupApplType | async }}</a></clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
        <clr-dg-pagination #pagination [clrDgPageSize]="10">
            <clr-dg-page-size [clrPageSizeOptions]="[10,20,50,100]" translate>message.rows_per_page</clr-dg-page-size>
            {{ 'message.total_items' | translate: {begin: pagination.firstItem + 1, end: pagination.lastItem + 1, total: pagination.totalItems} }}
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>