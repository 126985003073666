<div class="alert alert-app-level alert-warning" role="alert" [hidden]="hideWarning || production">
    <div class="alert-items">
        <div class="alert-item static">
            <div class="alert-icon-wrapper">
                <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
            </div>
            <div class="alert-text" translate>
                message.test_system
            </div>
        </div>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="hideWarning = true;">
        <clr-icon aria-hidden="true" shape="close"></clr-icon>
    </button>
</div>
<clr-header class="header header-4">
    <div class="branding" [title]="tenant">
        <a routerLink="/dashboard" class="nav-link">
            <clr-icon shape="vm-bug"></clr-icon>
            <span class="title">myClaims</span>
        </a>
    </div>
    <div class="header-nav" *ngIf="authenticated">
        <a routerLink="/dashboard" routerLinkActive="active" class="nav-link"><span class="nav-text">{{ 'menu.dashboard' | translate }}</span></a>
        <a routerLink='/cases' routerLinkActive="active" class="nav-link"><span class="nav-text" translate>menu.cases</span></a>
        <a routerLink='/processes' routerLinkActive="active" class="nav-link"><span class="nav-text" translate>menu.processes</span></a>
        <a routerLink='/admin/person-list' routerLinkActive="active" class="nav-link"><span class="nav-text" translate>menu.persons</span></a>
        <a routerLink='/admin/organisation-list' routerLinkActive="active" class="nav-link"><span class="nav-text" translate>menu.organisations</span></a>
        <a *appShowWithCdFunctionality="'cd_functionality_objects'" routerLink='/objects' routerLinkActive="active" class="nav-link"><span class="nav-text" translate>menu.objects</span></a>
        <a routerLink='/admin/report' routerLinkActive="active" class="nav-link"><span class="nav-text" translate>menu.reports</span></a>
<!--
        <a routerLink='/employees' routerLinkActive="active" class="nav-link"><span class="nav-text" translate>menu.employees</span></a>
-->
    </div>
    <div class="header-actions">
        <a *ngIf="!authenticated" (click)="login()" class="nav-link">
            <span class="nav-text" translate>menu.login</span>
        </a>

        <clr-dropdown *ngIf="authenticated">
            <button class="nav-icon" clrDropdownTrigger aria-label="toggle notification menu">
                <clr-icon shape="event" class="has-alert"></clr-icon>
                <cds-icon shape="angle" direction="down"></cds-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                <label class="dropdown-header" aria-hidden="true">Dropdown header</label>
                <a clrDropdownItem>
                    <clr-icon shape="alert" aria-hidden="true"></clr-icon>Test Message {{ alertCounter }}
                </a>
<!--                <div class="dropdown-divider" role="separator" aria-hidden="true"></div>
                <a href="..." clrDropdownItem translate>menu.show_all_notifications</a>
-->            </clr-dropdown-menu>
        </clr-dropdown>
        <clr-dropdown *ngIf="authenticated">
            <button class="nav-icon" clrDropdownTrigger>
                <clr-icon shape="cog"></clr-icon>
                <cds-icon shape="angle" direction="down"></cds-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                <a routerLink="/admin/organisation-list" clrDropdownItem translate>menu.organisations</a>
                <a routerLink="/admin/person-list" clrDropdownItem translate>menu.persons</a>
                <a *appShowWithCdFunctionality="'cd_functionality_objects'" routerLink="/objects/list" clrDropdownItem translate>menu.objects</a>
                <a routerLink="/admin/policy-list" clrDropdownItem translate>menu.policies</a>
                <a routerLink="/admin/report" clrDropdownItem translate>menu.reports</a>
                <a routerLink="/admin/acciline" clrDropdownItem translate>menu.acciline</a>
                <a *appShowWithCdFunctionality="'cd_functionality_users'" routerLink="/admin/user-list" clrDropdownItem translate>menu.users</a>
<!--                <a routerLink="/admin/role-list" clrDropdownItem translate>menu.roles</a>
                <a href="..." clrDropdownItem translate>menu.preferences</a>
-->            </clr-dropdown-menu>
        </clr-dropdown>
        <clr-dropdown *ngIf="authenticated">
            <button class="nav-icon-text" clrDropdownTrigger>
                <clr-icon shape="user"></clr-icon>
                <span class="nav-text"> {{ name }} </span>
                <cds-icon shape="angle" direction="down"></cds-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                <a (click)="logout()" clrDropdownItem translate>menu.logout</a>
                <a (click)="switch()" *ngIf="!production" clrDropdownItem translate>menu.switch_user</a>
            </clr-dropdown-menu>
        </clr-dropdown>
        <clr-dropdown>
            <button class="nav-icon" clrDropdownTrigger>
                <clr-icon shape="language"></clr-icon>
                <cds-icon shape="angle" direction="down"></cds-icon>
            </button>
            <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                <a (click)="changeLanguage('de')" [attr.disabled]="currentLang==='de'" clrDropdownItem translate>menu.german</a>
                <a (click)="changeLanguage('fr')" [attr.disabled]="currentLang==='fr'" clrDropdownItem translate>menu.french</a>
                <a (click)="changeLanguage('en')" [attr.disabled]="currentLang==='en'" clrDropdownItem translate>menu.english</a>
                <a *ngIf="!production" (click)="changeLanguage('it')" [attr.disabled]="currentLang==='it'" clrDropdownItem>Show label (test only)</a>
            </clr-dropdown-menu>
        </clr-dropdown>
    </div>
</clr-header>
<nav class="subnav" *ngIf="router.url.match('^/processes')">
    <ul class="nav">
        <li class="nav-item">
            <a routerLink='/processes/list' routerLinkActive="active" class="nav-link">{{ 'submenu.overview' | translate }}</a>
        </li>
        <li class="nav-item">
            <a routerLink='/processes/list-absences' routerLinkActive="active" class="nav-link">{{ 'submenu.absences' | translate }}</a>
        </li>
    </ul>
</nav>
<nav class="subnav" *ngIf="router.url.match('^/employees')">
    <ul class="nav">
        <li class="nav-item">
            <a routerLink='/employees/list' routerLinkActive="active" class="nav-link" translate>submenu.overview</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#">Employee Sub2</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#">Employee Sub3</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" href="#">Employee Sub3</a>
        </li>
    </ul>
</nav>
