import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ProcessHeaderPartnerForm } from 'src/app/core';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-process-header-partner-form',
  templateUrl: './process-header-partner-form.component.html',
  styleUrls: ['./process-header-partner-form.component.css']
})
export class ProcessHeaderPartnerFormComponent extends BaseFormComponent implements OnChanges {

  @Input() processHeaderPartner: ProcessHeaderPartnerForm;
  @Input() typeRight: string;
  @Output() processHeaderPartnerValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() processHeaderPartnerChange: EventEmitter<ProcessHeaderPartnerForm> = new EventEmitter<ProcessHeaderPartnerForm>();

  processHeaderPartnerEdit: ProcessHeaderPartnerForm = {};
  partnerId: number;

  constructor(
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.processHeaderPartner)) {
      this.processHeaderPartnerEdit = changes.processHeaderPartner.currentValue;
    }
  }

  onChange(): void {
    const valid = this.processHeaderPartnerEdit.partnerId != null;
    if (valid) {
      this.processHeaderPartnerChange.emit(this.processHeaderPartnerEdit);
    }
    this.processHeaderPartnerValid.emit(valid);
    console.log('changed', this.processHeaderPartnerEdit.partnerId);
  }
}
