import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LanguageService } from '../service/language.service';
import { RelationshipTypeService } from '../service/relationship-type.service';

@Pipe({
  name: 'lookupRelationshipType'
})

@Injectable()
export class LookupRelationshipTypePipe implements PipeTransform {

  constructor(
    private reltypeService: RelationshipTypeService,
    private languageService: LanguageService
  ) {
  }

  transform(id: string, direction?: string): Observable<string> {
    if (!id) {
      return of('');
    }

    const fieldName = (direction === 'rtl' ? 'rtlTextLong' : 'ltrTextLong');

    return this.reltypeService.getById(id).pipe(
      take(1),
      map(relType => {
        let name = id;
        if (relType) {
          name = this.languageService.getLanguageSpecifcValueFromApplText(relType.applText, 'textLong');
        }
        return name;
      })
    );
  }
}
