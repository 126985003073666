import { Injectable } from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class FileService {

  private filesBySection: Map<string, Array<File>> = new Map();

  constructor(
  ) {
  }

  reset(): void {
    this.filesBySection = new Map();

    this.filesBySection.set('document', []);
    this.filesBySection.set('processHeader', []);
  }

  isEmpty(section = 'document'): boolean {
    return this.filesBySection.get(section).filter( item => item != null).length === 0;
  }

  deletePosition(index: number, section = 'document'): void {
    this.filesBySection.get(section).splice(index, 1);
  }

  store(index: number, file: File, section = 'document'): void {
    this.filesBySection.get(section)[index] = file;
  }

  get(index: number, section = 'document'): File {
    return this.filesBySection.get(section)[index];
  }
}

