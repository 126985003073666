import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { EmploymentInfo } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sunet-employment-form',
  templateUrl: './sunet-employment-form.component.html',
  styleUrls: ['./sunet-employment-form.component.css']
})
export class SunetEmploymentFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() employment: EmploymentInfo;
  @Output() employmentChange: EventEmitter<EmploymentInfo> = new EventEmitter<EmploymentInfo>();
  @Output() employmentValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() processHeaderDate: string;

  employmentStatuses$: Observable<any[]>;
  positions$: Observable<any[]>;
  functions$: Observable<any[]>;
  workingFrequencies$: Observable<any[]>;

  constructor(
    private codeService: AppCodeService,
    private formBuilder: UntypedFormBuilder
  ) {
    super();
    this.form = this.formBuilder.group({
      cdEmploymentStatus: new UntypedFormControl(null, [Validators.required]),
      cdEmploymentPosition: new UntypedFormControl(),
      dateOfEmployment: new UntypedFormControl(null, [Validators.required]),
      terminationDate: new UntypedFormControl(),
      levelOfEmployment: new UntypedFormControl(null, [Validators.required]),
      usualWorkingPlace: new UntypedFormControl(null, [Validators.required]),
      workingDaysPerWeek: new UntypedFormControl(),
      workingHoursPerWeek: new UntypedFormControl(null, [Validators.required]),
      profession: new UntypedFormControl(null, [Validators.required]),
      cdWorkingFrequency: new UntypedFormControl(null, [Validators.required]),
      withholdingTax: new UntypedFormControl(false),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());

  }

  ngOnInit(): void {
    this.positions$ = this.codeService.getList('cd_emp_position', false);
    this.employmentStatuses$ = this.codeService.getList('cd_employment_status', false);
    this.functions$ = this.codeService.getList('cd_function', false);
    this.workingFrequencies$ = this.codeService.getList('cd_working_frequency', false);

    if (this.disabled) {
      this.form.disable();
    }
    this.form.markAllAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.patchValue(this.employment);
    this.form.markAllAsTouched();
  }

  onChange(): void {
    const modified = _.extend(this.employment, this.form.value);
    this.employmentChange.emit(modified);
    this.employmentValid.emit(this.form.valid);
  }
}

