<clr-datagrid>
    <clr-dg-column [clrDgSortBy]="'type'">{{ 'field.type' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'name'">{{ 'field.name' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'policyNumber'">{{ 'field.policy_nr' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.insurer' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.category' | translate }}
    </clr-dg-column>

    <clr-dg-row *ngFor="let policy of policies" [clrDgItem]="policy">
        <clr-dg-cell>{{ policy.type | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell>{{ policy.name }}</clr-dg-cell>
        <clr-dg-cell><a [routerLink]="['/admin/policy-detail', policy.id ]">{{ policy.policyNumber }}</a></clr-dg-cell>
        <clr-dg-cell><a [routerLink]="['/admin/partner-detail', policy.insurerId ]">{{ policy.insurerName }}</a></clr-dg-cell>
        <clr-dg-cell>{{ policy.cdCategory | lookupApplCode | async }}</clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>