import { Injectable } from '@angular/core';
import { ReferenceCheck } from '..';

@Injectable()
export class ValidationHelperService {

  id: number;
  type: string;

  constructor() { }

  public setId(id: number) {
    this.id = id;
  }

  public getId(): number {
    return this.id;
  }

  public setType(type: string) {
    this.type = type;
  }

  public getType(): string {
    return this.type;
  }

  public buildReferenceCheck(reference: string): ReferenceCheck {
    return {
      reference,
      type: this.type ? this.type : '',
      notId: this.id
    } as ReferenceCheck;
  }

}
