import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { concat, Observable, of, ReplaySubject } from 'rxjs';
import { catchError, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PagePolicyItemLiteView, PolicyItemLiteView, PolicyItemSearchCriteria, PolicyService } from 'src/app/core';
import { BaseSearchComponent } from '../../base/base-search/base-search.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-policy-item-search',
  templateUrl: './policy-item-search.component.html',
  styleUrls: ['./policy-item-search.component.css']
})
export class PolicyItemSearchComponent extends BaseSearchComponent implements OnInit, OnChanges {

  @Input()
  policyHeaderId: number;

  @Input()
  label: string;

  search$ = new ReplaySubject<PolicyItemSearchCriteria>();

  criteria: PolicyItemSearchCriteria;

  result$: Observable<any[]>;
  selectedItem: PolicyItemLiteView;

  constructor(
    private policyController: PolicyService,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    console.log('[pis] init');
    this.criteria = {
      type: this.type,
      policyHeaderId: -1,
      pageSize: 40,
      sortColumn: 'title'
    };
    this.search();
    if (this.policyHeaderId
        && !this.id) {
      this.handlePolicyHeaderIdChange();
    } else {
      this.searchInput$.next('');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.id)) {
      this.policyController.findByIdPolicyItemLite(this.id).pipe(
        takeUntil(this.ngUnsubscribe),
        first()
      ).subscribe(response => {
        this.selectedItem = response;
        this.ref.markForCheck();
      });
      return;
    }
    if (this.hasChanged(changes.policyHeaderId, false)) {
      console.log('[pis] policyHeaderId changed', changes.policyHeaderId);
      this.handlePolicyHeaderIdChange();
    }
  }

  handlePolicyHeaderIdChange() {
    this.criteria.type = this.type;
    this.criteria.policyHeaderId = this.policyHeaderId ? this.policyHeaderId : -1;
    this.search$.next(this.criteria);
    this.selectedItem = null;
  }

  trackByFn(item: PolicyItemLiteView) {
    return item.id;
  }

  onChange() {
    this.idChange.emit(this.selectedItem?.id);
  }

  private search() {
    this.result$ = concat(
      of([]), // default item
      this.search$.pipe(
        takeUntil(this.ngUnsubscribe),
        tap(() => this.loading = true),
        switchMap((criteria) => {
          return this.policyController.searchPolicyItemLite(criteria).pipe(
            catchError(() => of([])), // empty list on error
            map((response: PagePolicyItemLiteView) => response.content),
            tap( result => result.forEach((item: any) => {
              // tslint:disable-next-line:max-line-length
              item.search = [item.title, item.amountNet, item.minSalary, item.maxSalary, item.coverRate, item.minDegreeOfIncapicity, item.waitingPeriod, item.coveragePeriod].join(' ');
            })),
            tap((result: any[]) => {
              console.log('[pis] search result', result.length);
              if (result.length === 1) {
                this.selectedItem = result[0];
                this.onChange();
              }
            }),
            tap(() => this.loading = false)
          );
        })
      )
    );
  }

  evaluateDetail(item: PolicyItemLiteView): string {
    if (item.type === 'type_policyi_accident_illness') {
      // tslint:disable-next-line:max-line-length
      return [item.title, item.minSalary, item.maxSalary, item.coverRate, item.minDegreeOfIncapicity, item.waitingPeriod, item.coveragePeriod].join(':');
    }

    return [item.title, item.amountNet].join(' ');

  }
}


