<clr-datagrid>
    <clr-dg-column [clrDgSortBy]="'type'">{{ 'field.type' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.street_and_nr' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.post_code_and_city' | translate }}</clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'cdStatus'">{{ 'field.status' | translate }}</clr-dg-column>

    <clr-dg-row *clrDgItems="let pa of postalAddresses" [clrDgItem]="pa">
        <clr-dg-cell>{{ pa.type | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell>{{ pa.street }} {{ pa.streetNumber }}</clr-dg-cell>
        <clr-dg-cell>{{ pa.postCode }} {{ pa.city }}</clr-dg-cell>
        <clr-dg-cell>{{ pa.cdStatus | lookupApplCode | async }}</clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>
