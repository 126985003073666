import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PolicyHeaderObjectForm } from 'src/app/core';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-policy-header-object-form',
  templateUrl: './policy-header-object-form.component.html',
  styleUrls: ['./policy-header-object-form.component.css']
})
export class PolicyHeaderObjectFormComponent extends BaseFormComponent implements OnChanges {

  @Input() policyHeaderObject: PolicyHeaderObjectForm;
  @Input() typeObject: string;
  @Output() policyHeaderObjectValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() policyHeaderObjectChange: EventEmitter<PolicyHeaderObjectForm> = new EventEmitter<PolicyHeaderObjectForm>();

  policyHeaderObjectEdit: PolicyHeaderObjectForm = {};
  policyHeaderId: number;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.policyHeaderObject)) {
      this.policyHeaderObjectEdit = changes.policyHeaderObject.currentValue;
    }
  }

  onChange(): void {
    const valid = this.policyHeaderObjectEdit.policyHeaderId != null;
    if (valid) {
      this.policyHeaderObjectChange.emit(this.policyHeaderObjectEdit);
    }
    this.policyHeaderObjectValid.emit(valid);
    console.log('changed', this.policyHeaderObjectEdit.policyHeaderId);
  }
}

