import { Type } from '@angular/core';
import { Editor } from '../../editors/editor';
import { AccidentWizardComponent } from '../../wizards/accident-wizard/accident-wizard.component';
import { GenericPdfWizardComponent } from '../../wizards/generic-pdf-wizard/generic-pdf-wizard.component';
import { IllnessWizardComponent } from '../../wizards/illness-wizard/illness-wizard.component';
import { MaternityWizardComponent } from '../../wizards/maternity-wizard/maternity-wizard.component';
import { ProcessHeaderWizardComponent } from '../../wizards/process-header-wizard/process-header-wizard.component';

type ProceesHeaderContextMapType = Record<string, ProcessHeaderContext>;

export enum FieldOption {
    Optional,
    Mandatory,
    Hidden,
}

export interface ProcessHeaderContext {
    period: FieldOption;
    periodActual: FieldOption;
    completionDate?: FieldOption;
    entryDate?: FieldOption;
    dueDate?: FieldOption;
    location: FieldOption;
    processHeaderDateWithTime: boolean;
    incapacityForWork: FieldOption;
    processHeaderDateTag: string;
}

export class ProcessHeaderTypeUtils {

    static ACCIDENT_REP = 'type_processh_accident_rep';
    static AGR_ACCIDENT_REP = 'type_processh_agreed_accident_rep';
    static CERTIFICATE_MED = 'type_processh_certificate_med';
    static ABSENCE = 'type_processh_absence';
    static ABSENCE_ACCIDENT = 'type_processh_absence_accident';
    static ABSENCE_ILLNESS = 'type_processh_absence_illness';
    static ABSENCE_MATERNITY = 'type_processh_absence_maternity';
    static BILL = 'type_processh_bill';
    static BILL_PROVISIONAL = 'type_processh_bill_provisional';
    static CLAIM_REP = 'type_processh_claim_rep';
    static CLAIM_REP_INT = 'type_processh_claim_rep_internal';
    static COMPLAINT = 'type_processh_complaint';
    static EXPERT_REP = 'type_processh_expert_rep';
    static POLICE_REP = 'type_processh_police_rep';
    static MATERNITY = 'type_processh_maternity_rep';
    static MEASURE = 'type_processh_measure';
    static ILLNESS_REP = 'type_processh_illness_rep';
    static INVESTIGATE = 'type_processh_investigation';
    static INVOICE = 'type_processh_invoice';
    static REIMBURSEMENT = 'type_processh_reimbursement';
    static FRANCHISE = 'type_processh_franchise';
    static NOTE = 'type_processh_note';
    static TASK = 'type_processh_task';
    static IV = 'type_processh_iv_ins_benefit_request';
    static IV_EARLY_DETECTION = 'type_processh_iv_ins_early_detection';
    static IV_COMPENSATION = 'type_processh_iv_ins_compensation';
    static PENSION = 'type_processh_pension_request';
    static RETURN_CONVERSATION = 'type_processh_return_conversation';
    static CONVERSATION_LONG_TERM = 'type_processh_conversation_abs_long_term';
    static CONVERSATION_REPEATED = 'type_processh_conversation_abs_repeated';

    static KIND_GENERAL = 'general';
    static KIND_SUNET = 'sunet';

    static contextMap: ProceesHeaderContextMapType = {
        // tslint:disable:max-line-length
        [ProcessHeaderTypeUtils.ACCIDENT_REP]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Optional, location: FieldOption.Mandatory, processHeaderDateWithTime: true, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.AGR_ACCIDENT_REP]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Optional, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.emission_date' },
        [ProcessHeaderTypeUtils.CERTIFICATE_MED]: { period: FieldOption.Mandatory, periodActual: FieldOption.Optional, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Mandatory, processHeaderDateTag: 'field.emission_date' },
        [ProcessHeaderTypeUtils.ABSENCE]: { period: FieldOption.Mandatory, periodActual: FieldOption.Optional, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Mandatory, processHeaderDateTag: 'field.emission_date' },
        [ProcessHeaderTypeUtils.ABSENCE_ACCIDENT]: { period: FieldOption.Mandatory, periodActual: FieldOption.Optional, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Mandatory, processHeaderDateTag: 'field.emission_date' },
        [ProcessHeaderTypeUtils.ABSENCE_ILLNESS]: { period: FieldOption.Mandatory, periodActual: FieldOption.Optional, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Mandatory, processHeaderDateTag: 'field.emission_date' },
        [ProcessHeaderTypeUtils.ABSENCE_MATERNITY]: { period: FieldOption.Mandatory, periodActual: FieldOption.Optional, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Mandatory, processHeaderDateTag: 'field.emission_date' },
        [ProcessHeaderTypeUtils.BILL]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.billing_date' },
        [ProcessHeaderTypeUtils.BILL_PROVISIONAL]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.billing_date' },
        [ProcessHeaderTypeUtils.CLAIM_REP]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Mandatory, dueDate: FieldOption.Optional, location: FieldOption.Mandatory, processHeaderDateWithTime: true, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.CLAIM_REP_INT]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Mandatory, dueDate: FieldOption.Optional, location: FieldOption.Mandatory, processHeaderDateWithTime: true, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.COMPLAINT]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Optional, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.EXPERT_REP]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Optional, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.emission_date' },
        [ProcessHeaderTypeUtils.POLICE_REP]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Optional, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'emission_date' },
        [ProcessHeaderTypeUtils.MATERNITY]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.MEASURE]: { period: FieldOption.Optional, periodActual: FieldOption.Hidden, completionDate: FieldOption.Optional, entryDate: FieldOption.Mandatory, dueDate: FieldOption.Optional, location: FieldOption.Optional, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.ILLNESS_REP]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Optional, location: FieldOption.Hidden, processHeaderDateWithTime: true, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.INVESTIGATE]: { period: FieldOption.Optional, periodActual: FieldOption.Hidden, completionDate: FieldOption.Optional, entryDate: FieldOption.Mandatory, dueDate: FieldOption.Optional, location: FieldOption.Optional, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.emission_date' },
        [ProcessHeaderTypeUtils.INVOICE]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.invoice_date' },
        [ProcessHeaderTypeUtils.PENSION]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.IV]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.IV_EARLY_DETECTION]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.IV_COMPENSATION]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.REIMBURSEMENT]: { period: FieldOption.Optional, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.FRANCHISE]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.event_date' },
        [ProcessHeaderTypeUtils.NOTE]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Optional, entryDate: FieldOption.Optional, dueDate: FieldOption.Hidden, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.emission_date' },
        [ProcessHeaderTypeUtils.TASK]: { period: FieldOption.Optional, periodActual: FieldOption.Hidden, completionDate: FieldOption.Optional, entryDate: FieldOption.Optional, dueDate: FieldOption.Optional, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.emission_date' },
        [ProcessHeaderTypeUtils.RETURN_CONVERSATION]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Optional, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.conversation_date' },
        [ProcessHeaderTypeUtils.CONVERSATION_LONG_TERM]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Optional, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.conversation_date' },
        [ProcessHeaderTypeUtils.CONVERSATION_REPEATED]: { period: FieldOption.Hidden, periodActual: FieldOption.Hidden, completionDate: FieldOption.Hidden, entryDate: FieldOption.Optional, dueDate: FieldOption.Optional, location: FieldOption.Hidden, processHeaderDateWithTime: false, incapacityForWork: FieldOption.Hidden, processHeaderDateTag: 'field.conversation_date' }
    };

    static buildDefaultContext(): ProcessHeaderContext {
        return {
            period: FieldOption.Hidden,
            periodActual: FieldOption.Hidden,
            completionDate: FieldOption.Hidden,
            entryDate: FieldOption.Hidden,
            dueDate: FieldOption.Hidden,
            location: FieldOption.Hidden,
            processHeaderDateWithTime: false,
            processHeaderDateTag: 'field.event_date'
        } as ProcessHeaderContext;
    }

    static buildContext(type: string): ProcessHeaderContext {
        return ProcessHeaderTypeUtils.contextMap[type];
    }

    static isStatusSetByWorkflow(type: string): boolean {
        return type === ProcessHeaderTypeUtils.CLAIM_REP
            || type === ProcessHeaderTypeUtils.CLAIM_REP_INT
            || type === ProcessHeaderTypeUtils.ACCIDENT_REP
            || type === ProcessHeaderTypeUtils.ILLNESS_REP
            || type === ProcessHeaderTypeUtils.MATERNITY
            || type === ProcessHeaderTypeUtils.PENSION
            || type === ProcessHeaderTypeUtils.IV
            || type === ProcessHeaderTypeUtils.IV_COMPENSATION
            || type === ProcessHeaderTypeUtils.IV_EARLY_DETECTION;
    }

    static mapToWizard(type: string): Type<Editor> {
        if (type === ProcessHeaderTypeUtils.ACCIDENT_REP) {
            return AccidentWizardComponent;
        } else if (type === ProcessHeaderTypeUtils.ILLNESS_REP) {
            return IllnessWizardComponent;
        } else if (type === ProcessHeaderTypeUtils.MATERNITY) {
            return MaternityWizardComponent;
        } else if (type === ProcessHeaderTypeUtils.IV
            || type === ProcessHeaderTypeUtils.IV_EARLY_DETECTION
            || type === ProcessHeaderTypeUtils.IV_COMPENSATION
            || type === ProcessHeaderTypeUtils.PENSION) {
            return GenericPdfWizardComponent;
        }

        return ProcessHeaderWizardComponent;
    }

    static isAccident(type: string): boolean {
        if (type === ProcessHeaderTypeUtils.ACCIDENT_REP) {
            return true;
        }

        return false;
    }

    static isIllness(type: string): boolean {
        if (type === ProcessHeaderTypeUtils.ILLNESS_REP) {
            return true;
        }

        return false;
    }

    static isMaternity(type: string): boolean {
        if (type === ProcessHeaderTypeUtils.MATERNITY) {
            return true;
        }

        return false;
    }
}
