import { InjectionToken } from '@angular/core';
import { IAppConfig } from './iapp.config';

export let APP_CONFIG = new InjectionToken('app.config');

export const AppConfig: IAppConfig = {
    supportedLanguages: ['DE', 'EN', 'FR'],
    msal: {
        clientId: '04699797-2ec0-4aa3-a661-6fd907b7aec5', // client ID for myClaimsTest
        authority: 'https://login.microsoftonline.com/common', // This is your tenant ID
        redirectUri: 'http://localhost:4200'
    }

/*
    resource: 'ee92570f-90c0-490c-800d-2df4849cb028',
    adalConfig: {
        tenant: environment.tenant,
        clientId: environment.clientId,
        redirectUri: window.location.origin + environment.baseHref,
        postLogoutRedirectUri: window.location.origin + environment.baseHref + '#/logout',
        endpoints: {
            '`${environment.apiBaseUrl}`': environment.clientId
        }
    }
*/
};
