<clr-wizard #wizardlg [(clrWizardOpen)]="show" clrWizardSize="xl"
    (clrWizardOnCancel)="onCancel()" [clrWizardPreventDefaultCancel]="true"  [clrWizardPreventDefaultNext]="true" (clrWizardOnNext)="onNext()"
    (clrWizardOnFinish)="onFinish()">
    <clr-wizard-title>
        {{ toTranslateKey(entityEnum) | translate }}<br>
        <small>{{ object?.type | lookupApplType | async }}</small>
    </clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">{{ 'button.cancel' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{ 'button.back' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{ 'button.next' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{ 'button.finish' | translate }}</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!processHeaderValid" [clrWizardPageHasError]="!processHeaderValid">
        <ng-template clrPageTitle>{{ 'section.detail' | translate }}</ng-template>
        <app-process-header-form [allowSelectCase]="true" [(processHeader)]="processHeader" (processHeaderValid)="processHeaderValid = $event">
        </app-process-header-form>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!phPartnersValid" *ngIf="!hidePartnerStep">
        <ng-template clrPageTitle>{{ 'section.relations_to_partner' | translate }}</ng-template>
        <app-process-header-partner-editor [(processHeaderPartners)]="partners" [typeLeft]="processHeader?.type" (hide)="hidePartnerStep = $event"
            (valid)="phPartnersValid = $event">
        </app-process-header-partner-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!policiesValid" [clrWizardPageHasError]="!policiesValid" *ngIf="!hidePolicyStep">
        <ng-template clrPageTitle>{{ 'section.policies' | translate }}</ng-template>
        <app-process-policy-editor [(processPolicies)]="policies" [processType]="processHeader?.type" (hide)="hidePolicyStep = $event" (valid)="policiesValid = $event">
        </app-process-policy-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!itemsValid" [clrWizardPageHasError]="!itemsValid" *ngIf="!hideItemStep">
        <ng-template clrPageTitle>{{ 'section.process_items' | translate }}</ng-template>
        <app-process-item-editor [(processItems)]="items" [typeLeft]="processHeader?.type" (hide)="hideItemStep = $event" (valid)="itemsValid = $event">
        </app-process-item-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!documentValid" [clrWizardPageHasError]="!documentValid" *ngIf="!hideDocumentStep">
        <ng-template clrPageTitle>{{ 'section.documents' | translate }}</ng-template>
        <app-document-ref-editor [typeLeft]="processHeader?.type" [(documentRefs)]="documents" (hide)="hideDocumentStep = $event" (valid)="documentValid = $event">
        </app-document-ref-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageHasError]="error != null" *ngIf="error">
        <ng-template clrPageTitle>{{ 'section.error_occured' | translate }}</ng-template>
        <app-error [error]="error"></app-error>
    </clr-wizard-page>

</clr-wizard>