import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { first, takeUntil } from 'rxjs/operators';
import { DocumentForm } from 'src/app/core';
import { FileService } from 'src/app/core/service/file.service';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-document-form',
  templateUrl: './document-form.component.html',
  styleUrls: ['./document-form.component.css']
})
export class DocumentFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() allowSelectType = false;
  @Input() typeLeft: string;
  @Input() applDocument: DocumentForm;
  @Input() index: number;
  @Input() section = 'document';
  @Output() applDocumentValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() applDocumentChange: EventEmitter<DocumentForm> = new EventEmitter<DocumentForm>();

  documentTypes: any[];
  relTypes: any[];

  fileUploadControl = new FileUploadControl(null, [FileUploadValidators.filesLimit(1), FileUploadValidators.fileSize(5000000)]);

  constructor(
    private reltypeService: RelationshipTypeService,
    private fileService: FileService,
    private fb: UntypedFormBuilder
  ) {
    super();
    this.form = this.fb.group({
      id: new UntypedFormControl(),
      type: new UntypedFormControl('', [Validators.required]),
      receiptDate: new UntypedFormControl(new Date()),
      name: new UntypedFormControl(''),
      mimeType: new UntypedFormControl(''),
    });
  }

  ngOnInit(): void {
    console.log('onInit');
    if (this.applDocument) {
      this.form.patchValue(this.applDocument);
    }
    this.form.markAllAsTouched();

    if (!this.applDocument?.id) {
      this.fileUploadControl.valueChanges.pipe(
        takeUntil(this.ngUnsubscribe)
      ).subscribe(files => {
        this.onFileChange(files);
      });
    }

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(files => {
      this.onChange();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.applDocument)) {
      this.form.reset();
      this.form.patchValue(this.applDocument);
      this.form.markAllAsTouched();
    }
    if (this.hasChanged(changes.typeLeft)) {
      this.loadRelationships();
    }
  }

  loadRelationships(): void {
    this.reltypeService.getRelationships('doc_ref', {typeLeft: this.typeLeft}).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(result => {
      this.relTypes = result;

      if (result.length === 1) {
        this.form.patchValue({
          type: result[0].typeRight
        });
        this.form.markAllAsTouched();
      }
    }, error => {
      console.error('get relTypes - error', error);
    });
  }

  onFileChange(files?: File[]): void {
    if (files) {
      if (files.length > 0) {
        files.forEach(file => {
          console.log('file:', file);
          this.fileService.store(this.index, file, this.section);
          this.form.patchValue(
            {
              name: file.name,
              mimeType: file.type
            }
          );
        });
      } else {
        this.fileService.store(this.index, null, this.section);
        this.form.patchValue(
          {
            name: '',
            mimeType: ''
          });
      }
    }

    this.onChange();
  }

  onChange(): void {
    const validForm = this.form.value.name?.length > 0
      && this.form.value.type != null;

    if (validForm) {
      this.applDocument = _.extend(this.applDocument, this.form.value);
      this.applDocumentChange.emit(this.applDocument);
    }
    this.applDocumentValid.emit(validForm);
  }
}
