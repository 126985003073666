<form clrForm *ngIf="form" [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.type</mat-label>
            <input matInput [value]="processHeader?.type | lookupApplType | async" disabled required>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6" *ngIf="false">
            <mat-label translate>field.status</mat-label>
            <mtx-select [searchable]="true" formControlName="cdStatus" [appendTo]="'body'" disabled required>
                <mtx-option *ngFor="let status of cdStatuses$ | async" [value]="status.id">
                    {{ status | language:'name' }}</mtx-option>
            </mtx-select>
            <mat-hint translate>
                info.status_set_by_workflow
            </mat-hint>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.status</mat-label>
            <input matInput [value]="processHeader?.cdStatus | lookupApplCode | async" disabled required>
            <mat-hint translate>
                info.status_set_by_workflow
            </mat-hint>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.reference</mat-label>
            <input matInput formControlName="reference" maxlength="60" required>
            <mat-error *ngIf="form?.get('reference')?.hasError('referenceExists')" translate>
                error.reference_already_exists
            </mat-error>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.title</mat-label>
            <textarea matInput formControlName="title" maxlength="80" [rows]="2"></textarea>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.reference2</mat-label>
            <input matInput formControlName="reference2" maxlength="60" >
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.report_language</mat-label>
            <mtx-select [searchable]="true" formControlName="cdLanguageReport" required>
                <mtx-option *ngFor="let cdLanguage of cdLanguages$ | async" [value]="cdLanguage.id">
                    {{ cdLanguage | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row" *ngIf="allowSelectCase === true">
        <div class="clr-col-md-6">
            <app-case-search [fieldName]="'field.assign_to_case' | translate" [(id)]="caseId" (idChange)="caseSelected()"></app-case-search>
        </div>
    </div>
</form>