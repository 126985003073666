import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { ClrWizard, ClrWizardPage } from '@clr/angular';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { EntityEnum, toTranslateKey } from 'src/app/core/entity-enum';
import { AlertService } from 'src/app/core/service/alert.service';
import { BlockService } from 'src/app/core/service/block.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-base-wizard',
  templateUrl: './base-wizard.component.html',
  styleUrls: ['./base-wizard.component.css']
})
export abstract class BaseWizardComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('wizardlg') wizard: ClrWizard;
  error: any;
  entityId: any;
  newEntity = true;
  submitted = false;

  abstract entityEnum: EntityEnum;

  afterClosed = new Subject<any>();
  show = true;

  constructor(
    protected blockService?: BlockService,
    protected alertService?: AlertService
  ) {
    super();
  }
  ngAfterViewInit(): void {
    if (this.blockService) {
      setTimeout( () => this.blockService.stop(), 1500);
    }
  }

  protected onSubmit(): Observable<any> {
    throw new Error('onSubmit Method not implemented.');
  }

  evaluateWizardPagesCompleted(): void {
    setTimeout( () => {
      this.wizard.navService.pageCollection.pages.forEach((item: ClrWizardPage) => {
        item.completed = true;
      });
    }, 500);
  }

  goBack(): void {
    this.wizard.previous();
  }

  onNext(): void {
    console.log('called custom next');
    this.wizard.forceNext();
  }

  onFinish(): void {
    if (this.submitted === true) {
      return;
    }
    this.submitted = true;
    console.log('called custom finish');
    const errorExisting = this.error != null;
    this.onSubmit().pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: any) => {
        console.log('response', response);
        if (!this.entityId
            && response?.id) {
              this.entityId = response.id;
        }
        if (this.alertService
          && this.entityEnum) {
          if (this.newEntity) {
            this.alertService.sucessCreated(this.entityEnum);
          } else {
            this.alertService.sucessModified(this.entityEnum);
          }
        }
        this.error = null;
        if (this.wizard) {
          this.wizard.forceFinish();
        }
        this.onSuccess();
      },
      (error) => {
        this.error = error;
        console.error('error', error);
        this.submitted = false;
        if (this.blockService) {
          this.blockService.stop();
        }

        if (!errorExisting) {
          //TODO: find a solution to jump to error page
          const page = this.wizard.pageCollection.lastPage;
          this.wizard.goTo(page.id);
        }
      }
    );
  }

  onCancel() {
    console.log('onCancel');
    this.close();
    this.afterClosed.next({
      success: false
    });
  }

  toTranslateKey(entityEnum: EntityEnum): string {
    return toTranslateKey(entityEnum);
  }

  onSuccess() {
    const result = {
      success: true,
      id: this.entityId
    };
    this.close();
    this.afterClosed.next(result);
  }

  private close() {
    this.show = false;
    if ( this.blockService) {
      this.blockService.stop();
    }
    if (this.wizard) {
      this.wizard.close();
      this.wizard.reset();
    }
  }
}

