<div clrLayout="vertical">
    <div class="clr-row" *ngIf="relTypes?.length === 0">
        <div class="clr-col-md-12">
            {{ 'message.no_process_items_available' | translate }}
        </div>
    </div>

    <div class="clr-row" *ngIf="relTypes?.length > 0">
        <mat-form-field class="clr-col-md-5">
            <mat-label translate>field.type</mat-label>
            <mtx-select [searchable]="true" [(ngModel)]="selectedRelType" [appendTo]="'body'">
                <mtx-option *ngFor="let relType of relTypes" [value]="relType">
                    {{ relType.typeRight | lookupApplType | async }}</mtx-option>
            </mtx-select>
        </mat-form-field>

        <button class="btn btn-sm" [disabled]="!selectedRelType || !editorValid" (click)="onAdd()" translate>button.add_process_item</button>
    </div>

    <clr-accordion [clrAccordionMultiPanel]="true">
        <clr-accordion-panel *ngFor="let wrappedItem of wrappedItems; index as i" [clrAccordionPanelOpen]="wrappedItem.open">
            <clr-accordion-title>{{'process_item' | translate}} {{ wrappedItem.item.type | lookupApplType | async}}</clr-accordion-title>
            <clr-accordion-content *clrIfExpanded class="clr-row">
                <div class="clr-col-md-11 clr-col-lg-11">
                    <app-process-item-form [(processItem)]="wrappedItem.item" (processItemValid)="onChange(i, $event)">
                    </app-process-item-form>
                </div>
                <div class="clr-col-md-1 clr-col-lg-1">
                    <span *appDeleteAllowed="wrappedItem?.item?.type;immutable:wrappedItem?.required" (click)="onDelete(i)">
                        <clr-icon shape="trash"></clr-icon>
                    </span>
                </div>
            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
</div>