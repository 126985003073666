<clr-wizard #wizardlg [(clrWizardOpen)]="show" clrWizardSize="xl" [clrWizardClosable]="false" (clrWizardOnCancel)="onCancel()"
    [clrWizardPreventDefaultCancel]="true" [clrWizardPreventDefaultNext]="true" (clrWizardOnNext)="onNext()"
    (clrWizardOnFinish)="onFinish()">
    <clr-wizard-title>{{ toTranslateKey(entityEnum) | translate }} {{ object?.name }}</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">{{ 'button.cancel' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{ 'button.back' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{ 'button.next' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{ 'button.finish' | translate }}</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!form.valid || !identifiersValid"
        [clrWizardPageHasError]="!form.valid || !identifiersValid">
        <ng-template clrPageTitle translate>{{ 'section.orgunit_detail' | translate }}</ng-template>
        <ng-template clrPageNavTitle>
            {{ 'section.detail' | translate }}
        </ng-template>
        <form clrForm [formGroup]="form" clrLayout="vertical" #orgForm>
            <div class="clr-row">
                <mat-form-field class="clr-col-md-5">
                    <mat-label translate>field.name</mat-label>
                    <input matInput formControlName="name" required>
                </mat-form-field>
            </div>
            <div class="clr-row">
                <div class="clr-col-md-10">
                    <app-partner-role-form type="type_partner_orgunit" [(partnerRoles)]="partnerRoles"></app-partner-role-form>
                </div>
            </div>
            <mat-divider></mat-divider>
            <app-identifier-editor [selectable]="true" [(identifiers)]="identifiers" type="type_partner_orgunit" (identifiersValid)="identifiersValid = $event">
            </app-identifier-editor>
        </form>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!addressesValid" [clrWizardPageHasError]="!addressesValid">
        <ng-template clrPageTitle>{{ 'section.addresses' | translate }}</ng-template>
        <app-postal-address-editor [(postalAddresses)]="postalAddresses" (valid)="addressesValid = $event"></app-postal-address-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!commAddressesValid" [clrWizardPageHasError]="!commAddressesValid">
        <ng-template clrPageTitle>{{ 'section.communication_addresses' | translate }}</ng-template>
        <app-comm-address-editor  type="type_partner_orgunit" [(commAddresses)]="commAddresses" (valid)="commAddressesValid = $event">
        </app-comm-address-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!partnerPartnersValid" [clrWizardPageHasError]="!partnerPartnersValid">
        <ng-template clrPageTitle>{{ 'section.relations_to_partner' | translate }}</ng-template>
        <app-partner-partner-editor type="type_partner_orgunit" [(partnerPartners)]="partnerPartners" (valid)="partnerPartnersValid = $event">
        </app-partner-partner-editor>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageHasError]="error != null" *ngIf="error">
        <ng-template clrPageTitle>{{ 'section.error_occured' | translate }}</ng-template>
        <app-error [error]="error"></app-error>
    </clr-wizard-page>
</clr-wizard>
