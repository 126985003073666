import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { BankAccountForm } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

function isNotBlank(str) {
  return !(!str || /^\s*$/.test(str));
}
@Component({
  selector: 'app-bankaccount-form',
  templateUrl: './bankaccount-form.component.html',
  styleUrls: ['./bankaccount-form.component.scss']
})
export class BankaccountFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() bankaccount: BankAccountForm;
  @Output() bankaccountValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() bankaccountChange: EventEmitter<BankAccountForm> = new EventEmitter<BankAccountForm>();

  countries$: any;
  accountTypes$: any;

  formValid = false;

  constructor(
    private codeService: AppCodeService,
  ) {
    super();

    this.form = new UntypedFormGroup({
      cdAccountType: new UntypedFormControl('', [Validators.required]),
      iban: new UntypedFormControl(''),
      accountNr: new UntypedFormControl(''),
      accountOwner: new UntypedFormControl(''),
      clearingNr: new UntypedFormControl(''),
      swiftCode: new UntypedFormControl(''),
      cdCountry: new UntypedFormControl('cd_country_switzerland'),
      bankStreet: new UntypedFormControl(''),
      bankPostalcodePlace: new UntypedFormControl('')
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    console.log('onInit');
    if (this.bankaccount) {
      this.form.patchValue(this.bankaccount);
    }
    this.form.markAllAsTouched();

    this.countries$ = this.codeService.getList('cd_country', false);
    this.accountTypes$ = this.codeService.getList('cd_account_type', false);
  }



  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.bankaccount)) {
      this.form.patchValue(this.bankaccount);
      this.form.markAllAsTouched();
    }
  }

  validateForm() {
    const value = this.form.value;
    this.formValid = (value.cdAccountType === 'cd_account_type_iban'
      && isNotBlank(value.iban) && isNotBlank(value.accountOwner))
      || (value.cdAccountType === 'cd_account_type_post'
      && isNotBlank(value.accountNr) && isNotBlank(value.accountOwner))
      || (value.cdAccountType === 'cd_account_type_bank'
      && isNotBlank(value.accountNr) && isNotBlank(value.accountOwner)
      && isNotBlank(value.clearingNr) && isNotBlank(value.swiftCode)
      && isNotBlank(value.bankPostalcodePlace) && isNotBlank(value.cdCountry));
  }
  onChange(): void {
    this.validateForm();
    this.bankaccountValid.emit(this.formValid);
    if (this.formValid) {
      this.bankaccount = _.extend(this.bankaccount, this.form.value);
      this.bankaccountChange.emit(this.bankaccount);
    }
    console.log('changed');
  }
}
