<clr-modal [(clrModalOpen)]="show">
    <h3 class="modal-title" translate>title.confirm_deletion</h3>
    <div class="modal-body">
        <span [innerHTML]="'message.delete_confirmation' | translate:{ detail: objectDetail }">
        </span>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="onCancel()" translate>button.cancel</button>
        <button type="button" class="btn btn-primary" (click)="onSuccess()" translate>button.confirm</button>
    </div>
</clr-modal>
