import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { PartnerService, ProcessHeaderPartnerForm, RelationshipTypeForm } from 'src/app/core';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';

@Component({
  selector: 'app-process-header-partner-editor',
  templateUrl: './process-header-partner-editor.component.html',
  styleUrls: ['./process-header-partner-editor.component.css']
})
export class ProcessHeaderPartnerEditorComponent extends BaseEditorComponent<ProcessHeaderPartnerForm> implements OnInit, OnChanges {

  @Input() processHeaderPartners: ProcessHeaderPartnerForm[];
  @Input() typeLeft: string;
  @Input() typeRight: string;
  @Output() processHeaderPartnersChange: EventEmitter<ProcessHeaderPartnerForm[]> = new EventEmitter<ProcessHeaderPartnerForm[]>();

  tableName = 'processh_partner';
  translations = [];

  constructor(
    private translateService: TranslateService,
    private authenticationService: AuthenticationService,
    protected reltypeService: RelationshipTypeService,
    private partnerService: PartnerService,
  ) {
    super(reltypeService);
  }

  ngOnInit(): void {
    this.translations = this.translateService.instant(['button.add_relationship']);
  }

  protected buildFilter(): any {
    const filter = {};
    if (this.typeLeft) {
      filter['typeLeft'] = this.typeLeft;
    }
    if (this.typeRight) {
      filter['typeRight'] = this.typeRight;
    }

    return filter;
  }

  protected processObjects() {
    if (!this.relTypes
      || !this.processHeaderPartners) {
      return;
    }

    const wrappers = [];
    this.processHeaderPartners.forEach((processHeaderpar: ProcessHeaderPartnerForm) => {
      wrappers.push({
        item: processHeaderpar,
        valid: true,
        relType: _.find(this.relTypes, (relType => relType.id === processHeaderpar.relationshipType))
      } as ItemWrapper<ProcessHeaderPartnerForm>);
    });

    const counter = wrappers.length;
    this.relTypes.forEach(relType => {
      if (relType.ltrMinCardinality > 0
        && wrappers.filter(item => item.relType === relType).length === 0) {
        wrappers.push(this.buildItem(relType, false, true));
      }
    });

    wrappers.sort((e1, e2) => e1?.relType.sort - e2?.relType?.sort);
    this.wrappedItems = wrappers;
    this.calculateFiltered();

    this.valid.emit(counter === wrappers.length);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.processHeaderPartners)
      && !this.wrappedItems) {
      this.processObjects();
    }
    if (this.hasChanged(changes.typeLeft)
      || this.hasChanged(changes.typeRight)) {
      console.log('type left/right', this.typeLeft, this.typeRight);
      this.loadRelationships();
    }
  }

  onAdd() {
    const newItem = this.buildItem(this.selectedRelType, false, false);
    this.wrappedItems.push(newItem);

    if (newItem.relType.derivedFromReltype) {
      console.log('derivedReltype', newItem.relType.derivedFromReltype);
      const wrappedItem = _.find(this.wrappedItems, (item => item.relType.superRelationshipType === newItem.relType.derivedFromReltype));
      console.log('found wrapppeItem', wrappedItem);
      if (wrappedItem) {
        this.handleDerivedRelations(wrappedItem);
      }
    }
    this.calculateFiltered();
    this.handleRelTypeSelection();

    this.valid.emit(newItem.valid);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);
    this.calculateFiltered();

    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    console.log('onchange', index, valid);
    this.wrappedItems[index].valid = valid;

    if (valid) {
      this.handleDerivedRelations(this.wrappedItems[index]);
    }

    this.emit();
  }

  handleDerivedRelations(wrappedItem: ItemWrapper<ProcessHeaderPartnerForm>) {
    const reltypeSuper = wrappedItem.relType.superRelationshipType;
    const partnerId = wrappedItem.item.partnerId;

    this.wrappedItems
      .filter(item => item.relType.derivedFromReltype === reltypeSuper)
      .forEach(item => {
        if (partnerId) {
          this.addDerivedRleation(item, partnerId);
        } else {
          item.item.partnerId = null;
        }
      });
  }

  addDerivedRleation(item: ItemWrapper<ProcessHeaderPartnerForm>, partnerId: number) {
    console.log('addDerivedRleation');
    this.partnerService.getIdInRelation(partnerId, item.relType.logic.toUpperCase()).pipe(
      take(1)
    ).subscribe((id: number) => {
      item.item.partnerId = id;
      item.valid = !!id;
      this.emit();

      this.handleDerivedRelations(item);
    });
  }

  emit() {
    const allValid = this.checkValidity();
    this.processHeaderPartnersChange.emit(_.pluck(this.wrappedItems.filter(item => item.valid), 'item'));
    this.valid.emit(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => !item.valid).length === 0);
    return result;
  }

  private buildItem(relType: RelationshipTypeForm, valid: boolean, required: boolean): ItemWrapper<ProcessHeaderPartnerForm> {
    const item = {
      item: {
        relationshipType: relType.id
      } as ProcessHeaderPartnerForm,
      relType,
      valid,
      required
    } as ItemWrapper<ProcessHeaderPartnerForm>;

    if (relType.logic === 'CURRENT_USER'
      && !valid) {
      item.item.partnerId = this.authenticationService.authenticationInfoValue.partnerId;
      item.valid = true;
    }

    return item;
  }
}
