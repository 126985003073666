
<form clrForm *ngIf="form" [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-4">
            <mat-label translate>field.type</mat-label>
            <input matInput [value]="processHeader?.type | lookupApplType | async" disabled>
        </mat-form-field>
        <mat-form-field class="clr-col-md-4">
            <mat-label translate>field.sub_type</mat-label>
            <mtx-select [searchable]="true" [hideSelected]="true" formControlName="subType" [appendTo]="'body'" required="true">
                <mtx-option *ngFor="let subType of subTypes$ | async" [value]="subType.id">
                    {{ subType | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <mat-form-field class="clr-col-md-4">
            <mat-label translate>field.status</mat-label>
            <mtx-select [searchable]="true" formControlName="cdStatus" [appendTo]="'body'" [disabled]="statusSetByWorkflow">
                <mtx-option *ngFor="let status of cdStatuses$ | async" [value]="status.id">
                    {{ status | language:'name' }}</mtx-option>
            </mtx-select>
            <mat-hint *ngIf="statusSetByWorkflow" translate>
                info.status_set_by_workflow
            </mat-hint>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.reference</mat-label>
            <input matInput formControlName="reference" maxlength="60" required>
            <mat-error *ngIf="form.get('reference')?.hasError('referenceExists')" translate>
                error.reference_already_exists
            </mat-error>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.title</mat-label>
            <textarea matInput formControlName="title" maxlength="80" [rows]="2"></textarea>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.reference2</mat-label>
            <input matInput formControlName="reference2" maxlength="60">
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.report_language</mat-label>
            <mtx-select [searchable]="true" formControlName="cdLanguageReport" required>
                <mtx-option *ngFor="let cdLanguage of cdLanguages$ | async" [value]="cdLanguage.id">
                    {{ cdLanguage | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.description</mat-label>
            <textarea matInput formControlName="description" maxlength="4000" [rows]="5"></textarea>
        </mat-form-field>
    </div>
    <div class="clr-row" *ngIf="processHeader?.type === 'type_processh_claim_rep'">
        <mat-checkbox class="clr-col-md-12" [(ngModel)]="withoutInternalDescription" [ngModelOptions]="{standalone: true}" (ngModelChange)="onChange()">
            <mat-label translate>field.without_internal_description</mat-label>
        </mat-checkbox>
    </div>
    <div class="clr-row" *ngIf="processHeader?.type === 'type_processh_claim_rep' && !withoutInternalDescription">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.description_internal</mat-label>
            <textarea matInput formControlName="descriptionInternal" maxlength="4000" [rows]="5"></textarea>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6" *ngIf="context.processHeaderDateWithTime">
            <mat-label>{{ translations[context.processHeaderDateTag] }}</mat-label>
            <input matInput [ngxMatDatetimePicker]="picker1" formControlName="processHeaderDate" required>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <ngx-mat-datetime-picker [defaultTime]="defaultTime" #picker1></ngx-mat-datetime-picker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6" *ngIf="!context.processHeaderDateWithTime">
            <mat-label>{{ translations[context.processHeaderDateTag] }}</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="processHeaderDate" required>
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6" *ngIf="context.entryDate != hidden">
            <mat-label translate>field.entry_date</mat-label>
            <input matInput [matDatepicker]="picker3" formControlName="entryDate" [required]="context.entryDate == mandatory">
            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6" *ngIf="context.dueDate != hidden">
            <mat-label translate>field.due_date</mat-label>
            <input matInput [matDatepicker]="picker4" formControlName="dueDate" [required]="context.dueDate == mandatory">
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-datepicker #picker4></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6" *ngIf="context.completionDate != hidden">
            <mat-label translate>field.completion_date</mat-label>
            <input matInput [matDatepicker]="picker5" formControlName="completionDate">
            <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
            <mat-datepicker #picker5></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6" *ngIf="context.location != hidden">
            <mat-label translate>field.location</mat-label>
            <input matInput formControlName="location" maxlength="80" [required]="context.location == mandatory">
        </mat-form-field>
    </div>
    <div class="clr-row" *ngIf="context.period != hidden">
        <mat-form-field formGroupName="period" class="clr-col-md-6">
            <mat-label translate>field.start</mat-label>
            <input matInput [matDatepicker]="picker6" formControlName="start" [max]="form.value?.period?.end" [required]="context.period === mandatory">
            <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
            <mat-datepicker #picker6></mat-datepicker>
        </mat-form-field>
        <mat-form-field formGroupName="period" class="clr-col-md-6">
            <mat-label translate>field.end</mat-label>
            <input matInput [matDatepicker]="picker7" formControlName="end" [min]="form.value?.period?.start" [required]="context.period === mandatory">
            <mat-datepicker-toggle matSuffix [for]="picker7"></mat-datepicker-toggle>
            <mat-datepicker #picker7></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6" *ngIf="context.incapacityForWork != hidden">
            <mat-label translate>field.incapacity_to_work</mat-label>
            <input matInput type="number" formControlName="incapacityForWork" min="0" max="100" [required]="context.incapacityForWork == mandatory">
        </mat-form-field>
    </div>
    <div class="clr-row" formGroupName="periodActual" *ngIf="context.periodActual != hidden">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.start_date_actual</mat-label>
            <input matInput [matDatepicker]="picker8" formControlName="start" [max]="form.value?.periodActual?.end" [required]="context.periodActual == mandatory">
            <mat-datepicker-toggle matSuffix [for]="picker8"></mat-datepicker-toggle>
            <mat-datepicker #picker8></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.end_date_actual</mat-label>
            <input matInput [matDatepicker]="picker9" formControlName="end" [min]="form.value?.periodActual?.start" [required]="context.periodActual == mandatory">
            <mat-datepicker-toggle matSuffix [for]="picker9"></mat-datepicker-toggle>
            <mat-datepicker #picker9></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="clr-row" *ngIf="allowSelectCase">
        <div class="clr-col-md-6">
            <app-case-search [fieldName]="'field.assign_to_case' | translate" [(id)]="caseId" (idChange)="caseSelected()"></app-case-search>
        </div>
    </div>
</form>
