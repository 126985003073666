<clr-modal [(clrModalOpen)]="show" clrModalSize="xl">
  <div class="modal-body">
    <div class="clr-row">
        <ngx-extended-pdf-viewer *ngIf="data" class="clr-col-md-12" [src]="data" [height]="'58vh'" [useBrowserLocale]="true"></ngx-extended-pdf-viewer>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-primary" (click)="show = false">Ok</button>
  </div>
</clr-modal>
