import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CasePolicyHeaderForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';

@Component({
  selector: 'app-case-policy-editor',
  templateUrl: './case-policy-editor.component.html',
  styleUrls: ['./case-policy-editor.component.css']
})
export class CasePolicyEditorComponent extends BaseEditorComponent<CasePolicyHeaderForm> implements OnInit, OnChanges {

  @Input() casePolicies: CasePolicyHeaderForm[];
  @Input() caseType: string;
  @Output() casePoliciesChange: EventEmitter<CasePolicyHeaderForm[]> = new EventEmitter<CasePolicyHeaderForm[]>();

  tableName = 'applcase_policyh';

  translations = [];

  constructor(
    private translateService: TranslateService,
    protected reltypeService: RelationshipTypeService,
  ) {
    super(reltypeService);
  }

  ngOnInit(): void {
    this.translations = this.translateService.instant(['button.add_relationship']);
  }

  protected buildFilter(): any {
    const filter = {
      typeLeft: this.caseType
    };
    return filter;
  }

  protected processObjects() {
    if (!this.relTypes
      || !this.casePolicies) {
      return;
    }

    const wrappers = [];
    this.casePolicies.forEach((caseobj: CasePolicyHeaderForm) => {
      wrappers.push({
        item: caseobj,
        valid: true,
        relType: _.find(this.relTypes, (relType => relType.id === caseobj.relationshipType))
      } as ItemWrapper<CasePolicyHeaderForm>);
    });

    wrappers.sort((e1, e2) => e1?.relType.sort - e2?.relType?.sort);
    this.wrappedItems = wrappers;
    this.calculateFiltered();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.casePolicies)
      && !this.wrappedItems) {
      this.processObjects();
    }
    if (this.hasChanged(changes.caseType)) {
      this.loadRelationships();
    }
  }

  onAdd() {
    this.wrappedItems.push({
      item: {
        relationshipType: this.selectedRelType.id
      } as CasePolicyHeaderForm,
      relType: this.selectedRelType,
      valid: false
    } as ItemWrapper<CasePolicyHeaderForm>);
    this.calculateFiltered();
    this.handleRelTypeSelection();

    this.valid.emit(false);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);
    this.calculateFiltered();

    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    console.log('onchange', index, valid);
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.casePoliciesChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.valid.emit(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => !item.valid).length === 0);
    return result;
  }
}
