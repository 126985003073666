import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PartnerPartnerForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';


@Component({
  selector: 'app-partner-partner-editor',
  templateUrl: './partner-partner-editor.component.html',
  styleUrls: ['./partner-partner-editor.component.css']
})
export class PartnerPartnerEditorComponent extends BaseEditorComponent<PartnerPartnerForm> implements OnInit, OnChanges {

  @Input() type: string;
  @Input() mode = 'ltr';
  @Input() partnerPartners: PartnerPartnerForm[];
  @Output() partnerPartnersChange: EventEmitter<PartnerPartnerForm[]> = new EventEmitter<PartnerPartnerForm[]>();

  tableName = 'partner_partner';
  translations = [];

  constructor(
    private translateService: TranslateService,
    protected reltypeService: RelationshipTypeService,
  ) {
    super(reltypeService);
 }

  ngOnInit(): void {
    this.translations = this.translateService.instant(['button.add_relationship']);
  }

  protected buildFilter() {
    if (this.mode === 'ltr') {
      return { typeLeft: this.type };
    }
    return { typeRight: this.type };
  }

  protected processObjects() {
    const wrappers = [];

    if (!this.relTypes
      || !this.partnerPartners) {
      return;
    }

    this.partnerPartners.forEach((parpar: PartnerPartnerForm) => {
      wrappers.push({
        item: parpar,
        relType: _.find(this.relTypes, (relType => relType.id === parpar.relationshipType)),
        valid: true
      });
    });

    wrappers.sort((e1, e2) => e1?.relType?.sort - e2?.relType?.sort);
    this.wrappedItems = wrappers;
    this.calculateFiltered();
  }

  protected calculateFiltered() {
    if (this.mode === 'ltr') {
      super.calculateFiltered();
      return;
    }

    this.countByType = _.countBy(this.wrappedItems, (item: ItemWrapper<PartnerPartnerForm>) => item.item.relationshipType);
    // tslint:disable-next-line:max-line-length
    this.filteredRelTypes = this.relTypes.filter(item => (this.countByType[item.id] == null || this.countByType[item.id] < item.rtlMaxCardinality));
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.type)) {
      this.loadRelationships();
    }

    if (this.hasChanged(changes.partnerPartners)
      && !this.wrappedItems) {
      this.processObjects();
    }
  }

  onAdd() {
    this.wrappedItems.push({
      item: {
        relationshipType: this.selectedRelType.id
      },
      relType: this.selectedRelType,
      valid: false
    } as ItemWrapper<PartnerPartnerForm>);
    this.calculateFiltered();
    this.handleRelTypeSelection();

    this.valid.emit(false);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);
    this.calculateFiltered();

    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.partnerPartnersChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.valid.emit(allValid);
  }

  checkValidity(): boolean {
    const result = this.wrappedItems.filter(item => item.valid === false).length === 0;
    return result;
  }
}
