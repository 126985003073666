import { ComponentFactoryResolver, Injectable, Type, ViewContainerRef } from '@angular/core';
import { first, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/base/base/base.component';
import { ConfirmationDialogComponent } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog.component';
import { ProcessHeaderProcesseHeaderDialogComponent } from 'src/app/shared/dialogs/process-header-processe-header-dialog/process-header-processe-header-dialog.component';
import { ProcessHeaderReference } from 'src/app/shared/dialogs/process-header-reference';
import { ProcessHeaderTypeDialogComponent } from 'src/app/shared/dialogs/process-header-type-dialog/process-header-type-dialog.component';
import { Editor } from 'src/app/shared/editors/editor';
import { ProcessHeaderTypeUtils } from 'src/app/shared/forms/process-header-form/process-header-type-utils';
import { ProcessHeaderForm, ProcessHeaderProcessHeaderForm, RelationshipTypeForm, WizardhelperService } from '..';
import { BlockService } from './block.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicComponentService extends BaseComponent {

  vcr: ViewContainerRef;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private wizardhelperService: WizardhelperService,
    private blockService: BlockService
  ) {
    super();
  }

  public registerViewContainerRef(viewContainerRef: ViewContainerRef): void {
    this.vcr = viewContainerRef;
  }

  openConfirmationDialog(id: number, objectDetail: string, onClose: (result: any) => void) {
    this.openDialog(ConfirmationDialogComponent, null, objectDetail, (resultDialog) => {
      resultDialog.id = id;
      onClose(resultDialog);
    });
  }

  openPHDialogAndWizard(caseId: number, onClose: (result: any) => void) {
    this.openDialog(ProcessHeaderTypeDialogComponent, { caseId }, null, (resultDialog) => {
      if (resultDialog?.success) {
        const wizard = ProcessHeaderTypeUtils.mapToWizard(resultDialog.type);

        this.blockService.startWithMessage('message.prepare_wizard');
        if (caseId) {
          this.wizardhelperService.deriveProcessHeaderFromCase(caseId, resultDialog.type).pipe(
            takeUntil(this.ngUnsubscribe),
            first()
          ).subscribe(processHeader => {
            this.openEditor(wizard, this.buildParams(null, processHeader), (result) => onClose(result));
          }, error => {
            this.openEditor(wizard, this.buildParams(null, { type: resultDialog.type, caseId }), (result) => onClose(result));
          });
        } else {
          this.openEditor(wizard, this.buildParams(null, { type: resultDialog.type, caseId }), (result) => onClose(result));
        }
      }
    });
  }

  openPHPHDialogAndWizard(processHeaderId, onClose: (result: any) => void) {
    this.openDialog(ProcessHeaderProcesseHeaderDialogComponent, {processHeaderId}, null, (resultDialog) => {
      if (resultDialog?.success) {
        const wizard = ProcessHeaderTypeUtils.mapToWizard(resultDialog.relationshipType.typeRight);

        // tslint:disable-next-line:max-line-length
        const processHeader = this.buildProcessHeader(resultDialog.relationshipType.typeRight, resultDialog.relationshipType, resultDialog.caseId, processHeaderId);
        // tslint:disable-next-line:max-line-length
        this.newMethod().openEditor(wizard, this.buildParams(null, processHeader), (result) => onClose(result));
      }
    });
  }


  private newMethod() {
    return this;
  }

  openDialogAndWizard(dialogClass: Type<any>, wizardClass: Type<Editor>, params: Editor, onClose: (result: any) => void) {
    this.openDialog(dialogClass, null, null, (resultDialog) => {
      if (resultDialog?.success) {
        this.blockService.startWithMessage('message.prepare_wizard');
        if (resultDialog.type.includes('type_case')) {
          this.wizardhelperService.prefillCase(resultDialog.type).pipe(
            takeUntil(this.ngUnsubscribe),
            first()
          ).subscribe(caseForm => {
            this.openEditor(wizardClass, this.buildParams(null, caseForm), (result) => onClose(result));
          }, error => {
            this.openEditor(wizardClass, this.buildParams(null, { type: resultDialog.type }), (result) => onClose(result));
          });
        } else {
          this.openEditor(wizardClass, this.buildParams(null, { type: resultDialog.type }), (result) => onClose(result));
        }
      }
    });
  }

  private openDialog(componentClass: Type<any>, reference: ProcessHeaderReference, objectDetail: string, onClose: (result: any) => void) {
    const component = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    const componentRef = this.vcr.createComponent(component);
    const peRef = componentRef.instance;

    peRef.reference = reference;
    if (objectDetail) {
      peRef.objectDetail = objectDetail;
    }

    peRef.afterClosed.pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(result => {
      componentRef.destroy();
      onClose(result);
    });
  }

  openEditor(componentClass: Type<Editor>, params: Editor, onClose: (result: any) => void) {
    this.blockService.startWithMessage('message.prepare_wizard');

    const component = this.componentFactoryResolver.resolveComponentFactory(componentClass);
    const componentRef = this.vcr.createComponent(component);

    const peRef = (componentRef.instance as Editor);

    peRef.id = params.id;
    peRef.object = params?.object;

    peRef.afterClosed.pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(result => {
      componentRef.destroy();
      if (params?.closeWindowOnFinish === true) {
        window.close();
      }
      onClose(result);
    });
  }

  buildParams(id: number, object?: any, closeWindowOnFinish: boolean = false): Editor {
    return { id, object, closeWindowOnFinish } as Editor;
  }

  buildProcessHeader(type: string, relationshipType: RelationshipTypeForm, caseId: number, processHeaderId: number) {
    const phPh = {
      relationshipType: relationshipType.id,
      processHeaderIdRight: processHeaderId
    } as ProcessHeaderProcessHeaderForm;

    const processHeader = {
      type,
      caseId,
      processHeadersLeft: []
    } as ProcessHeaderForm;

    processHeader.processHeadersLeft.push(phPh);
    return processHeader;
  }


}
