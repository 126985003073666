<clr-datagrid>
    <clr-dg-column [clrDgSortBy]="'type'">{{ 'field.type' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'referenceNumber'">{{ 'field.reference_nr' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'registrationNr'">{{ 'field.registration_nr' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'vehicleNr'">{{ 'vehicle_nr' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.description' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'cdStatus'">{{ 'field.status' | translate }}
    </clr-dg-column>

    <clr-dg-row *ngFor="let obj of objects" [clrDgItem]="obj">
        <clr-dg-cell>{{ obj.type | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell><a [routerLink]="['/objects/detail', obj.id]">{{ obj.referenceNumber }}</a></clr-dg-cell>
        <clr-dg-cell>{{ obj.registrationNr }}</clr-dg-cell>
        <clr-dg-cell>{{ obj.vehicleNr }}</clr-dg-cell>
        <clr-dg-cell>{{ obj.description }}</clr-dg-cell>
        <clr-dg-cell>{{ obj.cdStatus | lookupApplCode | async }}</clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>
