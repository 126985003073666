/**
 * myclaims API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProcessHeaderObjectForm { 
    id?: number;
    relationshipType?: string;
    cdCovered?: string;
    amountNet?: number;
    description?: string;
    objectId?: number;
    objectName?: string;
    objectType?: string;
    objectReferenceNumber?: string;
    objectRegistrationNr?: string;
    objectVehicleNr?: string;
}

