
const BLANK_REGEX = /^\s*$/;

export function normalizePhoneNumber(phoneNumber: string): string {
    if (!phoneNumber) {
        return null;
    }

    return phoneNumber.split(' ').join('')
        .split('+').join('00')
        .split('-').join('')
        .split('(').join('')
        .split(')').join('');
}

export function isNotBlank(value: string): boolean {
    return !isBlank(value);
}

export function isBlank(str: string): boolean {
    return (!str || BLANK_REGEX.test(str));
}

export function toCamelCase(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}


