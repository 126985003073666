import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { take } from 'rxjs/operators';
import { CasePartnerForm, PartnerService, RelationshipTypeForm } from 'src/app/core';
import { AuthenticationService } from 'src/app/core/service/authentication.service';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import { showDegreeOfResponsability } from 'src/app/shared/case-util';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';


@Component({
  selector: 'app-case-partner-editor',
  templateUrl: './case-partner-editor.component.html',
  styleUrls: ['./case-partner-editor.component.css']
})
export class CasePartnerEditorComponent extends BaseEditorComponent<CasePartnerForm> implements OnChanges {

  @Input() casePartners: CasePartnerForm[];
  @Input() typeLeft: string;
  @Input() typeRight: string;
  @Input() excludedReltypes = [];
  @Output() casePartnersChange: EventEmitter<CasePartnerForm[]> = new EventEmitter<CasePartnerForm[]>();

  tableName = 'applcase_partner';
  showMaxResponsabilityExceedWarning = false;
  showDegreeOfResponsability = false;

  translations = [];

  constructor(
    private authenticationService: AuthenticationService,
    private partnerService: PartnerService,
    protected reltypeService: RelationshipTypeService,
  ) {
    super(reltypeService);
 }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.casePartners)
      && !this.wrappedItems) {
      this.processObjects();
    }
    if (this.hasChanged(changes.typeLeft)
      || this.hasChanged(changes.typeRight)) {
      console.log('type left/right', this.typeLeft, this.typeRight);
      this.showDegreeOfResponsability = showDegreeOfResponsability(this.typeLeft);

      this.loadRelationships();
    }
  }
  protected buildFilter(): any {
    const filter = {};
    if (this.typeLeft) {
      filter['typeLeft'] = this.typeLeft;
    }
    if (this.typeRight) {
      filter['typeRight'] = this.typeRight;
    }

    return filter;
  }

  protected processObjects() {
    if (!this.relTypes
      || !this.casePartners) {
      return;
    }

    const wrappers = [];
    console.log('reltypes', this.relTypes);
    this.casePartners.forEach((casepar: CasePartnerForm) => {
      const relType = _.find(this.relTypes, (item => item.id === casepar.relationshipType));
      const required = relType.ltrMinCardinality > 0
        && wrappers.filter(item => item.relType === relType).length < relType.ltrMinCardinality;

      wrappers.push({
        item: casepar,
        valid: true,
        relType,
        required
      } as ItemWrapper<CasePartnerForm>);
    });

    const counter = wrappers.length;
    this.relTypes.forEach( relType => {
      if (relType.ltrMinCardinality > 0
        && wrappers.filter(item => item.relType === relType).length < relType.ltrMinCardinality ) {
        const wrapperItem = this.buildWrapperItem(relType, false, true);
        wrappers.push(wrapperItem);
      }
    });
    wrappers.sort((e1, e2) => e1?.relType?.sort - e2?.relType?.sort);
    this.wrappedItems = wrappers;
    this.calculateFiltered();

    this.valid.emit(counter === wrappers.length);
  }

  onAdd() {
    const newItem = this.buildWrapperItem(this.selectedRelType, false);
    this.wrappedItems.push(newItem);
    if (newItem.relType.derivedFromReltype) {
      console.log('derivedReltype', newItem.relType.derivedFromReltype);
      const wrappedItem = _.find(this.wrappedItems, (item => item.relType.superRelationshipType === newItem.relType.derivedFromReltype));
      console.log('found wrapppeItem', wrappedItem);
      if (wrappedItem) {
        this.handleDerivedRelations(wrappedItem);
      }
    }

    this.calculateFiltered();
    this.handleRelTypeSelection();

    this.valid.emit(newItem.valid);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);
    this.calculateFiltered();

    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    console.log('onchange', index, valid);
    this.wrappedItems[index].valid = valid;

    if (valid) {
      this.handleDerivedRelations(this.wrappedItems[index]);
    }

    this.emit();
  }

  handleDerivedRelations(wrappedItem: ItemWrapper<CasePartnerForm>) {
    const reltypeSuper = wrappedItem.relType.superRelationshipType;
    const partnerId = wrappedItem.item.partnerId;

    this.wrappedItems
      .filter(item => item.relType.derivedFromReltype === reltypeSuper)
      .forEach( item => {
        if (partnerId) {
          this.addDerivedRleation(item, partnerId);
        } else {
          item.item.partnerId = null;
        }
      });
  }

  addDerivedRleation(item: ItemWrapper<CasePartnerForm>, partnerId: number) {
    console.log('addDerivedRleation');
    this.partnerService.getIdInRelation(partnerId, item.relType.logic.toUpperCase()).pipe(
      take(1)
    ).subscribe ( (id: number) => {
      item.item.partnerId = id;
      item.valid = !!id;
      this.emit();

      this.handleDerivedRelations(item);
    });
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.casePartnersChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.valid.emit(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => !item.valid).length === 0);

    const sumResponsebility = this.wrappedItems
      .filter(item => item.item.degreeOfResponsability)
      .map(item => item.item.degreeOfResponsability)
      .reduce((a, b) => a + b, 0);

    if (sumResponsebility && sumResponsebility > 100) {
      this.showMaxResponsabilityExceedWarning = true;
      return false;
    }
    this.showMaxResponsabilityExceedWarning = false;
    return result;
  }

  private buildWrapperItem(relType: RelationshipTypeForm, valid: boolean, required?: boolean): ItemWrapper<CasePartnerForm> {
    const item =  {
      item: {
        relationshipType: relType.id
      } as CasePartnerForm,
      relType,
      valid,
      required
    } as ItemWrapper<CasePartnerForm>;

    if (relType.logic === 'CURRENT_USER'
        && !valid) {
        item.item.partnerId = this.authenticationService.authenticationInfoValue.partnerId;
        item.valid = true;
    }

    return item;
  }
}
