import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ProcessHeaderProcessHeaderForm, ProcessheaderService, RelationshipTypeForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseComponent } from '../../base/base/base.component';
import { ProcessHeaderReference } from '../process-header-reference';

@Component({
  selector: 'app-process-header-processe-header-dialog',
  templateUrl: './process-header-processe-header-dialog.component.html',
  styleUrls: ['./process-header-processe-header-dialog.component.scss']
})
export class ProcessHeaderProcesseHeaderDialogComponent extends BaseComponent implements OnInit {

  loading = true;
  show = true;
  relationshipType: any;
  reference: ProcessHeaderReference;
  afterClosed = new Subject<any>();
  caseId: number;

  processhTypes$: Observable<any[]>;
  filteredRelTypes: RelationshipTypeForm[];

  constructor(
    private processHeaderController: ProcessheaderService,
    private reltypeService: RelationshipTypeService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.processHeaderController.findByIdProcessHeader(this.reference?.processHeaderId).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(processHeader => {
      this.caseId = processHeader?.caseId;
      this.loadProcessHeaderProcessHeaderRelationships(processHeader.type, processHeader.processHeadersLeft);
    });
  }

  private loadProcessHeaderProcessHeaderRelationships(type: string, processHeadersRigth: ProcessHeaderProcessHeaderForm[]): void {
    this.reltypeService.getRelationships('processh_processh', { typeLeft: type }).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(relTypes => {
      const countByType = _.countBy(processHeadersRigth, (item => item.typeRight));
      // tslint:disable-next-line:max-line-length
      this.filteredRelTypes = relTypes.filter(item => (countByType[item.typeLeft] == null || countByType[item.typeLeft] < item.ltrMaxCardinality));
      this.loading = false;
    }, error => {
      console.error('get relTypes - error', error);
    });
  }

  onCancel() {
    console.log('onCancel');
    this.close();
    this.afterClosed.next({
      success: false
    });
  }

  onSuccess() {
    this.close();
    this.afterClosed.next({
      success: true,
      relationshipType: this.relationshipType,
      caseId: this.caseId
    });
  }

  private close() {
    this.show = false;
  }

}
