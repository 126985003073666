<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.illness_date</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="illnessDate" required>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="clr-row" [hidden]="!insuranceSpecifics?.includes('PREDICTED_DURATION')">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.predicted_duration</mat-label>
            <mtx-select [searchable]="true"
                formControlName="cdPredictedDuration" [required]="insuranceSpecifics?.includes('PREDICTED_DURATION')">
            <mtx-option *ngFor="let cdPredictedDuration of cdPredictedDuration$ | async" [value]="cdPredictedDuration.id">
                    {{ cdPredictedDuration | language:'name' }}
            </mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <div class="clr-col-md-12 field">
            <label id="monitoring-label" translate>field.monitoring_needed</label>
            <mat-radio-group aria-labelledby="monitoring-label" formControlName="monitoringNeeded">
                <mat-radio-button [value]="false">{{ 'button.no' | translate }}</mat-radio-button>
                <mat-radio-button [value]="true">{{ 'button.yes' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.illness_kind</mat-label>
            <textarea matInput formControlName="illnessKind" maxlength="4000" [rows]="2"></textarea>
        </mat-form-field>
    </div>

    <div class="clr-row" formGroupName="incapacityToWork">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.stopped_work_date</mat-label>
            <input [ngxMatDatetimePicker]="picker2" formControlName="stoppedWorkDate" matInput required>
            <mat-datepicker-toggle [for]="picker2" matSuffix></mat-datepicker-toggle>
            <ngx-mat-datetime-picker [defaultTime]="defaultTime" #picker2></ngx-mat-datetime-picker>
        </mat-form-field>
    </div>
    <div class="clr-row" formGroupName="incapacityToWork">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.resumed_work_date</mat-label>
            <input [matDatepicker]="picker3" formControlName="resumedWorkDate" matInput>
            <mat-datepicker-toggle [for]="picker3" matSuffix></mat-datepicker-toggle>
            <mat-datepicker #picker3></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.resumed_work_percentage</mat-label>
            <input type="number" formControlName="resumedWorkPercentage" matInput>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <div class="clr-col-md-12 field">
            <label id="disability-insurance-label" translate>field.reported_to_disability_insurance</label>
            <mat-radio-group aria-labelledby="disability-insurance-label" formControlName="alreadyReportedToDisabilityInsurance">
                <mat-radio-button [value]="false">{{ 'button.no' | translate }}</mat-radio-button>
                <mat-radio-button [value]="true">{{ 'button.yes' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <div class="clr-row">
        <div class="clr-col-md-12 field">
            <label id="disability-insurance-label" translate>field.due_to_accidents</label>
            <mat-radio-group aria-labelledby="disability-insurance-label" formControlName="dueToAccidents">
                <mat-radio-button [value]="false">{{ 'button.no' | translate }}</mat-radio-button>
                <mat-radio-button [value]="true">{{ 'button.yes' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
<!--
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.illness_description</mat-label>
            <textarea matInput formControlName="description" maxlength="4000" [rows]="10" required></textarea>
        </mat-form-field>
    </div>
-->
</form>
