import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sunet-illness-form',
  templateUrl: './sunet-illness-form.component.html',
  styleUrls: ['./sunet-illness-form.component.css']
})
export class SunetIllnessFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() illnessDetail: any;
  @Input() insuranceSpecifics = [];
  @Output() illnessDetailValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() illnessDetailChange: EventEmitter<any> = new EventEmitter<any>();

  translations = [];

  cdPredictedDuration$: Observable<any[]>;
  defaultTime = [0, 0, 0];

  constructor(
    private codeService: AppCodeService,
    private fb: UntypedFormBuilder,
  ) {
    super();
    this.form = this.fb.group({
      illnessDate: this.fb.control('', [Validators.required]),
      illnessKind: this.fb.control(''),
      cdPredictedDuration: this.fb.control(null),
      incapacityToWork: this.fb.group({
        stoppedWorkDate: this.fb.control(null),
        resumedWorkDate: this.fb.control(null),
        resumedWorkPercentage: this.fb.control(0)
      }),
      monitoringNeeded: new UntypedFormControl(false),
      alreadyReportedToDisabilityInsurance: new UntypedFormControl(false),
      dueToAccidents: new UntypedFormControl(false),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    this.cdPredictedDuration$ = this.codeService.getList('cd_pred_duration', false);

    if (this.illnessDetail) {
      this.form.patchValue(this.illnessDetail);
    }
    this.form.markAllAsTouched();

    if (this.disabled) {
      this.form.disable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.illnessDetail)) {
      this.form.patchValue(this.illnessDetail);
      this.form.markAllAsTouched();
    }
    if (this.hasChanged(changes.insuranceSpecifics)) {
      this.form.markAllAsTouched();
      this.onChange();
    }
  }

  onChange(): void {
    this.illnessDetailValid.emit(this.form.valid);
    const modified = _.extend(this.illnessDetail, this.form.value);
    this.illnessDetailChange.emit(modified);
  }
}


