<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.type</mat-label>
            <input matInput [value]="commAddress.type | lookupApplType | async" disabled>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.value</mat-label>
            <input matInput formControlName="commAddress" required>
            <mat-error *ngIf="form?.get('commAddress')?.errors?.email" translate>form_error.not_an_email</mat-error>
        </mat-form-field>
    </div>
</form>