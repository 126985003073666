import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { PostalAddressForm, RelationshipTypeForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';

@Component({
  selector: 'app-postal-address-editor',
  templateUrl: './postal-address-editor.component.html',
  styleUrls: ['./postal-address-editor.component.css']
})
export class PostalAddressEditorComponent extends BaseEditorComponent<PostalAddressForm> implements OnInit, OnChanges {

  @Input() postalAddresses: PostalAddressForm[];
  @Input() typeRight: string;
  @Output() postalAddressesChange: EventEmitter<PostalAddressForm[]> = new EventEmitter<PostalAddressForm[]>();

  tableName = 'postaladdress';

  selectedType: any;

  constructor(
    protected relationshipTypeService: RelationshipTypeService,
  ) {
    super(relationshipTypeService);

    if (this.typeRight) {
      this.loadRelationships();
    }
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.postalAddresses)
      && !this.wrappedItems) {
      this.processObjects();
    }
    if (this.hasChanged(changes.typeRight)) {
      this.loadRelationships();
    }
  }

  protected buildFilter(): any {
    return { typeRight: this.typeRight };
  }

  protected processObjects() {
    if (!this.relTypes
      || !this.postalAddresses) {
      return;
    }

    const wrappers = [];
    this.postalAddresses.forEach((postalAdr) => {
      const relType = _.find(this.relTypes, (item => item.id === postalAdr.relationshipType));
      const required = relType?.ltrMinCardinality > 0
        && wrappers.filter(item => item.relType === relType).length < relType?.ltrMinCardinality;

      wrappers.push({
        item: postalAdr,
        valid: true,
        relType,
        required,
        open: !postalAdr.id,
      });
    });
    wrappers.sort((e1, e2) => e1?.relType?.sort - e2?.relType?.sort);
    this.wrappedItems = wrappers;

    this.calculateFiltered();
  }

  onAdd() {
    const newItem = this.buildWrapperItem(this.selectedRelType, false, false); this.wrappedItems.push(newItem);
    this.calculateFiltered();
    this.handleRelTypeSelection();

    this.valid.emit(newItem.valid);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);
    this.calculateFiltered();
    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    console.log('onChange', index, valid);
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.postalAddressesChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.emitValid(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => item.valid === false).length === 0);
    return result;
  }

  private buildWrapperItem(relType: RelationshipTypeForm, valid: boolean, required?: boolean): ItemWrapper<PostalAddressForm> {
    const item = {
      item: {
        relationshipType: relType.id,
        type: relType.typeLeft
      } as PostalAddressForm,
      relType,
      valid,
      required
    } as ItemWrapper<PostalAddressForm>;

    return item;
  }

}
