<span *ngIf="phone || email">
    ( 
        <span *ngIf="phone">
            <a href="tel:{{ phone }}">{{phone}}</a>
        </span>
        <span *ngIf="phone && email">
            ,&nbsp;
        </span>
        <span *ngIf="email">
            <a href="mailto:{{ email }}">{{email}}</a>
        </span>
    )
</span>
