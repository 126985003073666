import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ClrForm } from '@clr/angular';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ApplCodeForm, CommAddressForm, OrgUnitForm, OrgUnitService, PartnerIdentifierForm, PartnerPartnerForm, PartnerRoleForm, PostalAddressForm } from 'src/app/core';
import { EntityEnum } from 'src/app/core/entity-enum';
import { AlertService } from 'src/app/core/service/alert.service';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import { BlockService } from 'src/app/core/service/block.service';
import * as _ from 'underscore';
import { BaseWizardComponent } from '../../base/base-wizard/base-wizard.component';
import { Editor } from '../../editors/editor';



@Component({
  selector: 'app-orgunit-wizard',
  templateUrl: './orgunit-wizard.component.html',
  styleUrls: ['./orgunit-wizard.component.css']
})
export class OrgunitWizardComponent extends BaseWizardComponent implements OnInit, Editor {

  entityEnum = EntityEnum.Orgunit;

  @Input() id: number;
  @Input() object: OrgUnitForm;
  @ViewChild('orgForm') clrForm: ClrForm;


  cdSectors$: Observable<ApplCodeForm[]>;

  partnerRoles: PartnerRoleForm[];
  partnerRolesValid = true;

  identifiers: PartnerIdentifierForm[];
  identifiersValid = true;

  postalAddresses: PostalAddressForm[];
  addressesValid = true;

  commAddresses: CommAddressForm[];
  commAddressesValid = true;

  partnerPartners: PartnerPartnerForm[];
  partnerPartnersValid = true;

  form: UntypedFormGroup;

  constructor(
    private codeService: AppCodeService,
    private orgUnitService: OrgUnitService,
    private translateService: TranslateService,
    alertService: AlertService,
    blockService: BlockService,
  ) {
    super(blockService, alertService);

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(''),
      legalEntity: new UntypedFormControl(),
      cdSector: new UntypedFormControl()
    });
  }

  ngOnInit(): void {
    this.newEntity = !this.id;

    this.cdSectors$ = this.codeService.getList('cd_sector', false);

    if (this.object) {
      this.form.patchValue(this.object);
    }
    this.loadData();
  }

  loadData() {
    if (!this.id) {
      // tslint:disable-next-line:no-angle-bracket-type-assertion
      this.object = {} as OrgUnitForm;
      this.partnerRoles = [];
      this.identifiers = [];
      this.postalAddresses = [];
      this.commAddresses = [];
      this.partnerPartners = [];
      return;
    }
    this.orgUnitService.findByIdOrgUnit(this.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: OrgUnitForm) => {
        this.object = response;
        this.partnerRoles = response.partnerRoles;
        this.identifiers = response.partnerIdentifiers;
        this.postalAddresses = response.postalAddresses;
        this.commAddresses = response.commAddresses;
        this.partnerPartners = response.partnersLeft;
        this.form.patchValue(this.object);
        this.evaluateWizardPagesCompleted();
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  protected onSubmit(): Observable<any> {
    let orgUnit = this.form.value as OrgUnitForm;

    orgUnit = _.extendOwn(this.object, orgUnit);
    orgUnit.partnerRoles = this.partnerRoles;
    orgUnit.postalAddresses = this.postalAddresses;
    orgUnit.commAddresses = this.commAddresses;
    orgUnit.partnersLeft = this.partnerPartners;
    orgUnit.partnerIdentifiers = this.identifiers;
    const save$ = !orgUnit.id ? this.orgUnitService.createOrgUnit(orgUnit) : this.orgUnitService.updateOrgUnit(orgUnit);
    return save$;
  }
}
