import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from 'angular2-notifications';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { EntityEnum, toTranslateKey } from '../entity-enum';
import { Alert, AlertType } from './alert';


@Injectable({ providedIn: 'root' })
export class AlertService {
  private subject = new Subject<Alert>();
  private defaultId = 'default-alert';

  constructor(
    private notifier: NotificationsService,
    private translateService: TranslateService
  ) {
  }

  // enable subscribing to alerts observable
  onAlert(id = this.defaultId): Observable<Alert> {
    return this.subject.asObservable().pipe(filter(x => x && x.id === id));
  }

  sucessCreated(entityEnum: EntityEnum, translateParameters: any = {}) {
    const entity = this.translateService.instant(toTranslateKey(entityEnum));
    translateParameters.entity = entity;

    const message = this.translateService.instant('notification.created', translateParameters);
    this.success(message);
  }

  sucessModified(entityEnum: EntityEnum, translateParameters: any = {}) {
    const entity = this.translateService.instant(toTranslateKey(entityEnum));
    translateParameters.entity = entity;

    const message = this.translateService.instant('notification.modified', translateParameters);
    this.success(message);
  }

  sucessDeleted(entityEnum: EntityEnum, translateParameters: any = {}) {
    const entity = this.translateService.instant(toTranslateKey(entityEnum));
    translateParameters.entity = entity;

    const message = this.translateService.instant('notification.deleted', translateParameters);
    this.success(message);
  }

  success(message) {
    this.notifier.success(message, null, {
      timeOut: 1500,
      showProgressBar: false,
      pauseOnHover: false,
      clickToClose: true,
      clickIconToClose: true
    });
  }

  error(message: string, options?: any) {
    this.notifier.error('error', message);
  }

  info(message: string, options?: any) {
    this.notifier.info(message, null, {
      timeOut: 2500,
      showProgressBar: false,
      pauseOnHover: false,
      clickToClose: true,
      clickIconToClose: true
    });
  }

  warn(message: string, options?: any) {
    this.alert(new Alert({ ...options, type: AlertType.Warning, message }));
  }

  // main alert method
  alert(alert: Alert) {
    alert.id = alert.id || this.defaultId;
    this.subject.next(alert);
  }

  // clear alerts
  clear(id = this.defaultId) {
    this.subject.next(new Alert({ id }));
  }
}
