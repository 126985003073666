import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/base/base/base.component';
import { AppConfig } from '../../config/app.config';
import { CodeService } from '../api/api';
import { ApplCodeForm } from '../model/models';

@Injectable({
  providedIn: 'root'
})
export class AppCodeService extends BaseComponent {

  private list$ByDomain = new Map();
  private applCode$ById = new Map();

  private supportedLanguages: any[] = [];

  constructor(
    private codeService: CodeService
  ) {
    super();

    AppConfig.supportedLanguages.forEach(languageShortName => {
      this.supportedLanguages.push({
        code: languageShortName.charAt(0).toUpperCase() + languageShortName.slice(1).toLowerCase(),
        mnemonic: 'cd_language_' + languageShortName.toLowerCase()
      });
    });
  }

  getById(id: string): Observable<ApplCodeForm> {
    let result$ = this.applCode$ById.get(id);
    if (!result$) {
      result$ = this.codeService.findByIdApplCode(id).pipe(
        shareReplay()
      );
      this.applCode$ById.set(id, result$);
    }

    return result$;
  }

  getList(domain: string, withEmptyEntry: boolean): Observable<any[]> {
    // log//console.log('called getList', domain);
    return this.getByDomainApplCode(domain).pipe(
      map((items: any[]) => {
        if (withEmptyEntry) {
          items.unshift({
            id: undefined,
            nameDe: '-',
            nameFr: '-',
            nameEn: '-'
          });
        }
        return items;
      })
    );
  }

  getByDomainApplCode(domain: string): Observable<any[]> {
    let result$ = this.list$ByDomain.get(domain);
    if (!result$) {
      result$ = this.codeService.getByDomainApplCode(domain).pipe(
        shareReplay(),
        map(items => this.extract4ListWithId(items, 'id', 'name'))
      );
      this.list$ByDomain.set(domain, result$);
    }
    return result$;
  }

  extract4List(items: any[], attributeName: string): any[] {
    return this.extract4ListWithId(items, 'id', attributeName);
  }

  extract4ListWithId(items: any[], idAttributeName: string, valueAttributeName: string): any[] {
    // log//console.log('extract4list', items, idAttributeName, valueAttributeName);
    const result = [];
    items.sort((e1, e2) => e1.sort - e2.sort);
    for (const item of items) {
      const limitedItem = { id: item[idAttributeName] };
      for (const lang of this.supportedLanguages) {
        const textLanguage = item.applText.translations.find(translation => translation.cdLanguage === lang.mnemonic)
        if (textLanguage) {
          limitedItem[valueAttributeName + lang.code] = textLanguage.textLong;
        }
      }
      result.push(limitedItem);
    }
    return result;
  }
}

