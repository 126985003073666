
const POLICY_HEADERS_HEALTH = ['type_policyh_accident', 'type_policyh_accident_add', 'type_policyh_illness'];

const POLICY_ITEM_ACCIDENT_ILLNESS = 'type_policyi_accident_illness';
const POLICY_ITEM_FRANCHISE = 'type_policyi_franchise';

export function mapToPolicyItemType(policyHeadeType: string): string {
    if (POLICY_HEADERS_HEALTH.includes(policyHeadeType)) {
        return POLICY_ITEM_ACCIDENT_ILLNESS;
    }
    return POLICY_ITEM_FRANCHISE;
}



