<form clrForm [formGroup]="form" clrLayout="vertical" #empForm>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.organisation_name</mat-label>
            <input matInput formControlName="organisationName" required [readonly]="readonly">
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.policy_number</mat-label>
            <input matInput formControlName="policyNumber" required [readonly]="readonly">
            <mat-error *ngIf="form.get('policyNumber')?.hasError('required')" translate>
                error.no_policy_assigned_to_case
            </mat-error>
        </mat-form-field>
        <mat-form-field class="clr-col-md-3">
            <mat-label translate>field.working_days_per_week</mat-label>
            <input type="number" matInput formControlName="workingDaysPerWeek" required max="7">
        </mat-form-field>
        <mat-form-field class="clr-col-md-3">
            <mat-label translate>field.working_hours_per_week</mat-label>
            <input type="number" matInput formControlName="workingHoursPerWeek" required>
        </mat-form-field>
    </div>
    <h4 translate>field.contact_person</h4>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.name</mat-label>
            <input matInput formControlName="contactPersonName" required [readonly]="readonly">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.phone</mat-label>
            <input matInput formControlName="contactPersonPhone" required [readonly]="readonly">
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.email</mat-label>
            <input matInput formControlName="contactPersonEmail" required [readonly]="readonly">
            <mat-error *ngIf="form?.get('contactPersonEmail')?.errors?.email" translate>form_error.not_an_email</mat-error>
        </mat-form-field>
    </div>

</form>