import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TaskInstanceForm } from 'src/app/core';

@Component({
  selector: 'app-tasks-widget',
  templateUrl: './tasks-widget.component.html',
  styleUrls: ['./tasks-widget.component.css']
})
export class TasksWidgetComponent implements OnInit, OnChanges {

  @Input()
  tasks: TaskInstanceForm[] = [];

  items: TaskInstanceForm[] = [];

  constructor(
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tasks) {
      this.items = [];
      this.items = this.tasks;
    }
  }

  ngOnInit(): void {
    this.items = this.tasks;
  }

}
