import { Component, EventEmitter, Output } from '@angular/core';
import { first, takeUntil } from 'rxjs/operators';
import { DocumentForm, RelationshipTypeForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-base-editor',
  templateUrl: './base-editor.component.html',
  styleUrls: ['./base-editor.component.css']
})
export class BaseEditorComponent<T> extends BaseComponent {

  @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() hide: EventEmitter<boolean> = new EventEmitter<boolean>();

  wrappedItems: ItemWrapper<T>[];
  tableName = 'unknown';

  selectedRelType: RelationshipTypeForm;

  relTypes: RelationshipTypeForm[];
  filteredRelTypes: RelationshipTypeForm[];
  countByType = {};

  editorValid = true;

  constructor(
    protected reltypeService: RelationshipTypeService,
  ) {
    super();
    this.valid.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe( value => {
      this.editorValid = value;
    });
  }

  loadRelationships(): void {
    this.reltypeService.getRelationships(this.tableName, this.buildFilter()).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(result => {
      console.log('loadRelationships for ' + this.tableName, this.buildFilter(), result);
      this.relTypes = result;

      if (result.length === 1) {
        this.selectedRelType = result[0];
      }

      this.hide.emit(result.length === 0);
      this.processObjects();
    }, error => {
      console.error('get relTypes - error', error);
    });
  }

  protected calculateFiltered() {
    this.countByType = _.countBy(this.wrappedItems, (item: ItemWrapper<T>) => item.item['relationshipType']);
    // tslint:disable-next-line:max-line-length
    this.filteredRelTypes = this.relTypes.filter(item => (this.countByType[item.id] == null || this.countByType[item.id] < item.ltrMaxCardinality));
  }

  protected handleRelTypeSelection() {
    if (!this.filteredRelTypes.includes(this.selectedRelType)) {
      this.selectedRelType = null;
    }
  }

  protected emitValid(value: boolean) {
    this.valid.emit(value);
    this.editorValid = value;
  }

  protected processObjects() {}

  protected buildFilter() {}

}

export interface ItemWrapper<T> {
  item: T;
  withDocument: boolean;
  document: DocumentForm;
  valid: boolean;
  relType: RelationshipTypeForm;
  required: boolean;
  open: boolean;
  sort: number;
}
