export * from './access.service';
import { AccessService } from './access.service';
export * from './acciline.service';
import { AccilineService } from './acciline.service';
export * from './case.service';
import { CaseService } from './case.service';
export * from './code.service';
import { CodeService } from './code.service';
export * from './document.service';
import { DocumentService } from './document.service';
export * from './event.service';
import { EventService } from './event.service';
export * from './instantreport.service';
import { InstantreportService } from './instantreport.service';
export * from './mailTemplate.service';
import { MailTemplateService } from './mailTemplate.service';
export * from './maintenance.service';
import { MaintenanceService } from './maintenance.service';
export * from './object.service';
import { ObjectService } from './object.service';
export * from './orgUnit.service';
import { OrgUnitService } from './orgUnit.service';
export * from './organisation.service';
import { OrganisationService } from './organisation.service';
export * from './partner.service';
import { PartnerService } from './partner.service';
export * from './pdfform.service';
import { PdfformService } from './pdfform.service';
export * from './person.service';
import { PersonService } from './person.service';
export * from './policy.service';
import { PolicyService } from './policy.service';
export * from './processHeaderPushController.service';
import { ProcessHeaderPushControllerService } from './processHeaderPushController.service';
export * from './processheader.service';
import { ProcessheaderService } from './processheader.service';
export * from './profile.service';
import { ProfileService } from './profile.service';
export * from './reltype.service';
import { ReltypeService } from './reltype.service';
export * from './reportTemplate.service';
import { ReportTemplateService } from './reportTemplate.service';
export * from './role.service';
import { RoleService } from './role.service';
export * from './type.service';
import { TypeService } from './type.service';
export * from './uiElementTranslation.service';
import { UiElementTranslationService } from './uiElementTranslation.service';
export * from './user.service';
import { UserService } from './user.service';
export * from './wizardhelper.service';
import { WizardhelperService } from './wizardhelper.service';
export * from './workflow.service';
import { WorkflowService } from './workflow.service';
export const APIS = [AccessService, AccilineService, CaseService, CodeService, DocumentService, EventService, InstantreportService, MailTemplateService, MaintenanceService, ObjectService, OrgUnitService, OrganisationService, PartnerService, PdfformService, PersonService, PolicyService, ProcessHeaderPushControllerService, ProcessheaderService, ProfileService, ReltypeService, ReportTemplateService, RoleService, TypeService, UiElementTranslationService, UserService, WizardhelperService, WorkflowService];
