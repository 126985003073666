import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-address-list-widget',
  templateUrl: './address-list-widget.component.html',
  styleUrls: ['./address-list-widget.component.css']
})
export class AddressListWidgetComponent implements OnInit {

  @Input()
  postalAddresses = [];

  @Input()
  commAddresses = [];

  constructor() { }

  ngOnInit(): void {
  }

}
