<clr-datagrid>
    <clr-dg-column>{{ 'field.date' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.action' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.detail' | translate }}</clr-dg-column>

    <clr-dg-row *clrDgItems="let event of events" [clrDgItem]="event">
        <clr-dg-cell>{{ event.createdDate | date:'dd.MM.yyyy HH:mm' }}</clr-dg-cell>
        <clr-dg-cell>{{ event.cdAction | lookupApplCode | async }}</clr-dg-cell>
        <clr-dg-cell>{{ event.detail }}</clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>
