import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppPartnerService } from '../service/app-partner.service';

@Pipe({
  name: 'lookupPartner'
})

@Injectable()
export class LookupPartnerPipe implements PipeTransform {
  constructor(
    private appPartnerService: AppPartnerService,
    ) {}

  transform(id: number): Observable<string> {
    if (!id) {
      return of('');
    }

    return this.appPartnerService.getById(id).pipe(
      take(1),
      map(partner => {
        let name = id.toString();
        if (partner.type === 'type_partner_org'
          || partner.type === 'type_partner_orgunit') {
          name = partner.name;
        } else {
          name = partner.name + ' ' + partner.givenName;
        }
        return name;
      })
    );
  }
}
