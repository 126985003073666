import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CaseObjectForm } from 'src/app/core';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-case-object-form',
  templateUrl: './case-object-form.component.html',
  styleUrls: ['./case-object-form.component.css']
})
export class CaseObjectFormComponent extends BaseFormComponent implements OnChanges {

  @Input() caseObject: CaseObjectForm;
  @Input() typeObject: string;
  @Output() caseObjectValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() caseObjectChange: EventEmitter<CaseObjectForm> = new EventEmitter<CaseObjectForm>();

  caseObjectEdit: CaseObjectForm = {};
  partnerId: number;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.caseObject)) {
      this.caseObjectEdit = changes.caseObject.currentValue;
    }
  }

  onChange(): void {
    const valid = this.caseObjectEdit.objectId != null;
    if (valid) {
      this.caseObjectChange.emit(this.caseObjectEdit);
    }
    this.caseObjectValid.emit(valid);
    console.log('changed', this.caseObjectEdit.objectId);
  }
}
