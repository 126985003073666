import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { DocumentForm, DocumentRefForm } from 'src/app/core';
import { FileService } from 'src/app/core/service/file.service';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';




@Component({
  selector: 'app-document-ref-editor',
  templateUrl: './document-ref-editor.component.html',
  styleUrls: ['./document-ref-editor.component.css']
})
export class DocumentRefEditorComponent extends BaseEditorComponent<DocumentRefForm> implements OnChanges {

  @Input() documentRefs: DocumentRefForm[];
  @Input() typeLeft: string;
  @Output() documentRefsChange: EventEmitter<DocumentRefForm[]> = new EventEmitter<DocumentRefForm[]>();

  tableName = 'doc_ref';

  constructor(
    private fileService: FileService,
    protected reltypeService: RelationshipTypeService,
  ) {
    super(reltypeService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.documentRefs)
        && !this.wrappedItems) {
      this.processObjects();
    }
    if (this.hasChanged(changes.typeLeft)) {
      this.loadRelationships();
      this.processObjects();
    }
  }

  protected buildFilter() {
    return { typeLeft: this.typeLeft };
  }

  protected processObjects() {
    if (!this.relTypes
      || !this.documentRefs) {
      return;
    }
    const wrappers = [];

    this.documentRefs.forEach((documentRef) => {
      wrappers.push({
        item: documentRef,
        valid: true,
        open: false,
        sort: 0
      } as ItemWrapper<DocumentRefForm>);
    });

    wrappers.sort((e1, e2) => e1.sort - e2.sort);
    this.wrappedItems = wrappers;
  }

  onAdd() {
    this.wrappedItems.push({
      item: {
        document : {
          type: this.selectedRelType.typeRight
        } as DocumentForm
      } as DocumentRefForm,
      valid: false,
      open: true,
      sort: this.selectedRelType?.sort
    } as ItemWrapper<DocumentRefForm>);

    this.emitValid(false);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);
    this.fileService.deletePosition(index);

    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    console.log('onChange', index, valid);
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.documentRefsChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.emitValid(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => item.valid === false).length === 0);
    return result;
  }
}

