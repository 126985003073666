import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { concat, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { ApplObjectLiteView, ApplObjectSearchCriteria, ObjectService, PageApplObjectLiteView } from 'src/app/core';
import { BaseSearchComponent } from '../../base/base-search/base-search.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-object-search',
  templateUrl: './object-search.component.html',
  styleUrls: ['./object-search.component.css']
})
export class ObjectSearchComponent extends BaseSearchComponent implements OnInit, OnChanges {

  @Input() fieldName: string;
  @Input() allowClear = false;

  criteria: ApplObjectSearchCriteria = {
    pageSize: 50,
    sortColumn: 'referenceNumber'
  };

  result$: Observable<any[]>;
  selectedItem: ApplObjectLiteView;

  constructor(
    private objectController: ObjectService,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.id)) {
      this.objectController.getByIdApplObjectite(this.id).pipe(
        takeUntil(this.ngUnsubscribe),
        first()
      ).subscribe(response => {
        this.selectedItem = response;
        this.ref.markForCheck();
      });
    }
    if (this.hasChanged(changes.type)) {
      this.criteria.types = changes.type.currentValue;
    }
  }

  ngOnInit(): void {
    this.search();
  }

  trackByFn(item: ApplObjectLiteView) {
    return item.id;
  }

  onChange() {
    this.idChange.emit(this.selectedItem?.id);
  }

  open() {
    this.openWizardInNewWindow('/objects/list?open=new');
  }

  private search() {
    this.result$ = concat(
      of([]), // default item
      this.searchInput$.pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap((term) => {
          this.criteria.keywords = term;
          return this.objectController.searchApplObjectLite(this.criteria).pipe(
            catchError(() => of([])), // empty list on error
            map((response: PageApplObjectLiteView) => response.content),
            tap( result => result.forEach( (item: any) => {
              item.search = [item.referenceNumber, item.registrationNr, item.name].join(' ');
            })),
            tap((items) => {
              this.loading = false;
              this.nothingFound = (items.length === 0);
            })
          );
        })
      )
    );
  }
}
