import { NgxMatDateAdapter } from '@angular-material-components/datetime-picker';
import { EventEmitter, Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { first, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/base/base/base.component';
import { toCamelCase } from 'src/app/shared/string-util';
import * as _ from 'underscore';
import { UIElementTranslationForm, UiElementTranslationService } from '..';
import { ApplTextForm } from '../model/applTextForm';
import { AuthenticationService } from './authentication.service';


@Injectable({
  providedIn: 'root'
})
export class LanguageService extends BaseComponent {
  private language: string;

  private readonly SUPPORTED_LANGUAGES = ['en', 'fr', 'de'];
  private readonly MYCLAIM_LANGUAGE_KEY = 'myclaim.lang';

  uiTranslations: UIElementTranslationForm[];

  constructor(
    private translateService: TranslateService,
    private dateAdapter: DateAdapter<any>,
    private ngxMatDateAdapter: NgxMatDateAdapter<any>,
    private authenticationService: AuthenticationService,
    private uiElementTranslationService: UiElementTranslationService

) {
    super();

    this.translateService.onLangChange.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe((event: LangChangeEvent) => {
      console.log('language changed to', event.lang);
      this.language = toCamelCase(event.lang);
      localStorage.setItem(this.MYCLAIM_LANGUAGE_KEY, event.lang);
    });

    this.authenticationService.auhtenticationInfo.subscribe(
      info => {
        if (info) {
          this.loadTranslations();
        }
      }
    );
  }

  initializeLanguage(): string {
    this.translateService.addLangs([...this.SUPPORTED_LANGUAGES, 'it']);
    this.translateService.setDefaultLang('en');
    console.log('default lang', this.translateService.getDefaultLang());

    let storedLanguage = localStorage.getItem(this.MYCLAIM_LANGUAGE_KEY);
    if (!storedLanguage) {
      storedLanguage = this.translateService.getBrowserLang();
      console.log('browserLang', storedLanguage);
    }
    const lang = this.SUPPORTED_LANGUAGES.includes(storedLanguage) ? storedLanguage : 'en';
    this.changeLanguage(lang);
    return lang;
  }

  changeLanguage(language: string) {
    this.dateAdapter.setLocale(language);
    this.ngxMatDateAdapter.setLocale(language);
    this.applyTranslations(language);

    this.translateService.use(language).pipe(
      first()
    ).subscribe();
  }

  loadTranslations() {
    this.uiElementTranslationService.getAllTranslations().pipe(
      first()
    ).subscribe(data => {
      this.uiTranslations = data;
      const language = this.getCurrentLang();
      this.changeLanguage(language);
    });
  }

  applyTranslations(language: string) {
    if (!this.uiTranslations) {
      return;
    }

    let pairs;
    if (language === 'it') {
      pairs = this.uiTranslations.map(item => [`${item.element}`, `${item.element}`]);
    } else {
      const languageSpecificField = 'txt' + toCamelCase(language);
      pairs = this.uiTranslations.map(item => [`${item.element}`, item[languageSpecificField]]);
    }
    const translation = _.object(pairs);
    this.translateService.setTranslation(language, translation, false);

/*
    this.SUPPORTED_LANGUAGES.forEach( lang => {
      const translation = {};
      const languageSpecificField = 'txt' + toCamelCase(lang);
      this.uiTranslations.forEach(item => {
        translation[item.element] = item[languageSpecificField];
      });
      this.translateService.setTranslation(lang, translation, true);

    });
  */
   }

  getCurrentLang(): string {
    const result = this.translateService.currentLang;
    if (result) {
      return result;
    }

    return this.translateService.defaultLang;
  }

  getLanguageSpecificValue(item: any, attributeName: string): string {
    if (item == null) {
      // log//console.log('item is null');
      return '';
    }
    // log//console.log('item', item);
    // log//console.log('language', this.language);
    if (this.language
      && item.hasOwnProperty(attributeName + this.language)) {
      return item[attributeName + this.language];
    } else {
      return item[attributeName + 'En'];
    }
  }

  getLanguageSpecifcValueFromApplText(applText: ApplTextForm, fieldName: string): string{
    const cdlanguage = 'cd_language_' + this.getCurrentLang();
    const name = applText.id;

    let textLanguage = applText.translations.find(item => item.cdLanguage === cdlanguage);
    if (!textLanguage) {
      textLanguage = applText.translations.find(item => item.cdLanguage === 'cd_language_en');
    }
    return textLanguage ? textLanguage[fieldName] : name;
  }

  onLangChange(): EventEmitter<LangChangeEvent> {
    return this.translateService.onLangChange;
  }

}
