<clr-stack-view class="clr-col-md-12">
    <clr-stack-block *ngFor="let workflow of rows; index as i" [clrStackViewLevel]="1" >
        <clr-stack-label>{{ workflow.name }}</clr-stack-label>
        <clr-stack-content>
            <div class="clr-row clr-justify-content-between">
                <span class="clr-col-5 clr-col-5-lg clr-col-5-xl"></span>
                <span *ngIf="workflow.tasks.length === 0" class="clr-col-3 clr-align-items-end">
                </span>
            </div>
        </clr-stack-content>
        <clr-stack-block *ngFor="let task of workflow.tasks; index as j" [clrStackViewLevel]="2">
            <clr-stack-label>{{ task.applText | languageApplText:'textLong' }}</clr-stack-label>
            <clr-stack-content>
                <div class="clr-row clr-justify-content-between">
                    <span class="clr-col-4">{{ task.cdStatus | lookupApplCode | async }}</span>
                    <span class="clr-col-2">{{ task.doneDate | date:'dd.MM.yyyy'}}</span>
                    <span class="clr-col-6 clr-align-items-end">
                    </span>
                </div>

            </clr-stack-content>
        </clr-stack-block>
    </clr-stack-block>
</clr-stack-view>
