import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/base/base/base.component';
import * as _ from 'underscore';
import { AppConfig } from '../../config/app.config';
import { ReltypeService } from '../api/api';
import { RelationshipTypeCriteria, RelationshipTypeForm } from '../model/models';

@Injectable({
  providedIn: 'root'
})
export class RelationshipTypeService extends BaseComponent {

  private relTypes$ByTableName = new Map();
  private relTypes$ById = new Map();

  private supportedLanguages: any[] = [];

  constructor(
    private reltypeService: ReltypeService
  ) {
    super();
    AppConfig.supportedLanguages.forEach(languageShortName => {
      this.supportedLanguages.push({
        code: languageShortName.charAt(0).toUpperCase() + languageShortName.slice(1).toLowerCase(),
        mnemonic: 'cd_language_' + languageShortName.toLowerCase()
      });
    });
  }


  getById(id: string): Observable<RelationshipTypeForm> {
    let result$ = this.relTypes$ById.get(id);
    if (!result$) {
      result$ = this.reltypeService.findByIdRelationshipType(id).pipe(
        shareReplay()
      );
      this.relTypes$ById.set(id, result$);
    }
    return result$;
  }

  getRelationships(tableName: string, filter?: any): Observable<RelationshipTypeForm[]> {
    console.log('called getList', tableName, filter);
    const criteria = this.buildCriteria(tableName, filter);
    return this.getByRelationshipTypeByCriteria(criteria).pipe(
      map(result => {
        console.log('unfiltered', result);
        if (filter) {
          result = _.where(result, filter);
        }
        result.sort((e1, e2) => e1?.sort - e2?.sort);
        return result;
      })
    );
  }

  buildCriteria(tableName: string, filter?: any): RelationshipTypeCriteria {
    const criteria = {
      tableName
    } as RelationshipTypeCriteria;

    if (filter?.typeLeft) {
      criteria.typeLeft = filter.typeLeft;
    }
    if (filter?.typeRight) {
      criteria.typeRight = filter.typeRight;
    }
    return criteria;
  }

  getList(tableName: string, withEmptyEntry: boolean, filter?: any, translationAttribute?: string): Observable<any[]> {
    const criteria = this.buildCriteria(tableName, filter);
    return this.getByRelationshipTypeByCriteria(criteria).pipe(
      map(result => {
        if (filter) {
          result = _.where(result, filter);
        }
        result.sort((e1, e2) => e1?.sort - e2?.sort);
        result = this.extract4ListWithId(result, 'id', 'name');
        if (withEmptyEntry === true) {
          result.unshift({
            id: undefined,
            nameDe: '-',
            nameFr: '-',
            nameEn: '-'
          });
        }
        return result;
      })
    );
  }

  private getByRelationshipTypeByCriteria(criteria: RelationshipTypeCriteria): Observable<any[]> {
    let result$ = this.relTypes$ByTableName.get(criteria);
    if (!result$) {
      result$ = this.reltypeService.getByRelationshipTypeByCriteria(criteria).pipe(
        shareReplay(),
      );
      this.relTypes$ByTableName.set(criteria, result$);
    }

    return result$;
  }

  extract4List(items: any[], attributeName: string, translationAttribute?: string): any[] {
    return this.extract4ListWithId(items, 'id', attributeName, translationAttribute);
  }

  extract4ListWithId(items: any[], idAttributeName: string, valueAttributeName: string, translationAttribute?: string): any[] {
    // log//console.log('extract4list', items, idAttributeName, valueAttributeName);
    const result = [];
    items.sort((e1, e2) => e1.sort - e2.sort);
    for (const item of items) {
      const limitedItem = { id: item[idAttributeName] };
      const attributeName = translationAttribute ? translationAttribute : 'textLong';
      for (const lang of this.supportedLanguages) {
        const textLanguage = item.applText.translations.find(translation => translation.cdLanguage === lang.mnemonic)
        if (textLanguage) {
          limitedItem[valueAttributeName + lang.code] = textLanguage[attributeName];
        }
      }
      result.push(limitedItem);
    }
    return result;
  }
}

