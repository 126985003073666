<div class="clr-row">
    <clr-datagrid class="clr-col-7 clr-col-lg-7" *ngIf="postalAddresses?.length > 0">
        <clr-dg-column [clrDgSortBy]="'type'">{{ 'field.type' | translate }}</clr-dg-column>
        <clr-dg-column>{{ 'field.street_and_nr' | translate }}</clr-dg-column>
        <clr-dg-column>{{ 'field.post_code_and_city' | translate }}</clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'cdStatus'">{{ 'field.status' | translate }}</clr-dg-column>

        <clr-dg-row *clrDgItems="let pa of postalAddresses" [clrDgItem]="pa">
            <clr-dg-cell>{{ pa.type | lookupApplType | async }}</clr-dg-cell>
            <clr-dg-cell>{{ pa.street }} {{ pa.streetNumber }}</clr-dg-cell>
            <clr-dg-cell>{{ pa.postCode }} {{ pa.city }}</clr-dg-cell>
            <clr-dg-cell>{{ pa.cdStatus | lookupApplCode | async }}</clr-dg-cell>
        </clr-dg-row>
    </clr-datagrid>
    <div class="clr-col-5 clr-col-lg-5" *ngIf="commAddresses?.length > 0">
        <div class="card">
            <div class="card-block">
                <p class="card-text" translate>
                    block.communication_address
                </p>
                <div class="clr-row" *ngFor='let commAddresse of commAddresses'>
                    <div class="clr-col-4 clr-control-label-custom">
                        <span>{{ commAddresse.type | lookupApplType | async }}</span>
                    </div>
                    <div class="clr-col-8">
                        <span>
                            {{ commAddresse.commAddress }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
