import { KeyValueForm } from 'src/app/core';
import * as _ from 'underscore';

export function buildFormData(keyValues: KeyValueForm[]): any {
    const pairs = keyValues.map(item => [item.key, item.value]);
    //console.log('pairs', pairs);
    const result = _.object(pairs);
    //console.log('result', result);

    return result;
}

export function buildFormDataFromMap(data: Map<string, string>): any {
    const result = _.object(data);
    //console.log('result', result);

    return result;
}

export function mapFormData(formData: any, keyValues: KeyValueForm[]): any {
    const pairs = _.pairs(formData);
    const result = pairs.map(pair => {
        let item = keyValues.find(keyValue => keyValue.key === pair[0]);
        if (item) {
            item.value = pair[1];
        } else {
            item = { key: pair[0], value: pair[1] } as KeyValueForm;
        }
        return item;
    });

    return result;
}
