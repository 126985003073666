<clr-wizard #wizardlg (clrWizardOnCancel)="onCancel()" (clrWizardOnFinish)="onFinish()" (clrWizardOnNext)="onNext()" [(clrWizardOpen)]="show"
    [clrWizardClosable]="false" [clrWizardPreventDefaultCancel]="true" [clrWizardPreventDefaultNext]="true" clrWizardSize="xl">
    <clr-wizard-title>
        {{ toTranslateKey(entityEnum) | translate }}
    </clr-wizard-title>
    <clr-wizard-button [type]="'cancel'">{{ 'button.cancel' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{ 'button.back' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{ 'button.next' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{ 'button.finish' | translate }}</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!processHeaderValid" [clrWizardPageHasError]="!(processHeaderValid && illnessDetailValid)">
        <ng-template clrPageTitle>{{ 'field.details' | translate }}</ng-template>
        <app-process-header-lite-form [allowSelectCase]="false" [(processHeader)]="processHeaderLite" (processHeaderValid)="processHeaderValid = $event">
        </app-process-header-lite-form>
        <app-sunet-illness-form [insuranceSpecifics]="insuranceSpecifics" [(illnessDetail)]="illnessDetail" (illnessDetailValid)="illnessDetailValid = $event">
        </app-sunet-illness-form>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!phPartnersValid">
        <ng-template clrPageTitle>{{ 'section.relations_to_partner' | translate }}</ng-template>
        <app-process-header-partner-editor [(processHeaderPartners)]="partners" [typeLeft]="processHeader?.type"
            (valid)="phPartnersValid = $event" (processHeaderPartnersChange)="processPartners()">
        </app-process-header-partner-editor>
        <h4 translate>section.copy_data_from_partner</h4>
        <div class="toolbar">
            <button (click)="loadInsured()" [disabled]="!insuredPartnerId" class="btn btn-sm">
                <clr-icon shape="copy"></clr-icon> {{ 'button.copy_insured_data' | translate }}
            </button>
            <button (click)="loadEmployer()" class="btn btn-sm" [disabled]="!employerPartnerId">
                <clr-icon shape="copy"></clr-icon> {{ 'button.copy_employer_data' | translate }}
            </button>
            <button (click)="loadFirstTreatmentPartner()" class="btn btn-sm" [disabled]="!firstTreatmentPartnerId">
                <clr-icon shape="copy"></clr-icon> {{ 'button.copy_first_treatment_data' | translate }}
            </button>
            <button (click)="loadFollowupTreatmentPartner()" class="btn btn-sm" [disabled]="!secondTreatmentPartnerId">
                <clr-icon shape="copy"></clr-icon> {{ 'button.copy_followup_treatment_data' | translate }}
            </button>
            <button (click)="loadOtherEmployers()" class="btn btn-sm" [disabled]="otherEmployerIds.length == 0">
                <clr-icon shape="copy"></clr-icon> {{ 'button.copy_other_employers' | translate }}
            </button>
        </div>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!(employeeValid && employeeAddressValid)">
        <ng-template clrPageTitle>{{ 'section.employee' | translate }}</ng-template>
        <app-sunet-employee-form [readonly]="true" [(employee)]="employeeInfo" (employeeValid)="employeeValid = $event">
        </app-sunet-employee-form>
        <h4 translate>field.address</h4>
        <app-sunet-address-form [readonly]="true" [(address)]="employeeAddress" (addressValid)="employeeAddressValid = $event">
        </app-sunet-address-form>
    </clr-wizard-page>

  <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!(employerValid && employerAddressValid)">
        <ng-template clrPageTitle>{{ 'section.employer_info' | translate }}</ng-template>
        <app-sunet-employer-form [readonly]="true" [(employer)]="employerAdditionalInfo" (employerValid)="employerValid = $event">
        </app-sunet-employer-form>
        <h4 translate>section.organisation_address</h4>
        <app-sunet-address-form [readonly]="true" [(address)]="employerAddress" (addressValid)="employerAddressValid = $event">
        </app-sunet-address-form>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!paymentValid">
        <ng-template clrPageTitle>{{ 'section.payment_address' | translate }}</ng-template>
        <app-sunet-payment-form [(payment)]="payment" (paymentValid)="paymentValid = $event">
        </app-sunet-payment-form>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!employmentValid">
        <ng-template clrPageTitle>{{ 'section.employment_info' | translate }}</ng-template>
        <app-sunet-employment-form [readonly]="true" [processHeaderDate]="illnessDetail?.illnessDate" [(employment)]="employment" (employmentValid)="employmentValid = $event">
        </app-sunet-employment-form>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!salaryValid">
        <ng-template clrPageTitle>{{ 'section.salary_info' | translate }}</ng-template>
        <app-sunet-salary-form [readonly]="true" [(salary)]="salary" (salaryValid)="salaryValid = $event">
        </app-sunet-salary-form>
    </clr-wizard-page>

    <clr-wizard-page [clrWizardPageHasError]="!(firstTreatmentValid && secondTreatmentValid)">
        <ng-template clrPageTitle>{{ 'section.treatments' | translate }}</ng-template>
        <h4 translate>section.first_treatment</h4>
        <app-sunet-treatment-form [(treatment)]="firstTreatment" (treatmentValid)="firstTreatmentValid = $event">
        </app-sunet-treatment-form>
        <h4 translate>section.followup_treatment</h4>
        <app-sunet-treatment-form [(treatment)]="secondTreatment" (treatmentValid)="secondTreatmentValid = $event">
        </app-sunet-treatment-form>
    </clr-wizard-page>

    <clr-wizard-page *ngIf="error" [clrWizardPageHasError]="error != null">
        <ng-template clrPageTitle>{{ 'section.error_occured' | translate }}</ng-template>
        <app-error [error]="error"></app-error>
    </clr-wizard-page>

</clr-wizard>
