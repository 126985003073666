import { Component, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { first, map, takeUntil } from 'rxjs/operators';
import { CaseService, ProcessHeaderForm, RelationshipTypeForm } from 'src/app/core';
import { AppTypeService } from 'src/app/core/service/app-type.service';
import { AuthorizationService } from 'src/app/core/service/authorization.service';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseComponent } from '../../base/base/base.component';
import { ProcessHeaderReference } from '../process-header-reference';


@Component({
  selector: 'app-process-header-type-dialog',
  templateUrl: './process-header-type-dialog.component.html',
  styleUrls: ['./process-header-type-dialog.component.css']
})
export class ProcessHeaderTypeDialogComponent extends BaseComponent implements OnInit {

  loading = true;
  show = true;
  type: any;
  reference: ProcessHeaderReference;
  afterClosed = new Subject<any>();

  processhTypes$: Observable<any[]>;
  filteredRelTypes: RelationshipTypeForm[];

  constructor(
    private typeService: AppTypeService,
    private caseController: CaseService,
    private reltypeService: RelationshipTypeService,
    protected authorizationService: AuthorizationService
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.reference?.caseId) {
      this.caseController.getByIdApplCase(this.reference.caseId).pipe(
        takeUntil(this.ngUnsubscribe),
        first()
      ).subscribe( applCase => {
        this.loadProcessHeaderRelationships(applCase.type, applCase.processHeaders);
      });
    } else {
      this.processhTypes$ = this.typeService.getList('processh', false).pipe(
        map(items => items.filter(item => this.authorizationService.isCreateAllowed(item.id)))
      );
      this.loading = false;
    }
  }

  private loadProcessHeaderRelationships(type: string, processHeaders: ProcessHeaderForm[]): void {
    this.reltypeService.getRelationships('processh', this.buildFilter(type)).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(relTypes => {
      const countByType = _.countBy(processHeaders, (item => item.type));
      // tslint:disable-next-line:max-line-length
      this.filteredRelTypes = relTypes.filter(item => (countByType[item.typeLeft] == null || countByType[item.typeLeft] < item.rtlMaxCardinality));
      this.loading = false;
    }, error => {
      console.error('get relTypes - error', error);
    });
  }

  private buildFilter(type: string): any {
    return { typeRight: type };
  }

  onCancel() {
    console.log('onCancel');
    this.close();
    this.afterClosed.next({
      success: false
    });
  }

  onSuccess() {
    this.close();
    this.afterClosed.next({
      success: true,
      type: this.type
    });
  }

  private close() {
    this.show = false;
  }

}
