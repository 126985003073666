<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6" *ngIf="!allowSelectType">
            <mat-label translate>field.document_type</mat-label>
            <input matInput [value]="form.value.type | lookupApplType | async" required readonly>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6" *ngIf="allowSelectType">
            <mat-label translate>field.document_type</mat-label>
            <mtx-select [searchable]="true" formControlName="type">
                <mtx-option *ngFor="let relType of relTypes" [value]="relType.typeRight">
                    {{ relType.typeRight | lookupApplType | async }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.receipt_date</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="receiptDate">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="clr-row" *ngIf="!form.value.id">
        <file-upload class="clr-col-md-12 clr-col-lg-12" [control]="fileUploadControl" multiple="false">
            <ng-template let-isFileDragDropAvailable="isFileDragDropAvailable" #placeholder>
                <file-upload-drop-zone>
                    <ng-container *ngIf="isFileDragDropAvailable; else isNotDragDropAvailable" translate>
                        {{ 'message.fileupload_drag_and_drop' | translate }}
                    </ng-container>
                    <ng-template #isNotDragDropAvailable>
                        {{ 'message.fileupload_click' | translate }}
                    </ng-template>
                </file-upload-drop-zone>
            </ng-template>
    
            <ng-template let-i="index" let-file="file" let-control="control" #item>
                <file-upload-list-item [index]="i" [file]="file" [control]="control">{{'message.fileupload_remove' | translate}}</file-upload-list-item>
            </ng-template>
        </file-upload>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.name</mat-label>
            <input matInput formControlName="name" readonly>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.mime_type</mat-label>
            <input matInput formControlName="mimeType" readonly>
        </mat-form-field>
    </div>

</form>