import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-postal-address-list-widget',
  templateUrl: './postal-address-list-widget.component.html',
  styleUrls: ['./postal-address-list-widget.component.css']
})
export class PostalAddressListWidgetComponent implements OnInit {

  @Input()
  postalAddresses = [];
  
  constructor() { }

  ngOnInit(): void {
  }

}
