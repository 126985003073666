<clr-wizard #wizardlg [(clrWizardOpen)]="show" clrWizardSize="xl" [clrWizardClosable]="false" (clrWizardOnCancel)="onCancel()"
    [clrWizardPreventDefaultCancel]="true" [clrWizardPreventDefaultNext]="true" (clrWizardOnNext)="onNext()"
    (clrWizardOnFinish)="onFinish()">

    <clr-wizard-title>{{ 'field.object' | translate }} {{ object?.type | lookupApplType | async }}</clr-wizard-title>

    <clr-wizard-button [type]="'cancel'">{{ 'button.cancel' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'previous'">{{ 'button.back' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'next'">{{ 'button.next' | translate }}</clr-wizard-button>
    <clr-wizard-button [type]="'finish'">{{ 'button.finish' | translate }}</clr-wizard-button>

    <clr-wizard-page [clrWizardPageNextDisabled]="!applObjectValid" [clrWizardPageHasError]="!applObjectValid">
        <ng-template clrPageTitle>{{ 'section.detail' | translate }}</ng-template>
        <app-object-form [(applObject)]="applObject" (applObjectValid)="applObjectValid = $event">
        </app-object-form>
    </clr-wizard-page>
    <clr-wizard-page [clrWizardPageNextDisabled]="!parametersValid" [clrWizardPageHasError]="!parametersValid" *ngIf="!hideParameterStep">
        <ng-template clrPageTitle>{{ 'section.parameters' | translate }}</ng-template>
        <app-parameter-editor [type]="applObject?.type" [(parameters)]="parameters" (hide)="hideParameterStep = $event" (valid)="parametersValid = $event">
        </app-parameter-editor>
    </clr-wizard-page>
    <clr-wizard-page [clrWizardPageNextDisabled]="!addressesValid" [clrWizardPageHasError]="!addressesValid" *ngIf="!hideAddressStep">
        <ng-template clrPageTitle>{{ 'section.addresses' | translate }}</ng-template>
        <app-postal-address-editor [typeRight]="applObject?.type" [(postalAddresses)]="postalAddresses" (hide)="hideAddressStep = $event" (valid)="addressesValid = $event">
        </app-postal-address-editor>
    </clr-wizard-page>
    <clr-wizard-page [clrWizardPageNextDisabled]="!objectPartnersValid" [clrWizardPageHasError]="!objectPartnersValid" *ngIf="!hidePartnerStep">
        <ng-template clrPageTitle>{{ 'section.partners' | translate }}</ng-template>
        <app-object-partner-editor [typeLeft]="applObject?.type" [(objectPartners)]="objectPartners" (hide)="hidePartnerStep = $event" (valid)="objectPartnersValid = $event">
        </app-object-partner-editor>
    </clr-wizard-page>
    <clr-wizard-page [clrWizardPageNextDisabled]="!objectPartnersValid" [clrWizardPageHasError]="!objectPartnersValid" *ngIf="!hidePolicyStep">
        <ng-template clrPageTitle>{{ 'section.policies' | translate }}</ng-template>
        <app-policy-header-object-editor [typeRight]="applObject?.type" [(policyHeaderObjects)]="policyHeaderObjects" (hide)="hidePolicyStep = $event"
            (valid)="policiesValid = $event">
        </app-policy-header-object-editor>
    </clr-wizard-page>
    <clr-wizard-page [clrWizardPageHasError]="error != null" *ngIf="error">
        <ng-template clrPageTitle>{{ 'section.error_occured' | translate }}</ng-template>
        <app-error [error]="error"></app-error>
    </clr-wizard-page>
</clr-wizard>