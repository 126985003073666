import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FileUploadControl, FileUploadValidators } from '@iplab/ngx-file-upload';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { first, switchMap, take, takeUntil } from 'rxjs/operators';
import { MailForm, WorkflowService } from 'src/app/core';
import { EntityEnum } from 'src/app/core/entity-enum';
import { AlertService } from 'src/app/core/service/alert.service';
import { BlockService } from 'src/app/core/service/block.service';
import { BaseWizardComponent } from '../../base/base-wizard/base-wizard.component';
import { Editor } from '../../editors/editor';

const EMAIL_REGEX = '^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$';
@Component({
  selector: 'app-email-wizard',
  templateUrl: './email-wizard.component.html',
  styleUrls: ['./email-wizard.component.scss']
})
export class EmailWizardComponent extends BaseWizardComponent implements OnInit, Editor {

  entityEnum = EntityEnum.Email;

  @Input() id: number;
  @Input() object: MailForm;

  isEmail: (value: any) => Promise<any>;

  form: UntypedFormGroup;
  fileUploadControl = new FileUploadControl(null, [FileUploadValidators.fileSize(5000000)]);


  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [],
      ['insertImage',
        'insertVideo']
    ]
  };

  constructor(
    private workflowService: WorkflowService,
    private translateService: TranslateService,
    private fb: UntypedFormBuilder,
    alertService: AlertService,
    blockService: BlockService
  ) {
    super(blockService, alertService);

    this.form = this.fb.group({
      toRecipients: this.fb.control([], [Validators.required]),
      ccRecipients: this.fb.control([]),
      subject: this.fb.control('', [Validators.required]),
      content: this.fb.control('', [Validators.required])
    });
  }

  ngOnInit(): void {
    this.newEntity = !this.id;

    this.isEmail = (value: string) => {
      console.log('called isEmail', value);
      return new Promise((resolve, reject) => {
        if (value.match(EMAIL_REGEX)) {
          resolve(value);
        } else {
          reject();
        }
      });
    };

    this.loadData();
  }

  loadData() {
    this.workflowService.getEmail(this.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: MailForm) => {
        this.object = response;
        this.form.patchValue(response);
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  protected onSubmit(): Observable<any> {
    this.blockService.startWithMessage('message.sending');
    
    const tasks$ = [];
    this.fileUploadControl.value.forEach(file => {
      tasks$.push(this.workflowService.addAttachment(this.id, file.name , file));
    });

    if (tasks$.length === 0) {
      return this.sendEmail();
    }

    return forkJoin(tasks$).pipe(
      takeUntil(this.ngUnsubscribe),
      take(1),
      switchMap(() => this.sendEmail())
    );

    }

    protected sendEmail(): Observable<any> {
    const email = this.form.value;

    const sendEmail$ = this.workflowService.sendEmail(this.id, email);
    return sendEmail$;
  }
}
