import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { catchError, map, Observable, of, tap } from 'rxjs';
import { OrganisationLiteView, OrganisationSearchCriteria, OrganisationService, PageOrganisationLiteView } from 'src/app/core';
import { BaseSearchComponent } from '../../base/base-search/base-search.component';

@Component({
  selector: 'app-organisations-search',
  templateUrl: './organisations-search.component.html',
  styleUrls: ['./organisations-search.component.scss']
})
export class OrganisationsSearchComponent extends BaseSearchComponent implements OnInit, OnChanges {

  @Input() partnerRoleType: string;
  @Input() multipleSelect = true;

  criteria: OrganisationSearchCriteria = {
    pageSize: 100,
    sortColumn: 'name'
  };

  result$: Observable<OrganisationLiteView[]>;
  selectedItems: OrganisationLiteView[];
  selectedItem: OrganisationLiteView;

  constructor(
    private organisationController: OrganisationService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.partnerRoleType, false)) {
      this.criteria.partnerRoleTypes = this.partnerRoleType;
      this.searchOrganisations();
    }
  }

  ngOnInit(): void {
    this.searchOrganisations();
  }

  searchOrganisations() {
    this.criteria.partnerRoleTypes = this.partnerRoleType;
    this.result$ = this.organisationController.searchOrganisationLite(this.criteria).pipe(
      catchError(() => of([])), // empty list on error
      map((response: PageOrganisationLiteView) => response.content),
      tap((items) => {
        this.loading = false;
      })
    );
  }

  trackByFn(item: OrganisationLiteView) {
    return item.id;
  }

  onChange() {
    if (this.multipleSelect) {
      this.idsChange.emit(this.selectedItems.map(item => item.id));
    } else {
      const id = this.selectedItem?.id;
      this.idChange.emit(id);
    }
  }
}

