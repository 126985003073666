import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ApplTypeForm, ParameterForm } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import { AppTypeService } from 'src/app/core/service/app-type.service';
import { AuthorizationService } from 'src/app/core/service/authorization.service';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-parameter-form',
  templateUrl: './parameter-form.component.html',
  styleUrls: ['./parameter-form.component.css']
})
export class ParameterFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() fieldRequired: boolean;
  @Input() parameter: ParameterForm;
  @Output() parameterValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() parameterChange: EventEmitter<ParameterForm> = new EventEmitter<ParameterForm>();

  parameterEdit: ParameterForm = {} as ParameterForm;
  parameterType: ApplTypeForm;
  cdItem$: Observable<any[]>;
  editAllowed = false;

  constructor(
    private codeService: AppCodeService,
    private appTypeService: AppTypeService,
    private authorizationService: AuthorizationService
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.parameter)) {
      this.parameterEdit = this.parameter;
      this.appTypeService.getById(this.parameter.type).pipe(
        takeUntil(this.ngUnsubscribe),
        take(1)
      ).subscribe( result => {
        this.editAllowed = this.authorizationService.isEditAllowed(this.parameter.type);
        this.parameterType = result;
        if (this.parameterType?.cdParameter != null) {
          this.cdItem$ = this.codeService.getList(this.parameterType.cdParameter, !this.fieldRequired);

        }
      });
    }
  }

  ngOnInit(): void {
  }

  onChage() {
    console.log('onChange called');
    const valid = !this.fieldRequired || this.parameterEdit.value != null || this.parameterEdit.cdValue != null;
    if (valid) {
      this.parameterChange.emit(this.parameterEdit);
      console.log('emit', this.parameterEdit);
    }
    console.log('emit', valid);
    this.parameterValid.emit(valid);
  }
}
