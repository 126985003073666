/**
 * myclaims API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EmploymentInfo { 
    dateOfEmployment?: string;
    cdEmploymentPosition?: string;
    workingDaysPerWeek?: number;
    workingHoursPerWeek?: number;
    profession?: string;
    usualWorkingPlace?: string;
    cdEmploymentStatus?: string;
    terminationDate?: string;
    cdWorkingFrequency?: string;
    levelOfEmployment?: number;
}

