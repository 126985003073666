<div class="clr-row">
  <mat-checkbox class="clr-col-md-12" [(ngModel)]="withEmployeeInfo" (change)="onChange()">
    <mat-label translate>field.with_employee_info</mat-label>
  </mat-checkbox>
</div>
<form clrForm [formGroup]="form" clrLayout="vertical" #empForm *ngIf="withEmployeeInfo">
  <div class="clr-row">
    <mat-form-field class="clr-col-md-6">
      <mat-label translate>field.employment_status</mat-label>
      <mtx-select class="custom" [searchable]="true" formControlName="cdEmploymentStatus" [appendTo]="'body'" required>
        <mtx-option *ngFor="let employmentStatus of employmentStatuses$ | async" [value]="employmentStatus.id">
          {{ employmentStatus | language:'name' }}</mtx-option>
      </mtx-select>
    </mat-form-field>
    <mat-form-field class="clr-col-md-6">
      <mat-label translate>field.work_permit</mat-label>
      <input matInput formControlName="cdWorkPermit">
    </mat-form-field>
  </div>

  <div class="clr-row">
    <mat-form-field class="clr-col-md-6">
      <mat-label translate>field.employment_position</mat-label>
        <mtx-select class="custom" [searchable]="true" formControlName="cdEmploymentPosition" [appendTo]="'body'" required>
        <mtx-option *ngFor="let position of positions$ | async" [value]="position.id">
          {{ position | language:'name' }}</mtx-option>
      </mtx-select>
    </mat-form-field>
    <mat-form-field class="clr-col-md-6">
      <mat-label translate>field.number_of_children</mat-label>
      <input type="number" matInput formControlName="numberOfChildren">
    </mat-form-field>
  </div>

  <div class="clr-row">
    <mat-form-field class="clr-col-md-4">
      <mat-label translate>field.date_of_employment</mat-label>
      <input matInput [matDatepicker]="picker1" formControlName="dateOfEmployment" [max]="form.value.dateOfEmployment" required>
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="clr-col-md-4">
      <mat-label translate>field.termination_date</mat-label>
      <input matInput [matDatepicker]="picker2" formControlName="terminationDate" [min]="form.value.terminationDate">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>
    <mat-form-field class="clr-col-md-4">
      <mat-label translate>field.occupational_rate</mat-label>
      <input matInput formControlName="occupationalRate">
    </mat-form-field>
  </div>

  <div class="clr-row">
    <mat-form-field class="clr-col-md-6">
      <mat-label translate>field.working_days_per_week</mat-label>
      <input type="number" matInput formControlName="workingDaysPerWeek" max="7">
    </mat-form-field>
    <mat-form-field class="clr-col-md-6">
      <mat-label translate>field.working_hours_per_week</mat-label>
      <input type="number" matInput formControlName="workingHoursPerWeek" required>
    </mat-form-field>
  </div>

  <div class="clr-row">
    <mat-form-field class="clr-col-md-6">
      <mat-label translate>field.working_place</mat-label>
      <input matInput formControlName="workingPlace" required>
    </mat-form-field>
    <mat-form-field class="clr-col-md-6">
      <mat-label translate>field.cost_center</mat-label>
      <input matInput formControlName="costCenter">
    </mat-form-field>
  </div>
  <div class="clr-row">
    <mat-form-field class="clr-col-md-12">
      <mat-label translate>field.profession</mat-label>
      <input matInput formControlName="profession">
    </mat-form-field>
  </div>
  <div class="clr-row" formGroupName="basicSalary" *ngIf="salaryDataAllowed">
    <mat-form-field class="clr-col-md-8">
      <mat-label translate>field.basic_salary</mat-label>
      <input matInput formControlName="amount" [required]="!salaryDataAllowed" [disabled]="!salaryDataAllowed">
    </mat-form-field>
    <mat-form-field class="clr-col-md-4">
      <mat-label translate>field.type</mat-label>
        <mtx-select class="custom" formControlName="cdSalaryType" [appendTo]="'body'" [required]="salaryDataAllowed" [disabled]="!salaryDataAllowed">
        <mtx-option *ngFor="let salaryType of salaryTypes$ | async" [value]="salaryType.id">
          {{ salaryType | language:'name' }}</mtx-option>
      </mtx-select>
    </mat-form-field>
  </div>
  <div class="clr-row" formGroupName="familyBenefits" *ngIf="salaryDataAllowed">
    <mat-form-field class="clr-col-md-8">
      <mat-label translate>field.family_benefits</mat-label>
      <input matInput formControlName="amount" [disabled]="!salaryDataAllowed">
    </mat-form-field>
    <mat-form-field class="clr-col-md-4">
      <mat-label translate>field.type</mat-label>
          <mtx-select class="custom" formControlName="cdSalaryType" [appendTo]="'body'" [required]="form.value.familyBenefits.amount" [disabled]="!salaryDataAllowed">
        <mtx-option *ngFor="let salaryType of salaryTypes$ | async" [value]="salaryType.id">
          {{ salaryType | language:'name' }}</mtx-option>
      </mtx-select>
    </mat-form-field>
  </div>
  <div class="clr-row" formGroupName="holidayCompensation" *ngIf="salaryDataAllowed">
    <mat-form-field class="clr-col-md-8">
      <mat-label translate>field.holiday_compensation</mat-label>
      <input matInput formControlName="amount" [disabled]="!salaryDataAllowed">
    </mat-form-field>
    <mat-form-field class="clr-col-md-4">
      <mat-label translate>field.type</mat-label>
            <mtx-select class="custom" formControlName="cdSalaryType" [appendTo]="'body'" [required]="form.value.holidayCompensation.amount" [disabled]="!salaryDataAllowed">
        <mtx-option *ngFor="let salaryType of salaryTypes$ | async" [value]="salaryType.id">
          {{ salaryType | language:'name' }}</mtx-option>
      </mtx-select>
    </mat-form-field>
  </div>
  <div class="clr-row" formGroupName="gratuity" *ngIf="salaryDataAllowed">
    <mat-form-field class="clr-col-md-8">
      <mat-label translate>field.gratuity</mat-label>
      <input matInput formControlName="amount"[disabled]="!salaryDataAllowed">
    </mat-form-field>
    <mat-form-field class="clr-col-md-4">
      <mat-label translate>field.type</mat-label>
            <mtx-select class="custom" formControlName="cdSalaryType" [appendTo]="'body'" [required]="form.value.gratuity.amount" [disabled]="!salaryDataAllowed">
        <mtx-option *ngFor="let salaryType of salaryTypes$ | async" [value]="salaryType.id">
          {{ salaryType | language:'name' }}</mtx-option>
      </mtx-select>
    </mat-form-field>
  </div>
  <div class="clr-row" formGroupName="otherSupplements" *ngIf="salaryDataAllowed">
    <mat-form-field class="clr-col-md-8">
      <mat-label translate>field.other_supplements</mat-label>
      <input matInput formControlName="amount" [disabled]="!salaryDataAllowed">
    </mat-form-field>
    <mat-form-field class="clr-col-md-4">
      <mat-label translate>field.type</mat-label>
      <mtx-select class="custom" formControlName="cdSalaryType" [appendTo]="'body'" [required]="form.value.otherSupplements.amount" [disabled]="!salaryDataAllowed">
        <mtx-option *ngFor="let salaryType of salaryTypes$ | async" [value]="salaryType.id">
          {{ salaryType | language:'name' }}</mtx-option>
      </mtx-select>
    </mat-form-field>
  </div>
  <div class="clr-row" *ngIf="salaryDataAllowed">
    <mat-form-field class="clr-col-md-12">
      <mat-label translate>field.other_supplements_description</mat-label>
      <input matInput formControlName="otherSupplementsDescription" [disabled]="!salaryDataAllowed">
    </mat-form-field>
  </div>
  <div class="clr-row" *ngIf="salaryDataAllowed">
    <mat-checkbox class="clr-col-md-12" formControlName="withholdingTax" [disabled]="!salaryDataAllowed">
      <mat-label translate>field.withholding_tax</mat-label>
    </mat-checkbox>
  </div>
</form>
