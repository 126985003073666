import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { first, flatMap, switchMap, takeUntil } from 'rxjs/operators';
import {
  BankAccountForm,
  CommAddressForm,
  DocumentForm,
  DocumentRefForm,
  DocumentService,
  EmployeeModel,
  ParameterForm,
  PartnerIdentifierForm,
  PartnerPartnerForm,
  PersonForm,
  PersonService,
  PostalAddressForm
} from 'src/app/core';
import { EntityEnum } from 'src/app/core/entity-enum';
import { PartnerRole } from 'src/app/core/model/partnerRole';
import { AlertService } from 'src/app/core/service/alert.service';
import { AuthorizationService } from 'src/app/core/service/authorization.service';
import { BlockService } from 'src/app/core/service/block.service';
import { FileService } from 'src/app/core/service/file.service';
import * as _ from 'underscore';
import { BaseWizardComponent } from '../../base/base-wizard/base-wizard.component';
import { Editor } from '../../editors/editor';
import { sortDocuments } from '../../sort-util';

@Component({
  selector: 'app-person-wizard',
  templateUrl: './person-wizard.component.html',
  styleUrls: ['./person-wizard.component.css']
})
export class PersonWizardComponent extends BaseWizardComponent implements OnInit, Editor {

  entityEnum = EntityEnum.Person;

  @Input() id: number;
  @Input() object: PersonForm;

  partnerRoles: PartnerRole[];

  identifiers: PartnerIdentifierForm[];
  identifiersValid = true;

  parameters: ParameterForm[];
  parametersValid = true;

  person: PersonForm;
  personValid = false;

  employee: EmployeeModel;
  employeeValid = true;

  postalAddresses: PostalAddressForm[];
  addressesValid = true;

  commAddresses: CommAddressForm[];
  commAddressesValid = true;

  accounts: BankAccountForm[];
  accountsValid = true;

  partnerPartnersLeft: PartnerPartnerForm[];
  partnerPartnersLeftValid = true;

  partnerPartnersRight: PartnerPartnerForm[];
  partnerPartnersRightValid = true;

  documents: DocumentRefForm[];
  documentValid = true;

  hideParameterStep = false;
  hidePartnerPartnerLeftStep = false;
  hidePartnerPartnerRightStep = false;
  hideDocumentStep = false;

  salaryDataAllowed = true;

  constructor(
    private personService: PersonService,
    private documentService: DocumentService,
    private authorizationService: AuthorizationService,
    alertService: AlertService,
    blockService: BlockService,
    private translateService: TranslateService,
    private fileService: FileService
  ) {
    super(blockService, alertService);
   }

  ngOnInit(): void {
    this.newEntity = !this.id;
    
    if (this.object) {
      this.person = this.object;
      this.employee = this.object.employee as EmployeeModel;
      this.salaryDataAllowed = this.object.salaryDataAllowed;
    } else {
      this.salaryDataAllowed = this.authorizationService.showSalary();
    }

    this.fileService.reset();
    this.loadData();
  }

  loadData() {
    if (!this.id) {
      // tslint:disable-next-line:no-angle-bracket-type-assertion
      this.object = {} as PersonForm;
      this.person = this.object;
      this.employee = null;
      this.partnerRoles = [];
      this.identifiers = [];
      this.parameters = [];
      this.postalAddresses = [];
      this.commAddresses = [];
      this.accounts = [];
      this.partnerPartnersLeft = [];
      this.partnerPartnersRight = [];
      this.documents = [];
      return;
    }
    this.personService.findByIdPerson(this.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: PersonForm) => {
        this.object = response;
        this.person = response;
        this.employee = response.employee;
        this.partnerRoles = response.partnerRoles;
        this.identifiers = response.partnerIdentifiers;
        this.parameters = response.parameters;
        this.postalAddresses = response.postalAddresses;
        this.commAddresses = response.commAddresses;
        this.accounts = response.accounts;
        this.partnerPartnersLeft = response.partnersLeft;
        this.partnerPartnersRight = response.partnersRight;
        this.documents = sortDocuments(response.documents);
        this.evaluateWizardPagesCompleted();
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  protected onSubmit(): Observable<any> {
    const tasks$ = [];
    let index = 0;
    this.documents.forEach(documentRef => {
      if (!documentRef.document.id) {
        tasks$.push(this.storeDocument(documentRef.document, index));
      }
      index++;
    });

    if (tasks$.length === 0) {
      return this.storePerson();
    }

    return forkJoin(tasks$).pipe(
      takeUntil(this.ngUnsubscribe),
      first(),
      switchMap(() => this.storePerson())
    );
  }

  protected storePerson(): Observable<any> {
    let modifiedPerson = this.person;

    modifiedPerson = _.extendOwn(this.object, modifiedPerson);
    modifiedPerson.employee = this.employee;
    modifiedPerson.partnerRoles = this.partnerRoles;
    modifiedPerson.postalAddresses = this.postalAddresses;
    modifiedPerson.commAddresses = this.commAddresses;
    modifiedPerson.accounts = this.accounts;
    modifiedPerson.partnersLeft = this.partnerPartnersLeft;
    modifiedPerson.partnersRight = this.partnerPartnersRight;
    modifiedPerson.partnerIdentifiers = this.identifiers.filter(item => item.identifier && item.identifier.length > 0);
    modifiedPerson.documents = this.documents;

    const save$ = !modifiedPerson.id ? this.personService.createPerson(modifiedPerson) : this.personService.updatePerson(modifiedPerson);
    return save$;
  }

  private storeDocument(document: DocumentForm, index: number): Observable<any> {
    return forkJoin({
      doc: this.documentService.saveDocument(document),
      idx: of(index)
    }).pipe(
      takeUntil(this.ngUnsubscribe),
      flatMap(({ doc, idx }) => {
        this.documents[idx].document = doc;
        return this.documentService.storeContent(doc.id, this.fileService.get(idx));
      })
    );
  }

}
