<div clrLayout="vertical">
    <div class="clr-row" *ngIf="relTypes?.length === 0">
        <div class="clr-col-md-12">
            {{ 'message.no_process_header_available' | translate }}
        </div>
    </div>
    <div class="clr-row" *ngIf="relTypes?.length > 0">
        <mat-form-field class="clr-col-md-5">
            <mat-label translate>field.type</mat-label>
            <mtx-select [searchable]="true" [(ngModel)]="selectedRelType" [appendTo]="'body'">
                <mtx-option *ngFor="let relType of relTypes" [value]="relType">
                    {{ relType.typeRight | lookupApplType | async }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    
        <button class="btn btn-sm" [disabled]="!selectedRelType || !editorValid" (click)="onAdd()" translate>button.add_document</button>
    </div>
    <clr-accordion [clrAccordionMultiPanel]="true">
        <clr-accordion-panel *ngFor="let wrappedItem of wrappedItems; index as i" [clrAccordionPanelOpen]="wrappedItem.open">
            <clr-accordion-title>{{ wrappedItem.item.document?.type | lookupApplType| async}}: {{ wrappedItem.item.document?.name }}</clr-accordion-title>
            <clr-accordion-content *clrIfExpanded class="clr-row">
                <div class="clr-col-md-11">
                    <app-document-form [(applDocument)]="wrappedItem.item.document" [index]="i" (applDocumentValid)="onChange(i, $event)">
                    </app-document-form>
                </div>
                <div class="clr-col-md-1">
                    <span *appDeleteAllowed="wrappedItem?.item?.document?.type" (click)="onDelete(i)">
                        <clr-icon shape="trash"></clr-icon>
                    </span>
                </div>
            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
</div>

