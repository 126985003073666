<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.type</mat-label>
            <input matInput [value]="applObject?.type | lookupApplType | async" disabled>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.status</mat-label>
            <mtx-select [searchable]="true" formControlName="cdStatus" [appendTo]="'body'" required>
                <mtx-option *ngFor="let status of cdStatuses$ | async" [value]="status.id">
                    {{ status | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-3">
            <mat-label translate>field.reference</mat-label>
            <input matInput formControlName="referenceNumber" required>
            <mat-error *ngIf="form.get('referenceNumber').hasError('referenceExists')" translate>
                error.reference_number_already_exists
            </mat-error>
        </mat-form-field>
        <mat-form-field class="clr-col-md-3">
            <mat-label translate>field.reference2</mat-label>
            <input matInput formControlName="referenceNumber2">
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.vehicle_nr</mat-label>
            <input matInput formControlName="vehicleNr">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.name</mat-label>
            <input matInput formControlName="name" required>
        </mat-form-field>
    </div>

    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.registration_nr</mat-label>
            <input matInput formControlName="registrationNr">
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.registration_date</mat-label>
            <input matInput [matDatepicker]="pickerReg" formControlName="registrationDate">
            <mat-datepicker-toggle matSuffix [for]="pickerReg"></mat-datepicker-toggle>
            <mat-datepicker #pickerReg></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.object_value</mat-label>
            <input type="number" matInput formControlName="objectValue">
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.residual_value</mat-label>
            <input type="number" matInput formControlName="residualValue">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.description</mat-label>
            <input matInput formControlName="description">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.usage_begin_date</mat-label>
            <input matInput [matDatepicker]="pickerBegin" formControlName="usageBeginDate" [max]="form.value.usageEndDate">
            <mat-datepicker-toggle matSuffix [for]="pickerBegin"></mat-datepicker-toggle>
            <mat-datepicker #pickerBegin></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.usage_end_date</mat-label>
            <input matInput [matDatepicker]="pickerEnd" formControlName="usageEndDate" [min]="form.value.usageBeginDate">
            <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
            <mat-datepicker #pickerEnd></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.category</mat-label>
            <mtx-select [searchable]="true" formControlName="cdObjectCategory" [appendTo]="'body'">
                <mtx-option *ngFor="let cdObjectCategory of cdObjectCategories$ | async" [value]="cdObjectCategory.id">
                    {{ cdObjectCategory | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <div class="clr-col-md-6">
            <app-object-search [allowClear]="true" [fieldName]="'field.object_ref' | translate" [(id)]="objectIdRef" (idChange)="onChange()"></app-object-search>
        </div>
    </div>
</form>