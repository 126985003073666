import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef, ViewRef } from '@angular/core';
import { AuthorizationService } from '../service/authorization.service';

@Directive({
  selector: '[appShowWithCdFunctionality]'
})
export class ShowWithCdFunctionalityDirective implements OnDestroy {

  private hasView = false;
  private viewRef: ViewRef;

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private authorizationService: AuthorizationService
  ) { }

  @Input() set appShowWithCdFunctionality(cdFunctionality: string) {
    const show = this.authorizationService.hasCdFunctionality(cdFunctionality);

    if (!this.hasView && show) {
      this.viewRef = this.viewContainerRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    }
    if (!show && this.hasView) {
      this.viewContainerRef.detach();
    }
    if (show && this.hasView) {
      this.viewContainerRef?.insert(this.viewRef, 0);
    }
  }

  ngOnDestroy(): void {
    if (this.hasView) {
      this.viewRef.destroy();
    }
  }

}
