<clr-datagrid>
    <clr-dg-column [clrDgSortBy]="'relationshipType'">{{ 'field.relationship_type' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'type'">{{ 'field.type' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'cdAccountType'">{{ 'field.account_type' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'accountNr'">{{ 'field.account_nr' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'accountOwner'">{{ 'field.account_owner' | translate }}
    </clr-dg-column>

    <clr-dg-row *ngFor="let obj of accounts" [clrDgItem]="obj">
        <clr-dg-cell>{{ obj.relationshipType | lookupRelationshipType | async }}</clr-dg-cell>
        <clr-dg-cell>{{ obj.type | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell>{{ obj.cdAccountType | lookupApplCode | async }}</clr-dg-cell>
        <clr-dg-cell>{{ account(obj) }}</clr-dg-cell>
        <clr-dg-cell>{{ obj.accountOwner }}</clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>