import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-process-item-list-widget',
  templateUrl: './process-item-list-widget.component.html',
  styleUrls: ['./process-item-list-widget.component.css']
})
export class ProcessItemListWidgetComponent implements OnInit {

  @Input()
  processItems = [];
  
  constructor() { }

  ngOnInit(): void {
  }

}
