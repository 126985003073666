import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { PaymentInfo } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';


@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sunet-payment-form',
  templateUrl: './sunet-payment-form.component.html',
  styleUrls: ['./sunet-payment-form.component.css']
})
export class SunetPaymentFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() payment: PaymentInfo;
  @Output() paymentChange: EventEmitter<PaymentInfo> = new EventEmitter<PaymentInfo>();
  @Output() paymentValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  accountTypes$: any;

  constructor(
    private codeService: AppCodeService,
  ) {
    super();

    this.form = new UntypedFormGroup({
      cdAccountType: new UntypedFormControl(null, [Validators.required]),
      bankName: new UntypedFormControl(''),
      bankPlace: new UntypedFormControl(''),
      clearingNumber: new UntypedFormControl(''),
      accountNumber: new UntypedFormControl('', [Validators.required])
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    console.log('onInit');
    if (this.payment) {
      this.form.patchValue(this.payment);
    }
    if (this.disabled) {
      this.form.disable();
    }
    this.form.markAllAsTouched();
    this.accountTypes$ = this.codeService.getList('cd_account_type', false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.payment)) {
      this.form.patchValue(this.payment);
      this.form.markAllAsTouched();
    }
  }

  onChange(): void {
    this.paymentValid.emit(this.form.valid);
    const modified = _.extend(this.payment, this.form.value);
    this.paymentChange.emit(modified);
    console.log('changed');
  }
}
