import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { first, map, shareReplay, takeUntil } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/base/base/base.component';
import * as _ from 'underscore';
import { AppConfig } from '../../config/app.config';
import { TypeService } from '../api/api';
import { ApplTypeForm } from '../model/models';
import { RelationshipTypeService } from './relationship-type.service';




@Injectable({
  providedIn: 'root'
})
export class AppTypeService extends BaseComponent {

  private list$ByTableName = new Map();
  private applType$ById = new Map();

  private supportedLanguages: any[] = [];

  constructor(
    private typeService: TypeService,
    private reltypService: RelationshipTypeService
  ) {
    super();
    AppConfig.supportedLanguages.forEach(languageShortName => {
      this.supportedLanguages.push({
        code: languageShortName.charAt(0).toUpperCase() + languageShortName.slice(1).toLowerCase(),
        mnemonic: 'cd_language_' + languageShortName.toLowerCase()
      });
    });
  }

  getById(id: string): Observable<ApplTypeForm> {
    let result$ = this.applType$ById.get(id);
    if (!result$) {
        result$ = this.typeService.findByIdApplType(id).pipe(
          shareReplay()
        );
        this.applType$ById.set(id, result$);
    }
    return result$;
  }

  getList(tableName: string, withEmptyEntry: boolean): Observable<any[]> {
    // log//console.log('called getList', domain);
    return this.getByTableNameApplType(tableName).pipe(
      map((items: any[]) => {
        if (withEmptyEntry) {
          items.unshift({
            id: undefined,
            nameDe: '-',
            nameFr: '-',
            nameEn: '-'
          });
        }
        return items;
      })
    );
  }

  getByTableNameApplType(tableName: string): Observable<any[]> {
    let result$ = this.list$ByTableName.get(tableName);
    if (!result$) {
      result$ = this.typeService.getByTableNameApplType(tableName).pipe(
        shareReplay(),
        map(items => this.extract4ListWithId(items, 'id', 'name'))
      );

      this.list$ByTableName.set(tableName, result$);
    }
    return result$;
  }

  extract4List(items: any[], attributeName: string): any[] {
    return this.extract4ListWithId(items, 'id', attributeName);
  }

  extract4ListWithId(items: any[], idAttributeName: string, valueAttributeName: string): any[] {
    // log//console.log('extract4list', items, idAttributeName, valueAttributeName);
    const result = [];
    items.sort((e1, e2) => e1.sort - e2.sort);
    for (const item of items) {
      const limitedItem = { id: item[idAttributeName] };
      for (const lang of this.supportedLanguages) {
        const textLanguage = item.applText.translations.find(translation => translation.cdLanguage === lang.mnemonic)
        if (textLanguage) {
          limitedItem[valueAttributeName + lang.code] = textLanguage.textLong;
        }
      }
      result.push(limitedItem);
    }
    return result;
  }

  getListRelatedToReltype(tableName: string, typeLeft?: string, typeRight?: string): Observable<any[]> {
    return this.getListRelatedToReltypeForSubs(tableName, tableName, typeLeft, typeRight);
  }

  getListRelatedToReltypeForSubs(tableNameForType: string, tableName: string, typeLeft?: string, typeRight?: string): Observable < any[] > {
    return forkJoin({
      types: this.getList(tableNameForType, false),
      reltypes: this.reltypService.getRelationships(tableName, this.buildFilter(typeLeft, typeRight))
    }).pipe(
      takeUntil(this.ngUnsubscribe),
      first(),
      map((result) => {
        let typesFiltered;
        if (typeLeft) {
          typesFiltered = _.pluck(result.reltypes, 'typeRight');
        }
        if (typeRight) {
          typesFiltered = _.pluck(result.reltypes, 'typeLeft');
        }
        return result.types.filter(item => typesFiltered?.includes(item.id));
      })
    );
  }

  buildFilter(typeLeft?: string, typeRight?: string): any {
    const filter = {};
    if (typeLeft) {
      filter['typeLeft'] = typeLeft;
    }
    if (typeRight) {
      filter['typeRight'] = typeRight;
    }
    return filter;

  }
}

