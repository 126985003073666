<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-4">
            <mat-label translate>field.type</mat-label>
            <input matInput [value]="case?.type | lookupApplType | async" disabled>
        </mat-form-field>
        <mat-form-field class="clr-col-md-4">
            <mat-label translate>field.sub_type</mat-label>
            <mtx-select [searchable]="true" formControlName="subType" [required]="(subTypes$ | async)?.length != 0" [disabled]="(subTypes$ | async)?.length == 0">
                <mtx-option *ngFor="let subType of subTypes$ | async" [value]="subType.id">
                    {{ subType | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <mat-form-field class="clr-col-md-4">
            <mat-label translate>field.status</mat-label>
            <mtx-select [searchable]="true" formControlName="cdStatus" required>
                <mtx-option *ngFor="let cdStatus of cdStatuses$ | async" [value]="cdStatus.id">
                    {{ cdStatus | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-3">
            <mat-label translate>field.reference_nr</mat-label>
            <input matInput formControlName="referenceNumber" maxlength="60" required>
            <mat-error *ngIf="form.get('referenceNumber').hasError('referenceExists')" translate>
                error.reference_number_already_exists
            </mat-error>
        </mat-form-field>
        <mat-form-field class="clr-col-md-3">
            <mat-label translate>field.reference_nr2</mat-label>
            <input matInput formControlName="referenceNumber2" maxlength="60">
        </mat-form-field>
        <mat-form-field class="clr-col-md-3">
            <mat-label translate>field.report_language</mat-label>
            <mtx-select [searchable]="true" formControlName="cdLanguageReport" required>
                <mtx-option *ngFor="let cdLanguage of cdLanguages$ | async" [value]="cdLanguage.id">
                    {{ cdLanguage | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <mat-form-field class="clr-col-md-3">
            <mat-label translate>field.not_in_report</mat-label>
            <mtx-select [searchable]="true" formControlName="cdNotInReport">
                <mtx-option *ngFor="let cdNotInReport of cdNotInReport$ | async" [value]="cdNotInReport.id">
                    {{ cdNotInReport | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.entry_date</mat-label>
            <input matInput [matDatepicker]="pickerEntryDate" formControlName="entryDate">
            <mat-datepicker-toggle matSuffix [for]="pickerEntryDate"></mat-datepicker-toggle>
            <mat-datepicker #pickerEntryDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.covered</mat-label>
            <mtx-select [searchable]="true" formControlName="cdCovered">
                <mtx-option *ngFor="let cdCovered of cdCovered$ | async" [value]="cdCovered.id">
                    {{ cdCovered | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row" formGroupName="period">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.start</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="start" [max]="form.value.period.end" required>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.end</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="end" [min]="form.value.period.start">
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.title</mat-label>
            <textarea matInput formControlName="title" maxlength="80" [rows]="2"></textarea>
        </mat-form-field>
    </div>
    <div class="clr-row" *ngIf="!hideDescription">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.description</mat-label>
            <textarea matInput formControlName="description" maxlength="4000" [rows]="10"></textarea>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.remark</mat-label>
            <textarea matInput formControlName="remark" maxlength="4000" [rows]="2"></textarea>
        </mat-form-field>
    </div>
</form>