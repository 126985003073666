import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sunet-injuries-form',
  templateUrl: './sunet-injuries-form.component.html',
  styleUrls: ['./sunet-injuries-form.component.css']
})
export class SunetInjuriesFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() injuries: any;
  @Input() limitToTeeth = false;
  @Output() injuriesChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() injuriesValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  form: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder
  ) {
    super();

    this.form = this.fb.group({
      injury: this.fb.group({
        part: this.fb.control(null, Validators.required),
        side: this.fb.control(null),
        type: this.fb.control(null, Validators.required),
        description: this.fb.control(null)
      }),
      injury2: this.fb.group({
        part: this.fb.control(null),
        side: this.fb.control(null),
        type: this.fb.control(null),
        description: this.fb.control(null)
      }),
      injury3: this.fb.group({
        part: this.fb.control(null),
        side: this.fb.control(null),
        type: this.fb.control(null),
        description: this.fb.control(null)
      }),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.form.disable();
    }
    this.form.markAllAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.injuries) {
      this.form.patchValue(this.injuries);
      this.form.markAllAsTouched();
    }
    if (changes.limitToTeeth
        && this.limitToTeeth
      && this.form.value.injury.part !== 'cd_injured_part_teeth' ) {
      this.form.reset();
      this.form.get('injury.part').setValue('cd_injured_part_teeth');
      this.form.markAllAsTouched();
      this.onChange();
    }
  }

  onChange(): void {
    const modified = _.extend(this.injuries, this.form.value);
    this.injuriesChange.emit(modified);
    this.injuriesValid.emit(this.form.valid);
  }
}
