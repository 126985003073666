import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ParameterForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';



@Component({
  selector: 'app-parameter-editor',
  templateUrl: './parameter-editor.component.html',
  styleUrls: ['./parameter-editor.component.css']
})
export class ParameterEditorComponent extends BaseEditorComponent<ParameterForm> implements OnChanges {

  @Input() type: string;
  @Input() parameters: ParameterForm[];
  @Output() parametersChange: EventEmitter<ParameterForm[]> = new EventEmitter<ParameterForm[]>();

  tableName = 'prm';

  constructor(
    protected reltypeService: RelationshipTypeService,
  ) {
    super(reltypeService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.parameters)
      && !this.wrappedItems) {
      this.processObjects();
    }
    if (this.hasChanged(changes.type)) {
      console.log('changes type');
      this.loadRelationships();
    }
  }

  protected buildFilter(): any {
    return { typeLeft: this.type };
  }

  protected processObjects() {
    const wrappers = [];

    if (!this.relTypes
        || !this.parameters) {
      return;
    }

    this.parameters.forEach((param) => {
      const relType = this.relTypes.find(item => item.typeRight === param.type);
      wrappers.push( {
        item: param,
        valid: true,
        required: relType?.ltrMinCardinality > 0,
        sort: relType?.sort
      } as ItemWrapper<ParameterForm>);
    });

    // add missing parameters
    const typeRights = _.map(wrappers, wrap => wrap.item.type);
    let addedRequiredField = false;
    this.relTypes.filter(item => !typeRights.includes(item.typeRight)).forEach( (reltype) => {
      const required = reltype?.ltrMinCardinality > 0;
      addedRequiredField = addedRequiredField || required;
      wrappers.push({
        item: {
          type: reltype.typeRight
        },
        valid: !required,
        required,
        sort: reltype.sort
      } as ItemWrapper<ParameterForm>);
    });

    if (addedRequiredField) {
      this.valid.emit(false);
    }

    wrappers.sort( (e1, e2) => e1.sort - e2.sort);

    this.wrappedItems = wrappers;
  }

  onChange(index: number, valid: boolean): void {
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.parametersChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.valid.emit(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => item.valid === false).length === 0);
    return result;
  }
}
