import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';
import { ProcessHeaderProcessItemForm } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import { AppTypeService } from 'src/app/core/service/app-type.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-process-item-form',
  templateUrl: './process-item-form.component.html',
  styleUrls: ['./process-item-form.component.css']
})
export class ProcessItemFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() processItem: ProcessHeaderProcessItemForm;
  @Output() processItemValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() processItemChange: EventEmitter<ProcessHeaderProcessItemForm> = new EventEmitter<ProcessHeaderProcessItemForm>();

  processiTypes$: Observable<any[]>;
  cdStatuses$: Observable<any[]>;

  amountGross = 0;
  amountTotalNet = 0;
  amountTotalGross = 0;

  selectTypeLocked = false;

  constructor(
    private codeService: AppCodeService,
    private typeService: AppTypeService,
  ) {
    super();
    this.form = new UntypedFormGroup({
      type: new UntypedFormControl('', [Validators.required]),
      cdStatus: new UntypedFormControl('', [Validators.required]),
      reference: new UntypedFormControl(),
      title: new UntypedFormControl([Validators.required]),
      description: new UntypedFormControl(),
      position: new UntypedFormControl([Validators.required]),
      period: new UntypedFormGroup({
        start: new UntypedFormControl(),
        end: new UntypedFormControl(),
      }),
      caseId: new UntypedFormControl(),
      quantity: new UntypedFormControl(1),
      amountNet: new UntypedFormControl(),
      tax: new UntypedFormControl(0),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    console.log('onInit');
    if (this.processItem) {
      if (this.processItem.type) {
        this.selectTypeLocked = true;
      }
      this.form.patchValue(this.processItem);
    }
    this.form.markAllAsTouched();

    this.processiTypes$ = this.typeService.getList('processi', false);
    this.cdStatuses$ = this.codeService.getList('cd_processi_status', false);
    this.setDefaults();
  }

  private setDefaults() {
    if (this.processItem?.id) {
      return;
    }
    this.cdStatuses$.pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(result => {
      this.form.patchValue({
        cdStatus: result[0].id
      });
      this.form.markAllAsTouched();
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.processItem)) {
      if (this.processItem
        && this.processItem.type) {
        this.selectTypeLocked = true;
      }

      this.form.reset();
      this.form.patchValue(this.processItem);
      this.form.markAllAsTouched();
      this.onChange();
    }
  }

  onChange(): void {
    this.calculateTotals();
    this.processItemValid.emit(this.form.valid);
    if (this.form.valid) {
      this.processItem = _.extend(this.processItem, this.form.value);
      this.processItem.amountGross = this.amountGross;
      this.processItemChange.emit(this.processItem);
    }
    console.log('changed');
  }

  calculateTotals() {
    const quantity = this.form.value.quantity || 1;
    const amountNet = this.form.value.amountNet || 0;
    const tax = (this.form.value.tax || 0) / 100;

    this.amountGross = +(amountNet * (1 + tax)).toFixed(2);
    this.amountTotalNet = quantity * amountNet;
    this.amountTotalGross = +(this.amountTotalNet * (1 + tax)).toFixed(2);

  }
}
