import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProcessHeaderPolicyHeaderForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';


@Component({
  selector: 'app-process-policy-editor',
  templateUrl: './process-policy-editor.component.html',
  styleUrls: ['./process-policy-editor.component.scss']
})
export class ProcessPolicyEditorComponent extends BaseEditorComponent<ProcessHeaderPolicyHeaderForm> implements OnInit, OnChanges {

  @Input() processPolicies: ProcessHeaderPolicyHeaderForm[];
  @Input() processType: string;
  @Output() processPoliciesChange: EventEmitter<ProcessHeaderPolicyHeaderForm[]> = new EventEmitter<ProcessHeaderPolicyHeaderForm[]>();

  tableName = 'processh_policyh';

  translations = [];

  constructor(
    private translateService: TranslateService,
    protected reltypeService: RelationshipTypeService,
  ) {
    super(reltypeService);
  }

  ngOnInit(): void {
    this.translations = this.translateService.instant(['button.add_relationship']);
  }

  protected buildFilter(): any {
    const filter = {
      typeLeft: this.processType
    };
    return filter;
  }

  protected processObjects() {
    if (!this.relTypes
      || !this.processPolicies) {
      return;
    }

    const wrappers = [];
    this.processPolicies.forEach((obj: ProcessHeaderPolicyHeaderForm) => {
      wrappers.push({
        item: obj,
        valid: true,
        relType: _.find(this.relTypes, (relType => relType.id === obj.relationshipType))
      } as ItemWrapper<ProcessHeaderPolicyHeaderForm>);
    });

    wrappers.sort((e1, e2) => e1?.relType.sort - e2?.relType?.sort);
    this.wrappedItems = wrappers;
    this.calculateFiltered();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.processPolicies)
      && !this.wrappedItems) {
      this.processObjects();
    }
    if (this.hasChanged(changes.processType)) {
      this.loadRelationships();
    }
  }

  onAdd() {
    this.wrappedItems.push({
      item: {
        relationshipType: this.selectedRelType.id
      } as ProcessHeaderPolicyHeaderForm,
      relType: this.selectedRelType,
      valid: false
    } as ItemWrapper<ProcessHeaderPolicyHeaderForm>);
    this.calculateFiltered();
    this.handleRelTypeSelection();

    this.valid.emit(false);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);
    this.calculateFiltered();

    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    console.log('onchange', index, valid);
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.processPoliciesChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.valid.emit(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => !item.valid).length === 0);
    return result;
  }
}
