import { Injectable } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Injectable({
  providedIn: 'root'
})
export class BlockService {

  @BlockUI() blockUI: NgBlockUI;

  constructor() { }

  public startWithMessage(message: string) {
    if (!this.blockUI.isActive) {
      this.blockUI.start(message);
    }
  }

  public stop() {
    this.blockUI.stop();
  }
}
