<clr-datagrid>
    <clr-dg-column [clrDgSortBy]="'type'">{{ 'field.type' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'policyHeaderName'">{{ 'name' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'policyHeaderNumber'">{{ 'policy_nr' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.insurer' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.franchise' | translate }}
    </clr-dg-column>

    <clr-dg-row *ngFor="let policy of policies" [clrDgItem]="policy">
        <clr-dg-cell>{{ policy.policyHeaderType | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell>{{ policy.policyHeaderName }}</clr-dg-cell>
        <clr-dg-cell><a [routerLink]="['/admin/policy-detail', policy.policyHeaderId ]">{{ policy.policyHeaderNumber }}</a></clr-dg-cell>
        <clr-dg-cell><a [routerLink]="['/admin/partner-detail', policy.insurerId ]">{{ policy.insurerName }}</a></clr-dg-cell>
        <clr-dg-cell>{{ policy.franchiseName }} {{ policy.franchiseValue }}</clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>