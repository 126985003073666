import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-process-process-list-widget',
  templateUrl: './process-process-list-widget.component.html',
  styleUrls: ['./process-process-list-widget.component.scss']
})
export class ProcessProcessListWidgetComponent implements OnInit {

  @Input()
  processProcess = [];

  @Input()
  id: number;


  constructor() { }

  ngOnInit(): void {
  }

}
