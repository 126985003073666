import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';
@Injectable({ providedIn: 'root' })
export class PushService {

  constructor(
    private authenticationService: AuthenticationService
  ) {
  }

  register(onMessage: (result: any) => void): EventSource {
    const token = this.authenticationService.token;
    const authz = `?authz=${token}`;

    //const source = new EventSource('http://localhost:4200/api/service/processHeaderEvents/register' + authz);
    const source = new EventSource('/api/service/processHeaderEvents/register' + authz);
    source.onmessage = (eventResponse => {
      onMessage(eventResponse);
    });
    source.onopen = (event) => { console.log("connection opened") };
    source.onerror = (event) => {
      console.log('Error ', event.target);
    };

    return source;
  }

}
