import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AppCodeService } from 'src/app/core/service/app-code.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-sunet-injury-form',
  templateUrl: './sunet-injury-form.component.html',
  styleUrls: ['./sunet-injury-form.component.css']
})
export class SunetInjuryFormComponent implements OnInit {

  @Input() injury: UntypedFormGroup;
  @Input() limitToTeeth = false;
  @Input() required = false;

  sideDisabled = false;

  cdInjuredParts$: Observable<any[]>;
  cdInjuredSides$: Observable<any[]>;
  cdInjuryTypes$: Observable<any[]>;

  constructor(
    private codeService: AppCodeService
  ) { }

  ngOnInit(): void {
    this.cdInjuredParts$ = this.codeService.getList('cd_injured_part', false);
    this.cdInjuredSides$ = this.codeService.getList('cd_injured_side', false);
    this.cdInjuryTypes$ = this.codeService.getList('cd_injury_type', false);
  }

  evaluateSideDisabled(part: string) {
    if (!part) {
      this.sideDisabled = false;
    } else if (part === 'cd_injured_part_belly'
      || part === 'cd_injured_part_cervical'
      || part === 'cd_injured_part_coccyx'
      || part === 'cd_injured_part_bladder'
      || part === 'cd_injured_part_genitals'
      || part === 'cd_injured_part_heart'
      || part === 'cd_injured_part_internal'
      || part === 'cd_injured_part_liver'
      || part === 'cd_injured_part_lumbar'
      || part === 'cd_injured_part_neck'
      || part === 'cd_injured_part_shock'
      || part === 'cd_injured_part_spleen'
      || part === 'cd_injured_part_thorax'
      || part === 'cd_injured_part_vertebra'
      || part === 'cd_injured_part_polytrauma'
      || part === 'cd_injured_part_whole_body') {
      this.sideDisabled = true;
      this.injury.controls['side'].setValue('cd_injured_side_unknown');
    } else {
      this.sideDisabled = false;
    }

    this.injury.markAllAsTouched();
  }

}
