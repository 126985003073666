<clr-modal [(clrModalOpen)]="show">
    <h3 class="modal-title">Absence Editor {{ id }}</h3>
    <div class="modal-body">
        <form clrForm [formGroup]="absenceForm" clrLayout="vertical">
            <div class="clr-row">
                <div class="clr-col-md-5">
                    <clr-input-container>
                        <label>Name</label>
                        <input clrInput formControlName="name" />
                    </clr-input-container>                
                </div>
            </div>
            <div class="clr-row">
                <div class="clr-col-md-5">
                    <clr-date-container>
                        <label>field.start_date</label>
                        <input type="date" clrDate formControlName="startDate" />
                    </clr-date-container>
                </div>
                <div class="clr-col-md-5">
                    <clr-date-container>
                        <label>End date</label>
                        <input type="date" clrDate formControlName="endDate" />
                    </clr-date-container>
                </div>
            </div>
            <div class="clr-row">

            </div>
            <div class="clr-row">

            </div>
            <div class="clr-row">

            </div>

        </form>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="cancel()">Cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="absenceForm.invalid" (click)="submit()">Ok</button>
    </div>
</clr-modal>