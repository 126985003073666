import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {

  show = true;
  type: any;
  objectDetail: string;
  afterClosed = new Subject<any>();

  constructor(
  ) { }

  ngOnInit(): void {
  }

  onCancel() {
    console.log('onCancel');
    this.close();
    this.afterClosed.next({
      confirmed: false
    });
  }

  onSuccess() {
    this.close();
    this.afterClosed.next({
      confirmed: true
    });
  }

  private close() {
    this.show = false;
  }

}
