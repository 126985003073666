import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ApplCaseForm, ApplDocument, ProcessHeaderForm } from 'src/app/core';
import { FileService } from 'src/app/core/service/file.service';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';
import { ProcessHeaderTypeUtils } from '../../forms/process-header-form/process-header-type-utils';

@Component({
  selector: 'app-process-header-editor',
  templateUrl: './process-header-editor.component.html',
  styleUrls: ['./process-header-editor.component.css']
})
export class ProcessHeaderEditorComponent extends BaseEditorComponent<ProcessHeaderForm> implements OnInit, OnChanges {

  static COPY_CONTENT_FOR_TYPES = [ProcessHeaderTypeUtils.CLAIM_REP, ProcessHeaderTypeUtils.CLAIM_REP_INT];

  @Input() processHeaders: ProcessHeaderForm[];
  @Input() caseType: string;
  @Input() case: ApplCaseForm;
  @Output() processHeadersChange: EventEmitter<ProcessHeaderForm[]> = new EventEmitter<ProcessHeaderForm[]>();
  @Output() documentsChange: EventEmitter<ApplDocument[]> = new EventEmitter<ApplDocument[]>();

  tableName = 'processh';

  constructor(
    private fileService: FileService,
    protected reltypeService: RelationshipTypeService
  ) {
    super(reltypeService);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.processHeaders)
      && !this.wrappedItems) {
      this.processObjects();
    }
    if (this.hasChanged(changes.caseType)) {
      this.loadRelationships();
    }
  }

  protected buildFilter(): any {
    return { typeRight: this.caseType };
  }

  protected processObjects() {
    const wrappers = [];

    if (!this.relTypes
      || !this.processHeaders) {
        return;
    }

    this.processHeaders.forEach((processH) => {
      wrappers.push({
        item: processH,
        withDocument: false,
        valid: true,
        open: false,
        sort: 0
      } as ItemWrapper<ProcessHeaderForm>);
    });
    wrappers.sort((e1, e2) => e1.sort - e2.sort);
    this.wrappedItems = wrappers;
    this.calculateFiltered();
  }

  protected calculateFiltered() {
    this.countByType = _.countBy(this.wrappedItems, (item: ItemWrapper<ProcessHeaderForm>) => item.item.type);
    // tslint:disable-next-line:max-line-length
    this.filteredRelTypes = this.relTypes.filter(item => (this.countByType[item.typeLeft] == null || this.countByType[item.typeLeft] < item.rtlMaxCardinality));
  }

  onAdd() {
    this.wrappedItems.push({
      item: this.buildItem(this.selectedRelType.typeLeft),
      withDocument: false,
      document: {},
      valid: false,
      open: true,
      sort: this.selectedRelType?.sort
    } as ItemWrapper<ProcessHeaderForm>);
    this.calculateFiltered();
    this.handleRelTypeSelection();

    this.emitValid(false);
  }

  buildItem(type: string): ProcessHeaderForm {
    const item = {
      type,
    } as ProcessHeaderForm;

    if (ProcessHeaderEditorComponent.COPY_CONTENT_FOR_TYPES.includes(type)) {
      const matches = this.wrappedItems.filter(wi => ProcessHeaderEditorComponent.COPY_CONTENT_FOR_TYPES.includes(wi.item.type))
        .map(wi => wi.item);
      if (matches.length > 0) {
        item.reference = matches[0].reference;
        item.title = matches[0].title;
        item.location = matches[0].location;
        item.description = matches[0].description;
        item.processHeaderDate = matches[0].processHeaderDate;
        item.entryDate = matches[0].entryDate;
      } else {
        item.reference = this.case.referenceNumber;
        item.title = this.case.title;
        item.entryDate = this.case.entryDate;
        console.log('start:', this.case.period?.start);
        if (this.case.period?.start) {
          //fix localDate to LocalDateTime conversion
          item.processHeaderDate = this.case.period.start;
          if (item.processHeaderDate.length < 12) {
            item.processHeaderDate += 'T00:00:00.000Z';
          }
          console.log('processHeaderDate:', item.processHeaderDate);
        }
        //
      }
    }
    return item;
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);
    this.calculateFiltered();
    this.fileService.deletePosition(index, 'processHeader');

    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    console.log('onChange', index, valid);
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.processHeadersChange.emit(_.pluck(this.wrappedItems, 'item'));
      this.documentsChange.emit(_.pluck(this.wrappedItems, 'document'));
    }
    this.emitValid(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => item.valid === false).length === 0);
    return result;
  }
}

