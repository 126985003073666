import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.css']
})
export class ErrorComponent implements OnInit , OnChanges {

  @Input() error: any;

  show = false;
  translations = [];

  errorMessage: string = null;

  constructor(
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.translations = this.translateService.instant(['error.constraint.applcase_uk', 'error.constraint.processh_uk', 'error.constraint.applobject_uk', 'error.constraint.policyh_uk', 'error.constraint.template_identifier_uk']);
    this.processMessage();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.processMessage();
  }

  private processMessage() {
    this.show = this.error != null;
    if (!this.show) {
      return;
    }
    const key = this.buildKey();
    console.log('key', key);
    if (key) {
      this.errorMessage = this.translations[key];
    } else {
      this.errorMessage = null;
    }

  }

  private buildKey(): string {
    if (this.error?.error?.exceptionType === 'constraint') {
      return 'error.' + this.error.error.exceptionType + '.' + this.error.error.message;
    }

    return null;
  }

}
