import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../service/language.service';

@Pipe({ name: 'language' })
export class LanguagePipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(item: any, attributeName: string): string {
    return this.languageService.getLanguageSpecificValue(item, attributeName);
  }
}