<form clrForm [formGroup]="form" clrLayout="vertical" #empForm>
    <div class="clr-row">
        <mat-form-field [ngClass]="{'readonly-wrapper': readonly}" class="clr-col-md-6">
            <mat-label translate>field.employment_status</mat-label>
            <mtx-select [searchable]="true" formControlName="cdEmploymentStatus" [appendTo]="'body'"
                required  [readonly]="readonly">
                <mtx-option *ngFor="let employmentStatus of employmentStatuses$ | async" [value]="employmentStatus.id">
                    {{ employmentStatus | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.employment_position</mat-label>
            <mtx-select [searchable]="true" formControlName="cdEmploymentPosition"
                [appendTo]="'body'" required>
                <mtx-option *ngFor="let position of positions$ | async" [value]="position.id">
                    {{ position | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>

    <div class="clr-row">
        <mat-form-field [ngClass]="{'readonly-wrapper': readonly}" class="clr-col-md-6">
            <mat-label translate>field.date_of_employment</mat-label>
            <input matInput [matDatepicker]="picker1" formControlName="dateOfEmployment" [max]="processHeaderDate" required [readonly]="readonly">
            <mat-datepicker-toggle [ngClass]="{'readonly-block': readonly}" matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <mat-form-field [ngClass]="{'readonly-wrapper': readonly}" class="clr-col-md-6">
            <mat-label translate>field.termination_date</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="terminationDate" [min]="processHeaderDate" [required]="form.value.cdEmploymentStatus == 'cd_employment_status_fixed'" [readonly]="readonly">
            <mat-datepicker-toggle [ngClass]="{'readonly-block': readonly}" matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
    </div>

    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.working_frequency</mat-label>
            <mtx-select [searchable]="true" formControlName="cdWorkingFrequency" [appendTo]="'body'">
                <mtx-option *ngFor="let workingFrequency of workingFrequencies$ | async" [value]="workingFrequency.id">
                    {{ workingFrequency | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.occupational_rate</mat-label>
            <input type="number" matInput formControlName="levelOfEmployment" [readonly]="readonly">
        </mat-form-field>
    </div>

    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.working_days_per_week</mat-label>
            <input type="number" matInput formControlName="workingDaysPerWeek" [readonly]="readonly">
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.working_hours_per_week</mat-label>
            <input type="number" matInput formControlName="workingHoursPerWeek" required [readonly]="readonly">
        </mat-form-field>
    </div>

    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.working_place</mat-label>
            <input matInput formControlName="usualWorkingPlace" required [readonly]="readonly">
        </mat-form-field>
    </div>

    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.profession</mat-label>
            <input matInput formControlName="profession" required>
        </mat-form-field>
    </div>

</form>
