import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first, Observable, takeUntil } from 'rxjs';
import { DocumentRefForm, PdfformService, PrefillRequest, ProcessHeaderForm, ProcessHeaderPartnerForm, ProcessheaderService, WizardhelperService } from 'src/app/core';
import { EntityEnum } from 'src/app/core/entity-enum';
import { AlertService } from 'src/app/core/service/alert.service';
import { BlockService } from 'src/app/core/service/block.service';
import * as _ from 'underscore';
import { BaseWizardComponent } from '../../base/base-wizard/base-wizard.component';
import { Editor } from '../../editors/editor';
import { buildFormData, mapFormData } from '../../pdfform-util';
import { extractInsuredPartnerId } from '../../sunet-util';

@Component({
  selector: 'app-generic-pdf-wizard',
  templateUrl: './generic-pdf-wizard.component.html',
  styleUrls: ['./generic-pdf-wizard.component.scss']
})
export class GenericPdfWizardComponent extends BaseWizardComponent implements OnInit, Editor {

  entityEnum = EntityEnum.PdfReport;

  @Input() id: number;
  @Input() object: ProcessHeaderForm;

  processHeaderLite: ProcessHeaderForm;
  processHeader: ProcessHeaderForm;
  partners: ProcessHeaderPartnerForm[];
  documents: DocumentRefForm[];

  processHeaderValid = false;
  phPartnersValid = false;

  formData = {};

  insuredPartnerId: number;
  employerPartnerId: number;

  constructor(
    private processHeaderService: ProcessheaderService,
    private pdfFormService: PdfformService,
    private wizardHelper: WizardhelperService,
    private translateService: TranslateService,
    alertService: AlertService,
    blockService: BlockService
  ) {
    super(blockService, alertService);
  }

  ngOnInit(): void {
    this.newEntity = !this.id;

    if (this.object) {
      this.processHeader = this.object;
      this.processHeaderLite = this.object;
    }
    this.loadData();
  }

  loadData() {
    if (!this.id) {
      this.partners = this.processHeader?.partners || [];
      this.documents = [];
      this.formData = {};

      if (this.partners.length > 0) {
        this.processPartners();
      }

      return;
    }

    this.processHeaderService.findByIdProcessHeader(this.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: ProcessHeaderForm) => {
        this.object = response;
        this.processHeaderLite = response;
        this.processHeader = response;
        this.partners = response.partners;
        this.documents = response.documents;
        this.formData = buildFormData(response.keyValues);

        this.processPartners();
        this.evaluateWizardPagesCompleted();
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  processPartners() {
    this.processInsuredPartner();
  }

  processInsuredPartner() {
    this.insuredPartnerId = extractInsuredPartnerId(this.partners);
  }

  loadInsured() {
    const prefillRequest = {
      currentValues: this.formData
    } as PrefillRequest;
    this.wizardHelper.loadInsured(this.insuredPartnerId, prefillRequest).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: any) => {
        console.log('formData', response);
        //const insuredData = buildFormDataFromMap(response);
        this.formData = response;
        console.log('formData (new)', this.formData);
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  protected onSubmit(): Observable<any> {
    const modifiedPh = _.extend(this.object, this.processHeader);
    _.extend(modifiedPh, this.processHeaderLite);
    modifiedPh.keyValues = mapFormData(this.formData, this.processHeader.keyValues || []);
    modifiedPh.partners = this.partners;
    modifiedPh.documents = this.documents;
    modifiedPh.items = [];

    // tslint:disable-next-line:max-line-length
    const save$ = !modifiedPh.id ? this.processHeaderService.createProcessHeader(modifiedPh) : this.processHeaderService.updateProcessHeader(modifiedPh);
    return save$;
  }
}

