import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CasePolicyHeaderForm } from 'src/app/core';
import { mapToPolicyItemType } from 'src/app/shared/policy-util';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-case-policy-form',
  templateUrl: './case-policy-form.component.html',
  styleUrls: ['./case-policy-form.component.css']
})
export class CasePolicyFormComponent extends BaseFormComponent implements OnChanges {

  @Input() casePolicyHeader: CasePolicyHeaderForm;
  @Input() typeObject: string;
  @Output() casePolicyHeaderValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() casePolicyHeaderChange: EventEmitter<CasePolicyHeaderForm> = new EventEmitter<CasePolicyHeaderForm>();

  casePolicyHeaderEdit: CasePolicyHeaderForm = {};
  partnerId: number;

  policyItemType: string;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.casePolicyHeader)) {
      this.casePolicyHeaderEdit = changes.casePolicyHeader.currentValue;
      this.policyItemType = mapToPolicyItemType(this.casePolicyHeaderEdit.policyHeaderType);
    }
  }

  onChange(): void {
    const valid = this.casePolicyHeaderEdit.policyHeaderId != null;
    if (valid) {
      this.casePolicyHeaderChange.emit(this.casePolicyHeaderEdit);
    }
    this.casePolicyHeaderValid.emit(valid);
    console.log('changed', this.casePolicyHeaderEdit.policyHeaderId);
  }
}

