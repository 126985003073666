<clr-datagrid [(clrDgSingleSelected)]="selected" [clrDgRowSelection]="true">
    <clr-dg-column>{{ 'field.type' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.name' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.created_date' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.receipt_date' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.process' | translate }}</clr-dg-column>

    <clr-dg-row *clrDgItems="let document of documents" [clrDgItem]="document">
        <clr-dg-cell>{{ document.type | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell>{{ document.name }}</clr-dg-cell>
        <clr-dg-cell>{{ document?.createdDate | date:'dd.MM.yyyy HH:mm' }}</clr-dg-cell>
        <clr-dg-cell>{{ document?.receiptDate | date:'dd.MM.yyyy' }}</clr-dg-cell>
        <clr-dg-cell><a *ngIf="document.processHeaderId" [routerLink]="['/processes/detail', document.processHeaderId]">{{ document.processHeaderReference?document.processHeaderReference: '-' }}</a></clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>
<button [disabled]="!selected" (click)="onDownload()" class="btn btn-sm">
    <clr-icon shape="download"></clr-icon> {{ 'button.download' | translate }}
</button>
<button [disabled]="!selected?.mimeType.endsWith('pdf')" (click)="openViewer()" class="btn btn-sm">
    <clr-icon shape="eye"></clr-icon> {{ 'button.view' | translate }}
</button>