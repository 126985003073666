import { HttpBackend, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { MsalBroadcastService, MsalGuard, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { LogLevel } from 'msal';
import { EnvConfigService } from './core/service/env-config.service';

export function initializerFactory(httpBackend: HttpBackend, envConfigService: EnvConfigService) {
  const promise = envConfigService.init().then((value) => {
    console.log('msal', envConfigService.msal);
  });
  return () => promise;
}

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log('MSAL', message);
}

export function MSALInstanceFactory(envConfigService: EnvConfigService): IPublicClientApplication {
  console.log('called MSALInstanceFactory');
  return new PublicClientApplication({
    auth: envConfigService.msal,
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Verbose,
        piiLoggingEnabled: true
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);
  //protectedResourceMap.set('api/*', []);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
  providers: [
  ],
  imports: [
    MsalModule
  ],
})
export class MsalAppModule {
  static forRoot() {
    return {
      ngModule: MsalAppModule,
      providers: [
        EnvConfigService,
        { provide: APP_INITIALIZER, useFactory: initializerFactory, deps: [HttpBackend, EnvConfigService], multi: true },
        { provide: MSAL_INSTANCE, useFactory: MSALInstanceFactory, deps: [EnvConfigService] },
        { provide: MSAL_GUARD_CONFIG, useFactory: MSALGuardConfigFactory },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MSALInterceptorConfigFactory },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
      ]
    };
  }
 }
