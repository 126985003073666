<clr-datagrid>
    <clr-dg-column [clrDgSortBy]="'type'">{{ 'field.type' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'title'">{{ 'field.title' | translate }}
    </clr-dg-column>
    <clr-dg-column *ngIf="policyType == 'type_policyh_claim'">{{ 'field.amount_net' | translate }}
    </clr-dg-column>
    <clr-dg-column *ngIf="policyType != 'type_policyh_claim'">{{ 'field.salary_range' | translate }}
    </clr-dg-column>
    <clr-dg-column *ngIf="policyType != 'type_policyh_claim'">{{ 'field.cover_rate' | translate }}
    </clr-dg-column>
    <clr-dg-column *ngIf="policyType != 'type_policyh_claim'">{{ 'field.min_degree_of_incapicity' | translate }}
    </clr-dg-column>
    <clr-dg-column *ngIf="policyType != 'type_policyh_claim'">{{ 'field.waiting_period' | translate }}
    </clr-dg-column>
    <clr-dg-column *ngIf="policyType != 'type_policyh_claim'">{{ 'field.employe_group' | translate }}
    </clr-dg-column>

    <clr-dg-row *ngFor="let obj of policyItems" [clrDgItem]="obj">
        <clr-dg-cell>{{ obj.type | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell>{{ obj.title }}</clr-dg-cell>
        <clr-dg-cell *ngIf="policyType == 'type_policyh_claim'">{{ obj.amountNet }}</clr-dg-cell>
        <clr-dg-cell *ngIf="policyType != 'type_policyh_claim'">{{ obj.minSalary }} - {{ obj.maxSalary }}</clr-dg-cell>
        <clr-dg-cell *ngIf="policyType != 'type_policyh_claim'">{{ obj.coverRate }}</clr-dg-cell>
        <clr-dg-cell *ngIf="policyType != 'type_policyh_claim'">{{ obj.minDegreeOfIncapicity }}</clr-dg-cell>
        <clr-dg-cell *ngIf="policyType != 'type_policyh_claim'">{{ obj.waitingPeriod }}</clr-dg-cell>
        <clr-dg-cell *ngIf="policyType != 'type_policyh_claim'">{{ obj.cdEmployeeGroup | lookupApplCode | async }}</clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>