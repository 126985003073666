import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ClarityModule } from '@clr/angular';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../shared/shared.module';
import { ApiModule } from './api.module';
import { CreateAllowedDirective } from './directives/create-allowed.directive';
import { DeleteAllowedDirective } from './directives/delete-allowed.directive';
import { EditAllowedDirective } from './directives/edit-allowed.directive';
import { ShowWithCdFunctionalityDirective } from './directives/show-with-cd-functionality.directive';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { LookupApplCodePipe } from './pipe/lookup-appl-code.pipe';
import { LookupApplTypePipe } from './pipe/lookup-appl-type.pipe';
import { LookupPartnerPipe } from './pipe/lookup-partner.pipe';
import { LookupRelationshipTypePipe } from './pipe/lookup-relationship-type.pipe';
import { AlertService } from './service/alert.service';
import { AppCodeService } from './service/app-code.service';
import { AppPartnerService } from './service/app-partner.service';
import { AppTypeService } from './service/app-type.service';
import { AuthenticationService } from './service/authentication.service';
import { AuthorizationService } from './service/authorization.service';
import { BlockService } from './service/block.service';
import { DynamicComponentService } from './service/dynamic-component.service';
import { FileService } from './service/file.service';
import { LanguageService } from './service/language.service';
import { PartnerUpdateService } from './service/partner-update.service';
import { PushService } from './service/push.service';
import { RelationshipTypeService } from './service/relationship-type.service';
import { SearchParameterService } from './service/search-parameter.service';
import { SunetPreloadService } from './service/sunet-preload-service';
import { ValidationHelperService } from './service/validation-helper.service';
import { SideNavigationComponent } from './side-navigation/side-navigation.component';
import { TopNavigationComponent } from './top-navigation/top-navigation.component';
import { BASE_PATH } from './variables';

@NgModule({
  declarations: [
    TopNavigationComponent,
    SideNavigationComponent
  ],
  imports: [
    CommonModule,
    ClarityModule,
    RouterModule,
    ApiModule,
    SharedModule,
  ],
  exports: [
    TopNavigationComponent,
    SideNavigationComponent
  ],
  providers: [
    { provide: BASE_PATH, useValue: environment.API_BASE_PATH },
    AlertService,
    AppCodeService,
    AppTypeService,
    AppPartnerService,
    AuthenticationService,
    AuthorizationService,
    LanguageService,
    LookupApplCodePipe,
    LookupApplTypePipe,
    LookupPartnerPipe,
    LookupRelationshipTypePipe,
    ShowWithCdFunctionalityDirective,
    CreateAllowedDirective,
    EditAllowedDirective,
    DeleteAllowedDirective,
    PartnerUpdateService,
    RelationshipTypeService,
    SunetPreloadService,
    FileService,
    DynamicComponentService,
    BlockService,
    PushService,
    ValidationHelperService,
    SearchParameterService
  ]
})

export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
