import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ProcessHeaderProcessItemForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';



@Component({
  selector: 'app-process-item-editor',
  templateUrl: './process-item-editor.component.html',
  styleUrls: ['./process-item-editor.component.css']
})
export class ProcessItemEditorComponent extends BaseEditorComponent<ProcessHeaderProcessItemForm> implements OnInit, OnChanges {

  @Input() processItems: ProcessHeaderProcessItemForm[];
  @Input() typeLeft: string;
  @Output() processItemsChange: EventEmitter<ProcessHeaderProcessItemForm[]> = new EventEmitter<ProcessHeaderProcessItemForm[]>();

  tableName = 'processi';

  constructor(
    protected reltypeService: RelationshipTypeService
  ) {
    super(reltypeService);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.processItems)
      && !this.wrappedItems) {
      this.processProcessItems();
    }
    if (this.hasChanged(changes.typeLeft)) {
      this.loadRelationships();
    }
  }

  protected buildFilter() {
    return { typeLeft: this.typeLeft };
  }

  protected processProcessItems() {
    const wrappers = [];

    this.processItems.forEach((processI) => {
      wrappers.push({
        item: processI,
        valid: true,
        open: false,
        sort: 0
      } as ItemWrapper<ProcessHeaderProcessItemForm>);
    });

    wrappers.sort((e1, e2) => e1.sort - e2.sort);
    this.wrappedItems = wrappers;
  }

  onAdd() {
    this.wrappedItems.push({
      item: {
        type: this.selectedRelType.typeRight
      } as ProcessHeaderProcessItemForm,
      valid: false,
      open: true,
      sort: this.selectedRelType?.sort
    } as ItemWrapper<ProcessHeaderProcessItemForm>);

    this.emitValid(false);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);

    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    console.log('onChange', index, valid);
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.processItemsChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.emitValid(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => item.valid === false).length === 0);
    return result;
  }
}
