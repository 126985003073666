import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AppTypeService } from '../service/app-type.service';
import { LanguageService } from '../service/language.service';

@Pipe({
  name: 'lookupApplType'
})

@Injectable()
export class LookupApplTypePipe implements PipeTransform {

  constructor(
    private typeService: AppTypeService,
    private languageService: LanguageService
  ) {
  }

  transform(id: string): Observable<string> {
    if (!id) {
      return of('');
    }

    return this.typeService.getById(id).pipe(
      take(1),
      map((applType) => {
        let name = id;
        console.log('type', applType);
        if (applType != null) {
          name = this.languageService.getLanguageSpecifcValueFromApplText(applType.applText, 'textLong');
        }
        return name;
      })
    );
  }
}
