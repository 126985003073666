import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-object-list-widget',
  templateUrl: './object-list-widget.component.html',
  styleUrls: ['./object-list-widget.component.css']
})
export class ObjectListWidgetComponent {

  @Input()
  objects = [];

  constructor() { }

}
