<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <clr-dg-column [clrDgSortBy]="'referenceNumber'">{{ 'field.reference_nr' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'referenceNumber2'">{{ 'field.reference_nr2' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'applType.sort'">{{ 'field.type' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'applSubType.sort'">{{ 'field.sub_type' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.title' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.entry_date' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'codeStatus.sort'">{{ 'field.status' | translate }}
    </clr-dg-column>

    <clr-dg-row *ngFor="let case of pagedItems?.content" [clrDgItem]="case">
        <clr-dg-cell><a [routerLink]="['/cases/detail', case.id]">{{ case.referenceNumber?case.referenceNumber: '-' }}</a>
        </clr-dg-cell>
        <clr-dg-cell><a [routerLink]="['/cases/detail', case.id]">{{ case.referenceNumber2 }}</a>
        </clr-dg-cell>
        <clr-dg-cell>{{ case.type | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell>{{ case.subType | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell>{{ case.title }}</clr-dg-cell>
        <clr-dg-cell>{{ case.entryDate }}</clr-dg-cell>
        <clr-dg-cell>{{ case.cdStatus | lookupApplCode | async }}</clr-dg-cell>
    </clr-dg-row>
    <clr-dg-footer>
        <clr-dg-pagination #pagination [(clrDgPageSize)]="criteria.pageSize" [clrDgLastPage]="pagedItems.totalPages"
            [clrDgTotalItems]="pagedItems.totalElements">
            <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">{{ 'message.rows_per_page' | translate }}
            </clr-dg-page-size>
            {{ 'message.total_items' | translate: {begin: pagination.firstItem + 1, end: pagination.lastItem + 1, total: pagination.totalItems} }}
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>
