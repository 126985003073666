/**
 * myclaims API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface ProcessHeaderView { 
    caseId?: number;
    tax?: number;
    title?: string;
    description?: string;
    reference?: string;
    createdDate?: string;
    reference2?: string;
    amountGross?: number;
    processHeaderDate?: string;
    entryDate?: string;
    cdStatus?: string;
    caseUuid?: string;
    amountNet?: number;
    insuredName?: string;
    insuredPartnerId?: number;
    caseType?: string;
    caseReferenceNumber2?: string;
    caseReferenceNumber?: string;
    caseTitle?: string;
    id?: number;
    type?: string;
    sort?: number;
}

