<form [formGroup]="form" clrForm clrLayout="vertical">
    <div class="clr-row">
        <div class="clr-col-md-12 field">
            <label id="with-other-employers-label" translate>field.with_other_employers</label>
            <mat-radio-group aria-labelledby="with-other-employers-label" formControlName="withOtherEmployers">
                <mat-radio-button [value]="false">{{ 'button.no' | translate }}</mat-radio-button>
                <mat-radio-button [value]="true">{{ 'button.yes' | translate }}</mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
    <h4 translate>section.other_employer_1</h4>
    <app-partner-form [partner]="form.get('firstEmployer')"></app-partner-form>
    <h4 translate>section.other_employer_2</h4>
    <app-partner-form [partner]="form.get('secondEmployer')"></app-partner-form>
</form>