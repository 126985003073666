
<mat-form-field class="clr-col-md-6" *ngIf="(!parameterType?.parameterDataType && !parameterType?.cdParameter )|| parameterType?.parameterDataType == 'string'">
    <mat-label>{{ parameter.type | lookupApplType | async }}</mat-label>
    <input matInput [(ngModel)]="parameterEdit.value" [disabled]="!editAllowed" [required]="fieldRequired" (change)="onChage()">
</mat-form-field>
<mat-form-field class="clr-col-md-6" *ngIf="parameterType?.parameterDataType == 'numeric'">
    <mat-label>{{ parameter.type | lookupApplType | async }}</mat-label>
    <input matInput type="number" [(ngModel)]="parameterEdit.value" [disabled]="!editAllowed" [required]="fieldRequired" (change)="onChage()">
</mat-form-field>
<mat-form-field class="clr-col-md-6" *ngIf="parameterType?.parameterDataType == 'date'">
    <mat-label>{{ parameter.type | lookupApplType | async }}</mat-label>
    <input matInput [matDatepicker]="picker1" [(ngModel)]="parameterEdit.value" [disabled]="!editAllowed" [required]="this.fieldRequired">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
</mat-form-field>
<mat-form-field class="clr-col-md-6" *ngIf="parameterType?.cdParameter">
    <mat-label>{{ parameter.type | lookupApplType | async }}</mat-label>
    <mtx-select [searchable]="true" [(ngModel)]="parameterEdit.cdValue" [disabled]="!editAllowed" [required]="this.fieldRequired" (change)="onChage()">
        <mtx-option *ngFor="let cdItem of cdItem$ | async" [value]="cdItem.id">
            {{ cdItem | language:'name' }}</mtx-option>
    </mtx-select>
</mat-form-field>
