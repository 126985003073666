import { Component, OnInit } from '@angular/core';
import { map, Observable, Subject } from 'rxjs';
import { AppTypeService } from 'src/app/core/service/app-type.service';
import { AuthorizationService } from 'src/app/core/service/authorization.service';

@Component({
  selector: 'app-case-type-dialog',
  templateUrl: './case-type-dialog.component.html',
  styleUrls: ['./case-type-dialog.component.css']
})
export class CaseTypeDialogComponent implements OnInit {

  show = true;
  type: any;
  afterClosed = new Subject<any>();

  caseTypes$: Observable<any[]>;

  constructor(
    private typeService: AppTypeService,
    protected authorizationService: AuthorizationService,
  ) { }

  ngOnInit(): void {
    this.caseTypes$ = this.typeService.getList('applcase', false).pipe(
      map( items => items.filter(item => this.authorizationService.isCreateAllowed(item.id)))
    );
  }

  onCancel() {
    console.log('onCancel');
    this.close();
    this.afterClosed.next({
      success: false
    });
  }

  onSuccess() {
    this.close();
    this.afterClosed.next({
      success: true,
      type: this.type
    });
  }

  private close() {
    this.show = false;
  }

}
