import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { ApplObjectForm, ObjectPartnerForm, ObjectService, ParameterForm, PolicyHeaderObjectForm, PostalAddressForm } from 'src/app/core';
import { EntityEnum } from 'src/app/core/entity-enum';
import { AlertService } from 'src/app/core/service/alert.service';
import { BlockService } from 'src/app/core/service/block.service';
import * as _ from 'underscore';
import { BaseWizardComponent } from '../../base/base-wizard/base-wizard.component';
import { Editor } from '../../editors/editor';



@Component({
  selector: 'app-object-wizard',
  templateUrl: './object-wizard.component.html',
  styleUrls: ['./object-wizard.component.css']
})
export class ObjectWizardComponent extends BaseWizardComponent implements OnInit, Editor {

  entityEnum = EntityEnum.Object;

  @Input() id: number;
  @Input() object: ApplObjectForm;

  applObject: ApplObjectForm;

  parameters: ParameterForm[];
  postalAddresses: PostalAddressForm[];
  objectPartners: ObjectPartnerForm[];
  policyHeaderObjects: PolicyHeaderObjectForm[];

  applObjectValid = true;
  parametersValid = true;
  addressesValid = true;
  objectPartnersValid = true;
  policiesValid = true;
  documentValid = true;

  hideParameterStep = false;
  hideAddressStep = false;
  hidePartnerStep = false;
  hidePolicyStep = false;
  hideDocumentStep = false;

  constructor(
    private applObjectService: ObjectService,
    private translateService: TranslateService,
    alertService: AlertService,
    blockService: BlockService
  ) {
    super(blockService, alertService);
  }

  ngOnInit(): void {
    this.newEntity = !this.id;
    
    this.loadData();
  }

  loadData() {
    if (!this.id) {
      // tslint:disable-next-line:no-angle-bracket-type-assertion
      if (!this.object) {
        this.object = {} as ApplObjectForm;
        this.applObject = {} as ApplObjectForm;
      } else {
        this.applObject = this.object;
      }
      this.parameters = [];
      this.postalAddresses = [];
      this.objectPartners = [];
      this.policyHeaderObjects = [];
      return;
    }
    this.applObjectService.findByIdApplObject(this.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: ApplObjectForm) => {
        this.object = response;
        this.applObject = response;
        this.parameters = response.parameters;
        this.postalAddresses = response.postalAddresses;
        this.objectPartners = response.partners;
        this.policyHeaderObjects = response.policyHeaders;
        this.evaluateWizardPagesCompleted();
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  protected onSubmit(): Observable<any> {
    const modifiedObject = _.extendOwn(this.object, this.applObject);
    modifiedObject.postalAddresses = this.postalAddresses;
    console.log('parameters:', this.parameters);
    modifiedObject.parameters = this.parameters;
    modifiedObject.partners = this.objectPartners;
    modifiedObject.policyHeaders = this.policyHeaderObjects;
    // tslint:disable-next-line:max-line-length
    const save$ = !modifiedObject.id ? this.applObjectService.createApplObject(modifiedObject) : this.applObjectService.updateApplObject(modifiedObject);
    return save$;
  }
}
