import { Injectable } from '@angular/core';
import { ApplCaseSearchCriteria, ApplObjectSearchCriteria, OrganisationSearchCriteria, PersonSearchCriteria, ProcessHeaderSearchCriteria } from '..';

@Injectable({
  providedIn: 'root'
})
export class SearchParameterService {

  applCaseSearchCriteria: ApplCaseSearchCriteria;
  processHeaderSearchCriteria: ProcessHeaderSearchCriteria;
  absenceSearchCriteria: ProcessHeaderSearchCriteria;
  applObjectSearchCriteria: ApplObjectSearchCriteria;
  personSearchCriteria: PersonSearchCriteria;
  organisationSearchCriteria: OrganisationSearchCriteria;

  constructor() { }

  getApplCaseSearchCriteria(): ApplCaseSearchCriteria {
    return this.applCaseSearchCriteria;
  }

  setApplCaseSearchCriteria(value: ApplCaseSearchCriteria) {
    this.applCaseSearchCriteria = value;
  }

  getProcessHeaderSearchCriteria(): ProcessHeaderSearchCriteria {
    return this.processHeaderSearchCriteria;
  }

  setProcessHeaderSearchCriteria(value: ProcessHeaderSearchCriteria) {
    this.processHeaderSearchCriteria = value;
  }

  getAbsenceSearchCriteria(): ProcessHeaderSearchCriteria {
    return this.absenceSearchCriteria;
  }

  setAbsenceSearchCriteria(value: ProcessHeaderSearchCriteria) {
    this.absenceSearchCriteria = value;
  }

  getApplObjectSearchCriteria(): ApplObjectSearchCriteria {
    return this.applObjectSearchCriteria;
  }

  setApplObjectSearchCriteria(value: ApplCaseSearchCriteria) {
    this.applObjectSearchCriteria = value;
  }

  getPersonSearchCriteria(): PersonSearchCriteria {
    return this.personSearchCriteria;
  }

  setPersonSearchCriteria(value: PersonSearchCriteria) {
    this.personSearchCriteria = value;
  }

  getOrginsationSearchCriteria(): OrganisationSearchCriteria {
    return this.organisationSearchCriteria;
  }

  setOrginsationSearchCriteria(value: OrganisationSearchCriteria) {
    this.organisationSearchCriteria = value;
  }
}
