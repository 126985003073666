<clr-datagrid>
    <clr-dg-column [clrDgSortBy]="'cdAction'">{{ 'field.action' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'txt' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.status' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.type' | translate }}
    </clr-dg-column>

    <clr-dg-row *clrDgItems="let task of tasks" [clrDgItem]="task">
        <clr-dg-cell>{{ task.cdAction | lookupApplCode | async }}</clr-dg-cell>
        <clr-dg-cell>{{ task.applText | languageApplText }}</clr-dg-cell>
        <clr-dg-cell>{{ task.cdStatus | lookupApplCode | async }}</clr-dg-cell>
        <clr-dg-cell>{{ task.type }}</clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>