<div clrLayout="vertical">
    <div class="clr-row" *ngIf="relTypes?.length === 0">
        <div class="clr-col-md-12">
            {{ 'message.no_objects_available' | translate }}
        </div>
    </div>

    <div class="clr-row" *ngIf="relTypes?.length > 0">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.relationship</mat-label>
            <mtx-select [searchable]="true" [(ngModel)]="selectedRelType" [multiple]="false">
                <mtx-option *ngFor="let relType of filteredRelTypes" [value]="relType">
                    {{ relType.applText | languageApplText: 'ltrTextLong' }} ({{ relType.typeRight | lookupApplType | async }})</mtx-option>
            </mtx-select>
        </mat-form-field>
        <div class="clr-col-md-2">
            <button class="btn btn-sm" (click)="onAdd()" [disabled]="!selectedRelType || !editorValid">{{ translations['button.add_relationship'] }}</button>
        </div>
    </div>
    <div class="clr-row" *ngFor="let wrappedItem of wrappedItems, index as i">
        <div class="clr-col-md-11">
            <app-case-object-form [typeObject]="wrappedItem.relType?.typeRight" [(caseObject)]="wrappedItem.item" (caseObjectValid)="onChange(i, $event)">
            </app-case-object-form>
        </div>
        <div class="clr-col-md-1">
            <span *appDeleteAllowed="wrappedItem?.item?.relationshipType;immutable:wrappedItem.required" (click)="onDelete(i)">
                <clr-icon shape="trash"></clr-icon>
            </span>
        </div>
    </div>
</div>
