    <clr-datagrid class="clr-col-12" (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
        <clr-dg-column [clrDgSortBy]="'reference'">{{ 'field.reference' | translate }}
        </clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'title'">{{ 'field.title' | translate }}
        </clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'applType.sort'">{{ 'field.type' | translate }}
        </clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'entryDate'">{{ 'field.entry_date' | translate }}
        </clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'processHeaderDate'">{{ 'field.event_emission_date' | translate }}
        </clr-dg-column>
        <clr-dg-column [clrDgSortBy]="'codeStatus.sort'">{{ 'field.status' | translate }}
        </clr-dg-column>
        <clr-dg-column>{{ 'field.case' | translate }}
        </clr-dg-column>

        <clr-dg-row *ngFor="let ph of pageProcessHeaders.content" [clrDgItem]="ph">
            <clr-dg-cell><a [routerLink]="['/processes/detail', ph.id]">{{ ph.reference? ph.reference:'-' }}</a></clr-dg-cell>
            <clr-dg-cell>{{ ph.title }}</clr-dg-cell>
            <clr-dg-cell>{{ ph.type | lookupApplType | async }}</clr-dg-cell>
            <clr-dg-cell>{{ ph.entryDate | date:'dd.MM.yyyy' }}</clr-dg-cell>
            <clr-dg-cell>{{ ph.processHeaderDate | date:'dd.MM.yyyy' }}</clr-dg-cell>
            <clr-dg-cell>{{ ph.cdStatus | lookupApplCode | async }}</clr-dg-cell>
            <clr-dg-cell><a [routerLink]="['/cases/detail', ph.caseId]">{{ ph.caseReferenceNumber }} ({{ ph.caseType | lookupApplType | async }})</a></clr-dg-cell>
        </clr-dg-row>

        <clr-dg-footer>
            <clr-dg-pagination #pagination [(clrDgPageSize)]="criteria.pageSize" [clrDgLastPage]="pageProcessHeaders.totalPages"
                [clrDgTotalItems]="pageProcessHeaders.totalElements">
                <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">{{ 'message.rows_per_page' | translate }}
                </clr-dg-page-size>
                {{ 'message.total_items' | translate: {begin: pagination.firstItem + 1, end: pagination.lastItem + 1, total: pagination.totalItems} }}
            </clr-dg-pagination>
        </clr-dg-footer>
    </clr-datagrid>