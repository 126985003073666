import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { WorkflowService } from 'src/app/core';
import { AlertService } from 'src/app/core/service/alert.service';
import { BlockService } from 'src/app/core/service/block.service';
import { BaseWizardComponent } from '../../base/base-wizard/base-wizard.component';
import { Editor } from '../../editors/editor';

@Component({
  selector: 'app-fill-claim-nr-wizard',
  templateUrl: './fill-claim-nr-wizard.component.html',
  styleUrls: ['./fill-claim-nr-wizard.component.scss']
})
export class FillClaimNrWizardComponent extends BaseWizardComponent implements OnInit, Editor {

  entityEnum = null;

  @Input() id: number;
  @Input() object: any;

  claimNumber = '';

  constructor(
    private workflowService: WorkflowService,
    alertService: AlertService,
    blockService: BlockService
  ) {
    super(blockService, alertService);
  }

  ngOnInit(): void {
  }

  protected onSubmit(): Observable<any> {
    const fillClaimNumber$ = this.workflowService.fillClaimNr(this.id, this.claimNumber);

    return fillClaimNumber$;
  }
}
