<form clrForm [formGroup]="partner">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.name</mat-label>
            <input matInput formControlName="name">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.street</mat-label>
            <input matInput formControlName="street">
        </mat-form-field>
        <mat-form-field class="clr-col-md-2">
            <mat-label translate>field.post_code</mat-label>
            <input matInput formControlName="postCode">
        </mat-form-field>
        <mat-form-field class="clr-col-md-4">
            <mat-label translate>field.city</mat-label>
            <input matInput formControlName="city">
        </mat-form-field>
    </div>
</form>