import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { first, takeUntil } from 'rxjs/operators';
import { DocumentService, PdfformService } from 'src/app/core';
import { EntityEnum } from 'src/app/core/entity-enum';
import { BlockService } from 'src/app/core/service/block.service';
import { BaseWizardComponent } from '../base/base-wizard/base-wizard.component';
import { Editor } from '../editors/editor';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.css']
})
export class PdfViewerComponent extends BaseWizardComponent implements OnInit, OnChanges, Editor {

  entityEnum = EntityEnum.PdfReport;

  @Input() id: number;

  object: any;
  data: Blob;

  page = 1;
  totalPages: number;

  constructor(
    private documentService: DocumentService,
    private pdfFormService: PdfformService,
    blockService: BlockService,
  ) {
    super(blockService);
  }
  ngOnInit(): void {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.id)) {
      this.load();
    }
  }

  load() {
    let task$ = this.documentService.download(this.id, 'body', true, { httpHeaderAccept: "*/*" });
    if ( this.object
        && this.object?.preview) {
      task$ = this.pdfFormService.preview(this.id, 'body', true, { httpHeaderAccept: "*/*" });
    }
    task$.pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: Blob) => {
        console.log('success download', response);
        this.data = response;
      },
      (error) => {
        console.log('error download', error);
      }
    );
  }
}
