<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.street</mat-label>
            <input matInput formControlName="street" required [readonly]="readonly">
            <mat-error *ngIf="form.get('street')?.hasError('maxlength')" translate
                [translateParams]="{ value1: form.get('street')?.errors?.maxlength.actualLength - form.get('street')?.errors?.maxlength.requiredLength}">
                error.value_to_long
            </mat-error>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.name</mat-label>
            <input matInput formControlName="name" required [readonly]="readonly">
            <mat-error *ngIf="form.get('name')?.hasError('maxlength')" translate
                [translateParams]="{ value1: form.get('name')?.errors?.maxlength.actualLength - form.get('name')?.errors?.maxlength.requiredLength}">
                error.value_to_long
            </mat-error>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-5">
            <mat-label translate>field.post_code</mat-label>
            <input matInput type="number" formControlName="postCode" required [readonly]="readonly">
        </mat-form-field>
        <mat-form-field class="clr-col-md-7">
            <mat-label translate>field.city</mat-label>
            <input matInput formControlName="city" required [readonly]="readonly">
            <mat-error *ngIf="form.get('city')?.hasError('maxlength')" translate
                [translateParams]="{ value1: form.get('city')?.errors?.maxlength.actualLength - form.get('city')?.errors?.maxlength.requiredLength}">
                error.value_to_long
            </mat-error>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.phone</mat-label>
            <input matInput formControlName="phone" maxlength="15" [readonly]="readonly">
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.fax</mat-label>
            <input matInput formControlName="fax" maxlength="15" [readonly]="readonly">
        </mat-form-field>
    </div>
</form>