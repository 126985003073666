<clr-modal [(clrModalOpen)]="show">
    <h3 class="modal-title" translate>title.select_object_type</h3>
    <div class="modal-body">
        <mat-form-field class="clr-row clr-col-12">
            <mat-label translate>field.type</mat-label>
                <mtx-select [searchable]="true" required [(ngModel)]="type" [appendTo]="'body'">
                <mtx-option *ngFor="let objectType of objectTypes$ | async" [value]="objectType.id">
                    {{ objectType | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="onCancel()" translate>button.cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="!type" (click)="onSuccess()" translate>button.next</button>
    </div>
</clr-modal>