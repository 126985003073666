import { PartnerWithPhoneForm, TreatmentForm } from 'src/app/core';
import { isBlank } from './string-util';

export function partnerWithPhoneFormToNullIfEmpty(partnerForm: PartnerWithPhoneForm): PartnerWithPhoneForm {
    if (!isBlank(partnerForm?.name)
        || !isBlank(partnerForm?.street)
        || !isBlank(partnerForm?.addressLine2)
        || !isBlank(partnerForm?.postCode)
        || !isBlank(partnerForm?.city)
        || !isBlank(partnerForm?.phone)
        || !isBlank(partnerForm?.email)
        || !isBlank(partnerForm?.contactPerson)) {
        return partnerForm;
    }
    return null;
}

export function treatmentFormToNullIfEmpty(treatmentForm: TreatmentForm): TreatmentForm {
    if (!isBlank(treatmentForm?.name)
        || !isBlank(treatmentForm?.street)
        || !isBlank(treatmentForm?.postCode)
        || !isBlank(treatmentForm?.city)
        || !isBlank(treatmentForm?.title)) {
        return treatmentForm;
    }
    return null;
}


