import {
    EmployeeInfo,
    EmploymentInfo,
    OrganisationForm,
    PartnerWithPhoneForm,
    PersonForm,
    PostalAddressForm,
    ProcessHeaderForm,
    ProcessHeaderPartnerForm,
    ProcessHeaderSunetForm,
    SalaryInfo,
    TreatmentForm
} from 'src/app/core';
import { isNotBlank, normalizePhoneNumber } from 'src/app/shared/string-util';
import { AccidentDetailForm } from './sunet/sunet-accident-form/accident-detail-form';

export function extractInsuredPartnerId(partners: ProcessHeaderPartnerForm[]): number {
    return extractPartnerId(partners, 'insured');
}

export function extractEmployerPartnerId(partners: ProcessHeaderPartnerForm[]): number {
    return extractPartnerId(partners, 'org-employer');
}

export function extractPartnerId(partners: ProcessHeaderPartnerForm[], relationshipTypePart: string): number {
    const partner = partners.find(item => item.relationshipType.includes(relationshipTypePart));
    if (partner) {
        return partner.partnerId;
    }
    return null;
}

export function extractOtherEmployersPartnerIds(partners: ProcessHeaderPartnerForm[]): number[] {
    return extractPartnerIds(partners, 'org-other_employer');
}

export function extractPartnerIds(partners: ProcessHeaderPartnerForm[], relationshipTypePart: string): number[] {
    const partnerIds = partners.filter(item => item.relationshipType.includes(relationshipTypePart))
                    .map( item => item.partnerId);
    return partnerIds;
}

export function extractAddress(person: PersonForm, previous: PartnerWithPhoneForm): PartnerWithPhoneForm {
    const address = person.postalAddresses.find(item => item.type.includes('private'));
    const phonePrivate = person.commAddresses.find(item => item.type.includes('phone_private'));
    const addressLines = extractAddressLines(address);

    return {
        id: previous?.id,
        partnerId: person.id,
        street: addressLines[0],
        addressLine2: addressLines[1],
        postCode: address?.postCode,
        city: address?.city,
        cdCountry: address?.cdCountry,
        phone: normalizePhoneNumber(phonePrivate?.commAddress)
    } as PartnerWithPhoneForm;
}

export function extractWorkAddress(org: OrganisationForm): PartnerWithPhoneForm {
    const address = org.postalAddresses.find(item => item.type.includes('standard'));
    const workPrivate = org.commAddresses.find(item => item.type.includes('phone_work'));
    const addressLines = extractAddressLines(address);

    return {
        partnerId: org.id,
        street: addressLines[0],
        addressLine2: addressLines[1],
        postCode: address?.postCode,
        city: address?.city,
        cdCountry: address?.cdCountry,
        phone: normalizePhoneNumber(workPrivate?.commAddress)
    } as PartnerWithPhoneForm;
}

export function extractTreatmentFromPerson(person: PersonForm, treatmentId: number): TreatmentForm {
    const address = person.postalAddresses.find(item => item.type.includes('privat'));
    const addressLines = extractAddressLines(address);

    return {
        id: treatmentId,
        partnerId: person.id,
        name: person.name,
        street: addressLines[0],
        postCode: address?.postCode,
        city: address?.city,
        cdCountry: address?.cdCountry
    } as TreatmentForm;
}

export function extractTreatmentFromOrg(organisation: OrganisationForm, treatmentId: number): TreatmentForm {
    const address = organisation.postalAddresses.find(item => item.type.includes('standard'));
    const addressLines = extractAddressLines(address);

    return {
        id: treatmentId,
        partnerId: organisation.id,
        name: organisation.name,
        street: addressLines[0],
        postCode: address?.postCode,
        city: address?.city,
        cdCountry: address?.cdCountry
    } as TreatmentForm;
}

export function extractEmployeeInfo(person: PersonForm): EmployeeInfo {
    const employeeNo = person.partnerIdentifiers.find(item => item.type.includes('employee'))?.identifier;
    const socialSecurityNo = person.partnerIdentifiers.find(item => item.type.includes('social'))?.identifier;
    return {
        employeeNumber: employeeNo,
        avs: socialSecurityNo,
        lastName: person.name,
        firstName: person.givenName,
        cdGender: person.cdGender,
        cdLanguage: person.cdLanguage,
        cdNationality: person.cdNationality,
        cdMaritalStatus: person.cdMaritalStatus,
        birthdate: person.birthDate,
        numberOfChildren: person.employee?.numberOfChildren
    } as EmployeeInfo;
}

export function passPreviousId(partnerInfo: PartnerWithPhoneForm, previousId: number): PartnerWithPhoneForm {
    partnerInfo.id = previousId;
    return partnerInfo;
}

export function extractEmploymentInfo(person: PersonForm): EmploymentInfo {
    const employment = person?.employee;
    return {
        dateOfEmployment: employment?.dateOfEmployment,
        cdEmploymentPosition: employment?.cdEmploymentPosition,
        workingHoursPerWeek: employment?.workingHoursPerWeek,
        workingDaysPerWeek: employment?.workingDaysPerWeek,
        profession: employment?.profession,
        usualWorkingPlace: employment?.workingPlace,
        cdEmploymentStatus: employment?.cdEmploymentStatus,
        terminationDate: employment?.terminationDate,
        cdWorkingFrequency: 'cd_working_frequency_regular',
        levelOfEmployment: employment?.occupationalRate,
    } as EmploymentInfo;
}


export function extractSalaryInfo(person: PersonForm): SalaryInfo {
    const employment = person?.employee;
    return {
        basicSalary: employment?.basicSalary,
        familyBenefits: employment?.familyBenefits,
        holidayCompensation: employment?.holidayCompensation,
        gratuity: employment?.gratuity,
        otherSupplements: employment?.otherSupplements,
        otherSupplementsDescription: employment?.otherSupplementsDescription,
        cdSpecialCase: null,
        withholdingTax: employment?.withholdingTax,
        cdOtherInsurers: null,
        otherInsurersSpecification: null,
        otherEmployers: null,
    } as SalaryInfo;
}

export function extractAccidentDetail(processHeader: ProcessHeaderForm, processHeaderSunet: ProcessHeaderSunetForm): AccidentDetailForm {
    return {
        subType: processHeader?.subType,
        cdAccidentCategory: processHeaderSunet?.cdAccidentCategory,
        isRelapse: processHeader.reference2 !== null && processHeader.reference2.length > 0,
        relapseDate: processHeaderSunet?.relapseDate,
        accidentDate: processHeader?.processHeaderDate,
        preciseAccidentDate: processHeaderSunet?.preciseAccidentDate,
        location: processHeader?.location,
        sceneOfAccident: processHeaderSunet?.sceneOfAccident,
        description: processHeader?.description,
        involvedPersons: processHeaderSunet?.involvedPersons,
        involvedObjects: processHeaderSunet?.involvedObjects,
        cdPoliceReport: processHeaderSunet?.cdPoliceReport
    } as AccidentDetailForm;
}

export function extractMaternityDetail(processHeaderSunet: ProcessHeaderSunetForm): any {
    return {
        expectedBirthDate: processHeaderSunet?.expectedBirthDate,
        stoppedWorkDate: processHeaderSunet?.incapacityToWork?.stoppedWorkDate
    };
}

export function extractIllnessDetail(processHeader: ProcessHeaderForm, processHeaderSunet: ProcessHeaderSunetForm): any {
    return {
        illnessDate: processHeader?.processHeaderDate,
        illnessKind: processHeaderSunet?.illnessKind,
        cdPredictedDuration: processHeaderSunet?.cdPredictedDuration,
        incapacityToWork: processHeaderSunet?.incapacityToWork,
        alreadyReportedToDisabilityInsurance: processHeaderSunet?.alreadyReportedToDisabilityInsurance,
        monitoringNeeded: processHeaderSunet?.monitoringNeeded,
        dueToAccidents: processHeaderSunet?.dueToAccidents,
    };
}

export function extractInjuries(processHeaderSunet: ProcessHeaderSunetForm): any {
    return {
        injury: processHeaderSunet?.injury,
        injury2: processHeaderSunet?.injury2,
        injury3: processHeaderSunet?.injury3
    };
}

export function extractAdditionsAccident(processHeaderSunet: ProcessHeaderSunetForm): any {
    return {
        professionalAccident: processHeaderSunet?.professionalAccident,
        absenceReason: processHeaderSunet?.absenceReason,
        lastWorkingDate: processHeaderSunet?.lastWorkingDate,
        involvedObjects: processHeaderSunet?.involvedObjects,
        cdActivity: processHeaderSunet?.cdActivity,
    };
}

export function extractIncapacityToWork(processHeaderSunet: ProcessHeaderSunetForm): any {
    return {
        withIncapacityToWork: processHeaderSunet?.withIncapacityToWork,
        moreThan1month: processHeaderSunet?.moreThan1month,
        incapacityToWork: processHeaderSunet?.incapacityToWork,
    };
}

export function mapAccidentDetailToProcessHeader(accidentDetail: AccidentDetailForm): ProcessHeaderForm {
    return {
        subType: accidentDetail?.subType,
        processHeaderDate: accidentDetail?.accidentDate,
        location: accidentDetail?.location,
        description: accidentDetail?.description,
    } as ProcessHeaderForm;
}

export function mapIllnessDetailToProcessHeader(illnessDetail: any): ProcessHeaderForm {
    return {
        processHeaderDate: illnessDetail?.illnessDate,
    } as ProcessHeaderForm;
}

export function mapIllnessDetailToProcessHeaderSunet(illnessDetail: any): ProcessHeaderSunetForm {
    return {
        incapacityToWork: illnessDetail.incapacityToWork,
        illnessKind: illnessDetail.illnessKind,
        cdPredictedDuration: illnessDetail?.cdPredictedDuration,
        alreadyReportedToDisabilityInsurance: illnessDetail.alreadyReportedToDisabilityInsurance,
        monitoringNeeded: illnessDetail.monitoringNeeded,
        dueToAccidents: illnessDetail.dueToAccidents,
    } as ProcessHeaderSunetForm;
}

export function mapIncapacityToWorkToProcessHeaderSunet(incapacityToWork: any): ProcessHeaderSunetForm {
    return {
        withIncapacityToWork: incapacityToWork?.withIncapacityToWork,
        moreThan1month: incapacityToWork?.moreThan1month,
        incapacityToWork: incapacityToWork?.incapacityToWork,
    };
}

export function mapMaternityDetailToProcessHeaderSunet(maternityDetail: any): ProcessHeaderSunetForm {
    return {
        expectedBirthDate: maternityDetail?.expectedBirthDate,
        incapacityToWork: maternityDetail?.stoppedWorkDate ? { stoppedWorkDate: maternityDetail?.stoppedWorkDate } : null
    };
}

export function mapAdditionsAccidentToProcessHeaderSunet(additionsAccident: any): ProcessHeaderSunetForm {
    return {
        professionalAccident: additionsAccident?.professionalAccident,
        absenceReason: additionsAccident?.absenceReason,
        lastWorkingDate: additionsAccident?.lastWorkingDate,
        involvedObjects: additionsAccident?.involvedObjects,
        cdActivity: additionsAccident?.cdActivity,
    };
}

export function extractAddressLines(address: PostalAddressForm): string[] {
    const result = new Array();

    if (isNotBlank(address?.coAddress)) {
        result.push(address.coAddress);
    }
    if (isNotBlank(address?.street)) {
        let line = address.street;
        if (isNotBlank(address.streetNumber)) {
            line = [line, address.streetNumber].join(' ');
        }
        result.push(line);
    }
    if (isNotBlank(address?.poBox)) {
        let line = address.poBox;
        if (isNotBlank(address.poBoxNumber)) {
            line = [line, address.poBoxNumber].join(' ');
        }
        result.push(line);
    }

    if (result.length === 1) {
        result.push('');
    }
    if (result.length === 1) {
        result.push('');
    }

    console.log('addresslines', result);

    return result;
}



