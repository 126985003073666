<mat-form-field class="clr-row clr-col-12">
    <mat-label translate>field.policy</mat-label>
        <mtx-select [items]="result$ | async" bindLabel="search" [trackByFn]="trackByFn" [multiple]="false" [loading]="loading" clearable="false" [(ngModel)]="selectedItem"
        (change)="onChange()" [appendTo]="'body'">
        <ng-template ng-label-tmp let-item="item">
          {{ item.policyNumber }} - {{ item.name }} {{ item.insurerName?'-':''}} {{ item.insurerName }} -<small>{{ item.type | lookupApplType | async }} {{ item.insurerName?'-':''}} {{ item.insurerName }}</small>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            {{ item.policyNumber }} - {{ item.name }} {{ item.insurerName?'-':''}} {{ item.insurerName }} - <small>{{ item.type | lookupApplType | async }} {{ item.insurerName?'-':''}} {{ item.insurerName }}</small>
        </ng-template>
    </mtx-select>
</mat-form-field>
