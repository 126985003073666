import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BaseFormComponent } from 'src/app/shared/base/base-form/base-form.component';

@Component({
  selector: 'app-partner-with-phone-form',
  templateUrl: './partner-with-phone-form.component.html',
  styleUrls: ['./partner-with-phone-form.component.scss']
})
export class PartnerWithPhoneFormComponent extends BaseFormComponent {

  @Input() partner: UntypedFormGroup;

  constructor() {
    super();
   }


}
