import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/base/base/base.component';
import { PartnerService } from '../api/api';
import { PartnerView } from '../model/models';

@Injectable({
  providedIn: 'root'
})
export class AppPartnerService extends BaseComponent {

  private partner$ById = new Map();

  constructor(
    private partnerService: PartnerService
  ) {
    super();
  }

  getById(id: number): Observable<PartnerView> {
    let result$ = this.partner$ById.get(id);
    if (!result$) {
      result$ = this.partnerService.getByIdPartnerView(id).pipe(
        shareReplay()
      );
    }
    return result$;
  }

}

