<clr-wizard #wizardlg clrWizardSize="xl" (clrWizardOnCancel)="onCancel()" (clrWizardOnFinish)="onFinish()" (clrWizardOnNext)="onNext()" [(clrWizardOpen)]="show"
  [clrWizardClosable]="false" [clrWizardPreventDefaultCancel]="true" [clrWizardPreventDefaultNext]="true">
  <clr-wizard-title>
    {{ toTranslateKey(entityEnum) | translate }}
  </clr-wizard-title>

  <clr-wizard-button [type]="'cancel'">{{ 'button.cancel' | translate }}</clr-wizard-button>
  <clr-wizard-button [type]="'previous'">{{ 'button.back' | translate }}</clr-wizard-button>
  <clr-wizard-button [type]="'next'">{{ 'button.next' | translate }}</clr-wizard-button>
  <clr-wizard-button [type]="'finish'">{{ 'button.finish' | translate }}</clr-wizard-button>

  <clr-wizard-page [clrWizardPageNextDisabled]="!processHeaderValid" [clrWizardPageHasError]="!(processHeaderValid && accidentDetailValid)">
    <ng-template clrPageTitle>{{ 'section.detail' | translate }}</ng-template>
    <app-process-header-lite-form [allowSelectCase]="false" [(processHeader)]="processHeaderLite" (processHeaderValid)="processHeaderValid = $event">
    </app-process-header-lite-form>
    <app-sunet-accident-form *ngIf="accidentDetail" [(accidentDetail)]="accidentDetail" (accidentDetailValid)="accidentDetailValid  = $event">
    </app-sunet-accident-form>
  </clr-wizard-page>

  <clr-wizard-page [clrWizardPageHasError]="!additionsAccidentValid">
    <ng-template clrPageTitle>{{ 'section.additions_accident' | translate }}</ng-template>
    <app-sunet-additions-accident-form [(additionsAccident)]="additionsAccident" [accidentDetails]="accidentDetail"
      (additionsAccidentValid)="additionsAccidentValid = $event">
    </app-sunet-additions-accident-form>
  </clr-wizard-page>

  <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!phPartnersValid">
    <ng-template clrPageTitle>{{ 'section.relations_to_partner' | translate }}</ng-template>
    <app-process-header-partner-editor [(processHeaderPartners)]="partners" [typeLeft]="processHeader?.type" (valid)="phPartnersValid = $event"
      (processHeaderPartnersChange)="processPartners()">
    </app-process-header-partner-editor>
    <h4 translate>section.copy_data_from_partner</h4>
    <div class="toolbar">
      <button (click)="loadInsured()" [disabled]="!insuredPartnerId" class="btn btn-sm">
        <clr-icon shape="copy"></clr-icon> {{ 'button.copy_insured_data' | translate }}
      </button>
      <button (click)="loadEmployer()" class="btn btn-sm" [disabled]="!employerPartnerId">
        <clr-icon shape="copy"></clr-icon> {{ 'button.copy_employer_data' | translate }}
      </button>
      <button (click)="loadFirstTreatmentPartner()" class="btn btn-sm" [disabled]="!firstTreatmentPartnerId">
        <clr-icon shape="copy"></clr-icon> {{ 'button.copy_first_treatment_data' | translate }}
      </button>
      <button (click)="loadFollowupTreatmentPartner()" class="btn btn-sm" [disabled]="!secondTreatmentPartnerId">
        <clr-icon shape="copy"></clr-icon> {{ 'button.copy_followup_treatment_data' | translate }}
      </button>
      <button (click)="loadOtherEmployers()" class="btn btn-sm" [disabled]="otherEmployerIds.length == 0">
        <clr-icon shape="copy"></clr-icon> {{ 'button.copy_other_employers' | translate }}
      </button>

    </div>

  </clr-wizard-page>

  <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!(employeeValid && employeeAddressValid)">
    <ng-template clrPageTitle>{{ 'section.employee' | translate }}</ng-template>
    <app-sunet-employee-form [readonly]="true" [(employee)]="employeeInfo" (employeeValid)="employeeValid = $event">
    </app-sunet-employee-form>
    <h4 translate>section.address</h4>
    <app-sunet-address-form [readonly]="true" [(address)]="employeeAddress" (addressValid)="employeeAddressValid = $event">
    </app-sunet-address-form>
  </clr-wizard-page>

  <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!(employerValid && employerAddressValid && agencyValid)">
    <ng-template clrPageTitle>{{ 'section.employer_info' | translate }}</ng-template>
    <app-sunet-employer-form [readonly]="true" [(employer)]="employerAdditionalInfo" (employerValid)="employerValid = $event">
    </app-sunet-employer-form>
    <h4 translate>section.organisation_address</h4>
    <app-sunet-address-form [readonly]="true" [(address)]="employerAddress" (addressValid)="employerAddressValid = $event">
    </app-sunet-address-form>
    <h4 translate>section.agency</h4>
    <app-sunet-agency-form [readonly]="true" [(agency)]="agency" (agencyValid)="agencyValid = $event">
    </app-sunet-agency-form>
  </clr-wizard-page>

  <clr-wizard-page *ngIf="accidentDetail?.subType === 'type_processh_sub_accident_regular_rep'" [clrWizardPageHasError]="!incapacityToWorkValid"
    [clrWizardPageNextDisabled]="false">
    <ng-template clrPageTitle translate>{{ 'section.incapacity_to_work' | translate }}</ng-template>
    <app-sunet-incapacity-work-form [processHeaderDate]="accidentDetail?.accidentDate" [(incapacityWork)]="incapacityToWork"
      (incapacityWorkValid)="incapacityToWorkValid = $event"></app-sunet-incapacity-work-form>
  </clr-wizard-page>

  <clr-wizard-page [clrWizardPageHasError]="!injuriesValid">
    <ng-template clrPageTitle translate>{{ 'section.injuries' | translate }}</ng-template>
    <app-sunet-injuries-form [limitToTeeth]="accidentDetail?.cdAccidentCategory === 'cd_accident_cat_dental'" [(injuries)]="injuries"
      (injuriesValid)="injuriesValid = $event"></app-sunet-injuries-form>
  </clr-wizard-page>

  <clr-wizard-page [clrWizardPageHasError]="!firstTreatmentValid">
    <ng-template clrPageTitle>{{ 'section.treatments' | translate }}</ng-template>
    <h4 translate>section.first_treatment</h4>
    <app-sunet-treatment-form [required]="true" [(treatment)]="firstTreatment" (treatmentValid)="firstTreatmentValid = $event">
    </app-sunet-treatment-form>
    <h4 translate>section.followup_treatment</h4>
    <app-sunet-treatment-form [(treatment)]="secondTreatment" (treatmentValid)="secondTreatmentValid = $event">
    </app-sunet-treatment-form>
  </clr-wizard-page>

  <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!paymentValid">
    <ng-template clrPageTitle>{{ 'section.payment_address' | translate }}</ng-template>
    <app-sunet-payment-form [readonly]="true" [(payment)]="payment" (paymentValid)="paymentValid = $event">
    </app-sunet-payment-form>
  </clr-wizard-page>

  <clr-wizard-page [clrWizardPageNextDisabled]="!phPartnersValid" [clrWizardPageHasError]="!employmentValid">
    <ng-template clrPageTitle>{{ 'section.employment_info' | translate }}</ng-template>
    <app-sunet-employment-form [readonly]="true" [processHeaderDate]="accidentDetail?.accidentDate" [(employment)]="employment" (employmentValid)="employmentValid = $event">
    </app-sunet-employment-form>
  </clr-wizard-page>

  <clr-wizard-page *ngIf="accidentDetail?.subType === 'type_processh_sub_accident_regular_rep'" [clrWizardPageNextDisabled]="!phPartnersValid"
    [clrWizardPageHasError]="!salaryValid">
    <ng-template clrPageTitle>{{ 'section.salary_info' | translate }}</ng-template>
    <app-sunet-salary-form [readonly]="true" [(salary)]="salary" (salaryValid)="salaryValid = $event">
    </app-sunet-salary-form>
  </clr-wizard-page>

  <clr-wizard-page *ngIf="error" [clrWizardPageHasError]="error != null">
    <ng-template clrPageTitle>{{ 'section.error_occured' | translate }}</ng-template>
    <app-error [error]="error"></app-error>
  </clr-wizard-page>

</clr-wizard>