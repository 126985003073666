import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { EmployeeInfo } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-sunet-employee-form',
  templateUrl: './sunet-employee-form.component.html',
  styleUrls: ['./sunet-employee-form.component.scss']
})
export class SunetEmployeeFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() employee: EmployeeInfo;
  @Output() employeeChange: EventEmitter<EmployeeInfo> = new EventEmitter<EmployeeInfo>();
  @Output() employeeValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  maritalStatuses$: Observable<any[]>;
  languages$: Observable<any[]>;
  genders$: Observable<any[]>;
  nationalities$: Observable<any[]>;

  constructor(
    private codeService: AppCodeService,
  ) {
    super();
    this.form = new UntypedFormGroup({
      employeeNumber: new UntypedFormControl('', [Validators.required]),
      avs: new UntypedFormControl('', [Validators.required, Validators.pattern(/^[0-9]{3}.[0-9]{4}.[0-9]{4}.[0-9]{2}$/)]),
      firstName: new UntypedFormControl('', [Validators.required, Validators.maxLength(25)]),
      lastName: new UntypedFormControl('', [Validators.required, Validators.maxLength(25)]),
      birthdate: new UntypedFormControl(null, [Validators.required]),
      cdGender: new UntypedFormControl(null, [Validators.required]),
      cdLanguage: new UntypedFormControl('', [Validators.required]),
      cdNationality: new UntypedFormControl(null, [Validators.required]),
      cdMaritalStatus: new UntypedFormControl(null, [Validators.required]),
      numberOfChildren: new UntypedFormControl(0),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    if (this.employee) {
      this.form.patchValue(this.employee);
    }

    this.genders$ = this.codeService.getList('cd_gender', false);
    this.maritalStatuses$ = this.codeService.getList('cd_marital_status', false);
    this.languages$ = this.codeService.getList('cd_language', false);
    this.nationalities$ = this.codeService.getList('cd_nationality', false);

    if (this.disabled) {
      this.form.disable();
    }
    this.form.markAllAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.employee)) {
      this.applyChanges();
    }
  }

  applyChanges() {
    this.form.patchValue(this.employee);
    this.form.markAllAsTouched();
    this.onChange();
  }

  onChange(): void {
    const child = _.extend(this.employee, this.form.value);
    this.employeeChange.emit(child);
    this.employeeValid.emit(this.form.valid);
  }
}
