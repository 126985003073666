<div class="clr-row">
    <mat-form-field class="clr-col-md-4">
        <mat-label translate>field.relationship_type</mat-label>
        <input matInput [value]="processHeaderPolicyHeader.relationshipType | lookupRelationshipType: 'ltr'  | async" disabled>
    </mat-form-field>
    <div class="clr-col-md-8">
        <app-policy-header-search class="clr-row" [type]="typeObject" [(id)]="processHeaderPolicyHeader.policyHeaderId" (idChange)="onChange()"></app-policy-header-search>
        <app-policy-item-search class="clr-row" [label]="policyItemType | lookupApplCode | async" [policyHeaderId]="processHeaderPolicyHeader.policyHeaderId" [(id)]="processHeaderPolicyHeader.policyItemId"
            (idChange)="onChange()"></app-policy-item-search>    
        </div>
</div>