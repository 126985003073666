import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy-list-widget',
  templateUrl: './policy-list-widget.component.html',
  styleUrls: ['./policy-list-widget.component.css']
})
export class PolicyListWidgetComponent implements OnInit {

  @Input()
  policies = [];
  
  constructor() { }

  ngOnInit(): void {
  }

}
