<div class="content-container">
    <app-side-navigation></app-side-navigation>
    <div class="content-area">
        <div class="clr-row" hidden="true">
            <div class="clr-col-lg-4 clr-col-12">
                <a href="..." class="card clickable">
                    <div class="card-block">
                        <p class="card-text">
                            Statistics 1
                        </p>
                    </div>
                </a>
            </div>
            <div class="clr-col-lg-4 clr-col-12">
                <a href="..." class="card clickable">
                    <div class="card-block">
                        <p class="card-text">
                            Statistics 2
                        </p>
                    </div>
                </a>
            </div>
            <div class="clr-col-lg-4 clr-col-12">
                <a href="..." class="card clickable">
                    <div class="card-block">
                        <p class="card-text">
                            Statistics 3
                        </p>
                    </div>
                </a>
            </div>
        </div>
        <div class="clr-row">
            <div class="clr-col-lg-12 clr-col-12">
                <div class="card">
                    <div class="card-block">
                        <clr-tabs>
                            <clr-tab>
                                <button clrTabLink>{{ 'title.your_cases' | translate }}</button>
                                <clr-tab-content *clrIfActive>
                                    <app-cases-widget [mode]="casesWidgetMode.Own"></app-cases-widget>
                                </clr-tab-content>
                            </clr-tab>
                            <clr-tab>
                                <button clrTabLink>{{ 'title.overdue_cases' | translate }}</button>
                                <clr-tab-content *clrIfActive>
                                    <app-cases-widget [mode]="casesWidgetMode.Overdue"></app-cases-widget>
                                </clr-tab-content>
                            </clr-tab>
                        </clr-tabs>
                    </div>
                </div>
            </div>
            <div class="clr-col-lg-12 clr-col-12">
                <div class="card">
                    <div class="card-block">
                        <clr-tabs>
                            <clr-tab>
                                <button clrTabLink>{{ 'title.your_processes' | translate }}</button>
                                <clr-tab-content *clrIfActive>
                                    <app-processes-widget [mode]="processesWidgetMode.Own"></app-processes-widget>
                                </clr-tab-content>
                            </clr-tab>
                            <clr-tab>
                                <button clrTabLink>{{ 'title.overdue_processes' | translate }}</button>
                                <clr-tab-content *clrIfActive>
                                    <app-processes-widget [mode]="processesWidgetMode.Overdue"></app-processes-widget>
                                </clr-tab-content>
                            </clr-tab>
                        </clr-tabs>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
