import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable, of } from 'rxjs';
import { first, flatMap, switchMap, takeUntil } from 'rxjs/operators';
// tslint:disable-next-line:max-line-length
import { DocumentForm, DocumentRefForm, DocumentService, ProcessHeaderForm, ProcessHeaderPartnerForm, ProcessHeaderPolicyHeaderForm, ProcessHeaderProcessItemForm, ProcessheaderService } from 'src/app/core';
import { EntityEnum } from 'src/app/core/entity-enum';
import { AlertService } from 'src/app/core/service/alert.service';
import { BlockService } from 'src/app/core/service/block.service';
import { FileService } from 'src/app/core/service/file.service';
import * as _ from 'underscore';
import { BaseWizardComponent } from '../../base/base-wizard/base-wizard.component';
import { Editor } from '../../editors/editor';
import { sortDocuments } from '../../sort-util';


@Component({
  selector: 'app-process-header-wizard',
  templateUrl: './process-header-wizard.component.html',
  styleUrls: ['./process-header-wizard.component.css']
})
export class ProcessHeaderWizardComponent extends BaseWizardComponent implements OnInit, Editor {

  entityEnum = EntityEnum.Process;

  @Input() id: number;
  @Input() object: ProcessHeaderForm;

  processHeader: ProcessHeaderForm;
  partners: ProcessHeaderPartnerForm[];
  documents: DocumentRefForm[];
  items: ProcessHeaderProcessItemForm[];
  policies: ProcessHeaderPolicyHeaderForm[];

  processHeaderValid = false;
  itemsValid = true;
  policiesValid = true;
  phPartnersValid = true;
  documentValid = true;

  hideItemStep = false;
  hidePolicyStep = false;
  hidePartnerStep = false;
  hideDocumentStep = false;

  constructor(
    private processHeaderService: ProcessheaderService,
    private documentService: DocumentService,
    alertService: AlertService,
    blockService: BlockService,
    private fileService: FileService,
    private translateService: TranslateService,
  ) {
    super(blockService, alertService);
  }

  ngOnInit(): void {
    this.newEntity = !this.id;

    if (this.object) {
      this.processHeader = this.object;
    }

    this.fileService.reset();
    this.loadData();
  }

  loadData() {
    if (!this.id) {
      // tslint:disable-next-line:no-angle-bracket-type-assertion
      if (this.object) {
        this.processHeader = this.object;
      } else {
        this.object = {} as ProcessHeaderForm;
        this.processHeader = {} as ProcessHeaderForm;
      }
      this.partners = this.processHeader?.partners || [];
      this.items = [];
      this.documents = [];
      this.policies = [];
      return;
    }
    this.processHeaderService.findByIdProcessHeader(this.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: ProcessHeaderForm) => {
        this.object = response;
        this.processHeader = response;
        this.items = response.items;
        this.policies = response.policyHeaders;
        this.partners = response.partners;
        this.documents = sortDocuments(response.documents);
        this.evaluateWizardPagesCompleted();
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  protected onSubmit(): Observable<any> {
    const tasks$ = [];
    let index = 0;
    this.documents.forEach(documentRef => {
      if (!documentRef.document.id) {
        tasks$.push(this.storeDocument(documentRef.document, index));
      }
      index++;
    });

    if (tasks$.length === 0) {
      return this.storeProcessHeader();
    }

    return forkJoin(tasks$).pipe(
      takeUntil(this.ngUnsubscribe),
      first(),
      switchMap(() => this.storeProcessHeader())
    );
  }

  private storeDocument(document: DocumentForm, index: number): Observable<any> {
    return forkJoin({
        doc: this.documentService.saveDocument(document),
        idx: of(index)
    }).pipe(
      takeUntil(this.ngUnsubscribe),
      flatMap( ({doc, idx}) => {
        this.documents[idx].document = doc;
        return this.documentService.storeContent(doc.id, this.fileService.get(idx));
      })
    );
  }

  private storeProcessHeader(): Observable<any> {
    const modifiedPh = _.extendOwn(_.clone(this.object), this.processHeader);
    modifiedPh.partners = this.partners;
    modifiedPh.items = this.items;
    modifiedPh.policyHeaders = this.policies;
    modifiedPh.documents = this.documents;
    // tslint:disable-next-line:max-line-length
    const save$ = !modifiedPh.id ? this.processHeaderService.createProcessHeader(modifiedPh) : this.processHeaderService.updateProcessHeader(modifiedPh);
    return save$;
  }
}
