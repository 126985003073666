import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { debounceTime, first, takeUntil, tap } from 'rxjs/operators';
import { ProcessHeaderForm, ProcessheaderService } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import { AppTypeService } from 'src/app/core/service/app-type.service';
import { LanguageService } from 'src/app/core/service/language.service';
import { ValidationHelperService } from 'src/app/core/service/validation-helper.service';
import { ReferenceExistsValidator } from 'src/app/core/validator/reference-exists-validator';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';
import { FieldOption, ProcessHeaderTypeUtils } from './process-header-type-utils';


@Component({
  selector: 'app-process-header-form',
  templateUrl: './process-header-form.component.html',
  styleUrls: ['./process-header-form.component.css']
})
export class ProcessHeaderFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() processHeader: ProcessHeaderForm;
  @Input() allowSelectCase = false;
  @Output() processHeaderValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() processHeaderChange: EventEmitter<ProcessHeaderForm> = new EventEmitter<ProcessHeaderForm>();

  context = ProcessHeaderTypeUtils.buildDefaultContext();
  translations = [];

  subTypes$: Observable<any[]>;
  cdStatuses$: Observable<any[]>;
  cdLanguages$: Observable<any[]>;

  caseId: number;

  mandatory = FieldOption.Mandatory;
  hidden = FieldOption.Hidden;

  statusSetByWorkflow = false;
  withoutInternalDescription = true;

  defaultTime = [0, 0, 0];

  constructor(
    private codeService: AppCodeService,
    private typeService: AppTypeService,
    private processHeaderService: ProcessheaderService,
    private validationHelperService: ValidationHelperService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private cdRef: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
  ) {
    super();
    this.form = this.fb.group({
      type: this.fb.control(''),
      subType: this.fb.control({ value: '', disabled: true}),
      cdStatus: this.fb.control('', [Validators.required]),
      reference: this.fb.control('', {
        validators: [],
        // tslint:disable-next-line:max-line-length
        asyncValidators: [ReferenceExistsValidator.proccesshReferenceExistsValidator(this.processHeaderService, this.validationHelperService)],
        updateOn: 'blur'
      }),
      reference2: this.fb.control(''),
      cdLanguageReport: this.fb.control(''),
      title: this.fb.control(''),
      description: this.fb.control(''),
      descriptionInternal: this.fb.control(''),
      incapacityForWork: this.fb.control(''),
      period: this.fb.group({
        start: this.fb.control(null),
        end: this.fb.control(null),
      }),
      periodActual: this.fb.group({
        start: this.fb.control(null),
        end: this.fb.control(null),
      }),
      processHeaderDate: this.fb.control([Validators.required]),
      location: this.fb.control(null),
      entryDate: this.fb.control(null),
      completionDate: this.fb.control(null),
      postmarkDate: this.fb.control(null),
      dueDate: this.fb.control(null),
      caseId: this.fb.control(null),
    });
  }

  ngOnInit(): void {
    this.translations = this.translateService.instant(['field.billing_date', 'field.event_date', 'field.emission_date', 'field.invoice_date', 'field.franchise_date', 'field.refunding_date', 'field.conversation_date']);

    this.subTypes$ = this.typeService.getListRelatedToReltypeForSubs('processh_sub', 'processh', this.processHeader?.type).pipe(
      tap( items => {
        this.disableSubTypes(items.length === 0);
      })
    );
    this.cdStatuses$ = this.codeService.getList('cd_processh_status', false);
    this.cdLanguages$ = this.codeService.getList('cd_language', false);

    if (this.processHeader) {
      this.validationHelperService.setId(this.processHeader.id);
      this.validationHelperService.setType(this.processHeader.type);
      if (this.processHeader.type) {
        this.context = ProcessHeaderTypeUtils.buildContext(this.processHeader.type);
        this.statusSetByWorkflow = ProcessHeaderTypeUtils.isStatusSetByWorkflow(this.processHeader.type);
      }
      this.caseId = this.processHeader.caseId;
      if (!this.processHeader.period) {
        this.processHeader.period = {};
      }
      this.withoutInternalDescription = (this.processHeader?.description === this.processHeader?.descriptionInternal);
      this.form.patchValue(this.processHeader);
    }
    this.form.markAllAsTouched();

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());

    this.form.statusChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => {
      this.onChange();
    });

    this.setDefaults();
    this.cdRef.detectChanges();
  }

  disableSubTypes(value: boolean) {
    if (value) {
      this.form.get('subType').disable();
    } else {
      this.form.get('subType').enable();
    }
    this.cdRef.detectChanges();
  }

  private setDefaults() {
    if (this.processHeader?.id) {
      return;
    }

    this.cdStatuses$.pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(result => {
      this.form.patchValue({
        cdStatus: result[0].id,
        cdLanguageReport: 'cd_language_' + this.languageService.getCurrentLang()
      });
      this.form.markAllAsTouched();
    });
    this.form.markAllAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.processHeader)) {
      this.subTypes$ = this.typeService.getListRelatedToReltypeForSubs('processh_sub', 'processh', this.processHeader?.type);

      this.withoutInternalDescription = (this.processHeader?.description === this.processHeader?.descriptionInternal);
      this.validationHelperService.setId(this.processHeader.id);
      this.validationHelperService.setType(this.processHeader.type);
      if (this.processHeader.type) {
        this.context = ProcessHeaderTypeUtils.buildContext(this.processHeader.type);
        this.statusSetByWorkflow = ProcessHeaderTypeUtils.isStatusSetByWorkflow(this.processHeader.type);
      }

      this.caseId = this.processHeader.caseId;
      this.form.reset();
      if (!this.processHeader.period) {
        this.processHeader.period = {};
      }
      this.form.patchValue(this.processHeader);
      this.form.markAllAsTouched();
      this.onChange();
    }
  }

  onChange(): void {
    if (this.form.pending) {
      return;
    }
    if (this.form.valid) {
      _.extend(this.processHeader, this.form.value);
      if (this.withoutInternalDescription) {
        this.processHeader.descriptionInternal = this.processHeader.description;
      }
      this.processHeaderChange.emit(this.processHeader);
    }
    this.processHeaderValid.emit(this.form.valid);
    console.log('changed', this.form.valid);
  }

  caseSelected() {
    this.form.patchValue({
      caseId: this.caseId
    });
    this.onChange();
  }
}
