import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, takeUntil } from 'rxjs';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-other-employers-form',
  templateUrl: './other-employers-form.component.html',
  styleUrls: ['./other-employers-form.component.scss']
})
export class OtherEmployersFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() otherEmployers: any;
  @Output() otherEmployersChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() otherEmployersValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  translations = [];

  constructor(
    private translateService: TranslateService,
    private fb: UntypedFormBuilder
  ) {
    super();

    this.form = this.fb.group({
      withOtherEmployers: this.fb.control(false),
      firstEmployer: this.fb.group({
        name: this.fb.control(''),
        street: this.fb.control(''),
        postCode: this.fb.control(''),
        city: this.fb.control(''),
      }),
      secondEmployer: this.fb.group({
        name: this.fb.control(''),
        street: this.fb.control(''),
        postCode: this.fb.control(''),
        city: this.fb.control(''),
      })
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.form.disable();
    }
    this.form.markAllAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.patchValue(this.otherEmployers);
    this.form.markAllAsTouched();
  }

  onChange(): void {
    this.form.markAllAsTouched();
    const modified = _.extend(this.otherEmployers || {}, this.form.value);
    this.otherEmployersChange.emit(modified);
    this.otherEmployersValid.emit(this.form.valid);
  }
}
