import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
// tslint:disable-next-line:max-line-length
import { DocumentRefForm, EmployeeInfo, EmployerAdditionalInfo, EmploymentInfo, PartnerWithPhoneForm, PaymentInfo, PersonService, PrefillCriteria, ProcessHeaderForm, ProcessHeaderPartnerForm, ProcessheaderService, ProcessHeaderSunetForm, SalaryInfo, TreatmentForm, WizardhelperService } from 'src/app/core';
import { EntityEnum } from 'src/app/core/entity-enum';
import { AlertService } from 'src/app/core/service/alert.service';
import { BlockService } from 'src/app/core/service/block.service';
import { SunetPreloadService } from 'src/app/core/service/sunet-preload-service';
import * as _ from 'underscore';
import { BaseWizardComponent } from '../../base/base-wizard/base-wizard.component';
import { Editor } from '../../editors/editor';
import { treatmentFormToNullIfEmpty } from '../../form-util';
import { extractAccidentDetail, extractAdditionsAccident, extractEmployeeInfo, extractEmployerPartnerId, extractEmploymentInfo, extractIncapacityToWork, extractInjuries, extractInsuredPartnerId, extractOtherEmployersPartnerIds, extractSalaryInfo, mapAccidentDetailToProcessHeader, mapAdditionsAccidentToProcessHeaderSunet, mapIncapacityToWorkToProcessHeaderSunet, passPreviousId } from '../../sunet-util';
import { AccidentDetailForm } from '../../sunet/sunet-accident-form/accident-detail-form';

@Component({
  selector: 'app-accident-wizard',
  templateUrl: './accident-wizard.component.html',
  styleUrls: ['./accident-wizard.component.css']
})
export class AccidentWizardComponent extends BaseWizardComponent implements OnInit, Editor {

  entityEnum = EntityEnum.AccidentReport;

  @Input() id: number;
  @Input() object: ProcessHeaderForm;

  processHeader: ProcessHeaderForm;
  processHeaderLite: ProcessHeaderForm;
  processHeaderSunet: ProcessHeaderSunetForm;
  partners: ProcessHeaderPartnerForm[];
  documents: DocumentRefForm[];
  employeeInfo: EmployeeInfo;
  employeeAddress: PartnerWithPhoneForm;
  employerAdditionalInfo: EmployerAdditionalInfo;
  employerAddress: PartnerWithPhoneForm;
  agency: PartnerWithPhoneForm;
  employment: EmploymentInfo;
  payment: PaymentInfo;
  salary: SalaryInfo;
  accidentDetail: AccidentDetailForm;
  additionsAccident: any;
  firstTreatment: TreatmentForm;
  secondTreatment: TreatmentForm;
  injuries: any;
  incapacityToWork: any;

  insuredPartnerId: number;
  newEmployerPartnerId: number;
  employerPartnerId: number;
  firstTreatmentPartnerId: number;
  secondTreatmentPartnerId: number;
  otherEmployerIds = '';

  processHeaderValid = false;
  phPartnersValid = true;
  accidentDetailValid = false;
  employeeValid = false;
  employeeAddressValid = false;
  employerValid = false;
  employerAddressValid = false;
  agencyValid = false;
  employmentValid = false;
  paymentValid = false;
  salaryValid = false;
  firstTreatmentValid = false;
  secondTreatmentValid = false;
  injuriesValid = false;
  incapacityToWorkValid = true;
  additionsAccidentValid = false;

  constructor(
    private processHeaderService: ProcessheaderService,
    private personService: PersonService,
    private preloadService: SunetPreloadService,
    private wizardhelperService: WizardhelperService,
    private translateService: TranslateService,
    alertService: AlertService,
    blockService: BlockService
  ) {
    super(blockService, alertService);
  }

  ngOnInit(): void {
    this.newEntity = !this.id;

    if (this.object) {
      this.processHeader = this.object;
      this.processHeaderLite = this.object;
      if (this.object.processHeaderSunet) {
        this.processHeaderSunet = this.object.processHeaderSunet;
      } else {
        this.processHeaderSunet = {} as ProcessHeaderSunetForm;
      }

    }
    this.loadData();
  }

  loadData() {
    if (!this.id) {
      this.processHeaderSunet = {} as ProcessHeaderSunetForm;

      this.accidentDetail = {};
      this.injuries = {};
      this.additionsAccident = {};
      this.incapacityToWork = {};
      this.salary = {};
      this.employeeAddress = {};
      this.employeeInfo = {};
      this.employerAddress = {};
      this.agency = {};
      this.employerAdditionalInfo = {};
      this.employment = {
        cdWorkingFrequency: 'cd_working_frequency_regular'
      } as EmploymentInfo;
      this.payment = {};
      this.firstTreatment = {};
      this.secondTreatment = {};
      this.partners = this.processHeader?.partners || [];
      this.documents = [];

      if (this.partners.length > 0) {
        this.processPartners();
      }

      return;
    }

    this.processHeaderService.findByIdProcessHeader(this.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: ProcessHeaderForm) => {
        this.object = response;
        this.processHeaderLite = response;
        this.processHeader = response;
        this.processHeaderSunet = this.processHeader.processHeaderSunet || {} as ProcessHeaderSunetForm;
        this.accidentDetail = extractAccidentDetail(response, response.processHeaderSunet);
        this.partners = response.partners;
        this.documents = response.documents;
        this.injuries = extractInjuries(this.processHeaderSunet);
        this.additionsAccident = extractAdditionsAccident(this.processHeaderSunet);
        this.incapacityToWork = extractIncapacityToWork(this.processHeaderSunet);
        this.salary = this.processHeaderSunet.salary || {};
        this.employeeAddress = this.processHeaderSunet.employeeAddress || {};
        this.employeeInfo = this.processHeaderSunet.employeeInfo || {};
        this.employerAddress = this.processHeaderSunet.employerAddress || {};
        this.agency = this.processHeaderSunet.agency || {};
        this.employerAdditionalInfo = this.processHeaderSunet.employerAdditionalInfo || {};
        this.employment = this.processHeaderSunet.employment || {
          cdWorkingFrequency: 'cd_working_frequency_regular'
        } as EmploymentInfo;
        this.payment = this.processHeaderSunet.payment || {};
        this.firstTreatment = this.processHeaderSunet.firstTreatment || {};
        this.secondTreatment = this.processHeaderSunet.secondTreatment || {};

        this.accidentDetailValid = this.processHeader.accidentDetailValid || false;
        this.employeeValid = this.processHeader.employeeValid || false;
        this.employeeAddressValid = this.processHeader.employeeAddressValid || false;
        this.employerValid = this.processHeader.employerValid || false;
        this.employerAddressValid = this.processHeader.employerAddressValid || false;
        this.employmentValid = this.processHeader.employmentValid || false;
        this.salaryValid = this.processHeader.salaryValid || false;
        this.firstTreatmentValid = this.processHeader.firstTreatmentValid || false;
        this.secondTreatmentValid = this.processHeader.secondTreatmentValid || false;
        this.incapacityToWorkValid = this.processHeader.incapacityToWorkValid || false;
        this.additionsAccidentValid = this.processHeader.additionsAccidentValid || false;
        this.paymentValid = this.processHeader.paymentValid || false;

        this.processPartners();
        this.evaluateWizardPagesCompleted();
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  processPartners() {
    this.processInsuredPartner();
    this.processEmployerPartner();
    this.processFirstTreatmentPartner();
    this.processFollowupTreatmentPartner();
    this.processOtherEmployers();
  }

  processInsuredPartner() {
    this.insuredPartnerId = extractInsuredPartnerId(this.partners);
  }

  processEmployerPartner() {
      this.employerPartnerId = extractEmployerPartnerId(this.partners);
  }

  processFirstTreatmentPartner() {
    this.firstTreatmentPartnerId = this.preloadService.extractFirstTreatmentPartnerId(this.partners)?.id;
  }

  processFollowupTreatmentPartner() {
    this.secondTreatmentPartnerId = this.preloadService.extractFollowTreatmentPartnerId(this.partners)?.id;
  }

  processOtherEmployers() {
    this.otherEmployerIds = extractOtherEmployersPartnerIds(this.partners).join(',');
  }

  loadFirstTreatmentPartner() {
    this.preloadService.processFirstTreatmentPartner(this.partners, this.firstTreatment?.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response) => {
        if (response) {
          this.firstTreatmentPartnerId = response.newId;
          this.firstTreatment = response.treatment;
        }
      });
  }

  loadFollowupTreatmentPartner() {
    this.preloadService.processFollowupTreatmentPartner(this.partners, this.secondTreatment?.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response) => {
        if (response) {
          this.secondTreatmentPartnerId = response.newId;
          this.secondTreatment = response.treatment;
        }
      });
  }

  loadInsured() {
    forkJoin({
      person: this.personService.findByIdPerson(this.insuredPartnerId),
      address: this.wizardhelperService.prefillPartnerWithPhoneForm(this.insuredPartnerId)
    }).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response) => {
        // tslint:disable-next-line:max-line-length
        const organisationId = _.find(response?.person?.partnersRight, (partner => partner.relationshipType.endsWith('person-employee')))?.partnerIdLeft;
        this.employeeInfo = extractEmployeeInfo(response.person);
        this.employeeAddress = passPreviousId(response.address, this.employeeAddress?.id);

        this.employment = extractEmploymentInfo(response.person);
        const salary = extractSalaryInfo(response.person);
        salary.otherEmployers = this.salary?.otherEmployers;
        this.salary = salary;
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  loadEmployer() {
    const partnerId = this.employerPartnerId;
    const caseId = this.object?.caseId;
    const prefillCriteria = {
      partnerId,
      caseId
    } as PrefillCriteria;

    forkJoin({
      additionalInfo: this.wizardhelperService.prefillEmployerAdditionalInfo(prefillCriteria),
      agency: this.wizardhelperService.prefillAgency(prefillCriteria),
      address: this.wizardhelperService.prefillPartnerWithPhoneForm(partnerId),
      payment: this.wizardhelperService.prefillPaymentInfo(partnerId)
    }).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response) => {
        this.employerAdditionalInfo = response.additionalInfo;
        this.employerAddress = passPreviousId(response.address, this.employerAddress?.id);
        this.agency = passPreviousId(response.agency, this.agency?.id);
        this.payment = response.payment;
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  loadOtherEmployers() {
    this.wizardhelperService.loadOtherEmployers(this.otherEmployerIds)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        first()
      ).subscribe(
        (response) => {
          const salary = _.clone(this.salary);
          salary .otherEmployers = response.names.join(',');
          this.salary = salary;
        },
        (error) => {
          console.log('error', error);
        }
      );
  }

  protected onSubmit(): Observable<any> {
    const modifiedSunet = _.extend({}, this.processHeaderSunet, this.accidentDetail) as ProcessHeaderSunetForm;
    _.extend(modifiedSunet, mapAdditionsAccidentToProcessHeaderSunet(this.additionsAccident));
    _.extend(modifiedSunet, mapIncapacityToWorkToProcessHeaderSunet(this.incapacityToWork));
    _.extend(modifiedSunet, this.injuries);
    modifiedSunet.employeeInfo = this.employeeInfo;
    modifiedSunet.employeeAddress = this.employeeAddress;
    modifiedSunet.employerAdditionalInfo = this.employerAdditionalInfo;
    modifiedSunet.employerAddress = this.employerAddress;
    modifiedSunet.agency = this.agency;
    modifiedSunet.employment = this.employment;
    modifiedSunet.payment = this.payment;
    modifiedSunet.salary = this.salary;
    modifiedSunet.firstTreatment = treatmentFormToNullIfEmpty(this.firstTreatment);
    modifiedSunet.secondTreatment = treatmentFormToNullIfEmpty(this.secondTreatment);

    const modifiedPh = _.extend({}, this.object, this.processHeader);
    _.extend(modifiedPh, this.processHeaderLite);
    _.extend(modifiedPh, mapAccidentDetailToProcessHeader(this.accidentDetail));
    modifiedPh.accidentDetailValid = this.accidentDetailValid;
    modifiedPh.employeeValid = this.employeeValid;
    modifiedPh.employeeAddressValid = this.employeeAddressValid;
    modifiedPh.employerValid = this.employerValid;
    modifiedPh.employerAddressValid = this.employerAddressValid;
    modifiedPh.agencyValid = this.agencyValid;
    modifiedPh.employmentValid = this.employmentValid;
    modifiedPh.salaryValid = this.salaryValid;
    modifiedPh.injuriesValid = this.injuriesValid;
    modifiedPh.firstTreatmentValid = this.firstTreatmentValid;
    modifiedPh.secondTreatmentValid = this.secondTreatmentValid;
    modifiedPh.paymentValid = this.paymentValid;
    modifiedPh.additionsAccidentValid = this.additionsAccidentValid;
    modifiedPh.incapacityToWorkValid = this.incapacityToWorkValid;
    modifiedPh.processHeaderSunet = modifiedSunet;
    modifiedPh.partners = this.partners;
    modifiedPh.documents = this.documents;
    modifiedPh.items = [];

    // tslint:disable-next-line:max-line-length
    const save$ = !modifiedPh.id ? this.processHeaderService.createProcessHeader(modifiedPh) : this.processHeaderService.updateProcessHeader(modifiedPh);
    return save$;
  }
}
