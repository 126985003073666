import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { concat, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { OrganisationService } from 'src/app/core/api/organisation.service';
import { OrganisationLiteView } from 'src/app/core/model/organisationLiteView';
import { OrganisationSearchCriteria } from 'src/app/core/model/organisationSearchCriteria';
import { PageOrganisationLiteView } from 'src/app/core/model/pageOrganisationLiteView';
import { BaseSearchComponent } from '../../base/base-search/base-search.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-organisation-search',
  templateUrl: './organisation-search.component.html',
  styleUrls: ['./organisation-search.component.css']
})
export class OrganisationSearchComponent extends BaseSearchComponent implements OnInit, OnChanges {

  criteria: OrganisationSearchCriteria = {
    pageSize: 50,
    sortColumn: 'name'
  };

  result$: Observable<OrganisationLiteView[]>;
  selectedItem: OrganisationLiteView;

  constructor(
    private organisationController: OrganisationService,
    private ref: ChangeDetectorRef
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.id, false)) {
      if (!this.id) {
        this.selectedItem = null;
        this.ref.markForCheck();
        return;
      }
      this.organisationController.findByIdOrganisationLite(this.id).pipe(
        takeUntil(this.ngUnsubscribe),
        first()
      ).subscribe(response => {
        this.selectedItem = response;
        this.ref.markForCheck();
      });
    }
  }

  ngOnInit(): void {
    this.searchOrganisations();
  }

  trackByFn(item: OrganisationLiteView) {
    return item.id;
  }

  onChange() {
    this.idChange.emit(this.selectedItem.id);
  }

  open() {
    this.openWizardInNewWindow('/admin/organisation-list?open=new');
  }

  private searchOrganisations() {
    this.result$ = concat(
      of([]), // default item
      this.searchInput$.pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap((term) => {
          this.criteria.keywords = term;
          return this.organisationController.searchOrganisationLite(this.criteria).pipe(
            catchError(() => of([])), // empty list on error
            map((response: PageOrganisationLiteView) => response.content),
            tap(( items ) => {
              this.loading = false;
              this.nothingFound = (items.length === 0);
            })
          );
        })
      )
    );
  }
}
