import { Component, OnDestroy, SimpleChange } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.css']
})
export class BaseComponent implements OnDestroy {
  ngUnsubscribe = new Subject<void>();

  constructor() {
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  hasChanged(change: SimpleChange, ignoreChangeToNull: boolean = true): boolean {
    if (!change) {
      return false;
    }

    return (change.currentValue != null || !ignoreChangeToNull)
      && change.currentValue !== change.previousValue;
  }

  buildEntityDetail(name: string): string {
    return [' <strong>"', name, '"</strong>'].join('');
  }
}

