<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.address_line_1</mat-label>
            <input matInput formControlName="street" required [readonly]="readonly"><mat-error *ngIf="form.get('street')?.hasError('maxlength')" translate
                [translateParams]="{ value1: form.get('street')?.errors?.maxlength.actualLength - form.get('street')?.errors?.maxlength.requiredLength}">
                error.value_to_long
            </mat-error>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.address_line_2</mat-label>
            <input matInput formControlName="addressLine2" [readonly]="readonly">
            <mat-error *ngIf="form.get('addressLine2')?.hasError('maxlength')" translate
                [translateParams]="{ value1: form.get('addressLine2')?.errors?.maxlength.actualLength - form.get('addressLine2')?.errors?.maxlength.requiredLength}">
                error.value_to_long
            </mat-error>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-2">
            <mat-label translate>field.post_code</mat-label>
            <input matInput type="number" formControlName="postCode" required [readonly]="readonly">
        </mat-form-field>
        <mat-form-field class="clr-col-md-5">
            <mat-label translate>field.city</mat-label>
            <input matInput formControlName="city" required [readonly]="readonly">
            <mat-error *ngIf="form.get('city')?.hasError('maxlength')" translate
                [translateParams]="{ value1: form.get('city')?.errors?.maxlength.actualLength - form.get('city')?.errors?.maxlength.requiredLength}">
                error.value_to_long
            </mat-error>
        </mat-form-field>
        <mat-form-field [ngClass]="{'readonly-wrapper': readonly}" class="clr-col-md-5">
            <mat-label translate>field.country</mat-label>
            <mtx-select [ngClass]="{'readonly-block': readonly}" [searchable]="true" formControlName="cdCountry" [appendTo]="'body'" required [readonly]="readonly">
                <mtx-option *ngFor="let country of countries$ | async" [value]="country.id">
                    {{ country | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.phone</mat-label>
            <input matInput formControlName="phone" maxlength="15" [readonly]="readonly">
            <mat-hint>Formatted: {{ formattedPhone }}</mat-hint>
        </mat-form-field>
    </div>
</form>