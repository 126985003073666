<div clrLayout="vertical">

    <div class="clr-row" *ngIf="relTypes?.length === 0">
        <div class="clr-col-md-12">
            {{ 'message.no_bank_accounts_available' | translate }}
        </div>
    </div>

    <div class="clr-row" *ngIf="relTypes?.length > 0">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.bank_account_type</mat-label>
            <mtx-select [searchable]="true" class="custom" [(ngModel)]="selectedType">
                <mtx-option *ngFor="let relType of relTypes" [value]="relType">
                    {{ relType.applText | languageApplText }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <div class="clr-col-md-2">
            <button class="btn btn-sm" (click)="onAdd()" [disabled]="!selectedType" translate>button.add_bank_account</button>
        </div>
    </div>

    <clr-accordion [clrAccordionMultiPanel]="true">
        <clr-accordion-panel *ngFor="let wrappedItem of wrappedItems, index as i" [clrAccordionPanelOpen]="wrappedItem.open">
            <clr-accordion-title>{{ wrappedItem.item?.relationshipType | lookupRelationshipType | async}}: {{ wrappedItem.item?.accountHolder }}
                {{ wrappedItem.item?.city }}</clr-accordion-title>
            <clr-accordion-content *clrIfExpanded class="clr-row">
                <div class="clr-col-md-11">
                    <app-bankaccount-form [(bankaccount)]="wrappedItem.item" (bankaccountChange)="onChange(i, $event)" (bankaccountValid)="onChange(i, $event)">
                    </app-bankaccount-form>
                </div>
                <div class="clr-col-md-1">
                    <span *appDeleteAllowed="wrappedItem?.item?.relationshipType;immutable:wrappedItem.required" (click)="onDelete(i)">
                        <clr-icon shape="trash"></clr-icon>
                    </span>
                </div>
            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
</div>