<form [formGroup]="injury" clrForm clrLayout="vertical">
  <div class="clr-row">
    <mat-form-field class="clr-col-md-4">
      <mat-label translate>field.injured_body_part</mat-label>
            <mtx-select [searchable]="true" formControlName="part" [required]="required" [readonly]="limitToTeeth" (ngModelChange)="evaluateSideDisabled(injury.value.part)">
        <mtx-option *ngFor="let cdInjuredPartOfBody of cdInjuredParts$ | async" [value]="cdInjuredPartOfBody.id">
          {{ cdInjuredPartOfBody | language:'name' }}</mtx-option>
      </mtx-select>
    </mat-form-field>
    <mat-form-field class="clr-col-md-4">
      <mat-label translate>field.injured_body_side</mat-label>
            <mtx-select [searchable]="true" formControlName="side" [readonly]="sideDisabled" [required]="(required || injury.value.part != null) && !sideDisabled">
        <mtx-option *ngFor="let cdInjuredSide of cdInjuredSides$ | async" [value]="cdInjuredSide.id">
          {{ cdInjuredSide | language:'name' }}</mtx-option>
      </mtx-select>
    </mat-form-field>
    <mat-form-field class="clr-col-md-4">
      <mat-label translate>field.injury_type</mat-label>
            <mtx-select [searchable]="true" formControlName="type" [required]="required || injury.value.part != null">
        <mtx-option *ngFor="let cdInjuryType of cdInjuryTypes$ | async" [value]="cdInjuryType.id">
          {{ cdInjuryType | language:'name' }}</mtx-option>
      </mtx-select>
    </mat-form-field>
  </div>
  <div class="clr-row" *ngIf="injury.value.type == 'cd_injury_type_other_injuries'">
    <mat-form-field class="clr-col-md-12">
      <mat-label translate>field.description_of_injury</mat-label>
      <textarea [rows]="5" formControlName="description" matInput maxlength="4000"></textarea>
    </mat-form-field>
  </div>
</form>
