import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ProcessHeaderPolicyHeaderForm } from 'src/app/core';
import { mapToPolicyItemType } from 'src/app/shared/policy-util';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-process-policy-form',
  templateUrl: './process-policy-form.component.html',
  styleUrls: ['./process-policy-form.component.scss']
})
export class ProcessPolicyFormComponent extends BaseFormComponent implements OnChanges {

  @Input() processHeaderPolicyHeader: ProcessHeaderPolicyHeaderForm;
  @Input() typeObject: string;
  @Output() processHeaderPolicyHeaderValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  // tslint:disable-next-line:max-line-length
  @Output() processHeaderPolicyHeaderChange: EventEmitter<ProcessHeaderPolicyHeaderForm> = new EventEmitter<ProcessHeaderPolicyHeaderForm>();

  processHeaderPolicyHeaderEdit: ProcessHeaderPolicyHeaderForm = {};
  partnerId: number;

  policyItemType: string;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.processHeaderPolicyHeader)) {
      this.processHeaderPolicyHeaderEdit = changes.processHeaderPolicyHeader.currentValue;
      this.policyItemType = mapToPolicyItemType(this.processHeaderPolicyHeaderEdit.policyHeaderType);
    }
  }

  onChange(): void {
    const valid = this.processHeaderPolicyHeaderEdit.policyHeaderId != null;
    if (valid) {
      this.processHeaderPolicyHeaderChange.emit(this.processHeaderPolicyHeaderEdit);
    }
    this.processHeaderPolicyHeaderValid.emit(valid);
    console.log('changed', this.processHeaderPolicyHeaderEdit.policyHeaderId);
  }
}

