import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-case-policy-list-widget',
  templateUrl: './case-policy-list-widget.component.html',
  styleUrls: ['./case-policy-list-widget.component.scss']
})
export class CasePolicyListWidgetComponent {

  @Input()
  policies = [];

  constructor() { }

}