<div clrLayout="vertical">
    <div class="clr-row" *ngIf="relTypes?.length === 0">
        <div class="clr-col-md-12">
            {{ 'message.no_process_header_available' | translate }}
        </div>
    </div>
    <div class="clr-row" *ngIf="relTypes?.length > 0">
        <mat-form-field class="clr-col-md-5">
            <mat-label translate>field.type</mat-label>
            <mtx-select [searchable]="true" [(ngModel)]="selectedRelType" [appendTo]="'body'">
                <mtx-option *ngFor="let relType of filteredRelTypes" [value]="relType">
                    {{ relType.typeLeft | lookupApplType | async }}</mtx-option>
            </mtx-select>
        </mat-form-field>

        <button class="btn btn-sm" [disabled]="!selectedRelType || !editorValid" (click)="onAdd()" translate>button.add_process_header</button>
    </div>
    
    <clr-accordion [clrAccordionMultiPanel]="true">
        <clr-accordion-panel *ngFor="let wrappedItem of wrappedItems; index as i" [clrAccordionPanelOpen]="wrappedItem.open"> 
            <clr-accordion-title>{{'process_header' | translate}} {{ wrappedItem.item.type | lookupApplType | async}}</clr-accordion-title>
            <clr-accordion-content *clrIfExpanded class="clr-row">
                <div class="clr-col-md-11 clr-col-lg-11">
                    <app-process-header-form [allowSelectCase]="false" [(processHeader)]="wrappedItem.item" (processHeaderValid)="onChange(i, $event)">
                    </app-process-header-form>
                    <div class="clr-row" *ngIf="!wrappedItem.item.id">
                        <mat-checkbox class="clr-col-md-12" [(ngModel)]="wrappedItem.withDocument">
                            <mat-label translate>with_document</mat-label>
                        </mat-checkbox>
                    </div>

                    <app-document-form *ngIf="wrappedItem.withDocument" [index]="i" section="processHeader" [allowSelectType]="true" [typeLeft]="wrappedItem.item.type" [(applDocument)]="wrappedItem.document"></app-document-form>
                </div>
                <div class="clr-col-md-1 clr-col-lg-1">
                    <span (click)="onDelete(i)" *ngIf="wrappedItem?.item?.id == null">
                        <clr-icon shape="trash"></clr-icon>
                    </span>
                </div>
            </clr-accordion-content>
        </clr-accordion-panel>
    </clr-accordion>
</div>