import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { debounceTime, first, takeUntil } from 'rxjs/operators';
import { ApplObjectForm, ObjectService } from 'src/app/core';
import { AppCodeService } from 'src/app/core/service/app-code.service';
import { AppTypeService } from 'src/app/core/service/app-type.service';
import { ValidationHelperService } from 'src/app/core/service/validation-helper.service';
import { ReferenceExistsValidator } from 'src/app/core/validator/reference-exists-validator';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';


@Component({
  selector: 'app-object-form',
  templateUrl: './object-form.component.html',
  styleUrls: ['./object-form.component.css']
})
export class ObjectFormComponent extends BaseFormComponent implements OnInit, OnChanges {


  @Input() applObject: ApplObjectForm;
  @Output() applObjectValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() applObjectChange: EventEmitter<ApplObjectForm> = new EventEmitter<ApplObjectForm>();

  cdStatuses$: Observable<any[]>;
  cdObjectCategories$: Observable<any[]>;

  objectIdRef: number;

  constructor(
    private codeService: AppCodeService,
    private typeService: AppTypeService,
    private objectService: ObjectService,
    private validationHelperService: ValidationHelperService,
    private fb: UntypedFormBuilder,
  ) {
    super();
    this.form = this.fb.group({
      type: this.fb.control('', [Validators.required]),
      cdStatus: this.fb.control('', [Validators.required]),
      name: this.fb.control('', [Validators.required]),
      referenceNumber: this.fb.control('', {
        validators: [Validators.required],
        asyncValidators: [ReferenceExistsValidator.objectReferenceExistsValidator(this.objectService, validationHelperService)],
        updateOn: 'blur'
      }),
      referenceNumber2: this.fb.control(''),
      vehicleNr: this.fb.control(''),
      registrationNr: this.fb.control(''),
      registrationDate: this.fb.control(null),
      description: this.fb.control(''),
      usageBeginDate: this.fb.control(null),
      usageEndDate: this.fb.control(null),
      objectIdRef: this.fb.control(null),
      objectValue: this.fb.control(null),
      residualValue: this.fb.control(null),
      cdObjectCategory: this.fb.control(null),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => {
      this.onChange();
      this.validationHelperService.setType(this.form.value.type);
    });
    this.form.statusChanges.pipe(
      takeUntil(this.ngUnsubscribe),
    ).subscribe(() => {
      this.onChange();
    });
  }

  ngOnInit(): void {
    console.log('onInit');
    if (this.applObject) {
      this.processApplObject();
    } else {
    }

    this.cdStatuses$ = this.codeService.getList('cd_object_status', false);
    this.cdObjectCategories$ = this.codeService.getList('cd_object_cat', false);
    this.setDefaults();
    this.form.markAllAsTouched();
  }

  private setDefaults() {
    if (this.applObject?.id) {
      return;
    }
    this.cdStatuses$.pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(result => {
      this.form.patchValue({
        cdStatus: result[0].id
      });
      this.form.markAllAsTouched();
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.applObject)) {
      this.processApplObject();
      this.onChange();
    }
  }

  processApplObject(): void {
    this.validationHelperService.setId(this.applObject.id);
    this.validationHelperService.setType(this.applObject.type);

    this.objectIdRef = this.applObject.objectIdRef;
    this.form.reset();
    this.form.patchValue(this.applObject);
    this.form.markAllAsTouched();
  }

  onChange(): void {
    if (this.form.pending) {
      return;
    }
    this.applObjectValid.emit(this.form.valid);
    if (this.form.valid) {
      const obj = _.extend(this.applObject, this.form.value) as ApplObjectForm;
      obj.objectIdRef = this.objectIdRef;
      this.applObjectChange.emit(obj);
    }
  }

}
