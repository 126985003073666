import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PartnerUpdateService {

  private subject = new Subject<PartnerChange>();

  constructor() { }

  publish(partnerId: number, relType: string): void {
    const change = {
      partnerId,
      relType
    };
    this.subject.next(change);
  }

  onChange(relTypes: string[]): Observable<PartnerChange> {
    return this.subject.asObservable().pipe(
      filter(entry => relTypes.includes(entry.relType))
    );
  }

}

interface PartnerChange {
  partnerId: number;
  relType: string;
}

