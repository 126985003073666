import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { forkJoin, Observable } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
// tslint:disable-next-line:max-line-length
import {
  DocumentRefForm,
  EmployeeInfo,
  EmployerAdditionalInfo,
  EmploymentInfo,
  PartnerWithPhoneForm,
  PaymentInfo,
  PersonService,
  PrefillCriteria,
  ProcessHeaderForm,
  ProcessHeaderPartnerForm,
  ProcessheaderService,
  ProcessHeaderSunetForm,
  SalaryInfo,
  TreatmentForm,
  WizardhelperService
} from 'src/app/core';
import { EntityEnum } from 'src/app/core/entity-enum';
import { AlertService } from 'src/app/core/service/alert.service';
import { BlockService } from 'src/app/core/service/block.service';
import { SunetPreloadService } from 'src/app/core/service/sunet-preload-service';
import * as _ from 'underscore';
import { BaseWizardComponent } from '../../base/base-wizard/base-wizard.component';
import { Editor } from '../../editors/editor';
import { treatmentFormToNullIfEmpty } from '../../form-util';
import {
  extractEmployeeInfo,
  extractEmployerPartnerId,
  extractEmploymentInfo,
  extractInsuredPartnerId,
  extractMaternityDetail,
  extractOtherEmployersPartnerIds,
  extractSalaryInfo,
  mapMaternityDetailToProcessHeaderSunet,
  passPreviousId
} from '../../sunet-util';

@Component({
  selector: 'app-maternity-wizard',
  templateUrl: './maternity-wizard.component.html',
  styleUrls: ['./maternity-wizard.component.css']
})
export class MaternityWizardComponent extends BaseWizardComponent implements OnInit, Editor {

  entityEnum = EntityEnum.MaternityReport;

  @Input() id: number;
  @Input() object: ProcessHeaderForm;

  processHeaderSunet: ProcessHeaderSunetForm;
  processHeaderLite: ProcessHeaderForm;
  processHeader: ProcessHeaderForm;
  partners: ProcessHeaderPartnerForm[];
  documents: DocumentRefForm[];
  employeeInfo: EmployeeInfo;
  employeeAddress: PartnerWithPhoneForm;
  employerAdditionalInfo: EmployerAdditionalInfo;
  employerAddress: PartnerWithPhoneForm;
  employment: EmploymentInfo;
  payment: PaymentInfo;
  salary: SalaryInfo;
  maternityDetail: any;
  firstTreatment: TreatmentForm;
  secondTreatment: TreatmentForm;

  insuredPartnerId: number;
  newEmployerPartnerId: number;
  employerPartnerId: number;
  firstTreatmentPartnerId: number;
  secondTreatmentPartnerId: number;
  otherEmployerIds = '';

  processHeaderValid = false;
  phPartnersValid = true;
  maternityDetailValid = false;
  employeeValid = false;
  employeeAddressValid = false;
  employerValid = false;
  employerAddressValid = false;
  employmentValid = false;
  paymentValid = false;
  salaryValid = false;
  firstTreatmentValid = false;
  secondTreatmentValid = false;

  constructor(
    private processHeaderService: ProcessheaderService,
    private personService: PersonService,
    private wizardhelperService: WizardhelperService,
    private preloadService: SunetPreloadService,
    private translateService: TranslateService,
    alertService: AlertService,
    blockService: BlockService
  ) {
    super(blockService, alertService);
  }

  ngOnInit(): void {
    this.newEntity = !this.id;

    if (this.object) {
      this.processHeader = this.object;
      this.processHeaderLite = this.object;
      if (this.object.processHeaderSunet) {
        this.processHeaderSunet = this.object.processHeaderSunet;
      } else {
        this.processHeaderSunet = {} as ProcessHeaderSunetForm;
      }

    }
    this.loadData();
  }

  loadData() {
    if (!this.id) {
      this.processHeaderSunet = {} as ProcessHeaderSunetForm;
      this.partners = [];
      this.documents = [];
      this.maternityDetail = {};
      this.salary = {};
      this.employeeAddress = {};
      this.employeeInfo = {};
      this.employerAddress = {};
      this.employerAdditionalInfo = {};
      this.employment = {
        cdWorkingFrequency: 'cd_working_frequency_regular'
      } as EmploymentInfo;
      this.payment = {};
      this.firstTreatment = {};
      this.secondTreatment = {};

      return;
    }

    this.processHeaderService.findByIdProcessHeader(this.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: ProcessHeaderForm) => {
        this.object = response;
        this.processHeaderLite = response;
        this.processHeader = response;
        if (response.processHeaderSunet) {
          this.processHeaderSunet = response.processHeaderSunet;
        } else {
          this.processHeaderSunet = {} as ProcessHeaderSunetForm;
        }
        this.partners = response.partners;
        this.documents = response.documents;
        this.maternityDetail = extractMaternityDetail(this.processHeaderSunet);
        this.salary = this.processHeaderSunet.salary || {};
        this.employeeAddress = this.processHeaderSunet.employeeAddress || {};
        this.employeeInfo = this.processHeaderSunet.employeeInfo || {};
        this.employerAddress = this.processHeaderSunet.employerAddress || {};
        this.employerAdditionalInfo = this.processHeaderSunet.employerAdditionalInfo || {};
        this.employment = this.processHeaderSunet.employment ||
        {
          cdWorkingFrequency: 'cd_working_frequency_regular'
        } as EmploymentInfo;
        this.payment = this.processHeaderSunet.payment || {};
        this.firstTreatment = this.processHeaderSunet.firstTreatment || {};
        this.secondTreatment = this.processHeaderSunet.secondTreatment || {};

        this.maternityDetailValid = this.processHeader.maternityDetailValid || false;
        this.employeeValid = this.processHeader.employeeValid || false;
        this.employeeAddressValid = this.processHeader.employeeAddressValid || false;
        this.employmentValid = this.processHeader.employmentValid || false;
        this.salaryValid = this.processHeader.salaryValid || false;
        this.firstTreatmentValid = this.processHeader.firstTreatmentValid || false;
        this.secondTreatmentValid = this.processHeader.secondTreatmentValid || false;
        this.paymentValid = this.processHeader.paymentValid || false;

        this.processPartners();
        this.evaluateWizardPagesCompleted();
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  processPartners() {
    this.processInsuredPartner();
    this.processEmployerPartner();
    this.processFirstTreatmentPartner();
    this.processFollowupTreatmentPartner();
    this.processOtherEmployers();
  }

  processInsuredPartner() {
    this.insuredPartnerId = extractInsuredPartnerId(this.partners);
  }

  processEmployerPartner() {
    this.employerPartnerId = extractEmployerPartnerId(this.partners);
  }

  processFirstTreatmentPartner() {
    this.firstTreatmentPartnerId = this.preloadService.extractFirstTreatmentPartnerId(this.partners)?.id;
  }

  processFollowupTreatmentPartner() {
    this.secondTreatmentPartnerId = this.preloadService.extractFollowTreatmentPartnerId(this.partners)?.id;
  }

  processOtherEmployers() {
    this.otherEmployerIds = extractOtherEmployersPartnerIds(this.partners).join(',');
  }

  loadFirstTreatmentPartner() {
    this.preloadService.processFirstTreatmentPartner(this.partners, this.firstTreatment?.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response) => {
        if (response) {
          this.firstTreatmentPartnerId = response.newId;
          this.firstTreatment = response.treatment;
        }
      });
  }

  loadFollowupTreatmentPartner() {
    this.preloadService.processFollowupTreatmentPartner(this.partners, this.secondTreatment?.id).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response) => {
        if (response) {
          this.secondTreatmentPartnerId = response.newId;
          this.secondTreatment = response.treatment;
        }
      });
  }

  loadInsured() {
    forkJoin({
      person: this.personService.findByIdPerson(this.insuredPartnerId),
      address: this.wizardhelperService.prefillPartnerWithPhoneForm(this.insuredPartnerId)
    }).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response) => {
         // tslint:disable-next-line:max-line-length
        const organisationId = _.find(response.person.partnersRight, (partner => partner.relationshipType.endsWith('person-employee')))?.partnerIdLeft;
        this.employeeInfo = extractEmployeeInfo(response.person);
        this.employeeAddress = passPreviousId(response.address, this.employeeAddress?.id);
        this.employment = extractEmploymentInfo(response.person);
        this.salary = extractSalaryInfo(response.person);
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  loadEmployer() {
    const partnerId = this.employerPartnerId;
    const caseId = this.object?.caseId;

    const prefillCriteria = {
      partnerId,
      caseId
    } as PrefillCriteria;

    forkJoin({
      additionalInfo: this.wizardhelperService.prefillEmployerAdditionalInfo(prefillCriteria),
      address: this.wizardhelperService.prefillPartnerWithPhoneForm(partnerId),
      payment: this.wizardhelperService.prefillPaymentInfo(partnerId)
    }).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response) => {
        this.employerAdditionalInfo = response.additionalInfo;
        this.employerAddress = passPreviousId(response.address, this.employerAddress?.id);
        this.payment = response.payment;
      },
      (error) => {
        console.log('error', error);
      }
    );
  }

  loadOtherEmployers() {
    this.wizardhelperService.loadOtherEmployers(this.otherEmployerIds)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        first()
      ).subscribe(
        (response) => {
          const salary = _.clone(this.salary);
          salary.otherEmployers = response.names.join(',');
          this.salary = salary;
        },
        (error) => {
          console.log('error', error);
        }
      );
  }

  protected onSubmit(): Observable<any> {
    const modifiedSunet = _.extendOwn(this.processHeaderSunet, mapMaternityDetailToProcessHeaderSunet(this.maternityDetail));
    modifiedSunet.employeeInfo = this.employeeInfo;
    modifiedSunet.employeeAddress = this.employeeAddress;
    modifiedSunet.employerAdditionalInfo = this.employerAdditionalInfo;
    modifiedSunet.employerAddress = this.employerAddress;
    modifiedSunet.employment = this.employment;
    modifiedSunet.payment = this.payment;
    modifiedSunet.salary = this.salary;
    modifiedSunet.firstTreatment = treatmentFormToNullIfEmpty(this.firstTreatment);
    modifiedSunet.secondTreatment = treatmentFormToNullIfEmpty(this.secondTreatment);

    let modifiedPh = _.extendOwn(this.object, this.processHeader);
    modifiedPh = _.extendOwn(modifiedPh, this.processHeaderLite);
    modifiedPh.processHeaderSunet = modifiedSunet;
    modifiedPh.maternityDetailValid = this.maternityDetailValid;
    modifiedPh.employeeValid = this.employeeValid;
    modifiedPh.employeeAddressValid = this.employeeAddressValid;
    modifiedPh.employerValid = this.employerValid;
    modifiedPh.employerAddressValid = this.employerAddressValid;
    modifiedPh.employmentValid = this.employmentValid;
    modifiedPh.salaryValid = this.salaryValid;
    modifiedPh.firstTreatmentValid = this.firstTreatmentValid;
    modifiedPh.secondTreatmentValid = this.secondTreatmentValid;
    modifiedPh.paymentValid = this.paymentValid;
    modifiedPh.partners = this.partners;
    modifiedPh.documents = this.documents;
    modifiedPh.items = [];

    // tslint:disable-next-line:max-line-length
    const save$ = !modifiedPh.id ? this.processHeaderService.createProcessHeader(modifiedPh) : this.processHeaderService.updateProcessHeader(modifiedPh);
    return save$;
  }
}
