import { Component, Input, OnInit } from '@angular/core';
import { TaskInstanceForm } from 'src/app/core';

@Component({
  selector: 'app-task-list-widget',
  templateUrl: './task-list-widget.component.html',
  styleUrls: ['./task-list-widget.component.css']
})
export class TaskListWidgetComponent implements OnInit {

  @Input()
  tasks: TaskInstanceForm[] = [];

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
