import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PartnerIdentifierForm } from 'src/app/core';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-identifier-form',
  templateUrl: './identifier-form.component.html',
  styleUrls: ['./identifier-form.component.css']
})
export class IdentifierFormComponent extends BaseFormComponent implements OnChanges {
  @Input() identifier: PartnerIdentifierForm;
  @Input() fieldRequired: boolean;
  @Output() identifierValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() identifierChange: EventEmitter<PartnerIdentifierForm> = new EventEmitter<PartnerIdentifierForm>();

  identifierEdit: PartnerIdentifierForm;

  constructor(
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.identifier)) {
      this.identifierEdit = this.identifier;
    }
  }

  onChange(): void {
    const valid = this.identifierEdit.identifier != null || !this.fieldRequired;
    if (valid) {
      this.identifierChange.emit(this.identifierEdit);
    }
    this.identifierValid.emit(valid);
    console.log('changed');
  }

}
