<clr-modal [(clrModalOpen)]="show" clrModalSize="lg">
    <h3 class="modal-title" translate>title.fill_claim_number</h3>
    <div class="modal-body">
        <div class="clr-row">
            <mat-form-field class="clr-col-md-6">
                <mat-label translate>field.claim_number</mat-label>
                <input matInput [(ngModel)]="claimNumber" maxlength="40" required />
            </mat-form-field>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline" (click)="onCancel()" translate>button.cancel</button>
        <button type="button" class="btn btn-primary" [disabled]="!claimNumber" (click)="onFinish()" translate>button.save</button>
    </div>
</clr-modal>