<form clrForm [formGroup]="form" clrLayout="vertical" #persForm>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.employee_number</mat-label>
            <input matInput formControlName="employeeNumber" maxlength="15" required [readonly]="readonly"></mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.avs</mat-label>
            <input matInput formControlName="avs" required [readonly]="readonly">
            <mat-error *ngIf="form?.controls?.avs.errors?.pattern" translate>form_error.avs_pattern</mat-error>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.given_name</mat-label>
            <input matInput formControlName="firstName" required [readonly]="readonly">
            <mat-error *ngIf="form.get('firstName')?.hasError('maxlength')" translate
                [translateParams]="{ value1: form.get('firstName')?.errors?.maxlength.actualLength - form.get('firstName')?.errors?.maxlength.requiredLength}">
                error.value_to_long
            </mat-error>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.last_name</mat-label>
            <input matInput formControlName="lastName" required [readonly]="readonly">
            <mat-error *ngIf="form.get('lastName')?.hasError('maxlength')" translate
                [translateParams]="{ value1: form.get('lastName')?.errors?.maxlength.actualLength - form.get('lastName')?.errors?.maxlength.requiredLength}">
                error.value_to_long
            </mat-error>
        </mat-form-field>
    </div>

    <div class="clr-row">
        <mat-form-field class="clr-col-md-6"  [ngClass]="{'readonly-wrapper': readonly}" >
            <mat-label translate>field.gender</mat-label>
            <mtx-select formControlName="cdGender" [appendTo]="'body'" required [readonly]="readonly">
                <mtx-option *ngFor="let gender of genders$ | async" [value]="gender.id">
                    {{ gender | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <mat-form-field [ngClass]="{'readonly-wrapper': readonly}" class="clr-col-md-6">
            <mat-label translate>field.language</mat-label>
            <mtx-select [searchable]="true" formControlName="cdLanguage" [appendTo]="'body'" required [readonly]="readonly">
                <mtx-option *ngFor="let language of languages$ | async" [value]="language.id">
                    {{ language | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>

    <div class="clr-row">
        <mat-form-field [ngClass]="{'readonly-wrapper': readonly}" class="clr-col-md-6">
            <mat-label translate>field.birthdate</mat-label>
            <input matInput [matDatepicker]="picker2" formControlName="birthdate" required [readonly]="readonly">
            <mat-datepicker-toggle [ngClass]="{'readonly-block': readonly}" matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
        </mat-form-field>
        <mat-form-field [ngClass]="{'readonly-wrapper': readonly}" class="clr-col-md-6">
            <mat-label translate>field.nationality</mat-label>
            <mtx-select class="custom" formControlName="cdNationality" [appendTo]="'body'" required [readonly]="readonly">
                <mtx-option *ngFor="let nationality of nationalities$ | async" [value]="nationality.id">
                    {{ nationality | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field [ngClass]="{'readonly-wrapper': readonly}" class="clr-col-md-6">
            <mat-label translate>field.marital_status</mat-label>
            <mtx-select class="custom" formControlName="cdMaritalStatus" [appendTo]="'body'" [readonly]="readonly">
                <mtx-option *ngFor="let maritalStatus of maritalStatuses$ | async" [value]="maritalStatus.id">
                    {{ maritalStatus | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.number_of_children</mat-label>
            <input matInput formControlName="numberOfChildren" [readonly]="readonly">
        </mat-form-field>
    </div>
</form>
