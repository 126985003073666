<mat-form-field class="clr-row clr-col-12">
    <mat-label translate>field.person</mat-label>
    <mtx-select [items]="result$ | async" bindLabel="name" [trackByFn]="trackByFn" [multiple]="false" [minTermLength]="2" [loading]="loading" clearable="false"
        [typeToSearchText]="'message.enter_2_or_more_characters' | translate" [typeahead]="searchInput$" [required]="required" [(ngModel)]="selectedItem" [appendTo]="'body'"
        (change)="onChange()">
        <ng-template ng-label-tmp let-item="item">
            {{ item.name }} {{ item.birthDate?'-':''}} <small>{{ item.birthDate | date:'dd.MM.yyyy' }}</small>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            {{ item.name }} {{ item.birthDate?'-':''}} <small>{{ item.birthDate | date:'dd.MM.yyyy' }}</small>
        </ng-template>
        <ng-template ng-footer-tmp *ngIf="nothingFound">
            <button class="btn btn-link" (click)="open()" translate>button.create_person</button>
        </ng-template>
    </mtx-select>
</mat-form-field>
