import { AbstractControl, AsyncValidatorFn } from '@angular/forms';
import { CaseService } from '..';
import { ObjectService } from '../api/object.service';
import { ProcessheaderService } from '../api/processheader.service';
import { ValidationHelperService } from '../service/validation-helper.service';

export class ReferenceExistsValidator {
    // tslint:disable-next-line:align
    static  caseReferenceExistsValidator(caseService: CaseService, helperService: ValidationHelperService): AsyncValidatorFn {
        return (control: AbstractControl) => {
            return caseService.caseReferenceExists(helperService.buildReferenceCheck(control.value))
                .toPromise()
                .then(found => found === true ? { referenceExists: true } : null);
        };
    }

    // tslint:disable-next-line:max-line-length
    static proccesshReferenceExistsValidator(processHeaderService: ProcessheaderService, helperService: ValidationHelperService): AsyncValidatorFn {
        return (control: AbstractControl) => {
            return processHeaderService.processhReferenceExists(helperService.buildReferenceCheck(control.value))
                .toPromise()
                .then(found => found === true ? { referenceExists: true } : null);
        };
    }

    // tslint:disable-next-line:max-line-length
    static objectReferenceExistsValidator(objectService: ObjectService, helperService: ValidationHelperService): AsyncValidatorFn {
        return (control: AbstractControl) => {
            return objectService.objectReferenceExists(helperService.buildReferenceCheck(control.value))
                .toPromise()
                .then(found => found === true ? { referenceExists: true } : null);
        };
    }
}
