<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.bank_account_type</mat-label>
            <mtx-select [searchable]="true" class="custom" formControlName="cdAccountType" [appendTo]="'body'" required [readonly]="readonly">
                <mtx-option *ngFor="let accountType of accountTypes$ | async" [value]="accountType.id">
                    {{ accountType | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row" *ngIf="form.value.cdAccountType === 'cd_account_type_bank'">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.bank_name</mat-label>
            <input matInput formControlName="bankName" [readonly]="readonly">
        </mat-form-field>
    </div>
    <div class="clr-row" [hidden]="form.value.cdAccountType !== 'cd_account_type_bank'">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.bank_place</mat-label>
            <input matInput formControlName="bankPlace" [required]="form.value.cdAccountType === 'cd_account_type_bank'" [readonly]="readonly">
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.clearing_number</mat-label>
            <input matInput formControlName="clearingNumber" [required]="form.value.cdAccountType === 'cd_account_type_bank'" [readonly]="readonly">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label *ngIf="form.value.cdAccountType == 'cd_account_type_iban'" translate>field.iban</mat-label>
            <mat-label *ngIf="form.value.cdAccountType != 'cd_account_type_iban'" translate>field.account_nr</mat-label>
            <input matInput formControlName="accountNumber" [readonly]="readonly">
        </mat-form-field>
    </div>
</form>
