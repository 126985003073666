import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { concat, Observable, of } from 'rxjs';
import { catchError, distinctUntilChanged, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { PersonService } from 'src/app/core/api/person.service';
import { PagePersonLiteView } from 'src/app/core/model/pagePersonLiteView';
import { PersonLiteView } from 'src/app/core/model/personLiteView';
import { PersonSearchCriteria } from 'src/app/core/model/personSearchCriteria';
import { BaseSearchComponent } from '../../base/base-search/base-search.component';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-person-search',
  templateUrl: './person-search.component.html',
  styleUrls: ['./person-search.component.css']
})
export class PersonSearchComponent extends BaseSearchComponent implements OnInit, OnChanges {

  criteria: PersonSearchCriteria = {
    pageSize: 50,
    sortColumn: 'name'
  };

  result$: Observable<PersonLiteView[]>;
  selectedItem: PersonLiteView;

  constructor(
    private personController: PersonService,
    private ref: ChangeDetectorRef,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.id, false)) {
      if (!this.id) {
        this.selectedItem = null;
        this.ref.markForCheck();
        return;
      }
      this.personController.findByIdPersonLite(this.id).pipe(
        takeUntil(this.ngUnsubscribe),
        first()
      ).subscribe( response => {
        this.selectedItem = response;
        this.ref.markForCheck();
      });
    }
  }

  ngOnInit(): void {
    this.search();
  }

  trackByFn(item: PersonLiteView) {
    return item.id;
  }

  onChange() {
    this.idChange.emit(this.selectedItem.id);
  }

  open() {
    this.openWizardInNewWindow('/admin/person-list?open=new');
  }

  private search() {
    this.result$ = concat(
      of([]), // default item
      this.searchInput$.pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged(),
        tap(() => this.loading = true),
        switchMap( (term) => {
          this.criteria.keywords = term;
          return this.personController.searchPersonLite(this.criteria).pipe(
           catchError(() => of([])), // empty list on error
            map((response: PagePersonLiteView) => response.content),
           tap(( items ) => {
             this.loading = false;
             this.nothingFound = (items.length === 0);
           })
          );
        })
      )
    );
  }
}
