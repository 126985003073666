import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { first, takeUntil } from 'rxjs/operators';
import { PartnerRoleForm } from 'src/app/core';
import { AppTypeService } from 'src/app/core/service/app-type.service';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';


@Component({
  selector: 'app-partner-role-form',
  templateUrl: './partner-role-form.component.html',
  styleUrls: ['./partner-role-form.component.css']
})
export class PartnerRoleFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() partnerRoles: PartnerRoleForm[] = [];
  @Input() type: string;
  @Output() partnerRolesChange: EventEmitter<PartnerRoleForm[]> = new EventEmitter<PartnerRoleForm[]>();

  partnerRoleTypes = [];

  constructor(
    private typeService: AppTypeService,
    private fb: UntypedFormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      types: this.fb.control([])
    });

    this.loadTypes();
    this.form.markAllAsTouched();
  }


  ngOnChanges(changes: SimpleChanges): void {
    console.log('PartnerRole ngOnChanges', changes);
    if (this.hasChanged(changes.partnerRoles)) {
        this.applyChanges();
    }
    if (this.hasChanged(changes.types)) {
      this.loadTypes();
    }
  }

  loadTypes() {
    this.typeService.getListRelatedToReltype('partnerrole', this.type).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(result => {
      this.partnerRoleTypes = result;
      console.log('partnerroles', result);
      this.applyChanges();
    }, error => {
      console.log('get partnerRoleTypes - error', error);
    });
  }

  applyChanges() {
    console.log('PartnerRole applyChanges');
    if (this.partnerRoles) {
      this.form.patchValue({ types: this.partnerRoles.map(item => item.type) });
      this.form.markAllAsTouched();
    }
  }

  onChange() {
    console.log('PartnerRole onChange: ', this.form.value.types);
    const currentPartnerRoles = this?.partnerRoles.map(item => item.type);
    const newAccessPartnerRoles = this.form.value.types;

    const added = _.difference(newAccessPartnerRoles, currentPartnerRoles);
    const removed = _.difference(currentPartnerRoles, newAccessPartnerRoles);

    if (added.length === 0
      && removed.lenght === 0) {
        return;
    }

    const modiefiedPartnerRoles = this.partnerRoles.filter(item => !removed.includes(item.type));
    added.forEach(element => {
      const item = {
        type: element
      } as PartnerRoleForm;
      modiefiedPartnerRoles.push(item);
    });

    this.partnerRolesChange.emit(modiefiedPartnerRoles);
  }

}
