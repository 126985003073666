import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { BaseComponent } from 'src/app/shared/base/base/base.component';
import { extractTreatmentFromOrg, extractTreatmentFromPerson } from 'src/app/shared/sunet-util';
import { OrganisationService, PersonService } from '../api/api';
import { ProcessHeaderPartnerForm } from '../model/models';

interface TreatmentWithId {
    newId?: number;
    treatment?: any;
}

interface IdType {
    id?: number;
    person?: boolean;
}


@Injectable({
    providedIn: 'root'
})
export class SunetPreloadService extends BaseComponent {

    constructor(
        private organisationService: OrganisationService,
        private peronService: PersonService
    ) {
        super();
    }


    // tslint:disable-next-line:max-line-length
    processFirstTreatmentPartner(partners: ProcessHeaderPartnerForm[], treatmentId: number): Observable<TreatmentWithId> {
        const idWithType = this.extractFirstTreatmentPartnerId(partners);
        return this.preloadTreatment(idWithType, treatmentId);
    }

    // tslint:disable-next-line:max-line-length
    processFollowupTreatmentPartner(partners: ProcessHeaderPartnerForm[], treatmentId: number): Observable<TreatmentWithId> {
        const idWithType = this.extractFollowTreatmentPartnerId(partners);
        return this.preloadTreatment(idWithType, treatmentId);
    }

    isFormDataUpdateNeeded(previousId: number, newId: number): boolean {
        const changed = previousId !== newId;
        return changed;
    }

    extractFirstTreatmentPartnerId(partners: ProcessHeaderPartnerForm[]): IdType {
        let id = this.extractPartnerId(partners, 'person-1st_treatment');
        if (id) {
            return { id, person: true };
        }
        id = this.extractPartnerId(partners, '1st_treatment');
        if (id) {
            return { id, person: false };
        }
        return null;
    }

    extractFollowTreatmentPartnerId(partners: ProcessHeaderPartnerForm[]): IdType {
        let id = this.extractPartnerId(partners, 'person-follow_up_treatment');
        if (id) {
            return { id, person: true };
        }
        id = this.extractPartnerId(partners, 'follow_up_treatment');
        if (id) {
            return { id, person: false };
        }
        return null;
    }

    extractPartnerId(partners: ProcessHeaderPartnerForm[], relationshipTypePart: string): number {
        const partner = partners.find(item => item.relationshipType.includes(relationshipTypePart));
        if (partner) {
            return partner.partnerId;
        }
        return null;
    }


    preloadTreatment(partnerId: IdType, treatmentId: number): Observable<TreatmentWithId> {
        if (partnerId.person) {
            return this.peronService.findByIdPerson(partnerId.id).pipe(
                first(),
                map((response) => {
                    return {
                        newId: partnerId,
                        treatment: extractTreatmentFromPerson(response, treatmentId)
                    } as TreatmentWithId;
                })
            );
        }
        return this.organisationService.findByIdOrganisation(partnerId.id).pipe(
            first(),
            map((response) => {
                return {
                    newId: partnerId,
                    treatment: extractTreatmentFromOrg(response, treatmentId)
                } as TreatmentWithId;
            })
        );
    }
}

