<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.type</mat-label>
            <input matInput [value]="bankaccount.type | lookupApplType | async" disabled>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.account_type</mat-label>
            <mtx-select [searchable]="true" class="custom" required formControlName="cdAccountType" [appendTo]="'body'" required>
                <mtx-option *ngFor="let accountType of accountTypes$ | async" [value]="accountType.id">
                    {{ accountType | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
    <div class="clr-row" [hidden]="form.value?.cdAccountType !== 'cd_account_type_iban'">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.iban</mat-label>
            <input matInput formControlName="iban" [required]="form.value?.cdAccountType === 'cd_account_type_iban'">
        </mat-form-field>
    </div>
    <div class="clr-row" [hidden]="form.value?.cdAccountType == 'cd_account_type_iban'">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.account_nr</mat-label>
            <input matInput formControlName="accountNr" [required]="form.value?.cdAccountType !== 'cd_account_type_iban'">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.account_owner</mat-label>
            <input matInput formControlName="accountOwner" required>
        </mat-form-field>
    </div>
    <div class="clr-row" [hidden]="form.value?.cdAccountType !== 'cd_account_type_bank'">
        <mat-form-field class="clr-col-md-9">
            <mat-label translate>field.clearing_number</mat-label>
            <input matInput formControlName="clearingNr" [required]="form.value?.cdAccountType == 'cd_account_type_bank'">
        </mat-form-field>
        <mat-form-field class="clr-col-md-3">
            <mat-label translate>field.swift_code</mat-label>
            <input matInput formControlName="swiftCode" required>
        </mat-form-field>
    </div>
    <div class="clr-row" [hidden]="form.value?.cdAccountType !== 'cd_account_type_bank'">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.bank_street</mat-label>
            <input matInput formControlName="bankStreet">
        </mat-form-field>
    </div>
    <div class="clr-row" [hidden]="form.value?.cdAccountType !== 'cd_account_type_bank'">
        <mat-form-field class="clr-col-md-7">
            <mat-label translate>field.bank_postalcode_place</mat-label>
            <input matInput formControlName="bankPostalcodePlace" [required]="form.value?.cdAccountType == 'cd_account_type_bank'">
        </mat-form-field>
        <mat-form-field class="clr-col-md-5">
            <mat-label translate>field.country</mat-label>
            <mtx-select [searchable]="true" class="custom" required formControlName="cdCountry" [appendTo]="'body'" required>
                <mtx-option *ngFor="let country of countries$ | async" [value]="country.id">
                    {{ country | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
</form>