import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-policy-item-list-widget',
  templateUrl: './policy-item-list-widget.component.html',
  styleUrls: ['./policy-item-list-widget.component.scss']
})
export class PolicyItemListWidgetComponent implements OnInit {

  @Input()
  policyItems = [];

  @Input()
  policyType: string;

  constructor() { }

  ngOnInit(): void {
  }

}
