import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';


import { AccessService } from './api/access.service';
import { AccilineService } from './api/acciline.service';
import { CaseService } from './api/case.service';
import { CodeService } from './api/code.service';
import { DocumentService } from './api/document.service';
import { EventService } from './api/event.service';
import { InstantreportService } from './api/instantreport.service';
import { MailTemplateService } from './api/mailTemplate.service';
import { MaintenanceService } from './api/maintenance.service';
import { ObjectService } from './api/object.service';
import { OrgUnitService } from './api/orgUnit.service';
import { OrganisationService } from './api/organisation.service';
import { PartnerService } from './api/partner.service';
import { PdfformService } from './api/pdfform.service';
import { PersonService } from './api/person.service';
import { PolicyService } from './api/policy.service';
import { ProcessHeaderPushControllerService } from './api/processHeaderPushController.service';
import { ProcessheaderService } from './api/processheader.service';
import { ProfileService } from './api/profile.service';
import { ReltypeService } from './api/reltype.service';
import { ReportTemplateService } from './api/reportTemplate.service';
import { RoleService } from './api/role.service';
import { TypeService } from './api/type.service';
import { UiElementTranslationService } from './api/uiElementTranslation.service';
import { UserService } from './api/user.service';
import { WizardhelperService } from './api/wizardhelper.service';
import { WorkflowService } from './api/workflow.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
