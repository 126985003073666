import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { CommAddressForm } from 'src/app/core';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';


@Component({
  selector: 'app-comm-address-form',
  templateUrl: './comm-address-form.component.html',
  styleUrls: ['./comm-address-form.component.css']
})
export class CommAddressFormComponent extends BaseFormComponent implements OnInit, OnChanges {
  @Input() commAddress: CommAddressForm;
  @Output() commAddressValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() commAddressChange: EventEmitter<CommAddressForm> = new EventEmitter<CommAddressForm>();

  commAddressEdit: CommAddressForm;

  constructor(
  ) {
    super();
    this.form = new UntypedFormGroup({
      commAddress: new UntypedFormControl('', [Validators.required]),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe)
    ).subscribe(() => this.onChange());

  }

  ngOnInit(): void {
    if (this.commAddress) {
      this.commAddressEdit = this.commAddress;
      if (this.commAddress?.type.includes('email')) {
        this.form.get('commAddress').addValidators(Validators.email);
      }
      this.form.patchValue(this.commAddress);
    }
    this.form.markAllAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.commAddress)) {
      this.form.reset();
 
      if (this.commAddress?.type.includes('email')) {
        this.form.get('commAddress').addValidators(Validators.email);
      }
      this.commAddressEdit = this.commAddress;
      this.form.patchValue(this.commAddress);
      this.form.markAllAsTouched();
    }
  }

  onChange(): void {
    console.log('onChange', this.form.valid);
    if (this.form.valid) {
      const commAddressModified = _.extend(this.commAddressEdit, this.form.value);
      this.commAddressChange.emit(commAddressModified);
    }
    this.commAddressValid.emit(this.form.valid);
  }
}