<form clrForm [formGroup]="form" clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.name</mat-label>
            <input matInput formControlName="name" [required]="required || form.value.name?.length > 0" maxlength="50">
            <mat-error *ngIf="form.get('name')?.hasError('maxlength')" translate [translateParams]="{ value1: form.get('name')?.errors?.maxlength.actualLength - form.get('name')?.errors?.maxlength.requiredLength}">
                error.value_to_long
            </mat-error>
        </mat-form-field>
        <mat-form-field class="clr-col-md-6">
            <mat-label translate>field.title</mat-label>
            <input matInput formControlName="title">
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-12">
            <mat-label translate>field.street</mat-label>
            <input matInput formControlName="street" [required]="required || form.value.name?.length > 0" maxlength="50">
            <mat-error *ngIf="form.get('street')?.hasError('maxlength')" translate [translateParams]="{ value1: form.get('street')?.errors?.maxlength.actualLength - form.get('street')?.errors?.maxlength.requiredLength}">
                error.value_to_long
            </mat-error>
        </mat-form-field>
    </div>
    <div class="clr-row">
        <mat-form-field class="clr-col-md-2">
            <mat-label translate>field.post_code</mat-label>
            <input matInput type="number" formControlName="postCode" [required]="required || form.value.name?.length > 0" maxlength="10">
        </mat-form-field>
        <mat-form-field class="clr-col-md-5">
            <mat-label translate>field.city</mat-label>
            <input matInput formControlName="city" [required]="required || form.value.name?.length > 0" maxlength="50">
            <mat-error *ngIf="form.get('city')?.hasError('maxlength')" translate
                [translateParams]="{ value1: form.get('city')?.errors?.maxlength.actualLength - form.get('city')?.errors?.maxlength.requiredLength}">
                error.value_to_long
            </mat-error>
        </mat-form-field>
        <mat-form-field class="clr-col-md-5">
            <mat-label translate>field.country</mat-label>
            <mtx-select [searchable]="true" class="custom" formControlName="cdCountry" [appendTo]="'body'" [required]="required || form.value.name?.length > 0">
                <mtx-option *ngFor="let country of countries$ | async" [value]="country.id">
                    {{ country | language:'name' }}</mtx-option>
            </mtx-select>
        </mat-form-field>
    </div>
</form>
