import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PartnerIdentifierForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';


@Component({
  selector: 'app-identifier-editor',
  templateUrl: './identifier-editor.component.html',
  styleUrls: ['./identifier-editor.component.css']
})
export class IdentifierEditorComponent extends BaseEditorComponent<PartnerIdentifierForm> implements OnChanges {

  @Input() type: string;
  @Input() identifiers: PartnerIdentifierForm[];
  @Input() selectable = false;
  @Output() identifiersValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() identifiersChange: EventEmitter<PartnerIdentifierForm[]> = new EventEmitter<PartnerIdentifierForm[]>();

  tableName = 'partnerid';
  relTypes: any[];
  relTypeSelected: any;

  constructor(
    protected reltypeService: RelationshipTypeService,
  ) {
    super(reltypeService);
  }

  protected buildFilter(): any {
    const filter = { typeLeft: this.type };
    return filter;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.identifiers)
      && !this.wrappedItems) {
        this.processObjects();
    }
    if (this.hasChanged(changes.type)) {
        this.loadRelationships();
    }
  }

  protected processObjects() {
    const wrappers = [];

    if (!this.relTypes
      || !this.identifiers) {
      return;
    }

    this.identifiers.forEach((ident) => {
      const relType = this.relTypes.find(item => item.typeRight === ident.type);
      wrappers.push({
        item: ident,
        valid: true,
        sort: relType?.sort,
        required: relType?.cardinalityLtr > 0
      });
    });

    if (!this.selectable) {
      const typeRights = _.map(wrappers, wrappedItem => wrappedItem.item.type);
      this.relTypes.forEach(relType => {
        if (!typeRights.includes(relType.typeRight)) {
          const required = relType?.cardinalityLtr > 0;
          wrappers.push({
            item: {
              type: relType.typeRight
            },
            valid: !required,
            sort: relType.sort,
            required
          } as ItemWrapper<PartnerIdentifierForm>);
        }
      });
    }

    wrappers.sort((e1, e2) => e1?.sort - e2.sort);
    this.wrappedItems = wrappers;
  }

  onAdd() {
    const required = this.relTypeSelected?.cardinalityLtr > 0;
    this.wrappedItems.push({
      item: {
        type: this.relTypeSelected.typeRight
      } as PartnerIdentifierForm,
      valid: false,
      sort: this.relTypeSelected.sort,
      required: true
    } as ItemWrapper<PartnerIdentifierForm>);

    this.identifiersValid.emit(false);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);

    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.identifiersChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.identifiersValid.emit(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => item.valid === false).length === 0);
    return result;
  }
}

