import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { CommAddressForm, RelationshipTypeForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';

@Component({
  selector: 'app-comm-address-editor',
  templateUrl: './comm-address-editor.component.html',
  styleUrls: ['./comm-address-editor.component.css']
})
export class CommAddressEditorComponent extends BaseEditorComponent<CommAddressForm> implements OnChanges {

  @Input() commAddresses: CommAddressForm[];
  @Input() type: string;
  @Output() commAddressesChange: EventEmitter<CommAddressForm[]> = new EventEmitter<CommAddressForm[]>();

  tableName = 'commaddress';

  constructor(
    protected reltypeService: RelationshipTypeService,
  ) {
    super(reltypeService);
    if (this.type) {
      this.loadRelationships();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.commAddresses)
      && !this.wrappedItems) {
      this.processObjects();
    }
    if (this.hasChanged(changes.type)) {
      this.loadRelationships();
    }
  }

  protected buildFilter(): any {
    return {
      typeRight: this.type
    };
  }

  protected processObjects() {
    if (!this.relTypes
      || !this.commAddresses) {
      return;
    }

    const wrappers = [];
    this.commAddresses.forEach((comadr: CommAddressForm) => {
      const relType = _.find(this.relTypes, (item => item.id === comadr.relationshipType));
      const required = relType?.ltrMinCardinality > 0
        && wrappers.filter(item => item.relType === relType).length < relType?.ltrMinCardinality;

      wrappers.push({
        item: comadr,
        valid: true,
        relType,
        required
      } as ItemWrapper<CommAddressForm>);
    });
    wrappers.sort((e1, e2) => e1?.relType?.sort - e2?.relType?.sort);
    this.wrappedItems = wrappers;

    this.calculateFiltered();
  }

  onAdd() {
    const newItem = this.buildWrapperItem(this.selectedRelType, false, false);
    this.wrappedItems.push(newItem);
    this.calculateFiltered();
    this.handleRelTypeSelection();

    this.valid.emit(newItem.valid);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);
    this.calculateFiltered();

    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.commAddressesChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.emitValid(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => item.valid === false).length === 0);
    return result;
  }

  private buildWrapperItem(relType: RelationshipTypeForm, valid: boolean, required?: boolean): ItemWrapper<CommAddressForm> {
    const item = {
      item: {
        relationshipType: relType.id,
        type: relType.typeLeft
      } as CommAddressForm,
      relType,
      valid,
      required
    } as ItemWrapper<CommAddressForm>;

    return item;
  }
}
