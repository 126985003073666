<mat-form-field class="clr-row clr-col-12">
    <mat-label translate>{{ fieldName }}</mat-label>
    <mtx-select [items]="result$ | async" bindLabel="search" [multiple]="false" [minTermLength]="2" [loading]="loading" clearable="allowClear"
         [typeToSearchText]="'message.enter_2_or_more_characters' | translate" [typeahead]="searchInput$" [(ngModel)]="selectedItem"
        [appendTo]="'body'" (change)="onChange()">
        <ng-template ng-label-tmp let-item="item">
            {{ item.referenceNumber }} ({{ item.registrationNr }} {{ item.type | lookupApplType | async }} - {{ item.name }})
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            {{ item.referenceNumber }} ({{ item.registrationNr }} {{ item.type | lookupApplType | async }} - {{ item.name }})
        </ng-template>
        <ng-template ng-footer-tmp *ngIf="nothingFound">
            <button class="btn btn-link" (click)="open()" translate>button.create_object</button>
        </ng-template>
    </mtx-select>
</mat-form-field>