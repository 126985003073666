import { Component, OnInit } from '@angular/core';
import { CasesWidgetMode } from './widget/cases-widget/cases-widget.component';
import { ProcessesWidgetMode } from './widget/processes-widget/processes-widget.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  readonly casesWidgetMode = CasesWidgetMode;
  readonly processesWidgetMode = ProcessesWidgetMode;

  constructor() { }

  ngOnInit(): void {
  }

}
