/**
 * myclaims API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface PolicyItemForm { 
    id?: number;
    type?: string;
    position?: number;
    title?: string;
    cdStatus?: string;
    policyHeaderId?: number;
    amountNet?: number;
    amountGross?: number;
    minSalary?: number;
    maxSalary?: number;
    coverRate?: number;
    minDegreeOfIncapicity?: number;
    waitingPeriod?: number;
    coveragePeriod?: number;
    cdEmployeeGroup?: string;
    withBasicSalary?: boolean;
    withFamiliyBenefits?: boolean;
    withHolidayCompensation?: boolean;
    deleted?: boolean;
}

