import { Pipe, PipeTransform } from '@angular/core';
import { ApplTextForm } from '../model/applTextForm';
import { LanguageService } from '../service/language.service';

@Pipe({ name: 'languageApplText' })
export class LanguageApplTextPipe implements PipeTransform {

  constructor(private languageService: LanguageService) {
  }

  transform(applText: ApplTextForm, attributeName?: string): string {
    const attributeNameValue = attributeName ? attributeName : 'textLong';
    return this.languageService.getLanguageSpecifcValueFromApplText(applText, attributeNameValue);
  }
}