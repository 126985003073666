
import { DocumentRefForm } from '../core/model/documentRefForm';


export function sortDocuments(documents: DocumentRefForm[], ascendingOrder = false): DocumentRefForm[] {
    if (documents) {
        const order = ascendingOrder ? 1 : -1;
        documents.sort((a: DocumentRefForm, b: DocumentRefForm) => {
            return order * (new Date(a.document.createdDate).getTime() - new Date(b.document.createdDate).getTime());
        });
    }

    return documents;
}
