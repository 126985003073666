import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { first, takeUntil } from 'rxjs/operators';
import { PdfformService } from 'src/app/core';
import { BaseFormComponent } from '../../base/base-form/base-form.component';



@Component({
  selector: 'app-pdf-form',
  templateUrl: './pdf-form.component.html',
  styleUrls: ['./pdf-form.component.scss']
})
export class PdfFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() id: number;
  @Input() type: string;
  @Input() formData: { [fieldName: string]: string | string[] | number | boolean };
  @Output() formDataChange: EventEmitter<any> = new EventEmitter<any>();

  content_: {[fieldName: string]: string | string[] | number | boolean} = {};
  draw = true;

  data: Blob;

  constructor(
    private pdfFormService: PdfformService
  ) {
    super();
  }

  ngOnInit(): void {
    this.load();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.type)) {
      this.load();
    }

    if (this.hasChanged(changes.formData)) {
      console.log('formData changed', this.formData);
      this.content = this.formData;
    }
  }

  public get content(): {[fieldName: string]: string | string[] | number | boolean} {
    return this.content_;
  }

  public set content(content: { [fieldName: string]: string | string[] | number | boolean }) {
    this.content_ = content;
  }

  onChange() {
    this.formDataChange.emit(this.content);
  }

  load() {
    if (!this.type) {
      return;
    }
    this.pdfFormService.load(this.type, 'body', true, { httpHeaderAccept: "*/*" }).pipe(
      takeUntil(this.ngUnsubscribe),
      first()
    ).subscribe(
      (response: Blob) => {
        console.log('success download', response);
        this.data = response;
      },
      (error) => {
        console.log('error download', error);
      }
    );
  }
}
