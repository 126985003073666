<div clrLayout="vertical">
    <div class="clr-row">
        <mat-form-field class="clr-col-md-6" *ngIf="filteredRelTypes?.length > 0">
            <mat-label translate>field.relationship</mat-label>
            <mtx-select [searchable]="true" [(ngModel)]="selectedRelType" [multiple]="false">
                <mtx-option *ngFor="let filteredRelType of filteredRelTypes" [value]="filteredRelType">
                    {{ filteredRelType.applText | languageApplText: 'rtlTextLong' }} ({{ filteredRelType.typeLeft | lookupApplType | async }})</mtx-option>
            </mtx-select>
        </mat-form-field>
        <div class="clr-col-md-2">
            <button class="btn btn-sm" (click)="onAdd()" [disabled]="!selectedRelType || !editorValid">{{ 'button.add_relationship' | translate }}</button>
        </div>
    </div>
    <div class="clr-row" *ngFor="let wrappedItem of wrappedItems, index as i">
        <div class="clr-col-md-11">
            <app-comm-address-form [(commAddress)]="wrappedItem.item" (commAddressValid)="onChange(i, $event)">
            </app-comm-address-form>
        </div>
        <div class="clr-col-md-1">
            <span *appDeleteAllowed="wrappedItem?.item?.relationshipType" (click)="onDelete(i)">
                <clr-icon shape="trash"></clr-icon>
            </span>
        </div>
    </div>
</div>

