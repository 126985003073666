import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../core/service/authentication.service';

@Component({
  selector: 'app-entry',
  templateUrl: './entry.component.html',
  styleUrls: ['./entry.component.css']
})
export class EntryComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
  ) {
  }
  ngOnInit(): void {
    const returnUrl = this.route.snapshot.queryParams['returnUrl'];
    console.log('returnUrl', returnUrl);
    if (returnUrl) {
      this.authenticationService.login(returnUrl);
    }
  }

}
