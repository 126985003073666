<div class="alert alert-app-level alert-danger" style="margin-bottom:0px" role="alert" hidden>
  <div class="alert-items">
    <div class="alert-item static">
      <div class="alert-icon-wrapper">
        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
      </div>
      <div class="alert-text">
        Alert Type: Danger
      </div>
      <div class="alert-actions">
        <button class="btn alert-action">Action</button>
      </div>
    </div>
  </div>
  <button type="button" class="close" aria-label="Close">
    <clr-icon aria-hidden="true" shape="close"></clr-icon>
  </button>
</div>
<block-ui message="Default Message">
</block-ui>
<simple-notifications></simple-notifications>
<clr-main-container>
  <app-top-navigation></app-top-navigation>
  <router-outlet></router-outlet>
  <div #generic_editor></div>
</clr-main-container>
