<clr-datagrid (clrDgRefresh)="refresh($event)" [clrDgLoading]="loading">
    <clr-dg-column [clrDgSortBy]="'applType.sort'">{{ 'field.type' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'referenceNumber'">{{ 'field.reference_nr' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.title' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.amount_net' | translate }}
    </clr-dg-column>
    <clr-dg-column>{{ 'field.event_emission_date' | translate }}
    </clr-dg-column>
    <clr-dg-column [clrDgSortBy]="'codeStatus.sort'">{{ 'field.status' | translate }}
    </clr-dg-column>
    <clr-dg-column *ngIf="!hideCaseColumn" [clrDgSortBy]="'applCase.referenceNumber'">{{ 'field.case_reference' | translate }}
    </clr-dg-column>

    <clr-dg-row *ngFor="let ph of pagedItems?.content" [clrDgItem]="ph">
        <clr-dg-cell>{{ ph.type | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell><a [routerLink]="['/processes/detail', ph.id]">{{ ph.reference }}</a></clr-dg-cell>
        <clr-dg-cell>{{ ph.title }}</clr-dg-cell>
        <clr-dg-cell>{{ ph.amountNet }}</clr-dg-cell>
        <clr-dg-cell>{{ ph.processHeaderDate | date:'dd.MM.yyyy HH:mm' }}</clr-dg-cell>
        <clr-dg-cell>{{ ph.cdStatus | lookupApplCode | async }}</clr-dg-cell>
        <clr-dg-cell *ngIf="!hideCaseColumn"><a *ngIf="ph.caseId" [routerLink]="['/cases/detail', ph.caseId]">{{ ph.caseReferenceNumber? ph.caseReferenceNumber:'-' }}</a></clr-dg-cell>
        <clr-dg-row-detail *clrIfExpanded>
            {{ ph.description }}
        </clr-dg-row-detail>
    </clr-dg-row>
    <clr-dg-footer>
        <clr-dg-pagination #pagination [(clrDgPageSize)]="criteria.pageSize" [clrDgLastPage]="pagedItems.totalPages"
            [clrDgTotalItems]="pagedItems.totalElements">
            <clr-dg-page-size [clrPageSizeOptions]="[10, 20, 50, 100]">{{ 'message.rows_per_page' | translate }}
            </clr-dg-page-size>
            {{ 'message.total_items' | translate: {begin: pagination.firstItem + 1, end: pagination.lastItem + 1, total: pagination.totalItems} }}
        </clr-dg-pagination>
    </clr-dg-footer>
</clr-datagrid>
