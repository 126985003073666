import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.css']
})
export class DocumentViewerComponent implements OnInit, OnChanges {

  @Input() data: Blob;
  @Input() name: string;
  @Input() mimeType: string;

  downloadURL: string;
  src: ArrayBuffer;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.downloadURL = URL.createObjectURL(changes.data.currentValue);

      new Response(changes.data.currentValue).arrayBuffer().then(value => {
        this.src = value;
      });
    }
  }

  onDownload() {
    FileSaver.saveAs(this.data, this.name);
  }

  onOpenInWindow() {
    window.open(this.downloadURL);
  }
}
