import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PolicyHeaderObjectForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';


@Component({
  selector: 'app-policy-header-object-editor',
  templateUrl: './policy-header-object-editor.component.html',
  styleUrls: ['./policy-header-object-editor.component.css']
})
export class PolicyHeaderObjectEditorComponent extends BaseEditorComponent<PolicyHeaderObjectForm> implements OnInit, OnChanges {
  @Input() policyHeaderObjects: PolicyHeaderObjectForm[];
  @Input() typeLeft: string;
  @Input() typeRight: string;
  @Output() policyHeaderObjectsChange: EventEmitter<PolicyHeaderObjectForm[]> = new EventEmitter<PolicyHeaderObjectForm[]>();

  tableName = 'policyh_applobject';

  translations = [];

  constructor(
    private translateService: TranslateService,
    protected reltypeService: RelationshipTypeService,
  ) {
    super(reltypeService);
  }

  ngOnInit(): void {
    this.translations = this.translateService.instant(['button.add_relationship']);
  }

  protected buildFilter(): any {
    const filter = {};
    if (this.typeLeft) {
      filter['typeLeft'] = this.typeLeft;
    }
    if (this.typeRight) {
      filter['typeRight'] = this.typeRight;
    }

    return filter;
  }

  protected processObjects() {
    if (!this.relTypes
      || !this.policyHeaderObjects) {
      return;
    }

    const wrappers = [];
    this.policyHeaderObjects.forEach((policyHeaderObject: PolicyHeaderObjectForm) => {
      wrappers.push({
        item: policyHeaderObject,
        valid: true,
        relType: _.find(this.relTypes, (relType => relType.id === policyHeaderObject.relationshipType))
      } as ItemWrapper<PolicyHeaderObjectForm>);
    });

    wrappers.sort((e1, e2) => e1?.relType.sort - e2?.relType?.sort);
    this.wrappedItems = wrappers;
    this.calculateFiltered();
  }

  protected calculateFiltered() {
    this.countByType = _.countBy(this.wrappedItems, (item: ItemWrapper<PolicyHeaderObjectForm>) => item.item.relationshipType);
    // tslint:disable-next-line:max-line-length
    this.filteredRelTypes = this.relTypes.filter(item => (this.countByType[item.id] == null || this.countByType[item.id] < item.rtlMaxCardinality));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.policyHeaderObjects)
      && !this.wrappedItems) {
      this.processObjects();
    }
    if (this.hasChanged(changes.typeLeft)
      || this.hasChanged(changes.typeRight)) {
      console.log('type left/right', this.typeLeft, this.typeRight);
      this.loadRelationships();
    }
  }

  onAdd() {
    this.wrappedItems.push({
      item: {
        relationshipType: this.selectedRelType.id
      } as PolicyHeaderObjectForm,
      relType: this.selectedRelType,
      valid: false
    } as ItemWrapper<PolicyHeaderObjectForm>);

    this.calculateFiltered();
    this.handleRelTypeSelection();


    this.valid.emit(false);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);
    this.calculateFiltered();

    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    console.log('onchange', index, valid);
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.policyHeaderObjectsChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.valid.emit(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => !item.valid).length === 0);
    return result;
  }
}
