<clr-datagrid [(clrDgSingleSelected)]="selected" [clrDgRowSelection]="true">
    <clr-dg-column>{{ 'field.type' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.name' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.created_date' | translate }}</clr-dg-column>
    <clr-dg-column>{{ 'field.receipt_date' | translate }}</clr-dg-column>

    <clr-dg-row *clrDgItems="let docRef of documents" [clrDgItem]="docRef">
        <clr-dg-cell>{{ docRef.document.type | lookupApplType | async }}</clr-dg-cell>
        <clr-dg-cell>{{ docRef.document.name }}</clr-dg-cell>
        <clr-dg-cell>{{ docRef.document.createdDate | date:'dd.MM.yyyy HH:mm' }}</clr-dg-cell>
        <clr-dg-cell>{{ docRef.document.receiptDate | date:'dd.MM.yyyy' }}</clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>
<button [disabled]="!selected" (click)="onDownload()" class="btn btn-sm" translate>button.download</button>
<button [disabled]="!selected?.document?.mimeType.endsWith('pdf')" (click)="openViewer()" class="btn btn-sm" translate>button.view</button>