import {registerLocaleData} from '@angular/common';
import localeEnGB from '@angular/common/locales/en-GB';
import {AfterViewInit, Component, OnInit, ViewChild, ViewContainerRef, ViewEncapsulation} from '@angular/core';
import {DynamicComponentService} from './core/service/dynamic-component.service';
import {LanguageService} from './core/service/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'myClaims';

  @ViewChild('generic_editor', { read: ViewContainerRef })
  target: ViewContainerRef;

  constructor(
    private languageService: LanguageService,
    private dynamicComponentService: DynamicComponentService,
  ) {
    this.languageService.initializeLanguage();
    registerLocaleData(localeEnGB);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.dynamicComponentService.registerViewContainerRef(this.target);
  }
}
