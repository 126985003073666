import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { PartnerPartnerForm } from 'src/app/core';
import { BaseFormComponent } from '../../base/base-form/base-form.component';


@Component({
  selector: 'app-partner-partner-form',
  templateUrl: './partner-partner-form.component.html',
  styleUrls: ['./partner-partner-form.component.css']
})
export class PartnerPartnerFormComponent extends BaseFormComponent implements OnChanges {

  @Input() partnerPartner: PartnerPartnerForm;
  @Input() type: string;
  @Input() mode = 'ltr';
  @Output() partnerPartnerValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() partnerPartnerChange: EventEmitter<PartnerPartnerForm> = new EventEmitter<PartnerPartnerForm>();

  partnerPartnerEdit: PartnerPartnerForm;
  partnerId: number;

  constructor() {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.partnerPartner)) {
      this.partnerPartnerEdit = changes.partnerPartner.currentValue;
    }
  }

  onChange(): void {
    const valid = (this.partnerPartner.partnerIdRight != null && this.mode === 'ltr')
      || (this.partnerPartner.partnerIdLeft != null && this.mode === 'rtl');
    if (valid) {
      this.partnerPartnerChange.emit(this.partnerPartnerEdit);
    }
    this.partnerPartnerValid.emit(valid);
  }
}
