import { Injectable } from '@angular/core';
import { AccessRight } from '..';
import { AuthenticationService } from './authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthorizationService {

  constructor(
    private authenticationService: AuthenticationService
  ) {
  }

  public hasCdFunctionality(cdFunctionality: string): boolean {
    let allowed = false;
    const accessRights = this.authenticationService.authenticationInfoValue?.accessRightsByPartnerId['-1'];

    allowed = accessRights?.filter(item => item.cdFunctionality === cdFunctionality && item.showAllewed)?.length > 0 || false;
    return allowed;
  }

  public showSalary(): boolean {
    return this.hasCdFunctionality('cd_functionality_salary');
  }

  public showObjects(): boolean {
    return this.hasCdFunctionality('cd_functionality_objects');
  }

  public isCreateAllowed(type: string): boolean {
    let allowed = false;
    if (!type) {
      return false;
    }
    const accessRights = this.authenticationService.authenticationInfoValue?.accessRightsByPartnerId['-1'];

    allowed = accessRights?.filter(item => this.match(type, item) && item.createAllowed)?.length > 0 || false;
    return allowed;
  }

  public isEditAllowed(type: string): boolean {
    let allowed = false;
    if (!type) {
      return false;
    }
    const accessRights = this.authenticationService.authenticationInfoValue?.accessRightsByPartnerId['-1'];

    allowed = accessRights?.filter(item => this.match(type, item) && item.updateAllowed)?.length > 0 || false;
    return allowed;
  }

  public isDeleteAllowed(type: string): boolean {
    let allowed = false;
    if (!type) {
      return false;
    }
    const accessRights = this.authenticationService.authenticationInfoValue?.accessRightsByPartnerId['-1'];

    allowed = accessRights?.filter(item => this.match(type, item) && item.deleteAllowed)?.length > 0 || false;
    return allowed;
  }

  private match(type: string, accessRight: AccessRight): boolean {
    return accessRight.type === type
      || (accessRight.typeLike?.length > 0 && type.startsWith(accessRight.typeLike.replace('%', '')));
  }
}
