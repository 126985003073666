import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { take, takeUntil } from 'rxjs/operators';
import { WorkflowInstanceForm, WorkflowService } from 'src/app/core';
import { BaseComponent } from '../../base/base/base.component';

@Component({
  selector: 'app-workflow-list-widget',
  templateUrl: './workflow-list-widget.component.html',
  styleUrls: ['./workflow-list-widget.component.css']
})
export class WorkflowListWidgetComponent extends BaseComponent implements OnChanges {

  @Input()
  caseId: number;

  rows: WorkflowInstanceForm[] = [];

  constructor(
    private workflowService: WorkflowService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes?.caseId)) {
      this.load();
    }
  }

  load(): void {
    this.workflowService.findAllForCase(this.caseId).pipe(
      takeUntil(this.ngUnsubscribe),
      take(1)
    ).subscribe(data => {
      this.rows = data;
      this.rows.sort((e1, e2) => e1?.sort - e2?.sort);
    });
  }

}
