import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { EmployerAdditionalInfo } from 'src/app/core';
import * as _ from 'underscore';
import { BaseFormComponent } from '../../base/base-form/base-form.component';

@Component({
  selector: 'app-sunet-employer-form',
  templateUrl: './sunet-employer-form.component.html',
  styleUrls: ['./sunet-employer-form.component.css']
})
export class SunetEmployerFormComponent extends BaseFormComponent implements OnInit, OnChanges {

  @Input() employer: EmployerAdditionalInfo;
  @Output() employerChange: EventEmitter<EmployerAdditionalInfo> = new EventEmitter<EmployerAdditionalInfo>();
  @Output() employerValid: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder
  ) {
    super();
    this.form = this.formBuilder.group({
      organisationName: new UntypedFormControl(null, [Validators.required]),
      policyNumber: new UntypedFormControl(null, [Validators.required]),
      contactPersonName: new UntypedFormControl(null, [Validators.required]),
      contactPersonEmail: new UntypedFormControl(null, [Validators.required, Validators.email]),
      contactPersonPhone: new UntypedFormControl(null, [Validators.required]),
      contactPersonFax: new UntypedFormControl(),
      workingHoursPerWeek: new UntypedFormControl(null, [Validators.required]),
      workingDaysPerWeek: new UntypedFormControl(null, [Validators.required, Validators.pattern(/^[0-9]{1}(.[0-9]{1})?$/)]),
    });

    this.form.valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(500)
    ).subscribe(() => this.onChange());
  }

  ngOnInit(): void {
    if (this.disabled) {
      this.form.disable();
    }
    this.form.markAllAsTouched();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.patchValue(this.employer);
    this.form.markAllAsTouched();
  }

  onChange(): void {
    const modified = _.extend(this.employer || {}, this.form.value);
    this.employerChange.emit(modified);
    this.employerValid.emit(this.form.valid);
  }
}

