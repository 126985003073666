import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { BankAccountForm } from 'src/app/core';
import { RelationshipTypeService } from 'src/app/core/service/relationship-type.service';
import * as _ from 'underscore';
import { BaseEditorComponent, ItemWrapper } from '../../base/base-editor/base-editor.component';

@Component({
  selector: 'app-bankaccount-editor',
  templateUrl: './bankaccount-editor.component.html',
  styleUrls: ['./bankaccount-editor.component.scss']
})
export class BankaccountEditorComponent extends BaseEditorComponent<BankAccountForm> implements OnInit, OnChanges {

  @Input() bankAccounts: BankAccountForm[];
  @Input() type: string;
  @Output() bankAccountsChange: EventEmitter<BankAccountForm[]> = new EventEmitter<BankAccountForm[]>();

  tableName = 'bankaccount';

  selectedType: any;

  constructor(
    protected relationshipTypeService: RelationshipTypeService,
  ) {
    super(relationshipTypeService);
  }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.hasChanged(changes.bankAccounts)
      && !this.wrappedItems) {
      this.processObjects();
    }
    if (this.hasChanged(changes.type)) {
      this.loadRelationships();
    }
  }

  protected buildFilter() {
    return { typeLeft: this.type };
  }

  protected processObjects() {
    const wrappers = [];

    if (!this.relTypes
      || !this.bankAccounts) {
      return;
    }

    this.bankAccounts.forEach((bankAccount) => {
      wrappers.push({
        item: bankAccount,
        valid: true,
        open: !bankAccount.id,
      });
    });

    this.wrappedItems = wrappers;
  }

  onAdd() {
    this.wrappedItems.push({
      item: {
        type: this.selectedType.typeRight,
        relationshipType: this.selectedType.id
      } as BankAccountForm
      , valid: false,
      open: true
    } as ItemWrapper<BankAccountForm>);

    this.valid.emit(false);
  }

  onDelete(index: number) {
    this.wrappedItems.splice(index, 1);
    this.emit();
  }

  onChange(index: number, valid: boolean): void {
    console.log('onChange', index, valid);
    this.wrappedItems[index].valid = valid;
    this.emit();
  }

  emit() {
    const allValid = this.checkValidity();
    if (allValid) {
      this.bankAccountsChange.emit(_.pluck(this.wrappedItems, 'item'));
    }
    this.valid.emit(allValid);
  }

  checkValidity(): boolean {
    const result = (this.wrappedItems.filter(item => item.valid === false).length === 0);
    return result;
  }
}
